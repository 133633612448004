import {SessionReducerTypes, validSessionReducerCases} from '../Reducers/ReducerCases';

export interface SessionAction {
  type: SessionReducerTypes;
}

export interface InitializingSessionAction extends SessionAction {
  type: 'SET INITIALISER';
  payload: Promise<string>;
}

export interface ClearInitializingSessionAction extends SessionAction {
  type: 'CLEAR INITIALISER';
}

export interface SetSessionIdAction extends SessionAction {
  type: 'SET SESSION ID';
  payload: string;
}

export interface SetSessionIntervalAction extends SessionAction {
  type: 'SET SESSION INTERVAL';
  payload: NodeJS.Timeout;
}

export interface ClearSessionIntervalAction extends SessionAction {
  type: 'CLEAR SESSION INTERVAL';
}

export type SessionActions = InitializingSessionAction | SetSessionIdAction | SetSessionIntervalAction | ClearSessionIntervalAction | ClearInitializingSessionAction;

export function isSessionAction(action: unknown): action is SessionAction {
  const sessionAction = action as SessionAction;

  if (!sessionAction) {
    return false;
  }

  return validSessionReducerCases.includes(sessionAction.type);
}
