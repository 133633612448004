import {ModeType, ThemeVariants} from '../types';

export const getActiveMode = (brand: ThemeVariants, mode: ModeType) => {
  if (typeof mode === 'string') {
    return mode;
  } else if (typeof mode === 'object' && mode[brand]) {
    return mode[brand] as string;
  } else if (typeof mode === 'object' && mode['rest']) {
    return mode['rest'];
  } else {
    return undefined;
  }
};
