import React from 'react';
import {IconProps} from '@nib/icons';
import {BreakpointType} from '@nib-components/theme';
import {Hidden} from '@nib/layout';
import {CollapsedProgressStepper} from './CollapsedProgressStepper';
import {DesktopProgressStepper} from './DesktopProgressStepper';
export type StepType = {
  title: string;
  icon?: React.FC<IconProps>;
  href?: string;
  isAccessed: boolean;
  isDisabled: boolean;
  isComplete: boolean;
  component?: any; // eslint-disable-line  @typescript-eslint/no-explicit-any
};

export interface ProgressStepperProps {
  steps: Array<StepType>;
  align?: 'stacked' | 'inline';
  fill?: boolean;
  showIcons?: boolean;
  collapseBelow?: BreakpointType;
  stepWidth?: string;
  currentStep: number;
  goToStep?: (index: number) => void;
  isGated?: boolean;
}

export const ProgressStepper: React.FC<ProgressStepperProps> = props => {
  const {steps, align = 'inline', showIcons = true, fill = true, collapseBelow, stepWidth, currentStep, isGated = true, goToStep} = props;

  if (!collapseBelow) {
    return <DesktopProgressStepper align={align} showIcons={showIcons} fill={fill} stepWidth={stepWidth} isGated={isGated} {...props} />;
  }

  return (
    <>
      <Hidden below={collapseBelow}>
        <DesktopProgressStepper align={align} showIcons={showIcons} fill={fill} stepWidth={stepWidth} isGated={isGated} {...props} />
      </Hidden>

      <Hidden above={collapseBelow}>
        <CollapsedProgressStepper
          currentStep={currentStep}
          steps={steps}
          showIcons={showIcons}
          goToStep={goToStep}
          hasAccessedPrevious={currentStep >= 1 && steps[currentStep - 1].isAccessed}
          hasAccessedNext={currentStep < steps.length - 1 && steps[currentStep].isAccessed}
        />
      </Hidden>
    </>
  );
};
