// Works like processFileDrop. Only difference is the files param it receives.
import {AddFilesOnParentCallback, FileProgressEnum, FileState, NewFile} from '../Interfaces';

type MimeAcceptedType = {
  format: 'mime';
  value: string;
  isWildcard: boolean;
};

type ExtAcceptedType = {
  format: 'ext';
  value: string;
};

export type AcceptedFileType = MimeAcceptedType | ExtAcceptedType;

export const parseAllowedTypes = (acceptedFileTypes: string): AcceptedFileType[] => {
  return acceptedFileTypes.split(',').reduce((allMimeTypes, rawType) => {
    const type = rawType.trim().toLowerCase();

    const isExtension = type.startsWith('.');
    const isMimeType = type.includes('/');

    if (isExtension) {
      allMimeTypes.push({
        format: 'ext',
        value: type
      });
    } else if (isMimeType) {
      const isWildCard = type.endsWith('*');
      allMimeTypes.push({
        format: 'mime',
        value: isWildCard ? type.replace('*', '') : type,
        isWildcard: isWildCard
      });
    }

    return allMimeTypes;
  }, [] as AcceptedFileType[]);
};

export const isFileTypeAllowed = (file: File, acceptedFileTypes: AcceptedFileType[]): boolean => {
  const lowerFilename = file.name?.toLowerCase();
  const lowerMimeType = file.type?.toLowerCase();

  return acceptedFileTypes.some(allowedType => {
    const isExtensionMatch = allowedType.format === 'ext' && lowerFilename.endsWith(allowedType.value);
    const isMimeTypeMatch = allowedType.format === 'mime' && lowerMimeType === allowedType.value;
    const isMimeTypeWildMatch = allowedType.format === 'mime' && allowedType.isWildcard && lowerMimeType.startsWith(allowedType.value);

    return isExtensionMatch || isMimeTypeMatch || isMimeTypeWildMatch;
  });
};

export const getFileSizeInMB = (file: File) => file.size / (1024 * 1024);

export const isFileSizeLimitExceeded = (file: File, maxFileSizeInMB: number) => {
  const fileSizeInMB = getFileSizeInMB(file);

  return fileSizeInMB > maxFileSizeInMB;
};

type IsCombinedFileSizeLimitExceededParamsType = {
  currentFiles: FileState[];
  filesSelected: NewFile[];
  newFile: File;
  maxCombinedFileSizeLimitInMB: number;
};

export const isCombinedFileSizeLimitExceeded = ({currentFiles, filesSelected, newFile, maxCombinedFileSizeLimitInMB}: IsCombinedFileSizeLimitExceededParamsType) => {
  const totalFileSizeUploadedSoFar = [...currentFiles.map(fileUpload => fileUpload.file), ...filesSelected.map(selectedFile => selectedFile.file)].reduce(
    (totalSize, file) => totalSize + getFileSizeInMB(file),
    0
  );

  return totalFileSizeUploadedSoFar + getFileSizeInMB(newFile) > maxCombinedFileSizeLimitInMB;
};

export type ProcessFileSelectionParamsType = {
  currentFiles: FileState[];
  files: File[];
  addFiles: AddFilesOnParentCallback;
  acceptedFileTypes: string;
  maxFileSizeLimitInMB?: number;
  maxCombinedFileSizeLimitInMB?: number;
};

export const processFileSelection = ({currentFiles, files, addFiles, acceptedFileTypes, maxFileSizeLimitInMB: maxFileSizeInMB, maxCombinedFileSizeLimitInMB}: ProcessFileSelectionParamsType): void => {
  const allowedTypes = parseAllowedTypes(acceptedFileTypes);

  const selectedFiles: NewFile[] = files.filter(Boolean).reduce((filesSelected: NewFile[], file: File) => {
    const newFile: NewFile = {
      file
    };

    if (!isFileTypeAllowed(file, allowedTypes)) {
      newFile.progress = FileProgressEnum.ERROR_UPLOAD_BLOCKED;
      newFile.errorMessage = 'Unsupported file type';
    } else if (maxFileSizeInMB && isFileSizeLimitExceeded(file, maxFileSizeInMB)) {
      newFile.progress = FileProgressEnum.ERROR_UPLOAD_BLOCKED;
      newFile.errorMessage = 'File upload size limit exceeded';
    } else if (maxCombinedFileSizeLimitInMB && isCombinedFileSizeLimitExceeded({currentFiles, filesSelected, newFile: file, maxCombinedFileSizeLimitInMB})) {
      newFile.progress = FileProgressEnum.ERROR_UPLOAD_BLOCKED;
      newFile.errorMessage = 'Combined uploads size limit exceeded';
    }

    return [...filesSelected, newFile];
  }, []);

  if (selectedFiles.length > 0) {
    addFiles(selectedFiles);
  }
};
