import {createGlobalStyle} from 'styled-components';
import {FONTS_CDN_URL} from '../constants';

const BRAND_CDN_URL = `${FONTS_CDN_URL}/real`;
const FONT_FAMILY = 'DIN Next LT Pro';

export const RealFonts = createGlobalStyle`
  @font-face {
    font-family: ${FONT_FAMILY};
    src: url(${BRAND_CDN_URL}/DINNextProRegular.woff) format('woff'), 
        url(${BRAND_CDN_URL}/DINNextProRegular.woff2) format('woff2');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: ${FONT_FAMILY};
    src: url(${BRAND_CDN_URL}/DINNextProMedium.woff) format('woff'), 
        url(${BRAND_CDN_URL}/DINNextProMedium.woff2) format('woff2');
    font-style: normal;
    font-weight: 500;
    font-display: swap;
  }
`;
