import React from 'react';
import styled from 'styled-components';
import {Box, background} from '@nib/layout';

const Palette = styled.div`
  box-sizing: border-box;
  border: 1px solid hsla(0, 0%, 0%, 0.15);
  border-radius: 8px;
  overflow: hidden;
  height: 100%;
`;

export const ColorDot = styled.span`
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 10px;
  border: 1px solid hsla(0, 0%, 0%, 0.15);
  background-color: blue;
  ${props => background(props)};
`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ColorPalette = ({background, children}) => {
  return (
    <Palette>
      <Box background={background} height="7.5rem" />
      <Box padding={4}>{children}</Box>
    </Palette>
  );
};

ColorPalette.displayName = 'ColorPalette';

export default ColorPalette;
