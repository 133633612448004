import {css} from 'styled-components';
import {map} from '../../breakpoint';
import {ResponsiveOrStaticProp, Nullable} from './../../utils';

const validTextAlignValues = ['left', 'center', 'right', 'start', 'end'] as const;
export type textAlignValues = (typeof validTextAlignValues)[number];

export type TextAlignType = ResponsiveOrStaticProp<Nullable<textAlignValues>>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/explicit-module-boundary-types
export const textAlign = () => css`
  ${({textAlign}: {textAlign?: TextAlignType}) =>
    map(
      textAlign,
      (val: textAlignValues) =>
        val &&
        css`
          text-align: ${val === 'left' || val === 'start' ? 'start' : val === 'right' || val === 'end' ? 'end' : val};
        `
    )};
`;
