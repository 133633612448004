import React, {Children} from 'react';
import styled, {css} from 'styled-components';
import {SpacingType, BreakpointType} from '@nib-components/theme';
import {isValidSpacing, getSpacingValue, mapFlexAlignment, ResponsiveOrStaticProp, Nullable, ResponsiveSpaceProp, FlexAlignType, FlexVerticalAlignType} from '../utils';
import {breakpoint, map} from '../breakpoint';
import {mt, pl} from '../spacing';

export interface ColumnsProps {
  space?: ResponsiveSpaceProp;
  collapseBelow?: BreakpointType;
  wrap?: ResponsiveOrStaticProp<Nullable<boolean>>;
  align?: ResponsiveOrStaticProp<Nullable<FlexAlignType>>;
  verticalAlign?: ResponsiveOrStaticProp<Nullable<FlexVerticalAlignType>>;
  reverse?: ResponsiveOrStaticProp<Nullable<boolean>>;
  children: React.ReactNode;
  [key: string]: unknown;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ColumnsWrapper = styled(({space, collapseBelow, wrap, align, verticalAlign, reverse, ...rest}) => <div {...rest} />).attrs({'data-mesh-component': 'COLUMNS'})`
  display: flex;
  flex-direction: row;

  ${({align}) =>
    align &&
    map(
      align,
      (val: FlexAlignType) =>
        val &&
        css`
          justify-content: ${mapFlexAlignment(val)};
        `
    )}

  ${({verticalAlign}) =>
    verticalAlign &&
    map(
      verticalAlign,
      (val: FlexVerticalAlignType) =>
        val &&
        css`
          align-items: ${mapFlexAlignment(val)};
        `
    )}

    ${({reverse}: {reverse: ResponsiveSpaceProp}) =>
    map(reverse, (val: boolean) =>
      val
        ? css`
            flex-direction: row-reverse;
          `
        : css`
            flex-direction: row;
          `
    )}

    ${({wrap}: {wrap: ResponsiveSpaceProp}) =>
    map(wrap, (val: boolean) =>
      val
        ? css`
            flex-wrap: wrap;
          `
        : css`
            flex-wrap: nowrap;
          `
    )}

  ${({collapseBelow}) =>
    collapseBelow
      ? css`
          /* When we are below the collapseBelow breakpoint: */
          ${breakpoint('xs', collapseBelow)`

          display: block;

      > * {
        width: 100%;
        
        &:not(:first-child) {
        ${({space}: {space: ResponsiveSpaceProp}) =>
          map(
            space,
            (val: SpacingType) =>
              isValidSpacing(val) &&
              css`
                ${mt(val)};
              `
          )};
        }
      }
    `};

          /* When we are above the collapseBelow breakpoint: */
          ${breakpoint(collapseBelow)`
      // Apply negative margin based on space prop
      ${({space}: {space: ResponsiveSpaceProp}) =>
        map(
          space,
          (val: SpacingType) =>
            isValidSpacing(val) &&
            css`
              margin-inline-start: calc(0px - ${getSpacingValue(val)});
            `
        )};

      // Apply padding based on space prop to children
      > * > * {
        ${({space}: {space: ResponsiveSpaceProp}) =>
          map(
            space,
            (val: SpacingType) =>
              isValidSpacing(val) &&
              css`
                ${pl(val)};
              `
          )};
      }
    `};
        `
      : css`
          /* Apply negative margin based on space prop */
          ${({space}: {space: ResponsiveSpaceProp}) =>
            map(
              space,
              (val: SpacingType) =>
                isValidSpacing(val) &&
                css`
                  margin-inline-start: calc(0px - ${getSpacingValue(val)});
                `
            )};

          /* Apply margin based on space prop to children */

          > * > * {
            ${({space}: {space: ResponsiveSpaceProp}) =>
              map(
                space,
                (val: SpacingType) =>
                  isValidSpacing(val) &&
                  css`
                    ${pl(val)};
                  `
              )};
          }
        `}
`;

export const Columns: React.FC<ColumnsProps> = ({children, space = 0, reverse = false, wrap = false, align = 'left', verticalAlign = 'stretch', ...otherProps}) => (
  <ColumnsWrapper space={space} align={align} verticalAlign={verticalAlign} wrap={wrap} {...otherProps} reverse={reverse}>
    {Children.map(children, child => {
      if (React.isValidElement(child)) {
        return child;
      }
      return null;
    })}
  </ColumnsWrapper>
);

Columns.displayName = 'Columns';
