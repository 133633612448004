import Tag from '@nib/tag';
import Alert from '@nib-components/alert';
import { Link as GatsbyLink } from 'gatsby';
import Link from '@nib-components/link';
import * as React from 'react';
export default {
  Tag,
  Alert,
  GatsbyLink,
  Link,
  React
};