import React from 'react';
import styled from 'styled-components';
import {PhotoLibrarySystemIcon} from '@nib/icons';
import Copy from './Copy';
import {Column, Columns} from '@nib/layout';

const ZeroFontSizeColumn = styled(Column)`
  font-size: 0;

  svg {
    position: relative;
    inset-block-start: 2.5px;
  }
`;

export const ImageCaption = ({children, showIcon = false, ...otherProps}: {children: React.ReactNode; showIcon?: boolean}) => {
  if (!showIcon) {
    return (
      <Copy size="small" color="muted" measure={false} {...otherProps} data-mesh-component="IMAGE-CAPTION">
        {children}
      </Copy>
    );
  }
  return (
    <Columns space={2} {...otherProps} data-mesh-component="IMAGE-CAPTION">
      <ZeroFontSizeColumn width="content">
        <PhotoLibrarySystemIcon size="xs" fill="muted" />
      </ZeroFontSizeColumn>
      <Column>
        <Copy size="small" color="muted" component="div" measure={false}>
          {children}
        </Copy>
      </Column>
    </Columns>
  );
};

ImageCaption.displayName = 'ImageCaption';
