import {useEffect} from 'react';
import {fileUploadDispatchType, SessionReducerCases, SessionState, UploadProgressState} from '../../StateManagement';
import {scheduleFetchFileStatus} from '../../Upload/UploadHelper';

export type StartFileStatusPollWhenFilesUpdatedOptions = {
  currentFiles: UploadProgressState[];
  fileUploadDispatch: fileUploadDispatchType;
  gatewayEndpoint: string;
  session: SessionState;
};

export const useStartFileStatusPollWhenFilesUpdated = ({currentFiles, fileUploadDispatch, gatewayEndpoint, session}: StartFileStatusPollWhenFilesUpdatedOptions) => {
  useEffect(() => {
    if (session.sessionId && currentFiles.some(f => f.inProgress)) {
      const interval = scheduleFetchFileStatus(gatewayEndpoint, session.sessionId, currentFiles, fileUploadDispatch, session.interval);
      return () => {
        clearInterval(interval);
        fileUploadDispatch({
          type: SessionReducerCases.CLEAR_SESSION_INTERVAL
        });
      };
    } else if (session.interval) {
      clearInterval(session.interval);
      fileUploadDispatch({
        type: SessionReducerCases.CLEAR_SESSION_INTERVAL
      });
    }
  }, [currentFiles, session.sessionId]);
};
