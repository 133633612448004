import { Columns, Column } from '@nib/layout';
import { SmallPlaceholder, LargePlaceholder } from "../../../../src/components/examples/Placeholder";
import Alert from '@nib-components/alert';
import * as React from 'react';
export default {
  Columns,
  Column,
  SmallPlaceholder,
  LargePlaceholder,
  Alert,
  React
};