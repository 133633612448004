import React from 'react';
import {getFacetedRowModel, flexRender, getCoreRowModel, getFilteredRowModel, useReactTable, getFacetedUniqueValues, getFacetedMinMaxValues} from '@tanstack/react-table';
import {TableWrapper, Table, TableHead, TableHeadRow, TableHeading, TableBody, TableRow, TableData, SearchBox} from '@nib/table';
import {Stack} from '@nib/layout';
import {rankItem} from '@tanstack/match-sorter-utils';
import {makeData} from './MakeData';

export const FilterTable = () => {
  let makeDataArray = makeData(20);

  const fuzzyFilter = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);

    // Store the itemRank info
    addMeta({
      itemRank
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
  };

  const [globalFilter, setGlobalFilter] = React.useState('');
  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'firstName',
        enableColumnFilter: false,
        cell: info => info.getValue(),
        footer: props => props.column.id,
        header: () => <span>First Name</span>
      },
      {
        accessorFn: row => row.lastName,
        id: 'lastName',
        enableColumnFilter: false,
        cell: info => info.getValue(),
        header: () => <span>Last Name</span>,
        footer: props => props.column.id
      },
      {
        accessorFn: row => `${row.firstName} ${row.lastName}`,
        id: 'fullName',
        header: 'Full Name',
        enableColumnFilter: false,
        cell: info => info.getValue(),
        footer: props => props.column.id
      },

      {
        accessorKey: 'age',
        header: () => 'Age',
        enableColumnFilter: false,
        footer: props => props.column.id
      },

      {
        accessorKey: 'visits',
        enableColumnFilter: false,
        header: () => <span>Visits</span>,
        footer: props => props.column.id
      },
      {
        accessorKey: 'status',
        header: 'Status',
        enableColumnFilter: false,
        footer: props => props.column.id
      },
      {
        accessorKey: 'progress',
        header: 'Profile Progress',
        enableColumnFilter: false,
        footer: props => props.column.id
      }
    ],
    []
  );

  const [data, setData] = React.useState(makeDataArray); // eslint-disable-line no-unused-vars

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter
    },
    state: {
      globalFilter
    },
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false
  });

  let [value, setValue] = React.useState('');
  const onChangeSearch = e => {
    setValue(e.target.value);
    setGlobalFilter(e.target.value);
  };

  return (
    <Stack space={4}>
      <SearchBox value={value || ''} onChange={onChangeSearch} placeholder="Filter table" />

      <TableWrapper>
        <Table equalColumns={false}>
          <TableHead>
            {table.getHeaderGroups().map(headerGroup => (
              <TableHeadRow key={headerGroup.id} fixedHeader={false} rowHeight={'regular'}>
                {headerGroup.headers.map(header => {
                  return (
                    <TableHeading key={header.id} colSpan={header.colSpan} onClick={header.column.getToggleSortingHandler()} isSorted={header.column.getIsSorted()}>
                      {header.isPlaceholder ? null : <span>{flexRender(header.column.columnDef.header, header.getContext())}</span>}
                    </TableHeading>
                  );
                })}
              </TableHeadRow>
            ))}
          </TableHead>
          <TableBody stripedRows={true}>
            {table.getRowModel().rows.map(row => {
              return (
                <TableRow key={row.id} rowHeight="regular">
                  {row.getVisibleCells().map(cell => {
                    return <TableData key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableData>;
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableWrapper>
    </Stack>
  );
};
