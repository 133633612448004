// Special Dispatch module as Retry mutates both File and Upload states
import {fileUploadDispatchType, SessionState, UploadProgressState} from '../StateManagement';

import {updateFileThroughDispatch} from './File';
import {FileProgressEnum} from '../../FileSelect';
import {initiateFileUpload, UploadHelper} from '../Upload/UploadHelper';

type RetryFailedFileOptions = {
  file: UploadProgressState;
  fileUploadDispatch: fileUploadDispatchType;
  gatewayEndpoint: string;
  session: SessionState;
  proxyFileUploadUrl?: string;
  serviceName?: string;
  internalId?: string;
  customerId?: string;
};

export const retryFailedFile = ({file, fileUploadDispatch, gatewayEndpoint, session, proxyFileUploadUrl, serviceName, internalId, customerId}: RetryFailedFileOptions) => {
  const fileToUpdate: UploadProgressState = {
    ...file,
    progress: FileProgressEnum.UPLOADING
  };

  fileToUpdate.backgroundUploader = new UploadHelper({
    fileUploadDispatch,
    toUpload: fileToUpdate,
    gatewayEndpoint,
    proxyFileUploadUrl,
    serviceName,
    internalId,
    customerId
  });

  updateFileThroughDispatch({fileUploadDispatch, fileToUpdate});

  initiateFileUpload({
    file: fileToUpdate,
    fileUploadDispatch,
    sessionIdInitialiser: session.initializer
  });
};
