/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/explicit-module-boundary-types */
import {css} from 'styled-components';
import {map} from '../../breakpoint';
import {ResponsiveOrStaticProp, Nullable} from './../../utils';

// Flex Direction:

export const validFlexDirectionValues = ['row', 'column', 'row-reverse', 'column-reverse'] as const;
export type flexDirectionValues = (typeof validFlexDirectionValues)[number];

export type FlexDirectionType = ResponsiveOrStaticProp<Nullable<flexDirectionValues>>;

export const flexDirection = () => css`
  ${({flexDirection}: {flexDirection?: FlexDirectionType}) =>
    map(
      flexDirection,
      (val: flexDirectionValues) =>
        val &&
        css`
          flex-direction: ${val};
        `
    )};
`;

// Flex Wrap:

export const validFlexWrapValues = ['wrap', 'nowrap', 'wrap-reverse'] as const;
export type flexWrapValues = (typeof validFlexWrapValues)[number];

export type FlexWrapType = ResponsiveOrStaticProp<Nullable<flexWrapValues>>;

export const flexWrap = () => css`
  ${({flexWrap}: {flexWrap?: FlexWrapType}) =>
    map(
      flexWrap,
      (val: flexWrapValues) =>
        val &&
        css`
          flex-wrap: ${val};
        `
    )};
`;

// Flex Grow and Shrink

export type FlexGrowShrinkType = ResponsiveOrStaticProp<Nullable<number>>;

export const flexGrow = () => css`
  ${({flexGrow}: {flexGrow?: FlexGrowShrinkType}) =>
    map(
      flexGrow,
      (val: number) =>
        val !== undefined &&
        css`
          flex-grow: ${val};
        `
    )};
`;

export const flexShrink = () => css`
  ${({flexShrink}: {flexShrink?: FlexGrowShrinkType}) =>
    map(
      flexShrink,
      (val: number) =>
        val !== undefined &&
        css`
          flex-shrink: ${val};
        `
    )};
`;

// Flex Align Values - shared between align-items and align-self:

export const validFlexAlignValues = ['center', 'flex-start', 'flex-end', 'stretch'] as const;
export type flexAlignValues = (typeof validFlexAlignValues)[number];
export type FlexAlignType = ResponsiveOrStaticProp<Nullable<flexAlignValues>>;

// Align Items:

export const alignItems = () => css`
  ${({alignItems}: {alignItems?: FlexAlignType}) =>
    map(
      alignItems,
      (val: flexAlignValues) =>
        val &&
        css`
          align-items: ${val};
        `
    )};
`;

// Align Self:

export const alignSelf = () => css`
  ${({alignSelf}: {alignSelf?: FlexAlignType}) =>
    map(
      alignSelf,
      (val: flexAlignValues) =>
        val &&
        css`
          align-self: ${val};
        `
    )};
`;

// Justify Content:

export const validJustifyContentValues = ['center', 'flex-start', 'flex-end', 'space-between', 'space-around', 'space-evenly'] as const;
export type justifyContentValues = (typeof validJustifyContentValues)[number];

export type JustifyContentType = ResponsiveOrStaticProp<Nullable<justifyContentValues>>;

export const justifyContent = () => css`
  ${({justifyContent}: {justifyContent?: JustifyContentType}) =>
    map(
      justifyContent,
      (val: justifyContentValues) =>
        val &&
        css`
          justify-content: ${val};
        `
    )};
`;
