import Header, { SmallHeader, PortalHeader } from '@nib-components/header';
import Link from '@nib-components/link';
import { navLinks, iconLinks } from "../../../../src/components/portal-header/index.js";
import * as React from 'react';
export default {
  Header,
  SmallHeader,
  PortalHeader,
  Link,
  navLinks,
  iconLinks,
  React
};