import {createGlobalStyle} from 'styled-components';
import {FONTS_CDN_URL} from '../constants';

const BRAND_CDN_URL = `${FONTS_CDN_URL}/cota`;
const OPEN_SANS = 'Open Sans';

export const CotaFonts = createGlobalStyle`
@font-face {
  font-family: ${OPEN_SANS};
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(${BRAND_CDN_URL}/open-sans-v27-latin-regular.woff2) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
      url(${BRAND_CDN_URL}/open-sans-v27-latin-regular.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: ${OPEN_SANS};
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(${BRAND_CDN_URL}/open-sans-v27-latin-700.woff2) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
      url(${BRAND_CDN_URL}/open-sans-v27-latin-700.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
`;
