import React, {Children} from 'react';
import styled, {css} from 'styled-components';
import {BreakpointType} from '@nib-components/theme';
import {breakpoint} from '../breakpoint';

export interface HiddenProps {
  above?: BreakpointType;
  below?: BreakpointType;
  print?: boolean;
  screen?: boolean;
  screenReader?: boolean;
  children: React.ReactNode;
  [key: string]: unknown;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const HiddenWrapper = styled(({above, below, print, screen, ...rest}) => <div {...rest} />).attrs({
  'data-mesh-component': 'HIDDEN'
})`
  ${({below}) =>
    below &&
    css`
      /* When we are below the 'below' breakpoint: */
      ${breakpoint('xs', below)`
          display: none;
        `}
    `};

  ${({above}) =>
    above &&
    css`
      /* When we are above the 'above' breakpoint: */
      ${breakpoint(above)`
          display: none;
        `}
    `};

  ${({print}) =>
    print &&
    `
      @media print {
        display: none;
      }
  `};

  ${({screen}) =>
    screen &&
    `
      /* Keep focus and active visuals on interactive elements */
      > :not(:focus-visible):not(:active) {
        position: absolute !important;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px);
        white-space: nowrap;
      }
    `};
`;

export const Hidden: React.FC<HiddenProps> = ({children, above, below, screen = false, print = false, screenReader = false, ...otherProps}) => (
  <HiddenWrapper above={above} below={below} print={print} screen={screen} aria-hidden={screenReader} {...otherProps}>
    {Children.map(children, child => {
      if (React.isValidElement(child)) {
        return child;
      } else if (typeof child === 'string') {
        return <span>{child}</span>;
      }
      return null;
    })}
  </HiddenWrapper>
);

Hidden.displayName = 'Hidden';
