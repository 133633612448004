import React from 'react';
import styled, {css} from 'styled-components';
import ExpanderGroup from '@nib-components/expander-group';
import AccordionItem from './AccordionItem';
import {BORDER_VALUE} from './utils';

export interface AccordionTypes {
  borderBottom?: boolean;
  borderTop?: boolean;
  children?: React.ReactNode;
  [key: string]: unknown; // other props
}

const AccordionExpanderGroup = styled(
  (
    {borderTop, ...props} // eslint-disable-line  @typescript-eslint/no-unused-vars
  ) => <ExpanderGroup {...props} />
)`
  ${({borderTop}) =>
    borderTop &&
    css`
      border-top: ${BORDER_VALUE};
    `};
`;

const Accordion: React.FC<AccordionTypes> & {Item: typeof AccordionItem} = props => {
  const {children, borderBottom = true, borderTop = false, ...otherProps} = props;
  return (
    <AccordionExpanderGroup borderTop={borderTop} data-mesh-component="ACCORDION" {...otherProps}>
      {React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child as React.ReactElement, {
            borderBottom
          });
        }
        return null;
      })}
    </AccordionExpanderGroup>
  );
};

Accordion.displayName = 'Accordion';
Accordion.Item = AccordionItem;

export default Accordion;
