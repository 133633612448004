import {FileActions} from '../../Actions';
import {UploadProgressState} from '../../Interfaces';
import {FileReducerCases} from '../ReducerCases';

import {stateWithNewFile, stateWithUpdatedFile, stateWithErrorFile} from './FileReducerMethods';
import {stateWithErrorBlockFile} from './FileReducerMethods/stateWithErrorBlockFile';

export function filesReducer(state: UploadProgressState[], action: FileActions): UploadProgressState[] {
  switch (action.type) {
    case FileReducerCases.ADD_NEW_FILE:
      return stateWithNewFile(state, action.payload);

    case FileReducerCases.UPDATE_FILE:
      return stateWithUpdatedFile(state, action.payload);

    case FileReducerCases.REMOVE_FILE:
      return [...state.filter(file => file.name !== action.payload?.name)];

    case FileReducerCases.ERROR_FILE:
      return stateWithErrorFile(state, action.payload);

    case FileReducerCases.ERROR_BLOCK_FILE:
      return stateWithErrorBlockFile(state, action.payload);

    default:
      return state;
  }
}
