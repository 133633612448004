import {createGlobalStyle} from 'styled-components';
import {FONTS_CDN_URL} from '../constants';

const BRAND_CDN_URL = `${FONTS_CDN_URL}/apia`;
const FONT_FAMILY = 'Neue Haas Grotesk Text Pro';

export const ApiaFonts = createGlobalStyle`
  @font-face {
    font-family: ${FONT_FAMILY};
    src: url(${BRAND_CDN_URL}/NeueHaasGroteskTextPro55Roman.woff2) format('woff2'), 
        url(${BRAND_CDN_URL}/NeueHaasGroteskTextPro55Roman.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: ${FONT_FAMILY};
    src: url(${BRAND_CDN_URL}/NeueHaasGroteskTextPro65Medium.woff2) format('woff2'), 
        url(${BRAND_CDN_URL}/NeueHaasGroteskTextPro65Medium.woff) format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: ${FONT_FAMILY};
    src: url(${BRAND_CDN_URL}/NeueHaasGroteskTextPro75Bold.woff2) format('woff2'), 
        url(${BRAND_CDN_URL}/NeueHaasGroteskTextPro75Bold.woff) format('woff');
    font-weight: 700;
    font-style: bold;
    font-display: swap;
  }
`;
