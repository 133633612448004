export const procedures = [
  {
    id: 200,
    name: 'Accidental injury benefit',
    description:
      'The immediate necessary treatment required as a result of an accident. To be covered for the Accidental Injury Benefit, you must provide documented proof that you have sought treatment at a hospital emergency department within 24 hours of the Accident.\nThe Emergency Department attendance is classed by the Australian Department of Health as an outpatient service, not a hospital admission, and therefore it is not payable by the Fund.  The Accidental Injury Benefit covers further treatment in hospital as an admitted patient, where you are re-admitted to a hospital within 90 days of the initial visit.\n  \nFor example:\n- If you were sent home from the Emergency Department before returning within 90 days to be admitted as an inpatient for further treatment relating to the original accident.\n',
    coveredTypeCode: 'Inclusion',
    waitingPeriod: '2 Months'
  },
  {
    id: 202,
    name: 'Emergency Ambulance Cover',
    description:
      'If you need immediate medical attention and your condition means that you cannot be transported in another way, ambulance covers you for: \n- Emergency Ambulance transport to hospital provided by a State or Territory Ambulance Service paid at 100% of the cost\n- Emergency Ambulance call out fees (where the customer is treated at the scene by paramedics and it is determined that transport to hospital is not required)\n- Transport between hospitals when the transfer is required as a result of the existing hospital not specialising in the treatment required \n',
    coveredTypeCode: 'Restriction',
    waitingPeriod: '2 Months'
  },
  {
    id: 250,
    name: 'Services not covered by Medicare',
    description: 'Under all covers, no benefits are payable towards in hospital procedures or hospital costs associated with procedures not covered by Medicare. ',
    coveredTypeCode: 'Exclusion',
    waitingPeriod: '2 Months'
  },
  {
    id: 420,
    name: 'Assisted reproductive services',
    description:
      'Hospital treatment for fertility treatments or procedures. For example:\n- retrieval of eggs or sperm,\n- In vitro Fertilisation (IVF), and\n- Gamete Intra-fallopian Transfer (GIFT).\n',
    coveredTypeCode: 'Incl',
    waitingPeriod: '2 Months'
  },
  {
    id: 421,
    name: 'Back, neck and spine',
    description:
      'Hospital treatment for the investigation and treatment of the back, neck and spinal column, including spinal fusion. For example:\n- sciatica,\n- prolapsed or herniated disc, and\n- spine curvature disorders such as scoliosis, kyphosis and lordosis.\n',
    coveredTypeCode: 'MBP',
    waitingPeriod: '2 Months'
  },
  {
    id: 422,
    name: 'Blood',
    description: 'Hospital treatment for the investigation and treatment of blood and blood-related conditions. For example:\n- blood clotting disorders and\n- bone marrow transplants.\n',
    coveredTypeCode: 'R',
    waitingPeriod: '2 Months'
  }
];

export const services = [
  {
    id: 200,
    name: 'General dental',
    description:
      'Diagnostic and other preventative procedures (not covered under Preventative dental) completed to help you keep on top of your oral health. This is routine dental that includes:\t\n- fillings\t\n- basic extractions (excluding wisdom teeth)\t\n- x-rays\t\t\t\n',
    disclaimer: "Doesn't include more complex treatments or procedures such as orthodontic work, endodontic treatment (root canal therapy), crowns or bridges. See Dental (Major).",
    coveredTypeCode: 'Inclusion',
    waitingPeriod: '2 Months'
  },
  {
    id: 201,
    name: 'Major dental',
    description:
      'Major Dental is more complex dentistry procedures such as:\n- crowns and bridges.\n- root canal therapy.\n- Surgical removal of teeth (e.g wisdom teeth).\n- dentures.\n- implants.\n',
    disclaimer: null,
    coveredTypeCode: 'Inclusion',
    waitingPeriod: '2 Months'
  },
  {
    id: 204,
    name: 'Physiotherapy',
    description:
      'Treatment by a physiotherapist which uses physical means to relieve pain, regain range of movement, restore muscle strength and return patients to normal activities of daily living. \t\t\t\t\t\t\n',
    disclaimer: 'Benefits are only payable for consultations with a physiotherapist.',
    coveredTypeCode: 'Inclusion',
    waitingPeriod: '2 Months'
  },
  {
    id: 227,
    name: 'Unlimited preventative dental ',
    description: 'Preventative dental treatment to help you maintain your oral health.\nThis includes selected:\t\n- examinations\t\n- scale and clean\t\n- fluoride treatments\t\t\t\n',
    disclaimer: 'For more diagnostic and preventative dental procedures see General Dental.',
    coveredTypeCode: 'Inclusion',
    waitingPeriod: '2 Months'
  },
  {
    id: 230,
    name: 'Ambulance',
    description:
      'If you need immediate medical attention and your condition means that you cannot be transported in another way, you are covered for:\n- Emergency Ambulance transport to hospital provided by a State or Territory Ambulance service \n- Emergency Ambulance call out fees (where the customer is treated at the scene by paramedics and it is determined that transport to hospital is not required)\n- Transport between hospitals when the transfer is required as a result of the existing hospital not specialising in the treatment required\n- 100% of the cost. Additionally no hospital excess is applicable for this service.\n',
    disclaimer: 'Residents of Queensland and Tasmania who have Ambulance services provided by their State Ambulance Schemes are not covered.\n',
    coveredTypeCode: 'Exclusion',
    waitingPeriod: '2 Months'
  },
  {
    id: 303,
    name: 'Optical',
    description: 'Correction of visual impairment such as long or short sightedness.\nExamples of treatment include:\n- prescription glasses\t\n- contact lenses\t\t\t\t\n',
    disclaimer: 'No benefit for tinting or hardening of lenses.',
    coveredTypeCode: 'Exclusion',
    waitingPeriod: '2 Months'
  }
];

export const dummyLimitsForService = [
  {
    annualIncrease: null,
    applyToName: null,
    limitAmount: null,
    limitText: 'No Limit',
    maxLimitPerTimePeriod: null,
    productId: 1,
    productName: 'Core Extras',
    serviceId: 230,
    timePeriod: 'Day'
  },
  {
    annualIncrease: null,
    applyToName: null,
    limitAmount: 600,
    limitText: null,
    maxLimitPerTimePeriod: null,
    productId: 54,
    productName: 'Core Extras Boost',
    serviceId: 230,
    timePeriod: 'Year'
  },
  {
    annualIncrease: null,
    applyToName: null,
    limitAmount: 700,
    limitText: null,
    maxLimitPerTimePeriod: null,
    productId: 55,
    productName: 'Core and Wellbeing Extras',
    serviceId: 230,
    timePeriod: 'Year'
  }
];
