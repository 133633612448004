import {fileUploadDispatchType, FileReducerCases, UploadProgressState} from '../StateManagement';

export const addFileThroughDispatch = ({fileUploadDispatch, filesToAdd}: {fileUploadDispatch: fileUploadDispatchType; filesToAdd: UploadProgressState[]}) =>
  fileUploadDispatch({
    type: FileReducerCases.ADD_NEW_FILE,
    payload: filesToAdd
  });

export const updateFileThroughDispatch = ({fileUploadDispatch, fileToUpdate}: {fileUploadDispatch: fileUploadDispatchType; fileToUpdate: UploadProgressState}) =>
  fileUploadDispatch({
    type: FileReducerCases.UPDATE_FILE,
    payload: fileToUpdate
  });

export const removeFileThroughDispatch = ({fileUploadDispatch, fileToRemove}: {fileUploadDispatch: fileUploadDispatchType; fileToRemove: UploadProgressState}) =>
  fileUploadDispatch({
    type: FileReducerCases.REMOVE_FILE,
    payload: fileToRemove
  });

export const errorFileThroughDispatch = ({fileUploadDispatch, fileToError}: {fileUploadDispatch: fileUploadDispatchType; fileToError: UploadProgressState}) =>
  fileUploadDispatch({
    type: FileReducerCases.ERROR_FILE,
    payload: fileToError
  });

export const errorBlockFileThroughDispatch = ({fileUploadDispatch, fileToError}: {fileUploadDispatch: fileUploadDispatchType; fileToError: UploadProgressState}) =>
  fileUploadDispatch({
    type: FileReducerCases.ERROR_BLOCK_FILE,
    payload: fileToError
  });
