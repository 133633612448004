import React from 'react';
import styled, {css} from 'styled-components';
import {ErrorSystemIcon, ChecklistSystemIcon} from '@nib/icons';
import {Stack, Columns, Column, Hidden, p} from '@nib/layout';
import Loader from '@nib/loader';
import {Bold} from '@nib-components/copy';
import {borderRadiusSmall, colorError, colorLight, colorLightest, colorWhite} from '@nib-components/theme';
import {FileListDescription} from '../Components/FileListDescription';
import {ActionButton} from '../Components/FileListActionButton';
import {FileState, FileProgressEnum, ParentFileState} from '../Interfaces';

const sortByName = (a: FileState, b: FileState): number => {
  return a.name.localeCompare(b.name);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FileAttachmentBar = styled(({loading, progress, ...rest}) => <div {...rest} />)<{loading?: boolean; progress?: FileProgressEnum}>`
  ${p({xs: 2, mini: 4})};
  background-color: ${props => (props.loading ? `var(--inputColorBg,${colorLightest})` : `var(--themeColorBg,${colorWhite})`)};
  border: 1px solid var(--themeColorBorderGentle, ${colorLight});

  /* borderRadiusStandard varies across brands so just going with 4px */
  border-radius: ${borderRadiusSmall};
  transition: background-color ease 0.2s;

  ${props => {
    if (props.progress === FileProgressEnum.ERROR || props.progress === FileProgressEnum.ERROR_UPLOAD_BLOCKED) {
      return css`
        color: var(--themeColorFgError, ${colorError});
        border-color: currentColor;
      `;
    }
  }}
`;

const ZeroLineHeight = styled.div`
  line-height: 0;
`;

const FileName = styled(Bold)`
  display: block;
`;

type FileAttachmentProps<T extends FileState> = Omit<ParentFileState<T>, 'files' | 'addFiles'> & {
  file: T;
};

const FileAttachment = <T extends FileState>({file, ...rest}: FileAttachmentProps<T>): JSX.Element => {
  const isLoading = file.progress === FileProgressEnum.UPLOADING;

  return (
    <FileAttachmentBar loading={isLoading} progress={file.progress}>
      <Columns space={4} verticalAlign="center" collapseBelow="mini">
        <Hidden below="md">
          <Column width="content">
            <ZeroLineHeight>
              {file.progress === FileProgressEnum.UPLOADED && <ChecklistSystemIcon size="md" fill="success" />}
              {file.progress === FileProgressEnum.UPLOADING && <Loader size="md" />}
              {(file.progress === FileProgressEnum.ERROR || file.progress === FileProgressEnum.ERROR_UPLOAD_BLOCKED) && <ErrorSystemIcon size="md" fill="error" />}
            </ZeroLineHeight>
          </Column>
        </Hidden>
        <Column>
          <FileListDescription file={file} aria-live="polite" aria-busy={isLoading ? true : false} />
          <FileName>{file.name}</FileName>
        </Column>
        <Column width="content">
          <ActionButton file={file} {...rest} />
        </Column>
      </Columns>
    </FileAttachmentBar>
  );
};

export type FileListProps<T extends FileState> = Omit<ParentFileState<T>, 'addFiles'>;

export const FilesList = <T extends FileState>({files, ...restProps}: FileListProps<T>): JSX.Element | null => {
  if (files.length !== 0) {
    return (
      <Stack space={4} as="ul">
        {files.sort(sortByName).map((file, index) => {
          return <FileAttachment file={file} {...restProps} key={`file-attachment-${index}`} />;
        })}
      </Stack>
    );
  }
  return null;
};
