import React from 'react';
import {flexRender, getCoreRowModel, getPaginationRowModel, useReactTable} from '@tanstack/react-table';
import {TableWrapper, Table, TableHead, TableHeadRow, TableHeading, TableBody, TableRow, TableData, Pagination} from '@nib/table';
import {makeData} from './MakeData';

export const PaginationTable = () => {
  const makeDataArray = makeData(30);
  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'firstName',
        cell: info => info.getValue(),
        footer: props => props.column.id,
        header: () => <span>First Name</span>
      },
      {
        accessorFn: row => row.lastName,
        id: 'lastName',
        cell: info => info.getValue(),
        header: () => <span>Last Name</span>,
        footer: props => props.column.id
      },
      {
        accessorKey: 'age',
        header: () => 'Age',
        footer: props => props.column.id
      },
      {
        accessorKey: 'visits',
        header: () => <span>Visits</span>,
        footer: props => props.column.id
      },
      {
        accessorKey: 'status',
        header: 'Status',
        footer: props => props.column.id
      },
      {
        accessorKey: 'progress',
        header: 'Profile Progress',
        footer: props => props.column.id
      },
      {
        accessorKey: 'createdAt',
        header: 'Created At'
      }
    ],
    []
  );

  const [data] = React.useState(makeDataArray);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true
  });

  return (
    <>
      <TableWrapper>
        <Table equalColumns={false}>
          <TableHead>
            {table.getHeaderGroups().map(headerGroup => (
              <TableHeadRow key={headerGroup.id} fixedHeader={false} rowHeight={'regular'}>
                {headerGroup.headers.map(header => {
                  return (
                    <TableHeading fixedColumn={false} key={header.id} colSpan={header.colSpan} onClick={header.column.getToggleSortingHandler()} isSorted={header.column.getIsSorted()}>
                      {header.isPlaceholder ? null : <span>{flexRender(header.column.columnDef.header, header.getContext())}</span>}
                    </TableHeading>
                  );
                })}
              </TableHeadRow>
            ))}
          </TableHead>
          <TableBody stripedRows={true}>
            {table.getRowModel().rows.map(row => {
              return (
                <TableRow key={row.id} rowHeight={'regular'}>
                  {row.getVisibleCells().map(cell => {
                    return (
                      <TableData fixedColumn={false} key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableData>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <Pagination
          canPreviousPage={table.getCanPreviousPage()}
          canNextPage={table.getCanNextPage()}
          pageOptions={table.getPageOptions()}
          pageCount={table.getPageCount()}
          gotoPage={table.setPageIndex}
          nextPage={table.nextPage}
          previousPage={table.previousPage}
          setPageSize={table.setPageSize}
          pageIndex={table.getState().pagination.pageIndex}
          pageSize={table.getState().pagination.pageSize}
        />
      </TableWrapper>
    </>
  );
};
