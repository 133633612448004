import { Stack } from '@nib/layout';
import Alert from '@nib-components/alert';
import Copy from '@nib-components/copy';
import Divider from '@nib-components/divider';
import Heading from '@nib-components/heading';
import * as React from 'react';
export default {
  Stack,
  Alert,
  Copy,
  Divider,
  Heading,
  React
};