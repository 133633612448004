import React from 'react';
import styled from 'styled-components';
import {colorWhite} from '@nib-components/theme';
import {SizeMap, LogoComponent} from '../../types';

export const StyledPath = styled.path<{invert?: boolean}>`
  fill: ${props => (props.invert ? colorWhite : props.fill)}; /* colorBrandBase */
`;
// small === min-height from brand guidelines
const sizes: SizeMap = {
  small: {
    width: '114.82px',
    height: '16px'
  },
  header: {
    width: '172.24px',
    height: '24px'
  },
  medium: {
    width: '172.24px',
    height: '24px'
  },
  large: {
    width: '229.65px',
    height: '32px'
  }
};

export const GULogo: LogoComponent = props => {
  const {invert = false, size = 'small', ...otherProps} = props;

  return (
    <svg x="0" y="0" width={sizes[size].width} height={sizes[size].height} viewBox="0 0 173 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label="GU" role="img" {...otherProps}>
      <StyledPath
        invert={invert}
        d="M37.5 10.748h10.248v9.642C45.455 22.492 42.306 24 38.384 24c-7.5 0-11.395-5.478-11.395-11.675C26.989 5.727 31.38 0 38.936 0c4.254 0 7.43 1.84 9.53 5.187L44.737 7.94c-1.27-2.2-3.508-3.417-6.063-3.417-4.061 0-6.879 2.947-6.879 7.636 0 4.855 3.08 7.511 7.169 7.511 1.477 0 2.817-.429 4.033-1.148v-3.347h-5.498v-4.427ZM70.648.512V15.23c0 5.146-3.038 8.729-9.17 8.729-6.161 0-9.172-3.57-9.172-8.729V.512h4.751V14.87c0 2.85 1.7 4.565 4.42 4.565 2.721 0 4.392-1.701 4.392-4.565V.512h4.78Z"
        fill="#3D6C89"
      />
      <StyledPath
        invert={invert}
        d="M75.303.512h3.3v9.835h11.562V.512h3.3v22.962h-3.3v-9.973H78.603v9.973h-3.301V.512ZM98.838 16.226c.387 3.043 2.362 4.593 5.235 4.593 2.155 0 3.729-.955 4.779-2.421l1.796 1.936c-1.699 2.13-3.895 3.348-6.837 3.348-5.11 0-8.19-3.569-8.19-8.327 0-4.718 3.01-8.729 8.217-8.729 4.448 0 7.21 2.891 7.21 7.995v1.605h-12.21Zm9.006-2.462c0-2.822-1.381-4.427-4.13-4.427-2.79 0-4.489 1.674-4.876 4.427h9.006ZM131.987 20.75c.456 0 .953-.125 1.409-.388l.787 2.393a5.482 5.482 0 0 1-2.983.913c-1.961 0-3.702-1.217-3.937-3.375-1.174 2.227-3.37 3.375-6.187 3.375-5.042 0-7.859-3.928-7.859-8.466 0-4.537 2.748-8.59 7.859-8.59 2.79 0 4.848 1.148 5.897 3.154V7.013h3.15v11.772c0 1.37.856 1.964 1.864 1.964Zm-10.345.027c3.08 0 5.276-2.296 5.276-5.644 0-3.347-2.154-5.644-5.331-5.644-3.37 0-5.138 2.587-5.138 5.644-.014 3.32 2.085 5.644 5.193 5.644ZM140.274 23.668c-2.652 0-4.82-1.84-4.82-5.187V.51h3.204v17.61c0 1.702 1.022 2.628 2.293 2.628.622 0 1.271-.166 1.934-.525l.815 2.462c-1.133.65-2.307.982-3.426.982ZM145.578 7.013V2.614h3.246v4.4h5.041V9.89h-5.041v7.995c0 1.964 1.271 2.85 2.818 2.85.884 0 1.906-.291 2.748-.858l.912 2.324c-1.409.982-2.942 1.438-4.448 1.438-2.983 0-5.276-1.909-5.276-5.34V9.89h-3.439V7h3.439v.013ZM160.744.512v9.254c1.022-1.867 2.817-3.154 5.635-3.154 3.536 0 5.856 2.158 5.856 6.004v10.858h-3.204V13.446c0-2.532-1.381-3.943-3.605-3.943-2.528 0-4.682 1.868-4.682 5.284v8.687h-3.205V.512h3.205Z"
        fill="#525762"
      />
      <StyledPath invert={invert} d="M22.748.609H0v7.137h10.58l-7.486 7.498 5.041 5.049 7.486-7.498v10.596h7.127V.61Z" fill="#3F94AC" />
    </svg>
  );
};

GULogo.displayName = 'GULogo';

export default GULogo;
