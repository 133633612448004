/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {HeaderConfigType, GetConfigParams} from './types';
import getArhiConfig from './arhi';
import {ProfileSystemIcon} from '@nib/icons';
import {getBaseUrl} from './utils';

const PHONE_NUMBER = '1800 775 204';
const CORPORATE_PHONE_NUMBER = null;
const ABSOLUTE_BASEURL = 'https://www.nib.com.au';

const getInternationalWorkersConfig = (config: GetConfigParams): HeaderConfigType => {
  const arhiConfig = getArhiConfig(config);
  const baseUrl = getBaseUrl(config, ABSOLUTE_BASEURL);

  return {
    ...arhiConfig,
    id: 'header-workers',
    phoneNumber: PHONE_NUMBER,
    corporatePhoneNumber: CORPORATE_PHONE_NUMBER,
    callToAction: {
      id: 'ga-nav-workers-get-a-quote',
      title: 'Get a quote',
      url: `${baseUrl}/overseas-working-visitors`
    },
    smallHeaderConfig: {
      id: 'small-header-workers',
      logoUrl: arhiConfig.logoUrl,
      phoneNumber: PHONE_NUMBER,
      corporatePhoneNumber: CORPORATE_PHONE_NUMBER,
      navLinks: [
        {
          id: 'ga-nav-workers-my-account',
          label: arhiConfig.callToAction!.title,
          title: arhiConfig.callToAction!.title,
          icon: ProfileSystemIcon,
          url: arhiConfig.callToAction!.url
        }
      ]
    }
  };
};

export default getInternationalWorkersConfig;
