import HeaderFooterLayout from '@nib-components/header-footer-layout';
import Header, { SmallHeader } from '@nib-components/header';
import { SmallFooter } from '@nib-components/footer';
import { PrimaryButton } from '@nib-components/button';
import Link from '@nib-components/link';
import { JIRA_SPRINT_BOARD, JIRA_PREP_BOARD, SLACK_URL, WORKPLACE_URL, DESIGNOPS_MAILTO } from "../../../../src/constants.js";
import * as React from 'react';
export default {
  HeaderFooterLayout,
  Header,
  SmallHeader,
  SmallFooter,
  PrimaryButton,
  Link,
  JIRA_SPRINT_BOARD,
  JIRA_PREP_BOARD,
  SLACK_URL,
  WORKPLACE_URL,
  DESIGNOPS_MAILTO,
  React
};