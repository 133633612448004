import {NibThrive} from '@nib-components/logo';
import {HeaderConfigType, GetConfigParams} from './types';
import {getBaseUrl, getLogoUrl} from './utils';
import {PhoneSystemIcon} from '@nib/icons';

const LOGO_URL = '/thrive';
const ABSOLUTE_BASEURL = 'https://www.nib.com.au';
const CONTACT_US = 'https://nib.com.au/thrive/contact-us';

const PHONE_NUMBER = '1800 999 333';

const getThriveConfig = (config: GetConfigParams): HeaderConfigType => {
  const baseUrl = getBaseUrl(config, ABSOLUTE_BASEURL);
  const logoUrl = getLogoUrl(config, baseUrl, LOGO_URL);

  return {
    id: 'header-thrive',
    ribbon: [
      {
        id: 'ga-nav-thrive-nib',
        title: 'nib health funds',
        url: `${baseUrl}/`
      }
    ],
    logoUrl: `${logoUrl}/`,
    phoneNumber: PHONE_NUMBER,
    hideQuoteButton: true,
    logoComponent: NibThrive,
    logoLabel: 'nib Thrive',
    callToAction: {
      id: 'id',
      title: 'Contact us',
      url: `${CONTACT_US}/`
    },
    navLinks: [],
    smallHeaderConfig: {
      id: 'small-header-thrive',
      logoUrl: `${logoUrl}/`,
      navLinks: [
        {
          id: 'ga-nav-thrive-contact-us',
          label: 'Contact us',
          title: 'Contact us',
          icon: PhoneSystemIcon,
          url: `${CONTACT_US}/`
        }
      ]
    }
  };
};

export default getThriveConfig;
