import React, {useState, useContext} from 'react';
import styled, {ThemeContext, css} from 'styled-components';
import {p} from '@nib/layout';
import {ModeProvider, standardFocusStyleDeclarations, focusOutlineOffset, colorBrandBase, colorBrandLight, colorLightest, colorWhite, colorDarkest, token} from '@nib-components/theme';
import {MenuSystemIcon, SearchSystemIcon, PhoneSystemIcon} from '@nib/icons';
import Link from '@nib-components/link';
import Logo from '@nib-components/logo';
import Drawer, {NavLinksType} from '@nib/drawer';
import {variationValues, SEARCH_URL} from '../shared';
import variantConfig from '../config';
import {HeaderConfigType, SmallHeaderConfigType} from '../config/types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SmallHeaderWrapper = styled(({invert, invertSmallHeader, hiddenNav, ...rest}) => <header {...rest} />)`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({hiddenNav}) => (hiddenNav ? `center` : `space-between`)};

  ${({invert}) =>
    invert
      ? css`
          background-color: var(--smallHeaderColorBgInvert, ${colorBrandBase});
        `
      : css`
          background-color: var(--smallHeaderColorBg, ${colorWhite});
          border-block-end: 1px solid var(--smallHeaderColorBorderDivider, ${colorLightest});
        `}
`;

export const LogoLink = styled(Link).attrs({
  underline: false
})`
  display: flex;
  align-items: center;

  && {
    ${p(4)};
  }

  &:focus-visible {
    ${standardFocusStyleDeclarations};
    outline-offset: -${focusOutlineOffset};
  }
`;

const HeaderLinks = styled.nav`
  display: flex;
  flex-wrap: wrap;
`;

// eslint-disable-next-line jsx-a11y/anchor-has-content, @typescript-eslint/no-unused-vars
const HeaderLink = styled(({component: Component, hiddenLink, invert, ...rest}) => (Component ? <Component {...rest} /> : <a {...rest} />)).attrs({'data-testid': 'header-link'})`
  display: ${({hiddenLink}) => (hiddenLink ? `none` : `flex`)};
  flex-direction: row;
  align-items: center;
  ${p(4)};
  border: none;
  border-inline-start-width: 1px;
  border-inline-start-style: solid;
  background: transparent;

  ${({theme, invert}) =>
    invert
      ? css`
          border-inline-start-color: ${token('smallHeader.color.border.divider.invert', theme.id === 'nib' ? '#436e60' : 'hsla(0, 0%, 0%, 0.2)')};
          color: ${token('smallHeader.color.fg.icons.invert', theme.id === 'nib' ? colorBrandLight : colorWhite)};
        `
      : css`
          border-inline-start-color: var(--smallHeaderColorBorderDivider, ${colorLightest});
          color: var(--smallHeaderColorFgIcons, ${colorBrandBase});
        `}

  &:hover {
    cursor: pointer;
  }

  &:focus-visible {
    ${standardFocusStyleDeclarations};
    outline-offset: -${focusOutlineOffset};
    z-index: 1;
  }
`;
HeaderLink.displayName = 'HeaderLink';

const MenuLink = styled(HeaderLink).attrs({'data-testid': 'menu-link'})`
  ${({invert}) =>
    invert
      ? css`
          color: var(--smallHeaderColorFgMenuInvert, ${colorWhite});
        `
      : css`
          color: var(--smallHeaderColorFgMenu, ${colorDarkest});
        `}
`;
MenuLink.displayName = 'MenuLink';

export interface SmallHeaderProps {
  variation?: variationValues;
  config?: HeaderConfigType;
  showMenu?: boolean;
  logoLink?: string;
  enableSearch?: boolean;
  hidePhoneNumber?: boolean;
  isCorporate?: boolean;
  invert?: boolean;
  withAbsoluteLinks?: boolean;
  absoluteBaseUrl?: string;
  [key: string]: unknown;
}

export const SmallHeader: React.FC<SmallHeaderProps> & {
  SmallHeaderWrapper: typeof SmallHeaderWrapper;
} & {LogoLink: typeof LogoLink} & {HeaderLinks: typeof HeaderLinks} & {
  HeaderLink: typeof HeaderLink;
} = props => {
  const {
    variation = 'arhi',
    config,
    showMenu = false,
    logoLink,
    enableSearch = false,
    hidePhoneNumber = false,
    isCorporate = false,
    invert = false,
    withAbsoluteLinks = false,
    absoluteBaseUrl,
    ...otherProps
  } = props;

  const dataSource: HeaderConfigType = config ? config : variantConfig[variation]({withAbsoluteLinks, absoluteBaseUrl});

  const smallHeaderData: SmallHeaderConfigType = dataSource.smallHeaderConfig;

  const drawerLinks = dataSource.navLinks;
  const ribbonLinks = dataSource.ribbon;
  const drawerChildren = dataSource.drawerChildren;
  const customLogoLabel = dataSource.logoLabel;
  const logoHref = logoLink ? logoLink : smallHeaderData && smallHeaderData.logoUrl ? smallHeaderData.logoUrl : '/';

  const CustomLogo = dataSource?.logoComponent;

  const themeContext = useContext(ThemeContext);
  const logoLabel = `${themeContext.id} Logo`;

  const phoneNumberDefined = Boolean(smallHeaderData.phoneNumber);
  const defaultOrCorporatePhoneNumber = isCorporate && smallHeaderData.corporatePhoneNumber ? smallHeaderData.corporatePhoneNumber : smallHeaderData.phoneNumber;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <ModeProvider mode="default">
      <>
        {showMenu && drawerLinks && (
          <Drawer
            navLinks={drawerLinks}
            ribbonLinks={ribbonLinks}
            isOpen={isDrawerOpen}
            setIsOpen={setIsDrawerOpen}
            hideQuoteButton={dataSource.hideQuoteButton}
            quoteButtonUrl={dataSource.quoteButtonUrl ? dataSource.quoteButtonUrl : undefined}
          >
            {drawerChildren}
          </Drawer>
        )}
        <SmallHeaderWrapper data-mesh-component="SMALL-HEADER" id={smallHeaderData ? smallHeaderData.id : `small-header`} hiddenNav={!showMenu} invert={invert} {...otherProps}>
          <LogoLink href={logoHref} id="ga-inlink-logo-homepage-mobile" aria-label={customLogoLabel ? customLogoLabel : logoLabel}>
            {CustomLogo ? <CustomLogo size="small" invert={invert} /> : <Logo size="small" invert={invert} />}
          </LogoLink>
          {showMenu && (
            <HeaderLinks aria-label="Main mobile navigation">
              {enableSearch && (
                <HeaderLink id="ga-nav-search-mobile" href={SEARCH_URL} aria-label="Search" invert={invert}>
                  <SearchSystemIcon fill="currentColor" />
                </HeaderLink>
              )}

              {/* 
              We need to be able to change the phone number based on the `isCorporate` prop.
              Therefore, we cannot include it in the `navlinks` config.
            */}
              {phoneNumberDefined && !hidePhoneNumber && (
                <HeaderLink
                  component="a"
                  id={`ga-nav-${variation !== 'arhi' ? `${variation}-` : ''}call-mobile`}
                  href={`tel:${defaultOrCorporatePhoneNumber && defaultOrCorporatePhoneNumber.replace(/\s+/g, '')}`}
                  aria-label="Call"
                  invert={invert}
                >
                  <PhoneSystemIcon fill="currentColor" />
                </HeaderLink>
              )}

              {smallHeaderData.navLinks &&
                smallHeaderData.navLinks.map((link: NavLinksType) => {
                  const Icon = link.icon as React.ElementType;
                  return (
                    <HeaderLink
                      component="a"
                      id={`${link.id}-mobile`}
                      href={link.url}
                      target={link.targetBlank && '_blank'}
                      rel={link.targetBlank && 'noopener noreferrer'}
                      aria-label={link.label}
                      key={`${link.id}-mobile`}
                      invert={invert}
                    >
                      <Icon fill="currentColor" />
                    </HeaderLink>
                  );
                })}

              <MenuLink
                component="button"
                id="ga-nav-menu-mobile"
                onClick={() => {
                  setIsDrawerOpen(true);
                }}
                aria-label="Open menu"
                invert={invert}
              >
                <MenuSystemIcon />
              </MenuLink>
            </HeaderLinks>
          )}
        </SmallHeaderWrapper>
      </>
    </ModeProvider>
  );
};

SmallHeader.displayName = 'SmallHeader';
SmallHeader.SmallHeaderWrapper = SmallHeaderWrapper;
SmallHeader.LogoLink = LogoLink;
SmallHeader.HeaderLinks = HeaderLinks;
SmallHeader.HeaderLink = HeaderLink;
