import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import {MenuSystemIcon, IconType} from '@nib/icons';
import {Box, Columns, Column, Hidden, Inline, Container, breakpoint} from '@nib/layout';
import DefaultLogo from '@nib-components/logo';
import Drawer, {NavLinksType} from '@nib/drawer';
import {colorBrandBase, colorLightest} from '@nib-components/theme';
import {LogoLink, NavMenu, baseNavLinkStyles, NavLinkItem, HeaderLink} from '../shared';

const FontZero = styled.div`
  font-size: 0;
`;

export const NavLink = styled.a.attrs({'data-testid': 'nav-link'})`
  ${baseNavLinkStyles};

  ${({isActivePage}: {isActivePage?: boolean}) =>
    isActivePage &&
    css`
      ::after {
        content: '';
        position: absolute;
        inset-block-end: 0;
        inset-inline: 1rem;
        display: block;
        background: var(--themeColorFgSelected, ${colorBrandBase});
        height: 4px;
      }
    `}
`;
NavLink.displayName = 'NavLink';

export interface IconLinkProps {
  icon: IconType;
  children: React.ReactNode;
  [key: string]: unknown;
}

const PhoneNumberSpan = styled.span`
  unicode-bidi: plaintext;
`;

export const IconLink: React.FC<IconLinkProps> = ({icon: Icon, children, ...otherProps}) => (
  <NavLink {...otherProps}>
    <Inline space={2} verticalAlign="center">
      <FontZero>
        <Icon size={20} />
      </FontZero>
      <PhoneNumberSpan>{children}</PhoneNumberSpan>
    </Inline>
  </NavLink>
);

const HiddenBelowXLFlexAbove = styled.div`
  display: none;

  ${breakpoint('xl')`
    display: flex;
  `}
`;
HiddenBelowXLFlexAbove.displayName = 'HiddenBelowXLFlexAbove';

const FlexBelowXLHiddenAbove = styled.div`
  display: flex;

  ${breakpoint('xl')`
    display: none;
  `}
`;
FlexBelowXLHiddenAbove.displayName = 'FlexBelowXLHiddenAbove';

const BoxBorderBottom = styled(Box)`
  border-bottom: 1px solid var(--smallHeaderColorBorderDivider, ${colorLightest});
`;
BoxBorderBottom.displayName = 'BoxBorderBottom';

const ColumnPullLeft = styled(Column)`
  margin-inline-end: auto;
`;

const UnstyledUnorderedList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
`;
UnstyledUnorderedList.displayName = 'UnstyledUnorderedList';

const FlexListItem = styled.li`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
`;
FlexListItem.displayName = 'FlexListItem';

const TierOne = (props: {children?: React.ReactNode}) => <BoxBorderBottom data-testid="tier-one">{props.children}</BoxBorderBottom>;
const TierTwo = (props: {children?: React.ReactNode}) => (
  <Box data-testid="tier-two" as="nav" aria-label="Main navigation">
    {props.children}
  </Box>
);

export interface PortalHeaderProps {
  logo?: any; // eslint-disable-line  @typescript-eslint/no-explicit-any
  withContainer?: boolean;
  isSingleTier?: boolean;
  activePage?: string;
  navLinks?: NavLinksType[];
  iconLinks?: {
    id: string;
    title: string | JSX.Element;
    slug: string;
    url: string;
    icon: IconType;
    onClick?: () => void;
    [key: string]: unknown;
  }[];
  logoLinkProps?: Record<string, any>; // eslint-disable-line  @typescript-eslint/no-explicit-any
  drawerChildren?: React.ReactNode;
  navSlot?: React.ReactNode;
}

export const PortalHeader: React.FC<PortalHeaderProps> = props => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const {logo: Logo, withContainer = false, isSingleTier = false, activePage, navLinks = [], iconLinks = [], logoLinkProps, drawerChildren, navSlot} = props;
  const ConditionalContainer = withContainer ? Container : 'div';
  const DefaultOrCustomLogo = Logo || DefaultLogo;
  return (
    <>
      {/* Mobile off canvas menu solution below XL */}
      <Drawer navLinks={navLinks} isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen} hideQuoteButton navSlot={navSlot}>
        {drawerChildren}
      </Drawer>

      <TierOne>
        <ConditionalContainer>
          <Columns align="right" wrap>
            <ColumnPullLeft width="content" flex>
              <LogoLink href="/" id="ga-nav-logo" {...logoLinkProps}>
                <DefaultOrCustomLogo />
              </LogoLink>
            </ColumnPullLeft>
            <HiddenBelowXLFlexAbove>
              {isSingleTier && navLinks && (
                <nav>
                  <UnstyledUnorderedList>
                    {navLinks.map((link, i) => {
                      const {slug, url, ...otherProps} = link;
                      return (
                        <NavLinkItem key={`${slug}-${i}`} link={link} href={url} activePage={props.activePage} navLink={NavLink} dropdownIconSize={20} {...otherProps}>
                          {link.title}
                        </NavLinkItem>
                      );
                    })}
                  </UnstyledUnorderedList>
                </nav>
              )}

              {iconLinks && (
                <UnstyledUnorderedList>
                  {iconLinks.map(link => {
                    const {id, title, slug, url, icon: Icon, onClick, ...otherProps} = link;
                    return (
                      <FlexListItem key={slug}>
                        <IconLink href={url} icon={Icon} id={`${id}-desktop`} onClick={onClick && onClick} {...otherProps}>
                          {title}
                        </IconLink>
                      </FlexListItem>
                    );
                  })}
                </UnstyledUnorderedList>
              )}
            </HiddenBelowXLFlexAbove>

            <FlexBelowXLHiddenAbove>
              <Box display="flex" style={{flexWrap: 'wrap'}}>
                {iconLinks &&
                  iconLinks.map((link, i) => {
                    const {id, title, slug, url, icon, onClick, component, ...otherProps} = link;
                    const Icon = icon as React.ElementType;
                    return (
                      <HeaderLink key={`${slug}-${i}`} href={url} icon={Icon} id={`${id}-mobile`} aria-label={title} component={component || 'a'} onClick={onClick && onClick} {...otherProps}>
                        <Icon />
                      </HeaderLink>
                    );
                  })}

                <HeaderLink
                  component="button"
                  id="ga-nav-menu-mobile"
                  onClick={() => {
                    setIsDrawerOpen(true);
                  }}
                  aria-label="Open menu"
                >
                  <MenuSystemIcon />
                </HeaderLink>
              </Box>
            </FlexBelowXLHiddenAbove>
          </Columns>
        </ConditionalContainer>
      </TierOne>

      {!isSingleTier && (
        <Hidden below="xl">
          <TierTwo>
            <ConditionalContainer>
              <NavMenu>
                {navLinks && navLinks.map(link => <NavLinkItem link={link} activePage={activePage} key={link.id} {...link} navLink={NavLink} dropdownIconSize={20} />)}
                {navSlot && navSlot}
              </NavMenu>
            </ConditionalContainer>
          </TierTwo>
        </Hidden>
      )}
    </>
  );
};

PortalHeader.displayName = 'PortalHeader';
