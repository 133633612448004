export const makeData = len => {
  let makeDataArray = [
    {
      firstName: 'Kaley',
      lastName: 'Pollich',
      age: 12,
      visits: 563,
      progress: 58,
      createdAt: '2001-10-22T09:20:21.683Z',
      status: 'single'
    },
    {
      firstName: 'Lurline',
      lastName: 'Kerluke',
      age: 20,
      visits: 877,
      progress: 14,
      createdAt: '1995-09-28T06:49:17.599Z',
      status: 'complicated'
    },
    {
      firstName: 'Raquel',
      lastName: 'Kuhic',
      age: 15,
      visits: 939,
      progress: 91,
      createdAt: '1993-12-12T02:42:59.627Z',
      status: 'single'
    },
    {
      firstName: 'Vivienne',
      lastName: 'Schuppe',
      age: 34,
      visits: 807,
      progress: 49,
      createdAt: '1991-02-08T13:16:13.271Z',
      status: 'relationship'
    },
    {
      firstName: 'Isabella',
      lastName: 'Feeney',
      age: 10,
      visits: 979,
      progress: 31,
      createdAt: '2013-09-07T06:27:50.422Z',
      status: 'relationship'
    },
    {
      firstName: 'Harold',
      lastName: 'Keebler',
      age: 33,
      visits: 500,
      progress: 93,
      createdAt: '1994-11-01T21:55:06.822Z',
      status: 'complicated'
    },
    {
      firstName: 'Kali',
      lastName: 'McClure',
      age: 17,
      visits: 164,
      progress: 77,
      createdAt: '1999-04-27T05:42:43.256Z',
      status: 'complicated'
    },
    {
      firstName: 'Nichole',
      lastName: 'Daugherty',
      age: 40,
      visits: 495,
      progress: 82,
      createdAt: '2013-04-01T19:24:32.137Z',
      status: 'complicated'
    },
    {
      firstName: 'Dixie',
      lastName: 'Bayer',
      age: 14,
      visits: 478,
      progress: 34,
      createdAt: '2012-09-08T18:07:26.336Z',
      status: 'complicated'
    },
    {
      firstName: 'Bruce',
      lastName: 'Macejkovic',
      age: 34,
      visits: 43,
      progress: 99,
      createdAt: '2013-07-08T21:34:36.296Z',
      status: 'relationship'
    },
    {
      firstName: 'Pietro',
      lastName: 'Koss',
      age: 6,
      visits: 624,
      progress: 60,
      createdAt: '1991-09-30T08:13:10.353Z',
      status: 'single'
    },
    {
      firstName: 'Karen',
      lastName: 'Torphy',
      age: 18,
      visits: 21,
      progress: 51,
      createdAt: '2016-07-22T07:01:12.371Z',
      status: 'single'
    },
    {
      firstName: 'Marco',
      lastName: 'Abernathy',
      age: 11,
      visits: 325,
      progress: 80,
      createdAt: '2002-03-08T21:55:45.958Z',
      status: 'complicated'
    },
    {
      firstName: 'Lelah',
      lastName: 'Bradtke',
      age: 16,
      visits: 589,
      progress: 38,
      createdAt: '2014-12-11T00:52:35.246Z',
      status: 'complicated'
    },
    {
      firstName: 'Meda',
      lastName: 'Thiel',
      age: 22,
      visits: 767,
      progress: 58,
      createdAt: '2017-08-25T01:00:09.170Z',
      status: 'relationship'
    },
    {
      firstName: 'Elda',
      lastName: 'Berge',
      age: 7,
      visits: 639,
      progress: 3,
      createdAt: '2010-11-18T06:36:27.158Z',
      status: 'complicated'
    },
    {
      firstName: 'Lucy',
      lastName: 'Block',
      age: 30,
      visits: 970,
      progress: 98,
      createdAt: '1990-04-25T02:05:23.420Z',
      status: 'relationship'
    },
    {
      firstName: 'Adan',
      lastName: 'Mante',
      age: 12,
      visits: 326,
      progress: 77,
      createdAt: '1990-05-23T07:31:27.417Z',
      status: 'single'
    },
    {
      firstName: 'Patricia',
      lastName: 'Friesen',
      age: 11,
      visits: 954,
      progress: 75,
      createdAt: '2020-09-06T21:24:56.570Z',
      status: 'relationship'
    },
    {
      firstName: 'Katheryn',
      lastName: 'Walter',
      age: 11,
      visits: 558,
      progress: 33,
      createdAt: '1995-01-27T18:00:21.697Z',
      status: 'relationship'
    },
    {
      firstName: 'Adelle',
      lastName: 'Armstrong',
      age: 13,
      visits: 560,
      progress: 47,
      createdAt: '1999-08-05T19:16:37.027Z',
      status: 'relationship'
    },
    {
      firstName: 'Mose',
      lastName: 'Rowe',
      age: 18,
      visits: 198,
      progress: 42,
      createdAt: '2012-03-29T16:34:41.896Z',
      status: 'relationship'
    },
    {
      firstName: 'Itzel',
      lastName: 'Fritsch',
      age: 17,
      visits: 193,
      progress: 28,
      createdAt: '2015-01-31T04:16:40.666Z',
      status: 'complicated'
    },
    {
      firstName: 'Reinhold',
      lastName: 'Wiza',
      age: 17,
      visits: 390,
      progress: 67,
      createdAt: '1994-10-21T22:03:53.556Z',
      status: 'relationship'
    },
    {
      firstName: 'Giuseppe',
      lastName: 'Turner',
      age: 14,
      visits: 111,
      progress: 59,
      createdAt: '2021-05-12T23:03:07.619Z',
      status: 'single'
    },
    {
      firstName: 'Annalise',
      lastName: 'Barrows',
      age: 3,
      visits: 837,
      progress: 89,
      createdAt: '2005-06-11T08:18:18.014Z',
      status: 'relationship'
    },
    {
      firstName: 'Lilliana',
      lastName: 'Donnelly',
      age: 12,
      visits: 755,
      progress: 83,
      createdAt: '2010-01-30T01:44:18.351Z',
      status: 'complicated'
    },
    {
      firstName: 'Monique',
      lastName: 'Klein',
      age: 8,
      visits: 70,
      progress: 72,
      createdAt: '2010-08-23T09:52:11.800Z',
      status: 'single'
    },
    {
      firstName: 'Leland',
      lastName: 'Halvorson',
      age: 28,
      visits: 388,
      progress: 4,
      createdAt: '1998-01-03T07:14:59.111Z',
      status: 'single'
    },
    {
      firstName: 'Theresia',
      lastName: 'Stroman',
      age: 26,
      visits: 614,
      progress: 61,
      createdAt: '1993-12-25T11:24:05.765Z',
      status: 'complicated'
    },
    {
      firstName: 'Doyle',
      lastName: 'Kunde',
      age: 20,
      visits: 872,
      progress: 90,
      createdAt: '2004-03-31T04:54:12.640Z',
      status: 'complicated'
    },
    {
      firstName: 'Dovie',
      lastName: 'Mohr',
      age: 3,
      visits: 66,
      progress: 49,
      createdAt: '1991-08-21T05:54:53.713Z',
      status: 'relationship'
    },
    {
      firstName: 'Verla',
      lastName: 'Schmidt',
      age: 40,
      visits: 232,
      progress: 42,
      createdAt: '2017-07-07T02:42:10.801Z',
      status: 'single'
    },
    {
      firstName: 'Fae',
      lastName: 'Herman',
      age: 26,
      visits: 837,
      progress: 60,
      createdAt: '2009-09-06T23:57:22.971Z',
      status: 'single'
    },
    {
      firstName: 'Augusta',
      lastName: 'Dickinson',
      age: 1,
      visits: 518,
      progress: 18,
      createdAt: '2019-08-23T15:32:16.209Z',
      status: 'relationship'
    },
    {
      firstName: 'Pierre',
      lastName: 'Bednar',
      age: 27,
      visits: 833,
      progress: 80,
      createdAt: '2009-05-20T11:37:27.254Z',
      status: 'relationship'
    },
    {
      firstName: 'Delmer',
      lastName: 'Anderson',
      age: 31,
      visits: 569,
      progress: 33,
      createdAt: '1998-10-24T23:11:28.731Z',
      status: 'complicated'
    },
    {
      firstName: 'Keagan',
      lastName: 'Cassin',
      age: 2,
      visits: 741,
      progress: 52,
      createdAt: '1993-04-19T04:13:13.060Z',
      status: 'single'
    },
    {
      firstName: 'Augusta',
      lastName: 'Hermiston',
      age: 37,
      visits: 802,
      progress: 100,
      createdAt: '2015-12-17T15:35:45.491Z',
      status: 'single'
    },
    {
      firstName: 'Orion',
      lastName: 'Kozey',
      age: 22,
      visits: 968,
      progress: 20,
      createdAt: '1991-07-23T05:54:37.190Z',
      status: 'complicated'
    },
    {
      firstName: 'Abigayle',
      lastName: 'Bayer',
      age: 34,
      visits: 633,
      progress: 2,
      createdAt: '1990-02-16T12:55:32.131Z',
      status: 'relationship'
    },
    {
      firstName: 'Annabelle',
      lastName: 'Marvin',
      age: 24,
      visits: 926,
      progress: 37,
      createdAt: '1993-08-22T17:05:55.288Z',
      status: 'complicated'
    },
    {
      firstName: 'Hertha',
      lastName: 'Lind',
      age: 40,
      visits: 950,
      progress: 64,
      createdAt: '2021-06-01T06:59:04.243Z',
      status: 'relationship'
    },
    {
      firstName: 'Caleb',
      lastName: 'Conroy',
      age: 16,
      visits: 76,
      progress: 76,
      createdAt: '1991-12-05T15:02:36.510Z',
      status: 'relationship'
    },
    {
      firstName: 'Samir',
      lastName: 'Hodkiewicz',
      age: 3,
      visits: 129,
      progress: 87,
      createdAt: '2013-02-11T09:11:23.620Z',
      status: 'relationship'
    },
    {
      firstName: 'Lamar',
      lastName: 'Grimes',
      age: 4,
      visits: 462,
      progress: 61,
      createdAt: '2007-08-17T06:53:02.160Z',
      status: 'single'
    },
    {
      firstName: 'Wilford',
      lastName: 'Krajcik',
      age: 30,
      visits: 766,
      progress: 48,
      createdAt: '2015-05-14T03:46:06.483Z',
      status: 'relationship'
    },
    {
      firstName: 'Adelbert',
      lastName: 'Bahringer',
      age: 35,
      visits: 326,
      progress: 4,
      createdAt: '2015-02-27T22:47:44.243Z',
      status: 'relationship'
    },
    {
      firstName: 'Enola',
      lastName: 'Feil',
      age: 37,
      visits: 212,
      progress: 55,
      createdAt: '2006-02-21T06:19:38.908Z',
      status: 'relationship'
    },
    {
      firstName: 'Elvie',
      lastName: 'Kautzer',
      age: 14,
      visits: 699,
      progress: 44,
      createdAt: '2015-09-28T01:05:06.934Z',
      status: 'relationship'
    },
    {
      firstName: 'William',
      lastName: 'Fritsch',
      age: 37,
      visits: 363,
      progress: 67,
      createdAt: '1996-07-09T15:31:40.354Z',
      status: 'relationship'
    },
    {
      firstName: 'Genesis',
      lastName: 'McDermott',
      age: 8,
      visits: 158,
      progress: 91,
      createdAt: '1995-09-21T09:01:25.254Z',
      status: 'complicated'
    },
    {
      firstName: 'Kamron',
      lastName: 'Pfeffer',
      age: 31,
      visits: 182,
      progress: 75,
      createdAt: '1996-11-11T13:15:03.361Z',
      status: 'relationship'
    },
    {
      firstName: 'Lazaro',
      lastName: 'Sipes',
      age: 16,
      visits: 245,
      progress: 78,
      createdAt: '2010-04-23T06:30:09.509Z',
      status: 'single'
    },
    {
      firstName: 'Mylene',
      lastName: 'Funk',
      age: 10,
      visits: 596,
      progress: 14,
      createdAt: '1992-02-12T05:35:04.036Z',
      status: 'complicated'
    },
    {
      firstName: 'Talia',
      lastName: 'Hahn',
      age: 16,
      visits: 639,
      progress: 83,
      createdAt: '2000-01-06T18:44:06.954Z',
      status: 'single'
    },
    {
      firstName: 'Dylan',
      lastName: 'Gorczany',
      age: 24,
      visits: 401,
      progress: 29,
      createdAt: '2009-06-02T14:35:29.871Z',
      status: 'single'
    },
    {
      firstName: 'Everett',
      lastName: 'Durgan',
      age: 15,
      visits: 696,
      progress: 32,
      createdAt: '2000-10-23T14:59:20.139Z',
      status: 'complicated'
    },
    {
      firstName: 'Taya',
      lastName: 'Kunde',
      age: 12,
      visits: 542,
      progress: 81,
      createdAt: '1999-06-08T23:30:53.779Z',
      status: 'relationship'
    },
    {
      firstName: 'Jarod',
      lastName: 'Jacobs',
      age: 34,
      visits: 613,
      progress: 9,
      createdAt: '2008-01-05T23:16:06.105Z',
      status: 'relationship'
    },
    {
      firstName: 'Lauren',
      lastName: 'Prosacco',
      age: 10,
      visits: 905,
      progress: 14,
      createdAt: '2010-05-29T12:36:28.085Z',
      status: 'complicated'
    },
    {
      firstName: 'Alden',
      lastName: 'McClure',
      age: 1,
      visits: 140,
      progress: 85,
      createdAt: '1990-10-30T06:38:13.634Z',
      status: 'complicated'
    },
    {
      firstName: 'Gordon',
      lastName: 'Stokes',
      age: 30,
      visits: 317,
      progress: 91,
      createdAt: '1992-01-09T06:26:03.370Z',
      status: 'complicated'
    },
    {
      firstName: 'Sharon',
      lastName: 'Gibson',
      age: 1,
      visits: 855,
      progress: 18,
      createdAt: '2018-08-28T01:50:50.604Z',
      status: 'single'
    },
    {
      firstName: 'Marion',
      lastName: 'Ebert',
      age: 25,
      visits: 511,
      progress: 18,
      createdAt: '2002-08-21T19:03:30.639Z',
      status: 'single'
    },
    {
      firstName: 'Maye',
      lastName: 'Olson',
      age: 28,
      visits: 299,
      progress: 98,
      createdAt: '2003-02-12T15:25:33.756Z',
      status: 'complicated'
    },
    {
      firstName: 'Shyann',
      lastName: 'Barrows',
      age: 29,
      visits: 683,
      progress: 13,
      createdAt: '2003-07-05T04:27:17.429Z',
      status: 'complicated'
    },
    {
      firstName: 'Izabella',
      lastName: 'Beier',
      age: 19,
      visits: 418,
      progress: 1,
      createdAt: '2021-09-25T19:36:28.805Z',
      status: 'complicated'
    },
    {
      firstName: 'Skyla',
      lastName: 'Beer',
      age: 15,
      visits: 734,
      progress: 2,
      createdAt: '2010-12-10T17:49:57.977Z',
      status: 'single'
    },
    {
      firstName: 'Clark',
      lastName: 'Stoltenberg',
      age: 33,
      visits: 673,
      progress: 79,
      createdAt: '2017-01-25T01:41:27.288Z',
      status: 'relationship'
    },
    {
      firstName: 'Jade',
      lastName: 'Olson',
      age: 0,
      visits: 736,
      progress: 59,
      createdAt: '2002-09-21T09:48:41.188Z',
      status: 'single'
    },
    {
      firstName: 'Jerel',
      lastName: 'Kuphal',
      age: 23,
      visits: 199,
      progress: 6,
      createdAt: '2013-10-27T13:17:02.235Z',
      status: 'single'
    },
    {
      firstName: 'Kevin',
      lastName: 'Emmerich',
      age: 0,
      visits: 180,
      progress: 63,
      createdAt: '2006-10-16T17:31:59.046Z',
      status: 'relationship'
    },
    {
      firstName: 'Jude',
      lastName: 'Nicolas',
      age: 9,
      visits: 918,
      progress: 65,
      createdAt: '1995-11-01T20:31:10.551Z',
      status: 'single'
    },
    {
      firstName: 'Russel',
      lastName: 'Hane',
      age: 10,
      visits: 98,
      progress: 12,
      createdAt: '1999-12-14T05:22:56.809Z',
      status: 'single'
    },
    {
      firstName: 'Ernestina',
      lastName: 'Ritchie',
      age: 32,
      visits: 592,
      progress: 22,
      createdAt: '2003-10-23T10:06:51.841Z',
      status: 'complicated'
    },
    {
      firstName: 'Christ',
      lastName: 'Rath',
      age: 28,
      visits: 196,
      progress: 27,
      createdAt: '2005-05-15T17:34:17.193Z',
      status: 'relationship'
    },
    {
      firstName: 'Jerrod',
      lastName: 'Donnelly',
      age: 32,
      visits: 135,
      progress: 79,
      createdAt: '2022-04-03T14:38:02.490Z',
      status: 'complicated'
    },
    {
      firstName: 'Brendan',
      lastName: 'Bogan',
      age: 7,
      visits: 796,
      progress: 6,
      createdAt: '2009-08-20T01:45:57.450Z',
      status: 'complicated'
    },
    {
      firstName: 'Wallace',
      lastName: 'Hilpert',
      age: 1,
      visits: 998,
      progress: 50,
      createdAt: '1992-08-26T04:31:36.433Z',
      status: 'relationship'
    },
    {
      firstName: 'Alexandrine',
      lastName: 'Hodkiewicz',
      age: 11,
      visits: 458,
      progress: 19,
      createdAt: '1996-02-21T08:42:11.151Z',
      status: 'single'
    },
    {
      firstName: 'Isadore',
      lastName: 'Jaskolski',
      age: 2,
      visits: 534,
      progress: 44,
      createdAt: '2021-11-06T23:14:51.148Z',
      status: 'complicated'
    },
    {
      firstName: "D'angelo",
      lastName: 'Schiller',
      age: 32,
      visits: 300,
      progress: 86,
      createdAt: '2022-06-04T16:30:47.473Z',
      status: 'complicated'
    },
    {
      firstName: 'Leonel',
      lastName: 'Hand',
      age: 4,
      visits: 717,
      progress: 15,
      createdAt: '2007-10-20T15:59:54.536Z',
      status: 'single'
    },
    {
      firstName: 'Marcelino',
      lastName: 'Welch',
      age: 18,
      visits: 161,
      progress: 7,
      createdAt: '2014-06-26T13:34:31.257Z',
      status: 'complicated'
    },
    {
      firstName: 'Abelardo',
      lastName: 'Murphy',
      age: 7,
      visits: 38,
      progress: 85,
      createdAt: '2008-09-27T09:24:01.855Z',
      status: 'complicated'
    },
    {
      firstName: 'Louvenia',
      lastName: 'Walter',
      age: 25,
      visits: 270,
      progress: 0,
      createdAt: '2001-10-29T09:59:21.160Z',
      status: 'complicated'
    },
    {
      firstName: 'Flo',
      lastName: 'Hills',
      age: 24,
      visits: 736,
      progress: 38,
      createdAt: '2002-09-10T16:13:16.344Z',
      status: 'single'
    },
    {
      firstName: 'Morgan',
      lastName: 'Witting',
      age: 33,
      visits: 155,
      progress: 47,
      createdAt: '2015-07-29T05:29:02.167Z',
      status: 'relationship'
    },
    {
      firstName: 'Jensen',
      lastName: 'Prosacco',
      age: 1,
      visits: 928,
      progress: 52,
      createdAt: '2011-01-30T11:26:30.524Z',
      status: 'single'
    },
    {
      firstName: 'Joan',
      lastName: 'Mante',
      age: 19,
      visits: 785,
      progress: 6,
      createdAt: '1990-05-17T02:54:43.377Z',
      status: 'complicated'
    },
    {
      firstName: 'Tad',
      lastName: 'Ruecker',
      age: 5,
      visits: 588,
      progress: 98,
      createdAt: '2004-11-05T18:17:08.165Z',
      status: 'single'
    },
    {
      firstName: 'Edgardo',
      lastName: 'Konopelski',
      age: 24,
      visits: 229,
      progress: 53,
      createdAt: '1999-01-10T03:01:54.851Z',
      status: 'relationship'
    },
    {
      firstName: 'Dexter',
      lastName: 'Lindgren',
      age: 40,
      visits: 77,
      progress: 59,
      createdAt: '2012-03-06T08:24:38.190Z',
      status: 'relationship'
    },
    {
      firstName: 'Mina',
      lastName: 'Kohler',
      age: 18,
      visits: 837,
      progress: 96,
      createdAt: '1992-08-24T17:43:13.092Z',
      status: 'complicated'
    },
    {
      firstName: 'Rebekah',
      lastName: 'Reinger',
      age: 16,
      visits: 170,
      progress: 47,
      createdAt: '2008-04-26T00:02:43.232Z',
      status: 'relationship'
    },
    {
      firstName: 'Isai',
      lastName: 'Mills',
      age: 13,
      visits: 618,
      progress: 44,
      createdAt: '1994-04-09T02:19:42.579Z',
      status: 'single'
    },
    {
      firstName: 'Rickey',
      lastName: 'Cummerata',
      age: 5,
      visits: 975,
      progress: 58,
      createdAt: '2004-09-13T20:39:25.209Z',
      status: 'single'
    },
    {
      firstName: 'Wava',
      lastName: 'Stanton',
      age: 15,
      visits: 60,
      progress: 7,
      createdAt: '2017-04-29T16:17:09.487Z',
      status: 'complicated'
    },
    {
      firstName: 'Zoe',
      lastName: 'Skiles',
      age: 35,
      visits: 32,
      progress: 88,
      createdAt: '2014-08-27T17:07:58.004Z',
      status: 'relationship'
    }
  ];

  return makeDataArray.slice(0, len);
};
