import { Stack, Box } from '@nib/layout';
import Heading from '@nib-components/heading';
import Copy from '@nib-components/copy';
import { ColorDot } from "../../../../src/components/colors/ColorPalette.js";
import * as React from 'react';
export default {
  Stack,
  Box,
  Heading,
  Copy,
  ColorDot,
  React
};