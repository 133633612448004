import React from 'react';
import {Box, Columns, Column, Stack} from '@nib/layout';
import {ChecklistSuccessGraphicIcon} from '@nib/icons';
import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';
import {ProgressStepper, useProgressStepper, CollapsedProgressStepper} from '@nib/progress-stepper';

const TitlePropInfo = () => (
  <Box background="warmWhite" padding={7}>
    <Columns space={{xs: 5, sm: 7}} collapseBelow="sm">
      <Column width="content">
        <Box color="trueGreen">
          <ChecklistSuccessGraphicIcon />
        </Box>
      </Column>
      <Column>
        <Stack space={6}>
          <Box color="trueGreen">
            <Heading size={3}>Titling is key</Heading>
          </Box>
          <Copy measure={false}>To ensure a user's recognition of individual steps' purpose, and their ability to navigate between them, concise and communicative titles are key.</Copy>
          <Copy measure={false}>
            Due to overflow behaviours in some contexts, titles longer than one or two words should have their intent communicated as early as possible in the title. This ensures that, if truncated,
            the title still communicates its purpose.
          </Copy>
        </Stack>
      </Column>
    </Columns>
  </Box>
);

export const StackStepper = () => {
  const testSteps = [
    {
      title: 'First Step',
      isAccessed: true,
      isDisabled: false,
      isComplete: false
    },
    {
      title: 'Second Step',
      isAccessed: true,
      isDisabled: false,
      isComplete: false
    },
    {
      title: 'Third Step',
      isAccessed: false,
      isDisabled: false,
      isComplete: false
    },
    {
      title: 'Fourth Step',
      isAccessed: false,
      isDisabled: false,
      isComplete: false
    }
  ];

  const {currentStep, steps, goToStep} = useProgressStepper(testSteps, 1);

  return <ProgressStepper steps={steps} currentStep={currentStep} goToStep={goToStep} align="stacked" collapseBelow="xl" />;
};

export const MobileStepper = () => {
  const testSteps = [
    {
      title: 'First Step',
      isAccessed: true,
      isDisabled: false,
      isComplete: false
    },
    {
      title: 'Second Step',
      isAccessed: true,
      isDisabled: false,
      isComplete: false
    },
    {
      title: 'Third Step',
      isAccessed: false,
      isDisabled: false,
      isComplete: false
    },
    {
      title: 'Fourth Step',
      isAccessed: false,
      isDisabled: false,
      isComplete: false
    }
  ];

  const {currentStep, steps, goToStep} = useProgressStepper(testSteps, 1);

  return (
    <CollapsedProgressStepper
      currentStep={currentStep}
      steps={steps}
      goToStep={goToStep}
      hasAccessedPrevious={currentStep >= 1 && (steps[currentStep - 1].isAccessed || steps[currentStep + 1].isAccessed)}
      hasAccessedNext={currentStep < steps.length - 1 && steps[currentStep].isAccessed}
    />
  );
};

export default TitlePropInfo;
