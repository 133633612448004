import React from 'react';
import PropTypes from 'prop-types';
import {Padding} from '@nib/layout';
import {Bold} from '@nib-components/copy';
import Item from '../Item';

const SuggestedList = ({suggestions, getItemProps, suggestionsTitle, highlightedIndex}) => (
  <React.Fragment>
    <Padding top={5} horizontal={3} bottom={4}>
      <Bold>{suggestionsTitle ? suggestionsTitle : 'Suggested'}</Bold>
    </Padding>
    {suggestions.map((suggestion, index) => (
      <Item
        key={`suggestion-${suggestion.text}-${index}`}
        {...getItemProps({
          item: suggestion,
          index,
          isActive: highlightedIndex === index
        })}
      >
        {suggestion.text}
      </Item>
    ))}
  </React.Fragment>
);

SuggestedList.propTypes = {
  suggestionsTitle: PropTypes.string,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string
    })
  ),
  getItemProps: PropTypes.func,
  highlightedIndex: PropTypes.number
};

export default SuggestedList;
