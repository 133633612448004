import {css} from 'styled-components';
import {SpacingType} from '@nib-components/theme';
import {ResponsiveSpaceProp} from './../../utils';
import {map} from '../../breakpoint';
import {p, px, py, pt, pb, pl, pr, pbs, pie, pbe, pis, pbl, pi} from '../../spacing';

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const paddings = () => css`
  ${({padding}: {padding?: ResponsiveSpaceProp}) =>
    map(
      padding,
      (val: SpacingType) =>
        val !== undefined &&
        css`
          ${p(val)};
        `
    )};

  ${(
    {paddingHorizontal}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    map(
      paddingHorizontal,
      (val: SpacingType) =>
        val !== undefined &&
        css`
          ${px(val)};
        `
    )};

  ${(
    {paddingVertical}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    map(
      paddingVertical,
      (val: SpacingType) =>
        val !== undefined &&
        css`
          ${py(val)};
        `
    )};

  ${(
    {paddingTop}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    map(
      paddingTop,
      (val: SpacingType) =>
        val !== undefined &&
        css`
          ${pt(val)};
        `
    )};

  ${(
    {paddingBottom}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    map(
      paddingBottom,
      (val: SpacingType) =>
        val !== undefined &&
        css`
          ${pb(val)};
        `
    )};

  ${(
    {paddingLeft}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    map(
      paddingLeft,
      (val: SpacingType) =>
        val !== undefined &&
        css`
          ${pl(val)};
        `
    )};

  ${(
    {paddingRight}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    map(
      paddingRight,
      (val: SpacingType) =>
        val !== undefined &&
        css`
          ${pr(val)};
        `
    )};

  ${(
    {paddingBlockStart}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    map(
      paddingBlockStart,
      (val: SpacingType) =>
        val !== undefined &&
        css`
          ${pbs(val)};
        `
    )};

  ${(
    {paddingInlineEnd}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    map(
      paddingInlineEnd,
      (val: SpacingType) =>
        val !== undefined &&
        css`
          ${pie(val)};
        `
    )};

  ${(
    {paddingBlockEnd}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    map(
      paddingBlockEnd,
      (val: SpacingType) =>
        val !== undefined &&
        css`
          ${pbe(val)};
        `
    )};

  ${(
    {paddingInlineStart}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    map(
      paddingInlineStart,
      (val: SpacingType) =>
        val !== undefined &&
        css`
          ${pis(val)};
        `
    )};

  ${(
    {paddingBlock}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    map(
      paddingBlock,
      (val: SpacingType) =>
        val !== undefined &&
        css`
          ${pbl(val)};
        `
    )};

  ${(
    {paddingInline}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    map(
      paddingInline,
      (val: SpacingType) =>
        val !== undefined &&
        css`
          ${pi(val)};
        `
    )};
`;
