import {createGlobalStyle} from 'styled-components';
import {FONTS_CDN_URL} from '../constants';

const BRAND_CDN_URL = `${FONTS_CDN_URL}/gu`;
const FONT_FAMILY = 'Buenos Aires';

export const GuFonts = createGlobalStyle`
    @font-face {
    font-family: ${FONT_FAMILY};
    src: url(${BRAND_CDN_URL}/BuenosAiresWeb-Regular.woff2) format('woff2'), 
        url(${BRAND_CDN_URL}/BuenosAiresWeb-Regular.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: ${FONT_FAMILY};
    src: url(${BRAND_CDN_URL}/BuenosAiresWeb-SemiBold.woff2) format('woff2'), 
        url(${BRAND_CDN_URL}/BuenosAiresWeb-SemiBold.woff) format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: ${FONT_FAMILY};
    src: url(${BRAND_CDN_URL}/BuenosAiresWeb-Bold.woff2) format('woff2'), 
        url(${BRAND_CDN_URL}/BuenosAiresWeb-Bold.woff) format('woff');
    font-weight: 700;
    font-style: bold;
    font-display: swap;
  }
`;
