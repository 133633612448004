import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import isEqual from 'react-fast-compare';

class AddressLookup extends React.Component {
  state = {
    options: [],
    loading: false,
    error: false
  };

  abortController = new AbortController();

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate({...prevProps}) {
    const {...props} = this.props;
    if (!isEqual(prevProps, props)) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  makeNetworkRequest = debounce(() => {
    const {inputValue = '', stateToFilterOn = '', kleberApiKey} = this.props;
    if (inputValue.length < 5) {
      this.setState({
        options: [],
        loading: false,
        error: false
      });
      return false;
    }
    const baseApiUrl = 'https://kleber.datatoolscloud.net.au/KleberWebService/DtKleberService.svc/ProcessQueryStringRequest?Method=DataTools.Capture.Address.Predictive.AuPaf.SearchAddress';

    const AddressLine = inputValue;
    const DepartmentCode = 'JOL';
    const RequestId = '';
    const ResultLimit = '';
    const RequestKey = kleberApiKey;
    const OutputFormat = 'json';

    const apiRequestUrl = `${baseApiUrl}&AddressLine=${AddressLine}&DepartmentCode=${DepartmentCode}&RequestId=${RequestId}&ResultLimit=${ResultLimit}&RequestKey=${RequestKey}&OutputFormat=${OutputFormat}`;
    const asyncData = async () => await (await fetch(apiRequestUrl, {signal: this.abortController.signal})).json();
    asyncData()
      .then(data => {
        const options = data.DtResponse.Result.filter(item => {
          if (stateToFilterOn.length) {
            return item.State === stateToFilterOn;
          } else {
            return true;
          }
        }).map(item => {
          const addressFormatted = `${item.AddressLine}, ${item.Locality} ${item.State} ${item.Postcode}`;
          return {text: addressFormatted, value: JSON.stringify(item)};
        });

        this.setState({
          options: options.length ? options : [],
          loading: false,
          error: false
        });
      })
      .catch(e => {
        this.setState({options: [], error: e.message, loading: false});
        // eslint-disable-next-line no-console
        console.error(e);
      });
  }, 400);

  fetchData = () => {
    this.setState({error: false, loading: true});
    this.makeNetworkRequest();
  };

  render() {
    const {children} = this.props;
    const {options, loading, error} = this.state;

    return children({
      options,
      loading,
      error,
      refetch: this.fetchData
    });
  }
}

AddressLookup.propTypes = {
  inputValue: PropTypes.string,
  kleberApiKey: PropTypes.string.isRequired,
  stateToFilterOn: PropTypes.string,
  children: PropTypes.node
};

export default AddressLookup;
