import React from 'react';
import {TabList as ReactTabList} from 'react-tabs';
import styled from 'styled-components';
import {Box, Container, pt} from '@nib/layout';

export interface TabListProps {
  center?: boolean;
  fill?: boolean;
  children?: React.ReactNode;
  showBorder?: boolean;
  withContainer?: boolean;
  [key: string]: unknown; // other props
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Wrapper = styled(({...rest}) => <Box {...rest} />)`
  overflow: auto visible;

  &::-webkit-scrollbar {
    display: none; /* Potentially an a11y issue... */
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  ${pt(6)};
  flex-shrink: 0;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const InnerTabList = styled(({center, ...rest}) => <ReactTabList {...rest} />)<TabListProps>`
  display: inline-flex;
  align-items: flex-start;
  min-width: 100%;
  margin: 0;
  padding: 0;
  ${({center}) => center && 'justify-content: center'};
`;

const WrappedTabList: React.FC<TabListProps> & {tabsRole: string} = ({children, center = false, fill = false, showBorder, withContainer, ...otherProps}) => {
  //eslint-disable-next-line no-undef
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const invariant = require('invariant');
    invariant(!(center && fill), '@nib-components/tabs: TabList cannot have both `center={true}` and `fill={true}`. Choose one.');
  }
  const ConditionalContainer = withContainer ? Container : 'div';
  return (
    <Wrapper>
      <ConditionalContainer>
        <InnerTabList center={center} {...otherProps}>
          {React.Children.map(
            children,
            (
              child: any // eslint-disable-line  @typescript-eslint/no-explicit-any
            ) => React.cloneElement(child, {fill, showBorder})
          )}
        </InnerTabList>
      </ConditionalContainer>
    </Wrapper>
  );
};

WrappedTabList.tabsRole = 'TabList';
WrappedTabList.displayName = 'TabList';

export default WrappedTabList;
