import { Stack, Section } from '@nib/layout';
import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';
import * as React from 'react';
export default {
  Stack,
  Section,
  Copy,
  Heading,
  React
};