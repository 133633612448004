import React from 'react';
import styled from 'styled-components';
import {p, mb} from '@nib/layout';
import Copy from '@nib-components/copy';
import {colorHighlightBg, colorHighlightText} from '@nib-components/theme';

export interface HighlightProps {
  children: React.ReactNode;
}

const HighlightMessage = styled.div`
  ${p(2)};
  /* to offset the tip */
  ${mb(3)};
  position: relative;
  background-color: var(--productCardColorBgHighlight, ${colorHighlightBg});
  color: var(--productCardColorFgHighlight, ${colorHighlightText});
  border-radius: 0.25rem;

  &::after {
    content: '';
    display: block;
    position: absolute;
    inset-block-start: 100%;
    inset-inline-start: 50%;
    transform: translateX(-50%);
    border: 0.5rem solid transparent;
    border-block-start-color: var(--productCardColorBgHighlight, ${colorHighlightBg});
  }
`;

const Highlight: React.FC<HighlightProps> = ({children}) => {
  return (
    <HighlightMessage>
      <Copy align="center">{children}</Copy>
    </HighlightMessage>
  );
};

export default Highlight;
