import styled from 'styled-components';

const Menu = styled.ul`
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  background-color: var(--themeColorBg, white);
  max-height: 20rem;
  overflow: hidden auto;
  outline: 0;
  transition: opacity 0.1s ease;
  box-shadow: 0 2px 4px 0 rgb(203 203 203 / 50%);

  /* Above toggle-button (10) */
  z-index: 20;
`;

export default Menu;
