import React from 'react';
import styled from 'styled-components';
import {DeleteSystemIcon, CloseSystemIcon, RotateSystemIcon} from '@nib/icons';
import {Hidden} from '@nib/layout';
import {SecondaryButton, UnstyledButton} from '@nib-components/button';
import {FileState, FileProgressEnum, ParentFileState, ActionFileOnParentCallback} from '../Interfaces';

export const InvisibleButton = styled(props => <UnstyledButton {...props} padding={2} />)`
  line-height: 0;
`;

type TryAgainButtonProps<T extends FileState> = {
  file: T;
  retryFile: ActionFileOnParentCallback<T>;
};

const TryAgainButton = <T extends FileState>({file, retryFile}: TryAgainButtonProps<T>): JSX.Element => {
  return (
    <>
      <Hidden below="md" screen={false} print={true}>
        <SecondaryButton small type="button" onClick={() => retryFile(file)} icon={RotateSystemIcon} iconPlacement="left">
          Try again
        </SecondaryButton>
      </Hidden>
      <Hidden above="md" screen={false} print={true}>
        <InvisibleButton type="button" onClick={() => retryFile(file)}>
          <RotateSystemIcon size="sm" />
        </InvisibleButton>
      </Hidden>
    </>
  );
};

type ActionButtonProps<T extends FileState> = Omit<ParentFileState<T>, 'files' | 'addFiles'> & {
  file: T;
};

export const ActionButton = <T extends FileState>({file, removeFile, retryFile}: ActionButtonProps<T>): JSX.Element => {
  if (retryFile && file.progress === FileProgressEnum.ERROR) {
    return <TryAgainButton file={file} retryFile={retryFile} />;
  }

  if (file.progress === FileProgressEnum.UPLOADING) {
    return (
      <InvisibleButton type="button" aria-label={`Cancel upload of ${file.name}`} onClick={() => removeFile(file)}>
        <CloseSystemIcon size="sm" />
      </InvisibleButton>
    );
  }

  return (
    <InvisibleButton type="button" aria-label={`Delete ${file.name}`} onClick={() => removeFile(file)}>
      <DeleteSystemIcon size="sm" />
    </InvisibleButton>
  );
};
