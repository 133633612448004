import axios, {AxiosError} from 'axios';
import {UploadProgressState, APIUploadStatus, fileUploadDispatchType} from '../StateManagement';
import {defineFormData} from './defineFormData';
import {defaultValues} from '../util';
import {FileProgressEnum} from '../../FileSelect';
import {FileDispatches} from '../Dispatches';
import {SessionReducerCases} from '../StateManagement/Reducers/ReducerCases';

type DoFileUploadParams =
  | string
  | {
      sessionInitialiseResolve?: (sessionId: string) => void;
      sessionInialiseReject?: () => void;
    };

function isAxiosError(err: unknown): err is AxiosError {
  const parsedErr = err as AxiosError;

  return parsedErr.isAxiosError;
}

export class UploadHelper {
  private UploadProgressState: UploadProgressState;
  private gatewayEndpoint: string = defaultValues.gatewayEndpoint;
  private proxyFileUploadUrl?: string;
  private fileUploadDispatch: fileUploadDispatchType;
  fileMetadataPromiseLock?: Promise<unknown>;
  uploadCancellation: AbortController;
  fileToken?: string;
  serviceName?: string;
  internalId?: string;
  customerId?: string;

  constructor({
    toUpload,
    gatewayEndpoint,
    fileUploadDispatch,
    proxyFileUploadUrl,
    serviceName,
    internalId,
    customerId
  }: {
    toUpload: UploadProgressState;
    fileUploadDispatch: fileUploadDispatchType;
    gatewayEndpoint?: string;
    proxyFileUploadUrl?: string;
    serviceName?: string;
    internalId?: string;
    customerId?: string;
  }) {
    if (gatewayEndpoint) this.gatewayEndpoint = gatewayEndpoint;
    this.fileUploadDispatch = fileUploadDispatch;

    this.UploadProgressState = {
      ...toUpload,
      uploadStatus: APIUploadStatus.Unknown,
      file: toUpload.file,
      inProgress: false
    };

    this.uploadCancellation = new AbortController();

    this.proxyFileUploadUrl = proxyFileUploadUrl;
    this.serviceName = serviceName;
    this.internalId = internalId;
    this.customerId = customerId;
  }

  private setErrorDispatch(): void {
    FileDispatches.errorFileThroughDispatch({
      fileUploadDispatch: this.fileUploadDispatch,
      fileToError: this.UploadProgressState
    });
  }

  private setLatestVersionDispatch(): void {
    FileDispatches.updateFileThroughDispatch({
      fileToUpdate: {
        ...this.UploadProgressState,
        backgroundUploader: this
      },
      fileUploadDispatch: this.fileUploadDispatch
    });
  }

  private async retrieveUploadLink(existingSession?: string): Promise<string> {
    const encodedFilename = encodeURIComponent(this.UploadProgressState.name);
    let suffix = `/upload?fileName=${encodedFilename}`;

    if (existingSession) {
      suffix = `${suffix}&sessionId=${existingSession}`;
    }

    if (this.serviceName) {
      const encodedserviceName = encodeURIComponent(this.serviceName);
      suffix = `${suffix}&serviceName=${encodedserviceName}`;
    }

    if (this.internalId) {
      const encodedInternalId = encodeURIComponent(this.internalId);
      suffix = `${suffix}&internalId=${encodedInternalId}`;
    }

    if (this.customerId) {
      const encodedCustomerId = encodeURIComponent(this.customerId);
      suffix = `${suffix}&customerId=${encodedCustomerId}`;
    }

    const requestUrl = this.gatewayEndpoint + suffix;

    if (this.uploadCancellation.signal.aborted) {
      throw new Error('Aborted');
    }

    const result = await axios.get(requestUrl, {
      headers: {
        'Cache-Control': 'no-cache'
      }
    });

    const {fields, key, sessionId, url} = result.data.data ?? {};

    if (!fields || !key || !sessionId || !url) {
      throw new Error('Unexpected response, missing data.');
    }

    this.fileToken = key;

    this.UploadProgressState.data = {
      fields,
      key,
      sessionId,
      url
    };

    return sessionId;
  }

  private async uploadFile(): Promise<void> {
    if (!this.UploadProgressState.data) {
      throw new Error('Missing necessary data for uploading.');
    }

    const url = this.UploadProgressState.data.url;
    const formData = defineFormData({
      UploadProgressState: this.UploadProgressState
    });

    if (this.uploadCancellation.signal.aborted) {
      return;
    }

    const fileUploadUrl = this.proxyFileUploadUrl ?? url;
    const result = await axios.post(fileUploadUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*'
      },
      signal: this.uploadCancellation.signal
    });

    if (result.status !== 200 && result.status !== 204) {
      throw new Error(`Unexpected status code ${result.status}`);
    }
  }

  async doFileUpload(params: DoFileUploadParams) {
    // Don't want to double call (dont trust the useEffect to not double call this method before we setUploadInProgress())
    // Also using inProgress for files that have finished uploading :)
    if (this.UploadProgressState.inProgress) return;

    this.UploadProgressState.inProgress = true;
    this.setLatestVersionDispatch();

    let existingSessionId: string | undefined;
    let sessionInitialiseResolve: ((sessionId: string) => void) | undefined;
    let sessionInitialiseReject: (() => void) | undefined;

    if (typeof params === 'string') {
      existingSessionId = params;
    } else {
      sessionInitialiseResolve = params.sessionInitialiseResolve;
      sessionInitialiseReject = params.sessionInialiseReject;
    }

    try {
      let metadataPromise: Promise<string>;
      this.fileMetadataPromiseLock = metadataPromise = this.retrieveUploadLink(existingSessionId);

      this.fileMetadataPromiseLock = metadataPromise
        .then(() => {
          return;
        })
        .catch(() => {
          return;
        });

      const sessionId = await metadataPromise;

      this.setLatestVersionDispatch();

      if (sessionInitialiseResolve) {
        this.fileUploadDispatch({
          type: 'SET SESSION ID',
          payload: sessionId
        });
        sessionInitialiseResolve(sessionId);
      }
    } catch (error) {
      this.setErrorDispatch();
      if (sessionInitialiseReject) {
        sessionInitialiseReject();
      }
    }

    try {
      await this.uploadFile();
    } catch (error) {
      this.setErrorDispatch();
    }
  }
}

type InitiateFileUploadOptions = {
  file: UploadProgressState;
  fileUploadDispatch: fileUploadDispatchType;
  sessionIdInitialiser?: Promise<string>;
};

export const initiateFileUpload = ({file, fileUploadDispatch, sessionIdInitialiser}: InitiateFileUploadOptions): Promise<string> | undefined => {
  let initialiser = sessionIdInitialiser;

  if (!file.backgroundUploader) {
    throw new Error('File has not had upload helper set... Cannot upload.');
  }

  if (file.progress === FileProgressEnum.ERROR_UPLOAD_BLOCKED) {
    // Don't attempt to upload files that have already been marked as invalid by client side logic
    return;
  }

  if (!initialiser) {
    initialiser = new Promise<string>((resolve, reject) => {
      file.backgroundUploader!.doFileUpload({sessionInitialiseResolve: resolve, sessionInialiseReject: reject});
    });

    initialiser.catch(() => {
      fileUploadDispatch({
        type: 'CLEAR INITIALISER'
      });
    });

    fileUploadDispatch({
      type: 'SET INITIALISER',
      payload: initialiser
    });
  } else {
    initialiser
      .then(sessionId => {
        file.backgroundUploader!.doFileUpload(sessionId);
      })
      .catch(() => {
        FileDispatches.errorFileThroughDispatch({
          fileToError: file,
          fileUploadDispatch
        });
      });
  }

  return initialiser;
};

export const fetchFileStatus = async (
  gatewayEndpoint: string,
  sessionId: string,
  uploadProgressState: UploadProgressState[],
  fileUploadDispatch: fileUploadDispatchType
): Promise<UploadProgressState[]> => {
  const url = gatewayEndpoint + '/status/' + sessionId;

  try {
    const response = await axios.get(url, {
      headers: {
        'Cache-Control': 'no-cache'
      }
    });

    if (response.status !== 200) {
      throw new Error('Unexpected response.');
    } else {
      const {data} = response.data;

      // Using first entry in array
      for (const serverSideFile of data.files) {
        const file = uploadProgressState.find(file => file.name === serverSideFile.fileName);

        if (!file) {
          continue;
        }

        const uploadStatus = serverSideFile.status;

        if (!uploadStatus) throw new Error('Returned no status for file.');

        file.uploadStatus = uploadStatus;

        file.uploaded = file.uploadStatus === APIUploadStatus.Complete;
        file.inProgress = !file.uploaded;

        if (file.uploaded) {
          file.progress = FileProgressEnum.UPLOADED;
          FileDispatches.updateFileThroughDispatch({
            fileToUpdate: file,
            fileUploadDispatch: fileUploadDispatch
          });
        } else if (file.uploadStatus === APIUploadStatus.Error) {
          file.errorMessage = serverSideFile.errors;
          FileDispatches.errorBlockFileThroughDispatch({
            fileToError: file,
            fileUploadDispatch: fileUploadDispatch
          });
        }
      }

      return uploadProgressState;
    }
  } catch (ex) {
    if (isAxiosError(ex) && ex.response?.status === 404) {
      return [];
    }

    throw ex;
  }
};

export const scheduleFetchFileStatus = (
  gatewayEndpoint: string,
  sessionId: string,
  uploadProgressState: UploadProgressState[],
  fileUploadDispatch: fileUploadDispatchType,
  existingIntervalHandle?: NodeJS.Timeout
) => {
  if (existingIntervalHandle) {
    clearInterval(existingIntervalHandle);
  }

  const timeoutHandle = setInterval(async () => {
    const newState = await fetchFileStatus(gatewayEndpoint, sessionId, uploadProgressState, fileUploadDispatch);

    if (!newState.some(f => f.inProgress)) {
      // Sometimes we end up with multiple intervals running.
      // Locking is hard with the current state setup.
      // This just tries to make the interval clean up after itself
      // if this happens.
      clearInterval(timeoutHandle);
    }
  }, 3000); // Wait 3 seconds before pinging the API again

  fileUploadDispatch({
    type: SessionReducerCases.SET_SESSION_INTERVAL,
    payload: timeoutHandle
  });

  return timeoutHandle;
};
