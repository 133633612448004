import {SessionActions} from '../../Actions/Session';
import {SessionState} from '../../Interfaces';
import {SessionReducerCases} from '../ReducerCases';

export function sessionReducer(state: SessionState, action: SessionActions): SessionState {
  switch (action.type) {
    case SessionReducerCases.SET_INITIALISER:
      return {
        ...state,
        initializer: action.payload
      };

    case SessionReducerCases.SET_SESSION_ID:
      return {
        ...state,
        sessionId: action.payload
      };

    case SessionReducerCases.SET_SESSION_INTERVAL:
      return {
        ...state,
        interval: action.payload
      };

    case SessionReducerCases.CLEAR_SESSION_INTERVAL:
      return {
        ...state,
        interval: undefined
      };

    case SessionReducerCases.CLEAR_INITIALISER:
      return {
        ...state,
        initializer: undefined
      };

    default:
      return state;
  }
}
