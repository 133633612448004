import {copyFontFamily, copyColor, copyLineHeight, colorBrandBase, colorBrandDark, colorWhite, colorLighter, colorDark, colorBlack, focusOutlineColor, token} from '@nib-components/theme';

export const backgroundColor = token('theme.color.bg.surface.prominent', colorWhite);
export const borderColor = backgroundColor;
export const highlightedColor = focusOutlineColor;
export const focusedColor = token('datePicker.color.bg.selected', colorBrandBase);
export const mutedColor = token('theme.color.fg.muted', colorDark);
export const whiteColor = token('theme.color.bg.surface.prominent', colorWhite);

export const dayBackgroundColor = backgroundColor;
export const dayBackgroundHoverColor = token('datePicker.color.bg.hover', colorLighter);

// export const lightSelectedColor = colorBrandLight;
export const selectedColor = token('datePicker.color.bg.selected', colorBrandBase);
export const selectedTextColor = token('datePicker.color.fg.selected', colorBrandBase);
export const darkSelectedColor = token('datePicker.color.bg.selected', colorBrandDark);
export const textColor = token('theme.color.fg', copyColor);
export const headerColor = token('theme.color.fg', copyColor);

export const navigationColor = textColor;
export const navigationHoverColor = colorBlack;
export const navigationDisabledColor = mutedColor;

export const borderRadius = '50%';
export const dayMargin = '0.125rem';
export const fontSize = '0.875rem';
export const lineHeight = copyLineHeight;
export const fontFamily = copyFontFamily;
export const itemSize = '2.25rem';
export const margin = '0.4rem';
export const navigationSize = '0.45rem';
