import React from 'react';
import styled, {css} from 'styled-components';
import {breakpoint, mt} from '@nib/layout';

export const characterWidths: Record<number, string> = {
  1: '5.41em',
  2: '6.42em',
  3: '7.23em',
  4: '8.19em',
  5: '9.10em',
  6: '10.07em',
  7: '10.98em',
  8: '11.89em',
  9: '12.85em',
  10: '13.81em',
  11: '14.99em',
  12: '16.06em',
  13: '16.60em',
  14: '17.45em',
  15: '18.74em',

  20: '23.02em',

  30: '32.29em'
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const InputWrapper = styled(({width, shrink, isSelectInput, isCompact, inline, isFullWidthSelect, ...rest}) => <div data-mesh-component="FORM-CONTROL--INPUT-WRAPPER" {...rest} />)`
  position: relative;
  ${mt(2)};

  ${({isSelectInput, isFullWidthSelect}) =>
    isSelectInput &&
    css`
      display: inline-block;
      max-width: ${isFullWidthSelect ? '100%' : 'calc(100% - 0.5rem)'};

      ${breakpoint('mini')`
        max-width: ${isFullWidthSelect ? '100%' : 'calc(100% - 2.5rem)'};
      `}
    `};

  ${({shrink}) =>
    !shrink &&
    css`
      width: 100%;

      ${breakpoint('sm')`
        max-width: ${({width}: {width?: string | number}) => characterWidths[width as number] || width};
      `}
    `};
`;

InputWrapper.displayName = 'InputWrapper';

export default InputWrapper;
