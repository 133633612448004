import React from 'react';
import styled from 'styled-components';
import {TickSystemIcon, CloseSystemIcon, InfoSystemIcon} from '@nib/icons';
import {COVER_TYPES} from '../constants';

import {colorDarkest, token} from '@nib-components/theme';

type COVER_TYPES_Values_Keys = keyof typeof COVER_TYPES;
type COVER_TYPES_Values = (typeof COVER_TYPES)[COVER_TYPES_Values_Keys];

export interface RenderIconProps {
  type: string;
  color: string;
  [key: string]: unknown;
}

export interface CoveredIconProps {
  covered?: COVER_TYPES_Values;
  color?: string;
  [key: string]: unknown;
}

export interface PartialInclusionProps {
  fontSize?: string;
  underline?: boolean;
  [key: string]: unknown;
}

export interface InclusionIconProps {
  coveredTypeCode?: COVER_TYPES_Values;
  color?: string;
}

const renderIcon: React.FC<RenderIconProps> = ({type, color, ...rest}) => {
  switch (type) {
    case 'tick':
    default:
      return <TickSystemIcon fill={color} size="xs" {...rest} />;
    case 'close':
      return <CloseSystemIcon fill={color} size={13} {...rest} />;
    case 'info':
      return <InfoSystemIcon fill={color} size="xs" {...rest} />;
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ColouredIcon = styled(({color, underline, type, ...rest}) => renderIcon({type, color, ...rest}))`
  display: inline-block;
  position: relative;
  inset-block-start: 3px;
  font-size: 0;
  ${props => props.underline && `border-bottom: 2px solid ${props.color};`};
`;

export const PartialInclusion = styled.span<PartialInclusionProps>`
  display: inline-block;
  color: ${props => (props.color ? props.color : token('theme.color.fg', colorDarkest))};
  font-weight: 700;
  font-size: ${props => (props.fontSize ? props.fontSize : '10px')};
  ${props => props.underline && `border-bottom: 2px solid currentColor;`};
`;

const CoveredIcon: React.FC<CoveredIconProps> = ({covered, color}) => {
  const INCLUDED = 'var(--themeColorFgSuccess, #25b11b)';
  const INCL = 'var(--themeColorFgMuted, #8a8a8a)';
  const EXCLUDED = 'var(--themeColorFgError, #94040C)';
  const RESTRICTED = 'var(--themeColorFgWarning, #ffb400)';

  //Icons
  if (covered === COVER_TYPES.INCLUSION) return <ColouredIcon color={color || INCLUDED} type="tick" />;
  if (covered === COVER_TYPES.INCL) return <ColouredIcon underline color={color || INCL} type="tick" />;
  if (covered === COVER_TYPES.EXCLUSION) return <ColouredIcon color={color || EXCLUDED} type="close" />;
  if (covered === COVER_TYPES.RESTRICTION) return <ColouredIcon color={color || RESTRICTED} type="info" />;

  //Plain text
  if (covered === COVER_TYPES.MBP) {
    return (
      <PartialInclusion aria-hidden color={color}>
        MBP
      </PartialInclusion>
    );
  }
  if (covered === COVER_TYPES.R) {
    return (
      <PartialInclusion aria-hidden color={color} underline fontSize="12px">
        R
      </PartialInclusion>
    );
  }
  if (covered === COVER_TYPES.LB) {
    return (
      <PartialInclusion aria-hidden color={color}>
        LB
      </PartialInclusion>
    );
  }
  //Otherwise do nothing I guess
  return null;
};

const InclusionIcon: React.FC<InclusionIconProps> = ({coveredTypeCode, color}) => {
  return <CoveredIcon covered={coveredTypeCode} color={color} />;
};

InclusionIcon.displayName = 'InclusionIcon';

export default InclusionIcon;
