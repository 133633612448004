import React from 'react';
import styled from 'styled-components';
import {Columns, Column} from '@nib/layout';
import {ErrorMessage, InfoMessage} from '../ErrorMessage';
import {SharedFormControlProps} from '../FormControl';
import LabelWithTag from '../LabelWithTag';
import Fieldset from '../Fieldset';
import Help from '../Help';
import InputWrapper from '../InputWrapper';
import Legend from '../Legend';
import ValidationIcon from '../ValidationIcon';
import defaultProps from '../common-default-props';

const ZeroFontSizeColumn = styled(Column)`
  font-size: 0;
`;

export interface FieldsetFormControlProps extends SharedFormControlProps {
  /**
   * @deprecated no longer necessary since v8.1.0
   */
  hasNegativeFieldsetMargin?: boolean;
  inline?: boolean;
}

const FieldsetFormControl: React.FC<FieldsetFormControlProps> = ({
  children,
  className = defaultProps.className,
  disabled = defaultProps.disabled,
  error = defaultProps.error,
  info,
  help = defaultProps.help,
  id = defaultProps.id,
  label,
  name = defaultProps.name,
  valid = defaultProps.valid,
  validated = defaultProps.validated,
  isCompact = defaultProps.isCompact,
  fullstory = defaultProps.fullstory,

  formControlType,
  formType,
  /* eslint-disable @typescript-eslint/no-unused-vars */
  hasNegativeFieldsetMargin = true,
  //redux-formo methods
  blur,
  change,
  filter,
  focus,
  initialise,
  validate,

  //redux-form props
  active,
  filtered,
  filtering,
  initialised,
  lastValidValue,
  validating,
  inline = true,
  ...otherProps
}) => (
  <div className={className} data-mesh-component="FORM-CONTROL--FIELDSET-FORM-CONTROL">
    <Fieldset disabled={disabled} aria-describedby={`help-${id} error-${id}`} aria-required={formControlType === 'required' || (formType === 'required' && !formControlType)}>
      <Legend isCompact={isCompact} disabled={disabled}>
        {formControlType ? <LabelWithTag label={label} formControlType={formControlType} /> : label}
      </Legend>

      {help ? (
        React.isValidElement(help) ? (
          <div id={`help-${id}`}>{help}</div>
        ) : (
          <Help id={`help-${id}`} disabled={disabled}>
            {help}
          </Help>
        )
      ) : null}

      <InputWrapper inline={inline} shrink isCompact={isCompact}>
        {children &&
          React.cloneElement(children, {
            ...otherProps,
            fullstory,
            name,
            disabled,
            valid,
            validated,
            id,
            'aria-invalid': validated && !valid,
            isCompact,
            inline
          })}
      </InputWrapper>
    </Fieldset>

    {validated && (
      <Columns verticalAlign="center">
        <ZeroFontSizeColumn width="content">{validated && !valid && <ValidationIcon valid={valid} disabled={disabled} outside isFieldset isCompact={isCompact} />}</ZeroFontSizeColumn>
        <Column>{validated && error && <ErrorMessage valid={valid} disabled={disabled} id={`${id}`} error={error} />}</Column>
      </Columns>
    )}
    {validated && info && <InfoMessage valid={valid} disabled={disabled} id={`${id}`} info={info} fullstory={fullstory} />}
  </div>
);

export default FieldsetFormControl;
