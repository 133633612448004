import {createGlobalStyle} from 'styled-components';
import {FONTS_CDN_URL} from '../constants';

const BRAND_CDN_URL = `${FONTS_CDN_URL}/priceline`;
const FONT_FAMILY = 'IBM Plex Sans';

export const PricelineFonts = createGlobalStyle`
    @font-face {
    font-family: ${FONT_FAMILY};
    src: url(${BRAND_CDN_URL}/IBMPlexSans-Regular.woff2) format('woff2'), 
        url(${BRAND_CDN_URL}/IBMPlexSans-Regular.woff) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: ${FONT_FAMILY};
    src: url(${BRAND_CDN_URL}/IBMPlexSans-Medium.woff2) format('woff2'), 
        url(${BRAND_CDN_URL}/IBMPlexSans-Medium.woff) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
`;
