import React from 'react';
import {Box} from '@nib/layout';
import {Tab, TabList, TabPanel, Tabs} from '@nib-components/tabs';
import {ThemePanel} from './theme-panel';
import {ComponentPanel} from './component-panel';
import {CommonPanel} from './common-panel';
import {PrimitivePanel} from './primitive-panel';

const TokenList = () => {
  return (
    <Box>
      <Tabs showBorder>
        <TabList>
          <Tab>Theme</Tab>
          <Tab>Component </Tab>
          <Tab>Common</Tab>
          <Tab>Primitive</Tab>
        </TabList>
        <TabPanel>
          <ThemePanel />
        </TabPanel>
        <TabPanel>
          <ComponentPanel />
        </TabPanel>
        <TabPanel>
          <CommonPanel />
        </TabPanel>
        <TabPanel>
          <PrimitivePanel />
        </TabPanel>
      </Tabs>
    </Box>
  );
};

TokenList.displayName = 'TokenList';

export default TokenList;
