import {IconType} from '@nib/icons';

export const validVariationValues = ['arhi', 'shareholders', 'nz', 'foundation', 'thrive', 'providers', 'gu', 'international', 'internationalStudents', 'internationalWorkers'] as const;
export const internationalVariations = ['internationalStudents', 'internationalWorkers', 'international'];
export type Variation = (typeof validVariationValues)[number];

type Nullable<T> = T | null;

interface Link {
  id: string;
  title: string;
  url: string;
}

export interface SocialMediaLink extends Link {
  key: string;
}

export interface FooterLink extends Link {
  targetBlank?: boolean;
}

export interface ContactLink extends Link {
  icon?: IconType;
  targetBlank?: boolean;
}

export interface Association {
  name: string;
  src: string;
  alt?: string;
}

export interface ContactConfig {
  heading?: string;
  phoneNumber?: string;
  corporatePhoneNumber?: Nullable<string>;
  overseasPhoneNumber?: Nullable<string>;
  email?: string;
  contactUrl?: string;
  openHours?: string[];
  contactLinks?: ContactLink[];
  address?: {
    mail: string[];
    office: string[];
  };
  socialLinks?: SocialMediaLink[];
  associations?: Association[];
}

export interface ColumnConfig {
  id: string;
  title: string;
  children: FooterLink[];
}

export interface SmallFooterConfig {
  id: string;
  hideAcknowledgementOfCountry?: boolean;
  copyrightText: string | JSX.Element;
  phoneNumber?: string;
  corporatePhoneNumber?: Nullable<string>;
  overseasPhoneNumber?: Nullable<string>;
  email?: string;
  links?: FooterLink[];
}

export interface FooterConfig {
  id: string;
  countryOfOrigin?: 'Australia' | 'New Zealand';
  contact: ContactConfig;
  columns: ColumnConfig[];
  smallFooterConfig: SmallFooterConfig;
}

export type CustomFooterConfig = Pick<FooterConfig, 'id'> & Partial<Omit<FooterConfig, 'id'>>;

export type GetConfigParams = {
  withAbsoluteLinks?: boolean;
  absoluteBaseUrl?: string;
};
