import Card, { FlashCard } from '@nib/card';
import Copy from '@nib-components/copy';
import Link from '@nib-components/link';
import Tag from '@nib/tag';
import { DoctorGraphicIcon } from '@nib/icons';
import { PrimaryButton, SecondaryButton } from '@nib-components/button';
import GreyBox from "../../../../src/components/examples/GreyBox";
import { Box, Inline } from '@nib/layout';
import * as React from 'react';
export default {
  Card,
  FlashCard,
  Copy,
  Link,
  Tag,
  DoctorGraphicIcon,
  PrimaryButton,
  SecondaryButton,
  GreyBox,
  Box,
  Inline,
  React
};