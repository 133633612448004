import Tooltip from '@nib/tooltip';
import { InfoSystemIcon } from '@nib/icons';
import { Inline } from '@nib/layout';
import Copy from '@nib-components/copy';
import { ColorDot } from "../../../../src/components/colors/ColorPalette.js";
import * as React from 'react';
export default {
  Tooltip,
  InfoSystemIcon,
  Inline,
  Copy,
  ColorDot,
  React
};