import { Tabs, Tab, TabList, TabPanel } from '@nib-components/tabs';
import { Box } from '@nib/layout';
import Heading from '@nib-components/heading';
import * as React from 'react';
export default {
  Tabs,
  Tab,
  TabList,
  TabPanel,
  Box,
  Heading,
  React
};