import {CSSProp} from 'styled-components';
import {PropsWithTheme, ButtonSchema, ButtonVariantSchema} from '../types';
import theme from './theme';

const button = (props: PropsWithTheme): ButtonSchema => theme(props).button;

export const buttonPrimaryStyles = (props: PropsWithTheme): ButtonVariantSchema => button(props).primary;
export const buttonSecondaryStyles = (props: PropsWithTheme): ButtonVariantSchema => button(props).secondary;
export const buttonTertiaryStyles = (props: PropsWithTheme): ButtonVariantSchema => button(props).tertiary;
export const buttonDestructiveStyles = (props: PropsWithTheme): ButtonVariantSchema => button(props).destructive;
export const buttonSmallStyles = (props: PropsWithTheme): CSSProp => button(props).small;
export const buttonMediumStyles = (props: PropsWithTheme): CSSProp => button(props).medium;
export const buttonLargeStyles = (props: PropsWithTheme): CSSProp => button(props).large;
