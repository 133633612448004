import styled from 'styled-components';
import {colorLight} from '@nib-components/theme';
import {p} from '@nib/layout';

export const SmallPlaceholder = styled.div`
  ${p(2)};
  background-color: ${colorLight};
`;

export const LargePlaceholder = styled.div`
  ${p(6)};
  background-color: ${colorLight};
`;
