import {ReactElement} from 'react';

export enum FileProgressEnum {
  UPLOADING = 'Uploading...',
  UPLOADED = 'Uploaded',
  ERROR = 'Error',
  ERROR_UPLOAD_BLOCKED = 'Error upload blocked'
}

export interface NewFile {
  file: File;
  progress?: FileProgressEnum;
  errorMessage?: string | ReactElement;
}

export interface FileState extends NewFile {
  name: string;
  fileId: string;
}
