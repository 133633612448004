import {useState} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import {StepType} from './ProgressStepper';

interface ProgressStepperHook {
  currentStep: number;
  steps: Array<StepType>;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  goToStep: (index: number) => void;
  setSteps: React.Dispatch<React.SetStateAction<StepType[]>>;
}

export const useProgressStepper = (inputSteps: Array<StepType>, initialStep = 0): ProgressStepperHook => {
  const [currentStep, setCurrentStep] = useState<number>(initialStep);
  const [steps, setSteps] = useState<Array<StepType>>(inputSteps);

  const goToNextStep = () => {
    if (currentStep < steps.length - 1) {
      const newSteps = cloneDeep(steps);
      newSteps[currentStep].isAccessed = true;
      newSteps[currentStep].isComplete = true;
      newSteps[currentStep + 1].isAccessed = true;

      setSteps(newSteps);
      setCurrentStep(currentStep + 1);
    }
  };

  const goToPreviousStep = () => {
    if (currentStep > 0) {
      const newSteps = cloneDeep(steps);
      newSteps[currentStep].isAccessed = true;
      newSteps[currentStep].isComplete = true;
      newSteps[currentStep - 1].isAccessed = true;

      setSteps(newSteps);
      setCurrentStep(currentStep - 1);
    }
  };

  const goToStep = (index: number) => {
    const newSteps = cloneDeep(steps);
    newSteps[currentStep].isAccessed = true;
    newSteps[index].isAccessed = true;

    setSteps(newSteps);
    setCurrentStep(index);
  };

  return {
    currentStep,
    steps,
    goToNextStep,
    goToPreviousStep,
    goToStep,
    setSteps
  };
};
