import styled, {css} from 'styled-components';
import {py, px, my} from '@nib/layout';
import {colorBrandBase} from '@nib-components/theme';

const Item = styled.li`
  display: block;
  position: relative;
  ${my(2)};
  ${py(2)};
  ${px(3)};
  cursor: pointer;
  line-height: 1.5;

  ${({isActive}) =>
    isActive &&
    css`
      color: var(--themeColorFgBrand, ${colorBrandBase});
      box-shadow: inset 2px 0 0 currentColor;
    `}
`;

export default Item;
