import {fileUploadDispatchType, SessionState, UploadProgressState} from '../StateManagement';
import axios from 'axios';
import {removeFileThroughDispatch} from '../Dispatches/File';

type ProcessFileDeletionOptions = {
  file: UploadProgressState;
  fileUploadDispatch: fileUploadDispatchType;
  gatewayEndpoint: string;
  session: SessionState;
};

export const processFileDeletion = async ({file, fileUploadDispatch, gatewayEndpoint, session}: ProcessFileDeletionOptions) => {
  const initialiser = session.initializer ?? Promise.resolve();

  // FIXME: Yea... this is the hack work around for the async promises all being
  // dependant on each other...
  // This is required so once the upload starts, even though it dispatches updates,
  // they may not have been processed into state before a user attempts to delete
  // a file. What this would mean is we could miss an update and _think_ that the
  // file hasn't been pushed yet when it actually has. In this scenario we would
  // remove the file from the client side view, but it would still be in the
  // server side session.
  // The whole state management and upload management needs a rething, but I
  // just don't have time right now :'(.
  // Could possibly be solved by implementing thunks as then at least each
  // dispatched action _should_ be able to access the state after pending
  // updates have been processed. Currently the only way to access the latest
  // state is to wait it to be processed and made available by a react render
  // cycle.

  if (file.backgroundUploader) {
    file.backgroundUploader.uploadCancellation.abort();
  }

  initialiser
    ?.then(sessionId => {
      if (file.backgroundUploader?.fileMetadataPromiseLock) {
        return file.backgroundUploader.fileMetadataPromiseLock.then(() => sessionId);
      }

      return sessionId;
    })
    .then(sessionId => {
      if (!file.backgroundUploader?.fileToken) {
        return;
      }

      const suffix = `/delete/${sessionId}/file/${file.backgroundUploader.fileToken}`;

      const url = gatewayEndpoint + suffix;

      return axios.delete(url, {
        headers: {
          'Cache-Control': 'no-cache'
        }
      });
    })
    .then(() => {
      removeFileThroughDispatch({
        fileToRemove: file,
        fileUploadDispatch
      });
    })
    .catch(error => {
      throw error;
    });
};
