/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/explicit-module-boundary-types */
import {css} from 'styled-components';

import {
  colorBrandBase,
  colorBrandLight,
  colorBrandDark,
  colorAccent,
  colorBlack,
  colorDarkest,
  colorDarker,
  colorDark,
  colorLight,
  colorLighter,
  colorLightest,
  colorNearWhite,
  colorWhite,
  colorHospital,
  colorExtras,
  colorCombined,
  colorHighlightBg,
  colorHighlightText,
  colorTrueGreen,
  colorTrueGreen80,
  colorTrueGreen60,
  colorTrueGreen40,
  colorTrueGreen20,
  colorBrightGreen,
  colorBrightGreen80,
  colorBrightGreen60,
  colorBrightGreen40,
  colorBrightGreen20,
  colorSunsetPink,
  colorSunsetPink80,
  colorSunsetPink60,
  colorSunsetPink40,
  colorSunsetPink20,
  colorWarmWhite,
  colorWarmWhite80,
  colorWarmWhite60,
  colorWarmWhite40,
  colorWarmWhite20,
  colorSageGreen,
  colorSageGreen80,
  colorSageGreen60,
  colorSageGreen40,
  colorSageGreen20,
  colorSuccess,
  colorInfo,
  colorError
} from '@nib-components/theme';
import {map} from '../../breakpoint';
import {ResponsiveOrStaticProp, Nullable} from './../../utils';

const VALID_TOKEN_BG_COLOR_VALUES = {
  default: `var(--themeColorBg)`,
  surface: `var(--themeColorBgSurface)`,
  surfaceProminent: `var(--themeColorBgSurfaceProminent)`
};

const VALID_TOKEN_FG_COLOR_VALUES = {
  default: `var(--themeColorFg)`,
  prominent: `var(--themeColorFgProminent)`,
  gentle: `var(--themeColorFgGentle)`,
  muted: `var(--themeColorFgMuted)`,
  brand: `var(--themeColorFgBrand)`,
  selected: `var(--themeColorFgSelected)`,
  label: `var(--themeColorFgLabel)`,
  success: `var(--themeColorFgSuccess)`,
  error: `var(--themeColorFgError)`,
  warning: `var(--themeColorFgWarning)`,
  info: `var(--themeColorFgInfo)`,
  heading2xl: `var(--themeColorFgHeading2xl)`,
  headingXl: `var(--themeColorFgHeadingXl)`,
  headingLg: `var(--themeColorFgHeadingLg)`,
  headingMd: `var(--themeColorFgHeadingMd)`,
  headingSm: `var(--themeColorFgHeadingSm)`,
  headingXs: `var(--themeColorFgHeadingXs)`,
  interactive: `var(--themeColorFgLink)`,
  interactiveHover: `var(--themeColorFgLinkHover)`,
  interactiveActive: `var(--themeColorFgLinkActive)`,
  interactiveDisabled: `var(--themeColorFgLinkDisabled)`
};

const VALID_TOKEN_BORDER_COLOR_VALUES = {
  default: `var(--themeColorBorder)`,
  prominent: `var(--themeColorBorderProminent)`,
  gentle: `var(--themeColorBorderGentle)`,
  muted: `var(--themeColorBorderMuted)`,
  selected: `var(--themeColorBorderSelected)`,
  success: `var(--themeColorBorderSuccess)`,
  error: `var(--themeColorBorderError)`,
  warning: `var(--themeColorBorderWarning)`,
  info: `var(--themeColorBorderInfo)`,
  brand: `var(--themeColorBorderBrand)`,
  focus: `var(--themeColorBorderFocus)`,
  input: `var(--inputColorBorder)`,
  productBasic: `var(--themeColorBorderProductBasic)`,
  productBronze: `var(--themeColorBorderProductBronze)`,
  productSilver: `var(--themeColorBorderProductSilver)`,
  productGold: `var(--themeColorBorderProductGold)`,
  productHospital: `var(--themeColorBorderProductHospital)`,
  productExtras: `var(--themeColorBorderProductExtras)`,
  productCombined: `var(--themeColorBorderProductCombined)`
};

export const validNibColorValues = [
  'trueGreen',
  'trueGreen80',
  'trueGreen60',
  'trueGreen40',
  'trueGreen20',
  'brightGreen',
  'brightGreen80',
  'brightGreen60',
  'brightGreen40',
  'brightGreen20',
  'sageGreen',
  'sageGreen80',
  'sageGreen60',
  'sageGreen40',
  'sageGreen20',
  'sunsetPink',
  'sunsetPink80',
  'sunsetPink60',
  'sunsetPink40',
  'sunsetPink20',
  'warmWhite',
  'warmWhite80',
  'warmWhite60',
  'warmWhite40',
  'warmWhite20',
  'clearWhite',
  'deepBlack',
  'white',
  'black'
] as const;

export type nibColorValues = (typeof validNibColorValues)[number];

export const validGenericColorValues = [
  'brandBase',
  'brandLight',
  'brandDark',
  'accent',
  'black',
  'darkest',
  'darker',
  'dark',
  'light',
  'lighter',
  'lightest',
  'nearWhite',
  'white',
  'hospital',
  'extras',
  'combined',
  'highlightBg',
  'highlightText',
  'transparent'
] as const;

export const validTokenFgColorValues = Object.keys(VALID_TOKEN_FG_COLOR_VALUES);
export const validTokenBgColorValues = Object.keys(VALID_TOKEN_BG_COLOR_VALUES);
export const validTokenBorderColorValues = Object.keys(VALID_TOKEN_BORDER_COLOR_VALUES);

export type tokenFgColorValues = (typeof validTokenFgColorValues)[number];
export type tokenBgColorValues = (typeof validTokenBgColorValues)[number];
export type tokenBorderColorValues = (typeof validTokenBorderColorValues)[number];
export type genericColorValues = (typeof validGenericColorValues)[number];

export const validFgColorValues = [...validNibColorValues, ...validGenericColorValues, ...validTokenFgColorValues];
export type colorFgValues = nibColorValues | genericColorValues | tokenFgColorValues;

export const validBgColorValues = [...validNibColorValues, ...validGenericColorValues, ...validTokenBgColorValues];
export type colorBgValues = nibColorValues | genericColorValues | tokenBgColorValues;

export const validBorderColorValues = [...validNibColorValues, ...validGenericColorValues, ...validTokenBorderColorValues];
export type colorBorderValues = nibColorValues | genericColorValues | tokenBorderColorValues;

export type responsiveFgColorValues = ResponsiveOrStaticProp<Nullable<colorFgValues>>;
export type responsiveBgColorValues = ResponsiveOrStaticProp<Nullable<colorBgValues>>;
export type responsiveBorderColorValues = ResponsiveOrStaticProp<Nullable<colorBorderValues>>;

export const pickFgColor = (colorString: colorFgValues) => {
  if (validNibColorValues.includes(colorString as nibColorValues) || validGenericColorValues.includes(colorString as genericColorValues)) {
    return pickGenericColor(colorString as nibColorValues | genericColorValues);
  }
  switch (colorString) {
    case 'default':
      return VALID_TOKEN_FG_COLOR_VALUES.default;
    case 'prominent':
      return VALID_TOKEN_FG_COLOR_VALUES.prominent;
    case 'gentle':
      return VALID_TOKEN_FG_COLOR_VALUES.gentle;
    case 'muted':
      return VALID_TOKEN_FG_COLOR_VALUES.muted;
    case 'brand':
      return VALID_TOKEN_FG_COLOR_VALUES.brand;
    case 'selected':
      return VALID_TOKEN_FG_COLOR_VALUES.selected;
    case 'label':
      return VALID_TOKEN_FG_COLOR_VALUES.label;

    case 'success':
      return VALID_TOKEN_FG_COLOR_VALUES.success;
    case 'error':
      return VALID_TOKEN_FG_COLOR_VALUES.error;
    case 'warning':
      return VALID_TOKEN_FG_COLOR_VALUES.warning;
    case 'info':
      return VALID_TOKEN_FG_COLOR_VALUES.info;
    case 'heading2xl':
      return VALID_TOKEN_FG_COLOR_VALUES.heading2xl;
    case 'headingXl':
      return VALID_TOKEN_FG_COLOR_VALUES.headingXl;
    case 'headingLg':
      return VALID_TOKEN_FG_COLOR_VALUES.headingLg;

    case 'headingMd':
      return VALID_TOKEN_FG_COLOR_VALUES.headingMd;
    case 'headingSm':
      return VALID_TOKEN_FG_COLOR_VALUES.headingSm;
    case 'headingXs':
      return VALID_TOKEN_FG_COLOR_VALUES.headingXs;
    case 'interactive':
      return VALID_TOKEN_FG_COLOR_VALUES.interactive;
    case 'interactiveHover':
      return VALID_TOKEN_FG_COLOR_VALUES.interactiveHover;
    case 'interactiveActive':
      return VALID_TOKEN_FG_COLOR_VALUES.interactiveActive;
    case 'interactiveDisable':
      return VALID_TOKEN_FG_COLOR_VALUES.interactiveDisabled;
  }
};

export const pickBgColor = (colorString: colorFgValues) => {
  if (validNibColorValues.includes(colorString as nibColorValues) || validGenericColorValues.includes(colorString as genericColorValues)) {
    return pickGenericColor(colorString as nibColorValues | genericColorValues);
  }
  switch (colorString) {
    case 'default':
      return VALID_TOKEN_BG_COLOR_VALUES.default;
    case 'error':
      return colorError;
    case 'info':
      return colorInfo;
    case 'success':
      return colorSuccess;
    case 'surface':
      return VALID_TOKEN_BG_COLOR_VALUES.surface;
    case 'surfaceProminent':
      return VALID_TOKEN_BG_COLOR_VALUES.surfaceProminent;
    case 'warning':
      return '#ffb400';
  }
};

export const pickBorderColor = (colorString: colorBorderValues) => {
  if (validNibColorValues.includes(colorString as nibColorValues) || validGenericColorValues.includes(colorString as genericColorValues)) {
    return pickGenericColor(colorString as nibColorValues | genericColorValues);
  }
  switch (colorString) {
    case 'default':
      return VALID_TOKEN_BORDER_COLOR_VALUES.default;
    case 'prominent':
      return VALID_TOKEN_BORDER_COLOR_VALUES.prominent;
    case 'gentle':
      return VALID_TOKEN_BORDER_COLOR_VALUES.gentle;
    case 'muted':
      return VALID_TOKEN_BORDER_COLOR_VALUES.muted;
    case 'brand':
      return VALID_TOKEN_BORDER_COLOR_VALUES.brand;
    case 'selected':
      return VALID_TOKEN_BORDER_COLOR_VALUES.selected;
    case 'success':
      return VALID_TOKEN_BORDER_COLOR_VALUES.success;
    case 'error':
      return VALID_TOKEN_BORDER_COLOR_VALUES.error;
    case 'warning':
      return VALID_TOKEN_BORDER_COLOR_VALUES.warning;
    case 'info':
      return VALID_TOKEN_BORDER_COLOR_VALUES.info;

    case 'focus':
      return VALID_TOKEN_BORDER_COLOR_VALUES.focus;
    case 'input':
      return VALID_TOKEN_BORDER_COLOR_VALUES.input;
    case 'productBasic':
      return VALID_TOKEN_BORDER_COLOR_VALUES.productBasic;
    case 'productBronze':
      return VALID_TOKEN_BORDER_COLOR_VALUES.productBronze;
    case 'productSilver':
      return VALID_TOKEN_BORDER_COLOR_VALUES.productSilver;
    case 'productGold':
      return VALID_TOKEN_BORDER_COLOR_VALUES.productGold;

    case 'productHospital':
      return VALID_TOKEN_BORDER_COLOR_VALUES.productHospital;
    case 'productExtras':
      return VALID_TOKEN_BORDER_COLOR_VALUES.productExtras;
    case 'productCombined':
      return VALID_TOKEN_BORDER_COLOR_VALUES.productCombined;
  }
};

const pickGenericColor = (colorString: nibColorValues | genericColorValues) => {
  switch (colorString) {
    case 'trueGreen':
      return colorTrueGreen;
    case 'trueGreen80':
      return colorTrueGreen80;
    case 'trueGreen60':
      return colorTrueGreen60;
    case 'trueGreen40':
      return colorTrueGreen40;
    case 'trueGreen20':
      return colorTrueGreen20;
    case 'brightGreen':
      return colorBrightGreen;
    case 'brightGreen80':
      return colorBrightGreen80;
    case 'brightGreen60':
      return colorBrightGreen60;
    case 'brightGreen40':
      return colorBrightGreen40;
    case 'brightGreen20':
      return colorBrightGreen20;
    case 'sageGreen':
      return colorSageGreen;
    case 'sageGreen80':
      return colorSageGreen80;
    case 'sageGreen60':
      return colorSageGreen60;
    case 'sageGreen40':
      return colorSageGreen40;
    case 'sageGreen20':
      return colorSageGreen20;
    case 'sunsetPink':
      return colorSunsetPink;
    case 'sunsetPink80':
      return colorSunsetPink80;
    case 'sunsetPink60':
      return colorSunsetPink60;
    case 'sunsetPink40':
      return colorSunsetPink40;
    case 'sunsetPink20':
      return colorSunsetPink20;
    case 'warmWhite':
      return colorWarmWhite;
    case 'warmWhite80':
      return colorWarmWhite80;
    case 'warmWhite60':
      return colorWarmWhite60;
    case 'warmWhite40':
      return colorWarmWhite40;
    case 'warmWhite20':
      return colorWarmWhite20;
    case 'clearWhite':
      return '#ffffff';
    case 'deepBlack':
      return '#141414';
    case 'brandBase':
      return colorBrandBase;
    case 'brandLight':
      return colorBrandLight;
    case 'brandDark':
      return colorBrandDark;
    case 'accent':
      return colorAccent;
    case 'black':
      return colorBlack;
    case 'darkest':
      return colorDarkest;
    case 'darker':
      return colorDarker;
    case 'dark':
      return colorDark;
    case 'light':
      return colorLight;
    case 'lighter':
      return colorLighter;
    case 'lightest':
      return colorLightest;
    case 'nearWhite':
      return colorNearWhite;
    case 'white':
      return colorWhite;
    case 'hospital':
      return colorHospital;
    case 'extras':
      return colorExtras;
    case 'combined':
      return colorCombined;
    case 'highlightBg':
      return colorHighlightBg;
    case 'highlightText':
      return colorHighlightText;
    case 'transparent':
      return 'transparent';
  }
};

export const background = () => css`
  ${({background}: {background?: responsiveBgColorValues}) =>
    map(
      background,
      (val: colorBgValues) =>
        val !== undefined &&
        css`
          background: ${pickBgColor(val)};
        `
    )};
`;

export const color = () => css`
  ${({color}: {color?: responsiveFgColorValues}) =>
    map(
      color,
      (val: colorFgValues) =>
        val !== undefined &&
        css`
          color: ${pickFgColor(val)};
        `
    )};
`;
