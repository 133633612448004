import {AddFilesOnParentCallback, FileState} from '../Interfaces';
import {processFileSelection} from './processFileSelection';

export const processFileDrop = ({
  event,
  currentFiles,
  addFiles,
  acceptedFileTypes,
  maxFileSizeLimitInMB,
  maxCombinedFileSizeLimitInMB
}: {
  event: React.DragEvent<unknown>;
  currentFiles: FileState[];
  addFiles: AddFilesOnParentCallback;
  acceptedFileTypes: string;
  maxFileSizeLimitInMB?: number;
  maxCombinedFileSizeLimitInMB?: number;
}): void => {
  event.preventDefault();

  if (!event.dataTransfer.items) return;

  const {items} = event.dataTransfer;
  if (!items || items.length < 1) return;

  const files: File[] = [];

  for (let i = 0; i < items.length; i++) {
    const item = items[i];

    if (item.kind === 'file') {
      const file = item.getAsFile();

      if (file) {
        files.push(file);
      }
    }
  }

  processFileSelection({
    acceptedFileTypes,
    currentFiles,
    addFiles,
    files,
    maxFileSizeLimitInMB,
    maxCombinedFileSizeLimitInMB
  });
};
