import styled from 'styled-components';

const Fieldset = styled.fieldset.attrs({
  'data-mesh-component': 'FORM-CONTROL--FIELDSET'
})`
  padding: 0;
  margin: 0;
  border: none;
`;

export default Fieldset;
