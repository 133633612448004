import React, {Children} from 'react';

import styled, {css} from 'styled-components';
import {SpacingType, BreakpointType, spacing} from '@nib-components/theme';
import {Box, BoxProps} from '../Box';
import {Columns} from '../Columns';
import {Column} from '../Column';
import {breakpoint, map} from '../breakpoint';
import {ResponsiveSpaceProp} from '../utils';

export interface SplitSectionProps {
  padding?: ResponsiveSpaceProp;
  collapseBelow?: BreakpointType;
  children: React.ReactNode;
  reverse?: boolean;
  [key: string]: unknown;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ContainerWrapper = styled(({position, collapseBelow, reverse, ...rest}) => <Box {...rest}></Box>)`
  width: 100%;

  ${({collapseBelow, reverse}) =>
    collapseBelow &&
    css`
      ${breakpoint(collapseBelow)`
        ${({position}: {position?: string}) =>
          position === 'left'
            ? css`
                & > ${PartialContainer} {
                  margin-inline-start: ${reverse ? 'unset' : 'auto'};
                  margin-inline-end: ${reverse ? 'auto' : 'unset'};
                }
              `
            : css`
                & > ${PartialContainer} {
                  margin-inline-start: ${reverse ? 'auto' : 'unset'};
                  margin-inline-end: ${reverse ? 'unset' : 'unset'};
                }
              `}
      `};
    `};
`;

export const PartialContainer = styled.div<BoxProps>`
  ${({padding, collapseBelow}: {padding?: ResponsiveSpaceProp; collapseBelow?: BreakpointType}) =>
    map(
      padding,
      (val: SpacingType) =>
        val !== undefined &&
        collapseBelow &&
        css`
          ${breakpoint(collapseBelow)`
            max-width: calc((75rem / 2) - ${spacing[val]});
          `};
        `
    )};
`;

export const SplitSection: React.FC<SplitSectionProps> & {Item: typeof PartialContainer} = ({padding = {xs: 4, md: 6, lg: 8}, collapseBelow = 'xs', reverse, children}) => {
  return (
    <Columns data-mesh-component="SPLIT-SECTION" collapseBelow={collapseBelow} reverse={reverse}>
      {Children.map(children, (child, index) => {
        if (index < 2 && React.isValidElement(child)) {
          return (
            <Column width="1/2" flex>
              <ContainerWrapper position={index === 0 ? 'left' : 'right'} collapseBelow={collapseBelow} padding={padding} reverse={reverse} {...child.props}>
                {React.cloneElement(child as React.ReactElement, {
                  padding,
                  collapseBelow,
                  reverse
                })}
              </ContainerWrapper>
            </Column>
          );
        }
        return null;
      })}
    </Columns>
  );
};

SplitSection.displayName = 'SplitSection';
SplitSection.Item = PartialContainer;
