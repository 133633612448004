import React from 'react';
import styled, {css} from 'styled-components';
import {m, p, ml} from '@nib/layout';
import {SortDirection} from '@tanstack/react-table';
import {SortSystemIcon, ChevronDownSystemIcon, ChevronUpSystemIcon} from '@nib/icons';
import {colorLighter, colorWhite, colorBrandBase, standardFocusStyleDeclarations, focusOutlineWidth, colorNearWhite} from '@nib-components/theme';

export interface TableHeadingProps {
  textAlign?: string;
  collapseColumn?: boolean;
  fixedColumn?: boolean;
  isSorted?: false | SortDirection;
}

const thStyles = css<TableHeadingProps>`
  ${m(0)};
  ${p(2)};
  background-color: var(--themeColorBgSurface, ${colorWhite});
  border-bottom: 2px solid var(--themeColorBorderBrand, ${colorBrandBase});
  line-height: 1;
  font-weight: bold;
  font-size: 1rem;

  ${({textAlign}) =>
    textAlign
      ? css`
          text-align: ${textAlign};
        `
      : `text-align: start;`};

  ${({collapseColumn}) =>
    collapseColumn &&
    `
      width: 0.0000000001% !important;
  `};

  ${({fixedColumn}) =>
    fixedColumn &&
    css`
      &:first-child {
        background-color: var(--themeColorBgSurface, ${colorWhite});
        position: sticky;
        inset-inline-start: 0;
        z-index: 2;

        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 0;
          height: 100%;
          z-index: 2;
          inset-block: 0;
          inset-inline-end: 2px;
          box-shadow: 2px 0 1px 1px ${colorLighter};
        }
      }
    `};
`;

export const Th = styled.th<TableHeadingProps>`
  ${thStyles};
`;

const SortIconWrapper = styled.span`
  ${ml(2)};
  display: flex;
`;

const NoPaddingTableHeading = styled.th<TableHeadingProps>`
  ${thStyles};
  ${p(0)};
`;

export const TableHeadingButton = styled.button<{isSorted?: false | SortDirection}>`
  ${m(0)};
  ${p(2)};
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: bold;
  line-height: 1;
  color: inherit;

  ${({isSorted}) =>
    isSorted &&
    css`
      color: var(--themeColorFgLinkActive, ${colorBrandBase});
    `};

  &:focus-visible {
    ${standardFocusStyleDeclarations};
    outline-offset: -${focusOutlineWidth};
  }

  &:hover {
    background-color: var(--themeColorBgSurface, ${colorNearWhite});
    color: var(--themeColorFgBrand, ${colorBrandBase});
  }
`;
export interface SortableTableHeadingProps {
  colSpan?: number;
  textAlign?: string;
  collapseColumn?: boolean;
  fixedColumn?: boolean;
  isSorted?: false | SortDirection;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children: React.ReactNode;
  canColumnSort?: boolean;
}

export const TableHeading: React.FC<SortableTableHeadingProps> = ({textAlign, collapseColumn, fixedColumn, onClick, isSorted, canColumnSort, children, ...otherProps}) => {
  const UNSORT = 'Stop sorting rows by this header';
  const SORT_ASC = 'Sort rows by this header in ascending order';
  const SORT_DESC = 'Sort rows by this header in descending order';
  const label = isSorted ? (isSorted === 'desc' ? UNSORT : SORT_DESC) : SORT_ASC;

  // If not a sortable column
  if (!canColumnSort) {
    return (
      <Th textAlign={textAlign} collapseColumn={collapseColumn} fixedColumn={fixedColumn} {...otherProps}>
        {children}
      </Th>
    );
  }
  return (
    <NoPaddingTableHeading textAlign={textAlign} collapseColumn={collapseColumn} fixedColumn={fixedColumn} {...otherProps}>
      <TableHeadingButton isSorted={isSorted} onClick={onClick} aria-label={label}>
        {children}

        {/* Add a sort direction indicator */}
        <SortIconWrapper>{isSorted ? isSorted === 'asc' ? <ChevronUpSystemIcon size="xs" /> : <ChevronDownSystemIcon size="xs" /> : <SortSystemIcon size="xs" />}</SortIconWrapper>
      </TableHeadingButton>
    </NoPaddingTableHeading>
  );
};
