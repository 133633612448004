import React from 'react';
import {marked} from 'marked';

const renderer = {
  list(body: string, ordered: boolean) {
    return `<${ordered ? 'ol' : 'ul'}>${body}</${ordered ? 'ol' : 'ul'}>`;
  }
};

marked.use({renderer});

export interface MarkdownProps {
  content?: string;
}

class Markdown extends React.Component<MarkdownProps> {
  // eslint-disable-next-line   @typescript-eslint/no-explicit-any
  shouldComponentUpdate(nextProps: any) {
    return nextProps.content !== this.props.content;
  }

  render() {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: marked.parse(this.props.content || '')
        }}
      />
    );
  }
}
export default Markdown;
