import React from 'react';
import styled from 'styled-components';
import {colorLight} from '@nib-components/theme';
import {Box, breakpoint} from '@nib/layout';

const MatrixWrapper = styled(Box)`
  overflow: auto;

  max-width: calc(100vw - 2rem);

  ${breakpoint('sm')`
    max-width: calc(100vw - 4rem);
  `}

  ${breakpoint('lg')`
    max-width: calc(100vw - 15rem - 4rem);
  `}

  ${breakpoint('xxl')`
    max-width: calc(100vw - 15rem - 15rem - 9rem);
  `}
`;

export const MatrixHeader = styled(Box)`
  border-bottom: 1px solid ${colorLight};
`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const AccessibilityMatrix = ({children}) => {
  return (
    <MatrixWrapper display="flex" height="100%">
      {children}
    </MatrixWrapper>
  );
};

AccessibilityMatrix.displayName = 'AccessibilityMatrix';

export default AccessibilityMatrix;
