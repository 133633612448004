import styled from 'styled-components';

export const Container = styled.div.attrs({
  'data-mesh-component': 'CONTAINER'
})`
  width: 100%;
  max-width: ${({theme}) => (theme.container && theme.container.width) || '75rem'};
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
`;

Container.displayName = 'Container';
