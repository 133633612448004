import {css} from 'styled-components';
import Colr from 'colr';
import {
  buttonReset,
  getSmallButtonTokens,
  getMediumButtonTokens,
  getLargeButtonTokens,
  getPrimaryButtonTokens,
  getPrimaryButtonSelectedTokens,
  getPrimaryButtonDisabledTokens,
  getSecondaryButtonTokens,
  getSecondaryButtonSelectedTokens,
  getSecondaryButtonDisabledTokens,
  getTertiaryButtonTokens,
  getTertiaryButtonSelectedTokens,
  getTertiaryButtonDisabledTokens,
  getDestructiveButtonTokens,
  getDestructiveButtonSelectedTokens,
  getDestructiveButtonDisabledTokens
} from '../shared';
import common, {spacing} from '../../common';
import {ThemeSchema} from '../../types';
import token from '../../getToken';

export const hoverColor = (color: string): string => Colr.fromHex(color).darken(10).toHex();
export const activeColor = (color: string): string => Colr.fromHex(color).darken(20).toHex();

export const disabledColor = (color: string, percent?: number): string => {
  const opacity = percent || 0.3;
  const rgb = Colr.fromHex(color).toRgbArray().toString();
  return `rgba(${rgb}, ${opacity})`;
};

const typeScale = {
  1: '3.375rem',
  2: '2.25rem',
  3: '1.5rem',
  4: '1.125rem',
  5: '1rem',
  6: '.875rem'
};

const lineHeights = [1, 1.111, 1.333, 1.43];

const tracking = {
  normal: 'normal',
  tight: '-1px',
  tighter: '-2px'
};

const fonts = {
  heading: '"Roboto", "Helvetica Neue", "Helvetica", sans-serif',
  copy: '"Open Sans", "Helvetica Neue", "Helvetica", sans-serif'
};

const colors = {
  brand: {
    base: '#353269',
    light: '#47428c',
    dark: '#1E1743'
  },
  accent: '#FEBF00',
  shades: {
    black: '#222222',
    darkest: '#444444',
    darker: '#666666',
    dark: '#888888',
    light: '#cccccc',
    lighter: '#dddddd',
    lightest: '#eeeeee',
    nearWhite: '#fafafa',
    white: '#ffffff'
  },
  focus: '#89C531',
  success: '#47428c',
  error: '#d63e3e',
  info: '#47428c',
  product: {
    hospital: '#0088CC',
    extras: '#45428D',
    combined: '#45428D',
    highlight: {
      backgroundColor: '#008a20',
      textColor: '#ffffff'
    }
  }
};

const focus = {
  outlineColor: colors.focus,
  outlineStyle: 'dotted',
  outlineWidth: '1px',
  outlineOffset: '0.125em'
};

const baseButtonStyles = css`
  ${buttonReset};
  font-family: ${fonts.copy};
  font-weight: 700;
  letter-spacing: ${tracking.normal};
  border: none;
  border-radius: ${token('button.dimension.border.radius', '4px')};
  border-width: ${token('button.dimension.border.width', '2px')};
`;

const buttonStyles = {
  primary: {
    normal: css`
      ${baseButtonStyles};

      ${getPrimaryButtonTokens({
        bg: {
          default: colors.brand.base,
          hover: colors.brand.dark,
          active: colors.brand.dark,
          disabled: disabledColor(colors.brand.dark, 0.6)
        },
        fg: {
          default: colors.shades.white,
          hover: colors.shades.white,
          active: colors.shades.white,
          disabled: disabledColor(colors.shades.white)
        },
        border: {
          default: 'transparent',
          hover: 'transparent',
          active: 'transparent',
          disabled: 'transparent'
        }
      })};
    `,
    selected: css`
      ${getPrimaryButtonSelectedTokens({
        bg: colors.brand.dark,
        fg: colors.shades.white,
        border: 'transparent'
      })};
    `,
    disabled: css`
      ${getPrimaryButtonDisabledTokens({
        bg: disabledColor(colors.brand.base, 0.6),
        fg: disabledColor(colors.shades.white),
        border: 'transparent'
      })};
      cursor: not-allowed;
    `
  },
  secondary: {
    normal: css`
      ${baseButtonStyles};
      ${getSecondaryButtonTokens({
        bg: {
          default: colors.shades.white,
          hover: colors.shades.white,
          active: colors.shades.white,
          disabled: disabledColor(colors.shades.white, 0.4)
        },
        fg: {
          default: colors.brand.base,
          hover: colors.brand.dark,
          active: colors.brand.dark,
          disabled: disabledColor(colors.brand.base, 0.6)
        },
        border: {
          default: colors.brand.base,
          hover: colors.brand.light,
          active: colors.brand.base,
          disabled: disabledColor(colors.brand.base, 0.6)
        }
      })};
    `,
    selected: css`
      ${getSecondaryButtonSelectedTokens({
        bg: colors.shades.white,
        fg: colors.brand.dark,
        border: colors.brand.dark
      })};
    `,
    disabled: css`
      ${getSecondaryButtonDisabledTokens({
        bg: disabledColor(colors.shades.white, 0.4),
        fg: disabledColor(colors.brand.base, 0.6),
        border: disabledColor(colors.brand.base, 0.6)
      })};
      cursor: not-allowed;
    `
  },
  tertiary: {
    normal: css`
      ${baseButtonStyles};
      ${getTertiaryButtonTokens({
        bg: {
          default: colors.shades.white,
          hover: colors.shades.white,
          active: colors.shades.white,
          disabled: disabledColor(colors.shades.white, 0.4)
        },
        fg: {
          default: colors.brand.base,
          hover: colors.brand.dark,
          active: colors.brand.dark,
          disabled: disabledColor(colors.brand.base, 0.6)
        },
        border: {
          default: 'transparent',
          hover: 'transparent',
          active: 'transparent',
          disabled: 'transparent'
        }
      })};
    `,
    selected: css`
      ${getTertiaryButtonSelectedTokens({
        bg: colors.shades.white,
        fg: colors.brand.dark,
        border: 'transparent'
      })};
    `,
    disabled: css`
      ${getTertiaryButtonDisabledTokens({
        bg: disabledColor(colors.shades.white, 0.4),
        fg: disabledColor(colors.brand.base, 0.6),
        border: 'transparent'
      })};
      cursor: not-allowed;
    `
  },
  destructive: {
    normal: css`
      ${baseButtonStyles};
      ${getDestructiveButtonTokens({
        bg: {
          default: colors.shades.white,
          hover: colors.shades.white,
          active: colors.shades.white,
          disabled: disabledColor(colors.shades.white, 0.4)
        },
        fg: {
          default: colors.error,
          hover: hoverColor(colors.error),
          active: activeColor(colors.error),
          disabled: disabledColor(colors.error, 0.6)
        },
        border: {
          default: colors.error,
          hover: hoverColor(colors.error),
          active: activeColor(colors.error),
          disabled: disabledColor(colors.error, 0.6)
        }
      })};
    `,
    selected: css`
      ${getDestructiveButtonSelectedTokens({
        bg: colors.shades.white,
        fg: colors.error,
        border: colors.error
      })};
    `,
    disabled: css`
      ${getDestructiveButtonDisabledTokens({
        bg: disabledColor(colors.shades.white, 0.4),
        fg: disabledColor(colors.error, 0.6),
        border: disabledColor(colors.error, 0.6)
      })};
      cursor: not-allowed;
    `
  },
  small: css`
    ${getSmallButtonTokens({
      fontFamily: fonts.copy,
      fontSize: typeScale[6],
      fontWeight: 400,
      lineHeight: 1,
      letterSpacing: tracking.normal,
      textTransform: 'none',
      paddingBlock: spacing[3],
      paddingInline: spacing[3],
      gap: spacing[2]
    })}
  `,
  medium: css`
    ${getMediumButtonTokens({
      fontFamily: fonts.copy,
      fontSize: typeScale[5],
      fontWeight: 700,
      lineHeight: 1.25,
      letterSpacing: tracking.normal,
      textTransform: 'none',
      paddingBlock: spacing[4],
      paddingInline: spacing[6],
      gap: spacing[2]
    })}
  `,
  large: css`
    ${getLargeButtonTokens({
      fontFamily: fonts.copy,
      fontSize: typeScale[4],
      fontWeight: 700,
      lineHeight: 1.33,
      letterSpacing: tracking.normal,
      textTransform: 'none',
      paddingBlock: spacing[4],
      paddingInline: spacing[6],
      gap: spacing[2]
    })}
  `
};

const baseFormValues = {
  borderColor: colors.shades.dark,
  borderColorValid: colors.success,
  borderColorInvalid: colors.error,
  selectedToggleColor: colors.brand.light,
  helpTextColor: colors.shades.darker,
  helpTextSize: typeScale[6],
  labelColor: colors.shades.darkest,
  labelSize: typeScale[5],
  errorMessageTextColor: colors.error,
  infoMessageTextColor: colors.info
};

const iman: ThemeSchema = {
  ...common,
  typeScale,
  fonts,
  colors,
  focus,

  id: 'iman',
  mode: {
    form: 'light' //default
  },

  headings: {
    fontFamily: fonts.heading,
    color: 'currentColor',
    lineHeight: 1.333,

    h1: {
      fontFamily: fonts.heading,
      fontSize: typeScale[1],
      fontWeight: 300,
      lineHeight: lineHeights[1],
      tracking: tracking.tighter,
      uppercase: false
    },
    h2: {
      fontFamily: fonts.heading,
      fontSize: typeScale[2],
      lineHeight: lineHeights[2],
      fontWeight: 300,
      tracking: tracking.tight,
      uppercase: false
    },
    h3: {
      fontFamily: fonts.heading,
      fontSize: typeScale[3],
      lineHeight: lineHeights[2],
      fontWeight: 300,
      tracking: tracking.tight,
      uppercase: false
    },
    h4: {
      fontFamily: fonts.heading,
      fontSize: typeScale[4],
      lineHeight: lineHeights[2],
      fontWeight: 400,
      tracking: tracking.normal,
      uppercase: false
    },
    h5: {
      fontFamily: fonts.heading,
      fontSize: typeScale[5],
      lineHeight: lineHeights[2],
      fontWeight: 400,
      tracking: tracking.normal,
      uppercase: false
    },
    h6: {
      fontFamily: fonts.heading,
      fontSize: typeScale[5],
      lineHeight: lineHeights[2],
      fontWeight: 400,
      tracking: tracking.normal,
      uppercase: false
    }
  },

  copy: {
    color: colors.shades.darkest,
    fontFamily: fonts.copy,
    fontSize: typeScale[5],
    fontWeight: 400,
    lineHeight: lineHeights[3],
    smallFontSize: typeScale[6],
    largeFontSize: typeScale[4]
  },

  link: {
    loudColor: colors.brand.base,
    darkColor: colors.shades.darkest,
    lightColor: colors.shades.white,
    destructiveColor: colors.error
  },

  button: {
    small: buttonStyles.small,
    medium: buttonStyles.medium,
    large: buttonStyles.large,
    primary: buttonStyles.primary,
    secondary: buttonStyles.secondary,
    tertiary: buttonStyles.tertiary,
    destructive: buttonStyles.destructive
  },

  forms: {
    light: {
      ...baseFormValues,
      backgroundColor: colors.shades.lightest,
      inputBackgroundColor: colors.shades.white,
      boxShadow: `0 4px 8px 0 ${colors.shades.lighter}`
    },
    white: {
      ...baseFormValues,
      backgroundColor: colors.shades.white,
      inputBackgroundColor: colors.shades.lightest,
      boxShadow: 'none'
    }
  },

  smallFooter: {
    backgroundColor: colors.shades.darkest,
    textColor: colors.shades.lightest,
    linkColor: colors.shades.lightest
  },

  panel: {
    loud: {
      backgroundColor: colors.brand.base,
      textColor: colors.shades.white
    },
    muted: {
      backgroundColor: colors.shades.lighter,
      textColor: colors.shades.darkest
    },
    light: {
      backgroundColor: colors.shades.lightest,
      textColor: colors.shades.darkest
    },
    dark: {
      backgroundColor: colors.shades.darkest,
      textColor: colors.shades.white
    },
    white: {
      backgroundColor: colors.shades.white,
      textColor: colors.shades.darkest
    }
  }
};

export default iman;
