export const validBreakpointValues = ['xs', 'mini', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl'] as const;
export const validSpacingValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] as const;
export const validBorderRadiusValues = ['none', 'small', 'standard', 'full'] as const;
export const validBoxShadowValues = ['none', 'standard'] as const;

export type BreakpointType = (typeof validBreakpointValues)[number];
export type SpacingType = (typeof validSpacingValues)[number];

export type BorderRadiusType = (typeof validBorderRadiusValues)[number];
export type BoxShadowType = (typeof validBoxShadowValues)[number];

export const breakpoints: Record<BreakpointType, number> = {
  xs: 0,
  mini: 240,
  sm: 480,
  md: 640,
  lg: 800,
  xl: 960,
  xxl: 1200,
  xxxl: 1600
} as const;

export const container: string = '1200px' as const;

export const spacing: Record<SpacingType, string> = {
  0: 'var(--commonDimensionSpacing0, 0)',
  1: 'var(--commonDimensionSpacing1, .25rem)',
  2: 'var(--commonDimensionSpacing2, .5rem)',
  3: 'var(--commonDimensionSpacing3, .75rem)',
  4: 'var(--commonDimensionSpacing4, 1rem)',
  5: 'var(--commonDimensionSpacing5, 1.5rem)',
  6: 'var(--commonDimensionSpacing6, 2rem)',
  7: 'var(--commonDimensionSpacing7, 3rem)',
  8: 'var(--commonDimensionSpacing8, 4rem)',
  9: 'var(--commonDimensionSpacing9, 6rem)',
  10: 'var(--commonDimensionSpacing10, 8rem)'
} as const;

export const borderRadius: Record<BorderRadiusType, string> = {
  none: 'var(--themeDimensionBorderRadiusNone, 0)',
  small: 'var(--themeDimensionBorderRadiusSmall, 2px)',
  standard: 'var(--themeDimensionBorderRadiusStandard,4px)',
  full: 'var(--themeDimensionBorderRadiusFull,9999px)'
};

export const boxShadow: Record<BoxShadowType, string> = {
  none: 'none',
  standard: 'hsla(0, 0%, 0%, .13) 0px 4px 8px 0px'
};

export default {
  breakpoints,
  container,
  spacing,
  borderRadius,
  boxShadow
};
