import {type CSSProperties} from 'react';
import {css} from 'styled-components';
import {map} from '../../breakpoint';
import {ResponsiveOrStaticProp, Nullable} from './../../utils';

export type GridColumnType = ResponsiveOrStaticProp<Nullable<CSSProperties['gridColumn']>>;
export const gridColumn = () => css`
  ${({gridColumn}: {gridColumn?: GridColumnType}) =>
    map(
      gridColumn,
      (val: CSSProperties['gridColumn']) =>
        val &&
        css`
          grid-column: ${val};
        `
    )};
`;

export type GridRowType = ResponsiveOrStaticProp<Nullable<CSSProperties['gridRow']>>;
export const gridRow = () => css`
  ${({gridRow}: {gridRow?: GridRowType}) =>
    map(
      gridRow,
      (val: CSSProperties['gridRow']) =>
        val &&
        css`
          grid-row: ${val};
        `
    )};
`;
