import React from 'react';
import styled, {css} from 'styled-components';
import {mt} from '@nib/layout';
import {copyFontFamily, formErrorMessageColor, formInfoMessageColor, token} from '@nib-components/theme';
import {disabledOpacity} from '../disabled';
import FullStoryMask, {FullStoryLevel} from '../FullStoryMask';

export interface MessageProps {
  id: string;
  info?: string;
  valid?: boolean;
  disabled?: boolean;
  [key: string]: unknown;
}

export interface ErrorMessageProps extends MessageProps {
  error?: string;
}

export interface InfoMessageProps extends MessageProps {
  info?: string;
  fullstory?: FullStoryLevel;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Message = styled(({shouldDisplay, ...rest}) => <div {...rest} />)`
  ${mt(2)};

  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s;

  @media (prefers-reduced-motion: reduce) {
    .animation {
      transition: none;
    }
  }

  ${({shouldDisplay}) =>
    shouldDisplay &&
    `
    max-height: 80px;
  `};
`;
Message.displayName = 'Message';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const MessageText = styled(({type, ...rest}) => <div {...rest} />)`
  color: ${({type}) => (type === 'error' ? token('theme.color.fg.error', formErrorMessageColor) : type === 'info' ? token('theme.color.fg.info', formInfoMessageColor) : 'inherit')};
  font-size: 0.875rem;
  line-height: 1.5;
  font-weight: 700;
  font-family: ${copyFontFamily};

  ${({disabled}) =>
    disabled &&
    css`
      opacity: ${disabledOpacity};
    `};
`;

MessageText.displayName = 'MessageText';

export const ErrorMessage: React.FC<ErrorMessageProps> = props => {
  const {error = '', valid = false, id, disabled, ...otherProps} = props;
  return (
    <Message shouldDisplay={!valid} disabled={disabled} {...otherProps} data-mesh-component="FORM-CONTROL--ERROR-MESSAGE">
      <MessageText type="error" id={`error-${id}`} aria-live="polite" disabled={disabled}>
        {error}
      </MessageText>
    </Message>
  );
};

ErrorMessage.displayName = 'ErrorMessage';

export const InfoMessage: React.FC<InfoMessageProps> = props => {
  const {info = '', valid, id, disabled, fullstory, ...otherProps} = props;
  return (
    <Message shouldDisplay={valid && info && info.length} disabled={disabled} {...otherProps} data-mesh-component="FORM-CONTROL--INFO-MESSAGE">
      <MessageText type="info" id={`info-${id}`} aria-live="polite" disabled={disabled}>
        <FullStoryMask level={fullstory}>{info}</FullStoryMask>
      </MessageText>
    </Message>
  );
};

InfoMessage.displayName = 'InfoMessage';
