import React from 'react';
import styled from 'styled-components';
import {colorBrandBase, colorWhite} from '@nib-components/theme';
import {SizeMap, LogoComponent} from '../../types';

export const StyledPath = styled.path<{invert?: boolean}>`
  fill: ${props => (props.invert ? colorWhite : colorBrandBase)};
`;

export const StyledPolygon = styled.polygon<{invert?: boolean}>`
  fill: ${props => (props.invert ? colorWhite : colorBrandBase)};
`;

// small === min-height from brand guidelines
const sizes: SizeMap = {
  small: {
    width: '36px',
    height: '36px'
  },
  header: {
    width: '64px',
    height: '64px'
  },
  medium: {
    width: '48px',
    height: '48px'
  },
  large: {
    width: '64px',
    height: '64px'
  }
};

const ApiaLogo: LogoComponent = props => {
  const {invert = false, size = 'small', ...otherProps} = props;
  return (
    <svg x="0" y="0" viewBox="0 0 70.4 71" width={sizes[size].width} height={sizes[size].height} aria-label="APIA" role="img" {...otherProps}>
      <g>
        <StyledPath
          invert={invert}
          d="M55.7,63c1.7-0.2,3.3-0.4,3.7,0c0.4,0.4,0.7,1.8,0.4,2.5c-0.2,0.8-1.1,2.4-1.3,3c-0.2,0.5-0.2,1-0.4,1.2
        c-0.5,0.6-1.3,0.3-1.9,0.9c-0.5,0.6-1.9,0.3-2.5-0.5c-0.7-0.9-1.2-3.5-1.7-4.4c-0.5-0.9-0.7-2.2-0.3-2.8c0.4-0.5,1.9-1.2,2.2-0.4
        C54.2,63.1,54.3,63.1,55.7,63"
        />
        <StyledPath invert={invert} d="M49.8,36c0,1.2,1,1.5,2,1.5c2.6,0,2.7-2.1,2.7-2.8v-1.2C54,34,52.8,34,51.8,34.2C50.7,34.4,49.8,34.7,49.8,36" />
        <StyledPath
          invert={invert}
          d="M54.9,39.4c-0.1-0.4-0.2-0.8-0.2-1.2c-1.1,1.1-2.6,1.5-4.1,1.5c-2.3,0-4.1-1.2-4.1-3.6c0-2.7,2.1-3.4,4.1-3.7
        c2-0.3,3.9-0.2,3.9-1.6c0-1.4-1-1.6-2.2-1.6c-1.3,0-2.1,0.5-2.2,1.8h-3.3c0.2-3.1,3-4.1,5.7-4.1c2.4,0,5.3,0.5,5.3,3.5v6.3
        c0,1.1,0.1,2.2,0.4,2.7H54.9z M45.2,25.4h-3.3v-2.7h3.3V25.4z M45.2,39.4h-3.3V27.3h3.3V39.4z M35.4,39.7c-1.5,0-2.8-0.6-3.6-1.8h0
        v5.8h-3.3V27.3h3.2v1.5h0c0.8-1.3,2.1-1.9,3.6-1.9c3.8,0,5.5,3.1,5.5,6.5C40.7,36.7,38.9,39.7,35.4,39.7 M23.4,39.4l-1.3-3.7h-6.3
        l-1.3,3.7h-3.7l6.4-16.8h3.8l6.3,16.8H23.4z M69.7,35.5c0-3.4-2.3-6.1-3.3-7.8c-1-1.6-4.8-6.6-6.2-9.5c-1.1-2.2-1.7-4.3-2.2-5.5
        c-0.4-1-1.4-2.9-2.1-4c-0.6-1-1.7-3.1-2-4c-0.3-0.9-0.7-2.1-0.9-2.9c-0.2-0.7-0.6-1.5-1.6-1.4c-1.6,0.2-1.4,2.4-2,4.5
        c-0.5,2.1-0.9,7.1-1.9,8.5c0,0-1.5,1.9-1.8,2.1c-0.5,0.6-1.6,0.3-2.1,0.1c0,0-7.8-2.6-7.8-2.6c-1.1-0.3-1.7-0.5-1.8-0.6
        c0,0,0-0.1,0-0.1c-0.2-0.4,0.1-1.3,0.3-1.5c1.6-2.5,1-3,0.8-3.2c-0.3-0.3-0.7-0.4-1.2-0.5l-1.3-0.1c0,0-4.4-0.4-5.4-0.6
        c-0.5-0.1-1.6-0.4-2.2,0.1c-1.3,0.9-1.9,2.6-2.4,3.8l-0.2,0.5c-0.5,1.3-1.7,2.3-2.5,2.6c-0.7,0.2-1.8-0.3-2.6-1.2
        c-0.1-0.1-0.3-0.2-0.6-0.2c-0.7,0.1-3.6,4.4-3.6,4.4c-0.5,0.9-6.3,9.4-6.3,9.4l-1.4,1.7l-2.4,2.9l-1.4,1.7c-0.5,0.6-1,1.3-1.1,2.1
        c-0.2,2-0.2,6.9-0.2,6.9c0,0.2,0.2,1.6,1.2,4.6L3.3,50l1.1,3.1L5.1,55c0.3,0.6,0.6,1.3,1.3,1.7c1.1,0.7,2.7,0.8,3.5-0.1l3.5-4.1
        c1.1-1.2,2.3-1.9,2.9-2.3c2.7-1.6,4.9-2.2,6.1-2.5c0,0,6-0.9,7-0.9c2.2,0.1,4.3,0.4,6,1.1c1,0.4,1.9,1,2.8,1.9c0,0,1.1,0.9,1.8,0.5
        c0.4-0.3,0.8-1.5,0.8-1.5l0.1-1c0-0.2,0.1-1.1,0.3-1.4c0.2-0.2,0.5-0.4,0.7-0.3c0.7,0.4,1.3,1,1.6,1.6c0.4,0.8,0.7,1.8,1.1,2.8
        c0.4,1.1,0.7,2.2,1.2,3l1.7,2.6c0.8,0.9,2.2,2.1,3.4,2.5c0.8,0.3,1.5,0.2,2.1-0.2c0.5-0.4,1.6-2,1.9-2.2c0.5-0.3,1.2-0.5,1.7,0
        c0.3,0.2,1,1.5,1,1.5c0.4,0.6,1,1,1.6,1.1c0.7,0,1-0.5,1.2-0.9l1.2-2l1.2-2.5c0.5-1.1,6.4-9.9,6.4-9.9l0.6-1.3
        C70.2,40.2,69.7,35.5,69.7,35.5"
        />
      </g>
      <StyledPolygon invert={invert} points="19,26.8 16.8,32.9 21.1,32.9 19,26.8 " />
      <g>
        <StyledPath invert={invert} d="M34.5,29.5c-2.2,0-2.9,1.9-2.9,3.9c0,2,0.8,3.9,2.9,3.9c2.2,0,2.9-1.9,2.9-3.9C37.4,31.5,36.6,29.5,34.5,29.5" />
      </g>
    </svg>
  );
};

ApiaLogo.displayName = 'ApiaLogo';

export default ApiaLogo;
