// External URLs
export const GITHUB_URL = 'https://github.com/nib-group/design-system';
export const STORYBOOK_URL = 'https://www.meshdesignsystem.com/storybook/';
export const SKETCH_LIBRARY_URL = 'sketch://add-library?url=https%3A%2F%2Fmeshdesignsystem.com%2Fassets%2Fsketch%2Fmesh.xml';
export const FIGMA_LIBRARY_COMPONENTS_URL = 'https://www.figma.com/design/if2AnUKDXCewe0ZIZZXRyD/%F0%9F%92%A0-Mesh---Web-Components?node-id=19-9178&t=ag8bx5C02ZaqfwOc-0';
export const ROADMAP_URL = 'https://nibgroup.atlassian.net/jira/polaris/projects/DESOPS/ideas/view/3704728#';

// Emails
export const EMAIL_TEMPLATE = `mailto:designops@nib.com.au`;
// If the icon email template is changed, please update the link in website/README.md as well.
export const ICON_EMAIL_BODY = `To%20request%20an%20icon%2C%20please%20attach%20the%20svg%20file%20to%20the%20email%20and%20complete%20the%20form%20below%3A%0A%0AIcon%20details%3A%0A%0A-%20Icon%20name%3A%0A-%20System%20or%20Graphic%3A%0A-%20Svg%20description%20for%20accessibility%20%28if%20required%29%3A%0A-%20Tags%20for%20icon%20filters%3A%0A%0ADescribe%20the%20use%20case%20of%20this%20new%20icon%3A%0A%0A%7EA%20clear%20and%20concise%20description%20of%20why%20this%20icon%20is%20being%20created.%7E%0A%0AIcon%20Preview%0A%0A%7EProvide%20an%20image%20preview%20of%20the%20icon%20svg%7E%0A%0AAdditional%20information%3A%0A%0A%7EAdd%20any%20other%20context%20or%20screenshots%20about%20the%20icon%20request%20here.%7E%0A`;
export const ICON_EMAIL_TEMPLATE = `${EMAIL_TEMPLATE}?subject=Icon%20Request:%20[ICON_NAME]&body=${ICON_EMAIL_BODY}`;

// Icon Assets
export const ICON_ASSETS_BASEURL = `https://www.meshdesignsystem.com/assets/icons`;
export const SVG_ICON_DOWNLOAD_LINK = `${ICON_ASSETS_BASEURL}/icons.zip`;
export const PNG_ICON_DOWNLOAD_LINK = `${ICON_ASSETS_BASEURL}/png-icons.zip`;

// Brand Assets
export const BRAND_ASSETS_BASEURL = `https://www.meshdesignsystem.com/assets/brand`;
export const BRAND_PACK_URL =
  'https://nibholdings.sharepoint.com/sites/Brand/BrandTemplates/Forms/AllItems.aspx?RootFolder=%2Fsites%2FBrand%2FBrandTemplates%2FLogos%2Fnib%20Core&FolderCTID=0x012000A938ABFA6CB3054D95F49B913B7287DA';

// nib Logos
export const NIB_CORE_LOGOS_PACK_DOWNLOAD_LINK = `${BRAND_ASSETS_BASEURL}/nib-core-brand-logos.zip`;
export const NIB_FIRST_CHOICE_LOGOS_PACK_DOWNLOAD_LINK = `${BRAND_ASSETS_BASEURL}/nib-first-choice-logos.zip`;
export const NIB_FOUNDATION_LOGOS_PACK_DOWNLOAD_LINK = `${BRAND_ASSETS_BASEURL}/nib-foundation-logos.zip`;
export const NIB_REWARDS_LOGOS_PACK_DOWNLOAD_LINK = `${BRAND_ASSETS_BASEURL}/nib-rewards-logos.zip`;
export const NIB_THRIVE_LOGOS_PACK_DOWNLOAD_LINK = `${BRAND_ASSETS_BASEURL}/nib-thrive-logos.zip`;
export const NIB_THE_CHECKUP_LOGOS_PACK_DOWNLOAD_LINK = `${BRAND_ASSETS_BASEURL}/nib-the-checkup-logos.zip`;

// Star Symbol Pack
export const STAR_SYMBOL_DOWNLOAD_LINK = `${BRAND_ASSETS_BASEURL}/star-symbol.zip`;

// Star Symbol Pack
export const ARROW_SYMBOL_DOWNLOAD_LINK = `${BRAND_ASSETS_BASEURL}/arrow-symbol.zip`;

// Partner brand logos
export const IMAN_LOGOS_PACK_DOWNLOAD_LINK = `${BRAND_ASSETS_BASEURL}/iman-brand-logos.zip`;
export const GU_HEALTH_LOGOS_PACK_DOWNLOAD_LINK = `${BRAND_ASSETS_BASEURL}/gu-health-brand-logos.zip`;

// Lockup logos
export const AAMI_NIB_LOCKUP_PACK_DOWNLOAD_LINK = `${BRAND_ASSETS_BASEURL}/aami-nib-lockup.zip`;
export const APIA_NIB_LOCKUP_PACK_DOWNLOAD_LINK = `${BRAND_ASSETS_BASEURL}/apia-nib-lockup.zip`;
export const GU_HEALTH_NIB_LOCKUP_PACK_DOWNLOAD_LINK = `${BRAND_ASSETS_BASEURL}/gu-health-nib-lockup.zip`;
export const HONEYSUCKLE_HEALTH_NIB_LOCKUP_PACK_DOWNLOAD_LINK = `${BRAND_ASSETS_BASEURL}/honeysuckle-health-nib-lockup.zip`;
export const ING_NIB_LOCKUP_PACK_DOWNLOAD_LINK = `${BRAND_ASSETS_BASEURL}/ing-nib-lockup.zip`;
export const QANTAS_NIB_LOCKUP_PACK_DOWNLOAD_LINK = `${BRAND_ASSETS_BASEURL}/qantas-nib-lockup.zip`;
export const STAGGER_NIB_LOCKUP_PACK_DOWNLOAD_LINK = `${BRAND_ASSETS_BASEURL}/stagger-nib-lockup.zip`;
export const SUNCORP_NIB_LOCKUP_PACK_DOWNLOAD_LINK = `${BRAND_ASSETS_BASEURL}/suncorp-nib-lockup.zip`;
export const YOULY_NIB_LOCKUP_PACK_DOWNLOAD_LINK = `${BRAND_ASSETS_BASEURL}/youly-nib-lockup.zip`;
export const ORBIT_PROTECT_LOCKUP_PACK_DOWNLOAD_LINK = `${BRAND_ASSETS_BASEURL}/orbit-protect-lockup.zip`;

// Comms
export const SLACK_URL = 'https://nibit.slack.com/channels/mesh';
export const WORKPLACE_URL = 'https://nibgroup.workplace.com/groups/1079231549122810/';
export const DESIGNOPS_EMAIL = 'mailto:designops@nib.com.au';
export const DESIGNOPS_MAILTO = `mailto:${DESIGNOPS_EMAIL}`;

// JIRA
export const JIRA_SPRINT_BOARD = 'https://nibgroup.atlassian.net/jira/software/c/projects/DES/boards/72';
export const JIRA_PREP_BOARD = 'https://nibgroup.atlassian.net/jira/software/c/projects/DES/boards/73';

// PlatFORMs
export const CAPTCHA_SITE_KEY = '6LebofsmAAAAAD89iVdGB5021nHfZPG70FBUezWR'; // This is not a secret and is safe to be shared.
export const FILE_UPLOAD_API_URL = 'https://api-gateway.nib.com.au/file-upload/v1';
export const PLATFORMS_BASEURL = 'https://forms.nib.com.au';

export const BREAKPOINTS = {
  DRAWER: 'lg'
};
