import {APIUploadStatus, FileUploadStore, ParentFileStateRehydrate, UploadProgressState} from './Interfaces';

export type RehydrateStateParams = Partial<ParentFileStateRehydrate> & Pick<FileUploadStore, 'gatewayEndpoint'>;

export const rehydrateState = ({filesOnParent, sessionInitializer, gatewayEndpoint}: RehydrateStateParams): FileUploadStore => {
  const newFiles: UploadProgressState[] =
    filesOnParent?.map(file => {
      const newFile: UploadProgressState = {
        ...file,
        uploadStatus: APIUploadStatus.Unknown,
        inProgress: true,
        uploaded: false
      };

      return newFile;
    }) ?? [];

  return {
    files: newFiles,
    gatewayEndpoint,
    session: {
      initializer: sessionInitializer
    }
  };
};
