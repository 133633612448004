import {UploadProgressState} from '../StateManagement';

export const defineFormData = ({UploadProgressState}: {UploadProgressState: UploadProgressState}): FormData => {
  if (!UploadProgressState.data) {
    throw new Error('File missing data.');
  }

  const formData = new FormData();

  UploadProgressState.data.fields.map(field => formData.append(field.key, field.value));

  formData.append('file', UploadProgressState.file); // Stupid S3 POST requires file to be the last entry.

  return formData;
};
