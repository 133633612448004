import { colorDarker } from '@nib-components/theme';
import GreyBox from "../../../../src/components/examples/GreyBox";
import { ProductCardWrapper, ProductCardHeader, ProductCardBody, HighlightMessage, HospitalTiersBadgeModal, MinHeight, InclusionList, ResponsiveInclusionList } from '@nib-components/product-card';
import { procedures, services, dummyLimitsForService } from "../../../../src/components/product-card/index.js";
import * as React from 'react';
export default {
  colorDarker,
  GreyBox,
  ProductCardWrapper,
  ProductCardHeader,
  ProductCardBody,
  HighlightMessage,
  HospitalTiersBadgeModal,
  MinHeight,
  InclusionList,
  ResponsiveInclusionList,
  procedures,
  services,
  dummyLimitsForService,
  React
};