// Works like processFileDrop. Only difference is the files param it receives.
import {customAlphabet} from 'nanoid';
import {fileUploadDispatchType, SessionState, UploadProgressState} from '../StateManagement';
import {FileDispatches} from '../Dispatches';
import {FileProgressEnum, NewFile} from '../../FileSelect';
import {fileAlreadyInState} from './fileAlreadyInState';
import {initiateFileUpload, UploadHelper} from '../Upload/UploadHelper';

const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyz', 32);

type ProcessFileSelectionOptions = {
  files: NewFile[];
  fileUploadDispatch: fileUploadDispatchType;
  currentState: UploadProgressState[];
  sessionState: SessionState;
  gatewayEndpoint: string;
  proxyFileUploadUrl?: string;
  serviceName?: string;
  internalId?: string;
  customerId?: string;
};

export const processFileSelection = ({
  files,
  fileUploadDispatch,
  currentState,
  gatewayEndpoint,
  proxyFileUploadUrl,
  sessionState,
  serviceName,
  internalId,
  customerId
}: ProcessFileSelectionOptions) => {
  const newFileState: UploadProgressState[] = files.reduce((newFiles, addedFile) => {
    if (!addedFile || fileAlreadyInState(currentState, addedFile.file)) {
      return newFiles;
    }

    const newFile: UploadProgressState = {
      ...addedFile,
      fileId: nanoid(),
      name: addedFile.file.name as string,
      progress: addedFile.progress || FileProgressEnum.UPLOADING,
      inProgress: false,
      uploaded: false
    };

    // FIXME: Yep... This cirucular reference is SUPER gross... :'(
    // The state management needs a larger rework, but currently this is necessary
    // because as all the async operations are happening in the backgound
    // and interrupting, there are issues with the dispatched updates making
    // it through the state/reducer quick enough that the start/stopping of
    // the polling and delete functionality being able to work.
    newFile.backgroundUploader = new UploadHelper({
      fileUploadDispatch,
      toUpload: newFile,
      gatewayEndpoint,
      proxyFileUploadUrl,
      serviceName,
      internalId,
      customerId
    });

    newFiles.push(newFile);

    return newFiles;
  }, [] as UploadProgressState[]);

  FileDispatches.addFileThroughDispatch({
    fileUploadDispatch,
    filesToAdd: newFileState
  });

  let sessionIdInitialiser = sessionState.initializer;

  for (const fileToAdd of newFileState) {
    sessionIdInitialiser =
      initiateFileUpload({
        file: fileToAdd,
        fileUploadDispatch,
        sessionIdInitialiser
      }) ?? sessionIdInitialiser;
  }
};
