import React, {Component} from 'react';
import {withTheme} from 'styled-components';
import Media from 'react-media';
import {Margin, Inline} from '@nib/layout';
import {UnstyledButton} from '@nib-components/button';
import Copy from '@nib-components/copy';
import Expander from '@nib-components/expander';
import Link from '@nib-components/link';
import {ChevronDownSystemIcon, ChevronUpSystemIcon} from '@nib/icons';
import {BreakpointType} from '@nib-components/theme';
import {ResponsiveSpaceProp} from '../constants';

export interface ResponsiveInclusionListProps {
  // TODO: Replace this with useContext(ThemeContext) when we move away from a class component
  theme?: any; // eslint-disable-line  @typescript-eslint/no-explicit-any
  space?: ResponsiveSpaceProp;
  breakpoint?: BreakpointType;
  children?: React.ReactNode;
}
export interface ResponsiveInclusionListPropsState {
  showInclusions: boolean;
}

class ResponsiveInclusionList extends Component<ResponsiveInclusionListProps, ResponsiveInclusionListPropsState> {
  constructor(props: ResponsiveInclusionListProps) {
    super(props);
    this.state = {
      showInclusions: false
    };

    this.handleToggleInclusionsList = this.handleToggleInclusionsList.bind(this);
  }

  handleToggleInclusionsList() {
    this.setState({
      ...this.state,
      showInclusions: !this.state.showInclusions
    });
  }
  render() {
    const {
      theme: {breakpoints},
      space = 6,
      breakpoint = 'xxl',
      children
    } = this.props;

    return (
      <React.Fragment>
        <Media query={`(max-width: ${breakpoints[breakpoint]}px)`}>
          {matches =>
            matches ? (
              <React.Fragment>
                <Margin bottom={this.state.showInclusions ? space : 0}>
                  <Copy>
                    <Link component={UnstyledButton} onClick={this.handleToggleInclusionsList}>
                      <Inline space={2}>
                        <span>{this.state.showInclusions ? 'Hide' : 'Show'} cover details</span>
                        {this.state.showInclusions ? <ChevronUpSystemIcon size="xs" /> : <ChevronDownSystemIcon size="xs" />}
                      </Inline>
                    </Link>
                  </Copy>
                </Margin>

                <Expander expanded={this.state.showInclusions}>{children}</Expander>
              </React.Fragment>
            ) : (
              children
            )
          }
        </Media>
      </React.Fragment>
    );
  }
}

export default withTheme(ResponsiveInclusionList);
