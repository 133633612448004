import React from 'react';
import {Link as GatsbyLink} from 'gatsby';
import {Box, Columns, Column, Stack} from '@nib/layout';
import {SuccessGraphicIcon} from '@nib/icons';
import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';
import Link from '@nib-components/link';

const ImagerySignoff = () => (
  <Box background="trueGreen" color="white" padding={7}>
    <Columns space={{xs: 5, sm: 7}} collapseBelow="sm">
      <Column width="content">
        <Box color="brightGreen">
          <SuccessGraphicIcon />
        </Box>
      </Column>
      <Column>
        <Stack space={6}>
          <Box color="brightGreen">
            <Heading size={3}>Sign-off &amp; approval</Heading>
          </Box>
          <Copy measure={false}>
            Any usage of illustrations and photography (excluding iconography<sup>*</sup>) across non-whitelabeled projects needs to be approved by nib's{' '}
            <Link href="mailto:brandteam@nib.com.au" color="light">
              brand team
            </Link>{' '}
            before development or deployment. This is to ensure that selected imagery is appropriate for context, is validated against other usage of any particular image across our network, and is in
            line with our brand guidelines and proposition. Once your design(s) are ready for approval, please submit your Figma link or mockups to{' '}
            <Link href="mailto:brandteam@nib.com.au" color="light">
              brandteam@nib.com.au
            </Link>{' '}
            for review.
          </Copy>
          <Copy size="small" measure={false}>
            <sup>*</sup>
            For guidelines specific to iconography, refer to its{' '}
            <Link component={GatsbyLink} to="/foundations/iconography/usage/" color="light">
              Mesh documentation
            </Link>
            .
          </Copy>
        </Stack>
      </Column>
    </Columns>
  </Box>
);

export default ImagerySignoff;
