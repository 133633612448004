import {css} from 'styled-components';
import {MarginAutoSpacingType, ResponsiveMarginSpaceProp} from './../../utils';
import {map} from '../../breakpoint';
import {m, mx, my, mt, mb, ml, mr, mbs, mie, mbe, mis, mbl, mi} from '../../spacing';

// eslint-disable-line  @typescript-eslint/no-explicit-any
export const margins = () => css`
  ${({margin}: {margin?: ResponsiveMarginSpaceProp}) =>
    map(
      margin,
      (val: MarginAutoSpacingType) =>
        val !== undefined &&
        css`
          ${m(val)};
        `
    )};

  ${(
    {marginHorizontal}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    map(
      marginHorizontal,
      (
        val: any // eslint-disable-line  @typescript-eslint/no-explicit-any
      ) =>
        val !== undefined &&
        css`
          ${mx(val)};
        `
    )};

  ${(
    {marginVertical}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    map(
      marginVertical,
      (
        val: any // eslint-disable-line  @typescript-eslint/no-explicit-any
      ) =>
        val !== undefined &&
        css`
          ${my(val)};
        `
    )};

  ${(
    {marginTop}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    map(
      marginTop,
      (
        val: any // eslint-disable-line  @typescript-eslint/no-explicit-any
      ) =>
        val !== undefined &&
        css`
          ${mt(val)};
        `
    )};

  ${(
    {marginBottom}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    map(
      marginBottom,
      (
        val: any // eslint-disable-line  @typescript-eslint/no-explicit-any
      ) =>
        val !== undefined &&
        css`
          ${mb(val)};
        `
    )};

  ${(
    {marginLeft}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    map(
      marginLeft,
      (
        val: any // eslint-disable-line  @typescript-eslint/no-explicit-any
      ) =>
        val !== undefined &&
        css`
          ${ml(val)};
        `
    )};

  ${(
    {marginRight}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    map(
      marginRight,
      (
        val: any // eslint-disable-line  @typescript-eslint/no-explicit-any
      ) =>
        val !== undefined &&
        css`
          ${mr(val)};
        `
    )};

  ${(
    {marginBlockStart}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    map(
      marginBlockStart,
      (
        val: any // eslint-disable-line  @typescript-eslint/no-explicit-any
      ) =>
        val !== undefined &&
        css`
          ${mbs(val)};
        `
    )};

  ${(
    {marginInlineEnd}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    map(
      marginInlineEnd,
      (
        val: any // eslint-disable-line  @typescript-eslint/no-explicit-any
      ) =>
        val !== undefined &&
        css`
          ${mie(val)};
        `
    )};

  ${(
    {marginBlockEnd}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    map(
      marginBlockEnd,
      (
        val: any // eslint-disable-line  @typescript-eslint/no-explicit-any
      ) =>
        val !== undefined &&
        css`
          ${mbe(val)};
        `
    )};

  ${(
    {marginInlineStart}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    map(
      marginInlineStart,
      (
        val: any // eslint-disable-line  @typescript-eslint/no-explicit-any
      ) =>
        val !== undefined &&
        css`
          ${mis(val)};
        `
    )};

  ${(
    {marginBlock}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    map(
      marginBlock,
      (
        val: any // eslint-disable-line  @typescript-eslint/no-explicit-any
      ) =>
        val !== undefined &&
        css`
          ${mbl(val)};
        `
    )};

  ${(
    {marginInline}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    map(
      marginInline,
      (
        val: any // eslint-disable-line  @typescript-eslint/no-explicit-any
      ) =>
        val !== undefined &&
        css`
          ${mi(val)};
        `
    )};
`;
