import React, {useReducer} from 'react';
import {defaultValues, processFileDeletion, processFileSelection} from './util';

import {useRehydrateFromParentState, useSetFilesOnParent, useStartFileStatusPollWhenFilesUpdated} from './Effects';
import {FileSelect} from '../FileSelect';
import {retryFailedFile} from './Dispatches';
import {GlobalReducer, ParentFileStateProps, ParentUploadReadyState, rehydrateState} from './StateManagement';

export interface FileUploadParams extends ParentFileStateProps, ParentUploadReadyState {
  acceptedFileTypes?: string;
  gatewayEndpoint?: string;
  children?: React.ReactNode;
  id?: string;
  name?: string;
  isCompact?: boolean;
  maxFileSizeLimitInMB?: number;
  maxCombinedFileSizeLimitInMB?: number;
  proxyFileUploadUrl?: string;
  serviceName?: string;
  internalId?: string;
  customerId?: string;
}

export const FileUpload: React.FC<FileUploadParams> = ({
  acceptedFileTypes,
  gatewayEndpoint = defaultValues.gatewayEndpoint,
  filesOnParent,
  setFilesOnParent,
  setFilesReadyForUpload,
  id,
  name,
  sessionInitializer,
  isCompact,
  children,
  maxFileSizeLimitInMB,
  maxCombinedFileSizeLimitInMB,
  proxyFileUploadUrl,
  serviceName,
  internalId,
  customerId
}) => {
  const [fileUploadState, fileUploadDispatch] = useReducer(
    GlobalReducer,
    {
      gatewayEndpoint,
      filesOnParent,
      sessionInitializer
    },
    rehydrateState
  );

  const {files: currentFiles, session} = fileUploadState;

  useRehydrateFromParentState({
    fileUploadDispatch,
    sessionInitializer
  });

  useSetFilesOnParent({
    filesOnParent,
    setFilesOnParent,
    setFilesReadyForUpload,
    fileUploadState
  });

  useStartFileStatusPollWhenFilesUpdated({
    currentFiles,
    fileUploadDispatch,
    gatewayEndpoint,
    session
  });

  return (
    <FileSelect
      id={id}
      name={name}
      acceptedFileTypes={acceptedFileTypes}
      files={currentFiles}
      addFiles={files => processFileSelection({files, fileUploadDispatch, currentState: currentFiles, gatewayEndpoint, sessionState: session, proxyFileUploadUrl, serviceName, internalId, customerId})}
      removeFile={file => processFileDeletion({fileUploadDispatch, file, gatewayEndpoint, session})}
      retryFile={file => retryFailedFile({file, fileUploadDispatch, gatewayEndpoint, session, proxyFileUploadUrl, serviceName, internalId, customerId})}
      isCompact={isCompact}
      maxFileSizeLimitInMB={maxFileSizeLimitInMB}
      maxCombinedFileSizeLimitInMB={maxCombinedFileSizeLimitInMB}
      sessionId={session?.sessionId}
    >
      {children}
    </FileSelect>
  );
};

FileUpload.displayName = 'FileUploadComponent';
