import React from 'react';
import styled, {css} from 'styled-components';
import {copyFontFamily, copyFontSizeSmall, formLabelColor, formLabelSize, token} from '@nib-components/theme';
import {disabledOpacity} from '../disabled';

// eslint-disable-next-line
const Label = styled(({isCompact, ...rest}) => <label data-mesh-component="FORM-CONTROL--LABEL" {...rest} />)`
  display: block;
  color: var(--themeColorFgLabel, ${formLabelColor});
  font-family: ${token('formControl.typography.label.fontFamily', copyFontFamily)};
  font-size: ${token('formControl.typography.label.fontSize', formLabelSize)};
  font-weight: ${token('formControl.typography.label.fontWeight', 700)};
  line-height: ${token('formControl.typography.label.lineHeight', 1.5)};
  letter-spacing: ${token('formControl.typography.label.letterSpacing')};
  text-transform: ${token('formControl.typography.label.textTransform')};
  width: 100%;

  ${({isCompact}) =>
    isCompact &&
    css`
      font-size: ${token('theme.typography.copy.small.fontSize', copyFontSizeSmall)};
    `};

  ${({disabled}) =>
    disabled &&
    css`
      opacity: ${disabledOpacity};
    `};
`;

Label.displayName = 'Label';

export default Label;
