import React, {useId} from 'react';
import {flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, useReactTable} from '@tanstack/react-table';
import {TableWrapper, Table, TableHead, TableHeadRow, TableHeading, TableBody, TableRow, TableData, IndeterminateCheckbox} from '@nib/table';
import {makeData} from './MakeData';

export const RowSelectionTable = () => {
  const data = makeData(10);
  const [rowSelection, setRowSelection] = React.useState({});
  const id = useId();
  const columns = React.useMemo(
    () => [
      {
        id: 'select',
        header: ({table}) => (
          <IndeterminateCheckbox
            {...{
              id,
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler()
            }}
          />
        ),
        cell: ({row}) => (
          <div>
            <IndeterminateCheckbox
              {...{
                id: row.id,
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler()
              }}
            />
          </div>
        )
      },
      {
        accessorKey: 'firstName',
        cell: info => info.getValue(),
        footer: props => props.column.id,
        header: () => <span>First Name</span>,
        enableColumnFilter: false
      },
      {
        accessorFn: row => row.lastName,
        enableColumnFilter: false,
        id: 'lastName',
        cell: info => info.getValue(),
        header: () => <span>Last Name</span>,
        footer: props => props.column.id
      },

      {
        accessorKey: 'age',
        enableColumnFilter: false,
        header: () => 'Age',
        footer: props => props.column.id
      },
      {
        accessorKey: 'visits',
        enableColumnFilter: false,
        header: () => <span>Visits</span>,
        footer: props => props.column.id
      },
      {
        accessorKey: 'status',
        enableColumnFilter: false,
        header: 'Status',
        footer: props => props.column.id
      },
      {
        accessorKey: 'progress',
        enableColumnFilter: false,
        header: 'Profile Progress',
        footer: props => props.column.id
      }
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection
    },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true
  });

  return (
    <TableWrapper>
      <Table equalColumns={false}>
        <TableHead>
          {table.getHeaderGroups().map(headerGroup => (
            <TableHeadRow key={headerGroup.id} fixedHeader={false} rowHeight={'regular'}>
              {headerGroup.headers.map(header => {
                return (
                  <TableHeading fixedColumn={false} key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHeading>
                );
              })}
            </TableHeadRow>
          ))}
        </TableHead>
        <TableBody stripedRows={true}>
          {table.getRowModel().rows.map(row => {
            return (
              <TableRow key={row.id} rowHeight={'regular'}>
                {row.getVisibleCells().map(cell => {
                  return (
                    <TableData fixedColumn={false} key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableData>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};
