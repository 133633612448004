const DIGIT = /^\d$/;

/**
 * Insert/remove slashes where applicable
 * @param event
 */

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export default (event: any) => {
  let value = event.value,
    start = event.selectionStart, //where the cursor is after the value has ben added
    end = event.selectionEnd; //where the cursor is after the value has ben added

  //backspace the slash immediately to the left of the number
  if (event.name === 'BACKSPACE') {
    if (start === end && (start === 2 || start === 5)) {
      value = value.substr(0, start - 1) + value.substr(start);
      --start;
      --end;
    }
  }

  //filter non-digit characters so we don't need to know where the digit was inserted
  for (let i = 0; i < value.length; ++i) {
    if (!DIGIT.test(value[i])) {
      value = value.substr(0, i) + value.substr(i + 1);
      if (start > i) --start;
      if (end > i) --end;
      --i;
    }
  }

  //add the first slash
  if ((event.name !== 'BACKSPACE' && value.length >= 2) || (event.name === 'BACKSPACE' && value.length > 2)) {
    value = `${value.substr(0, 2)}/${value.substr(2)}`;
    if (start >= 2) ++start;
    if (end >= 2) ++end;
  }

  //add the second slash
  if ((event.name !== 'BACKSPACE' && value.length >= 5) || (event.name === 'BACKSPACE' && value.length > 5)) {
    value = `${value.substr(0, 5)}/${value.substr(5)}`;
    if (start >= 5) ++start;
    if (end >= 5) ++end;
  }

  //set the value and position
  return {
    value: value.substr(0, 10),
    selectionStart: start,
    selectionEnd: end
  };
};
