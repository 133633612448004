import * as Tokens from '@nib-group/design-tokens/build/web';
import {ThemeVariants} from '../types';

const NibModes = {
  default: Tokens.NibDefault,
  alt: Tokens.NibAlt,
  feature: Tokens.NibFeature,
  sage: Tokens.NibSage,
  sunset: Tokens.NibSunset,
  warm: Tokens.NibWarm,
  primitive: Tokens.NibPrimitive
};

export const tokenMapping: Record<ThemeVariants, {[key: string]: string}> = {
  aami: {
    default: Tokens.AamiDefault,
    alt: Tokens.AamiAlt,
    feature: Tokens.AamiFeature,
    primitive: Tokens.AamiPrimitive
  },
  aanz: {
    default: Tokens.AanzDefault,
    alt: Tokens.AanzAlt,
    feature: Tokens.AanzFeature,
    primitive: Tokens.AanzPrimitive
  },
  apia: {
    default: Tokens.ApiaDefault,
    alt: Tokens.ApiaAlt,
    feature: Tokens.ApiaFeature,
    primitive: Tokens.ApiaPrimitive
  },
  australianSeniors: {
    default: Tokens.AustralianSeniorsDefault,
    alt: Tokens.AustralianSeniorsAlt,
    feature: Tokens.AustralianSeniorsFeature,
    primitive: Tokens.AustralianSeniorsPrimitive
  },
  cota: {
    default: Tokens.CotaDefault,
    alt: Tokens.CotaAlt,
    feature: Tokens.CotaFeature,
    primitive: Tokens.CotaPrimitive
  },
  edi: {
    default: Tokens.EdiDefault,
    alt: Tokens.EdiAlt,
    feature: Tokens.EdiFeature,
    primitive: Tokens.EdiPrimitive
  },
  foundation: NibModes,
  gu: {
    default: Tokens.GuDefault,
    alt: Tokens.GuAlt,
    feature: Tokens.GuFeature,
    primitive: Tokens.GuPrimitive
  },
  iman: {
    default: Tokens.ImanDefault,
    alt: Tokens.ImanAlt,
    feature: Tokens.ImanFeature,
    primitive: Tokens.ImanPrimitive
  },
  ing: {
    default: Tokens.IngDefault,
    alt: Tokens.IngAlt,
    feature: Tokens.IngFeature,
    primitive: Tokens.IngPrimitive
  },
  nib: NibModes,
  nsi: {
    default: Tokens.NsiDefault,
    alt: Tokens.NsiAlt,
    feature: Tokens.NsiFeature,
    primitive: Tokens.NsiPrimitive
  },
  orbitProtect: {
    default: Tokens.OrbitProtectDefault,
    alt: Tokens.OrbitProtectAlt,
    primitive: Tokens.OrbitProtectPrimitive
  },
  priceline: {
    default: Tokens.PricelineDefault,
    alt: Tokens.PricelineAlt,
    feature: Tokens.PricelineFeature,
    primitive: Tokens.PricelinePrimitive
  },
  qantas: {
    default: Tokens.QantasDefault,
    alt: Tokens.QantasAlt,
    feature: Tokens.QantasFeature,
    primitive: Tokens.QantasPrimitive
  },
  real: {
    default: Tokens.RealDefault,
    alt: Tokens.RealAlt,
    feature: Tokens.RealFeature,
    primitive: Tokens.RealPrimitive
  },
  suncorp: {
    default: Tokens.SuncorpDefault,
    alt: Tokens.SuncorpAlt,
    feature: Tokens.SuncorpFeature,
    primitive: Tokens.SuncorpPrimitive
  },
  tid: {
    default: Tokens.TidDefault,
    alt: Tokens.TidAlt,
    feature: Tokens.TidFeature,
    primitive: Tokens.TidPrimitive
  },
  worldNomads: {
    default: Tokens.WorldNomadsDefault,
    alt: Tokens.WorldNomadsAlt,
    feature: Tokens.WorldNomadsFeature,
    primitive: Tokens.WorldNomadsPrimitive
  }
};
