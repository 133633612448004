import {createGlobalStyle} from 'styled-components';
import {FONTS_CDN_URL} from '../constants';

const BRAND_CDN_URL = `${FONTS_CDN_URL}/aami`;
const FONT_FAMILY = 'Futura BT';

export const AamiFonts = createGlobalStyle`
  @font-face {
    font-family: ${FONT_FAMILY};
    src: url(${BRAND_CDN_URL}/FuturaBTBook.woff2) format('woff2'), 
        url(${BRAND_CDN_URL}/FuturaBTBook.woff) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: ${FONT_FAMILY};
    src: url(${BRAND_CDN_URL}/FuturaBTMedium.woff2) format('woff2'), 
        url(${BRAND_CDN_URL}/FuturaBTMedium.woff) format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
`;
