import React from 'react';
import {flexRender, getCoreRowModel, useReactTable, getExpandedRowModel} from '@tanstack/react-table';
import {TableWrapper, Table, TableHead, TableHeadRow, TableHeading, TableBody, TableExpandRow, TableExpandHeading, TableData} from '@nib/table';
import {makeData} from './MakeData';

export const ExpandingRowTable = () => {
  let makeDataArray = makeData(10);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'firstName',
        header: () => <>First Name</>,
        cell: ({getValue}) => getValue(),
        footer: props => props.column.id
      },
      {
        accessorFn: row => row.lastName,
        id: 'lastName',
        cell: info => info.getValue(),
        header: () => <span>Last Name</span>,
        footer: props => props.column.id
      },

      {
        accessorKey: 'age',
        header: () => 'Age',
        footer: props => props.column.id
      },
      {
        accessorKey: 'visits',
        header: () => <span>Visits</span>,
        footer: props => props.column.id
      },
      {
        accessorKey: 'progress',
        header: 'Profile Progress',
        footer: props => props.column.id
      },
      {
        accessorKey: 'createdAt',
        header: 'Created At'
      }
    ],
    []
  );

  const [data, setData] = React.useState(makeDataArray);
  const refreshData = () => setData(makeDataArray); // eslint-disable-line no-unused-vars

  const [sorting, setSorting] = React.useState([]);
  const [expanded, setExpanded] = React.useState({});

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      expanded
    },
    onExpandedChange: setExpanded,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: () => true,
    debugTable: true
  });

  const renderSubComponent = row => {
    return <p>Render extra details here about {row.row.original.firstName}</p>;
  };

  return (
    <>
      <TableWrapper>
        <Table equalColumns={false}>
          <TableHead>
            {table.getHeaderGroups().map(headerGroup => (
              <TableHeadRow key={headerGroup.id} fixedHeader={false} rowHeight={'regular'}>
                {headerGroup.headers.map(header => {
                  return (
                    <TableHeading fixedColumn={false} key={header.id} colSpan={header.colSpan} onClick={header.column.getToggleSortingHandler()} isSorted={header.column.getIsSorted()}>
                      {header.isPlaceholder ? null : <span>{flexRender(header.column.columnDef.header, header.getContext())}</span>}
                    </TableHeading>
                  );
                })}
                <TableExpandHeading /> {/* Empty th for expander column */}
              </TableHeadRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map(row => {
              return (
                <React.Fragment key={row.id}>
                  <TableExpandRow isExpanded={row.getIsExpanded()} onClick={row.getToggleExpandedHandler()} rowHeight={'regular'}>
                    {row.getVisibleCells().map(cell => {
                      return (
                        <TableData fixedColumn={false} key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableData>
                      );
                    })}
                  </TableExpandRow>
                  {row.getIsExpanded() && (
                    <tr>
                      {/* 2nd row is a custom 1 cell row (+1 to cater for empty TableExpandHeading) */}
                      <td colSpan={row.getVisibleCells().length + 1}>{renderSubComponent({row})}</td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableWrapper>
    </>
  );
};
