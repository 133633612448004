'use client';

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Downshift from 'downshift';
import matchSorter from 'match-sorter';
import Textbox from '@nib-components/textbox';
import {py, mx, px} from '@nib/layout';
import {Label, Help, ValidationIcon, ErrorMessage, InfoMessage, InputWrapper, FullStoryMask, validFullStoryLevels} from '@nib-components/form-control';
import Menu from './Menu';
import SuggestedList from './SuggestedList';
import OptionsList from './OptionsList';
import AddressLookup from './AddressLookup';
import Link from '@nib-components/link';
import Item from './Item';

const isString = value => {
  return Object.prototype.toString.call(value) === '[object String]';
};

const Relative = styled.div`
  position: relative;
`;

const DividingLine = styled.hr`
  margin: 0;
  ${mx(2)};
  border: none;
  border-top: 2px solid rgb(0 0 0 / 20%);
`;

const ManualAddContainer = styled(Item)`
  ${py(4)};
  ${px(3)};
`;

export const ItemList = props => {
  const {getItemProps, highlightedIndex, inputValue, suggestions, suggestionsTitle, options, maxOptionsDisplayed, keys, loading, skipSorting} = props;
  // Show suggestions if user has not typed in the field AND suggestions exist
  if (inputValue.length === 0 && suggestions) {
    return <SuggestedList getItemProps={getItemProps} suggestions={suggestions} suggestionsTitle={suggestionsTitle} highlightedIndex={highlightedIndex} />;
  }

  return (
    <OptionsList
      options={options}
      inputValue={inputValue}
      getItemProps={getItemProps}
      highlightedIndex={highlightedIndex}
      maxOptionsDisplayed={maxOptionsDisplayed}
      keys={keys}
      loading={loading}
      skipSorting={skipSorting}
    />
  );
};

ItemList.propTypes = {
  getItemProps: PropTypes.func,
  highlightedIndex: PropTypes.number,
  inputValue: PropTypes.string,
  suggestionsTitle: PropTypes.string,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string
    })
  ),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string
    })
  ),
  maxOptionsDisplayed: PropTypes.number,
  keys: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  skipSorting: PropTypes.bool
};

const Autocomplete = props => {
  const {
    placeholder,
    options = [{}],
    suggestions,
    suggestionsTitle,
    onChange,
    onBlur,
    onFocus,
    field,
    input,
    meta,
    value,
    validated,
    valid,
    error,
    info,
    disabled,
    label,
    help,
    width,
    id,
    name,
    className,
    maxOptionsDisplayed = 5,
    keys = ['value', 'text'],
    isAddressLookup = false,
    showHideManualEntryFields,
    isEmptyAndOptional = false,
    fullstory = 'mask',
    ...otherProps
  } = props;

  let entriesList = options;
  const itemToString = item => {
    //if an address lookup, it'll have no item.text here before any API calls have been made.
    if (isString(item)) {
      return item;
    }
    return !item || !item.text ? '' : String(item.text);
  };

  const optionFromValue = val => {
    //if an address lookup, it'll have no entriesList object up here.
    if (isAddressLookup) {
      return value;
    }
    return entriesList.find(x => x.value === val);
  };

  const handleFocus = openFn => {
    if (onFocus) onFocus();
    openFn();
  };

  const handleBlur = (closeFn, inputValue, selectItem) => {
    // onChange will clear 'value' so if 'value' === 'inputValue', then user hasn't changed last option.
    const hasInputChanged = value !== inputValue;
    if (hasInputChanged) {
      //onblur check what the user has typed manually and if it matches an available option set it onblur
      if (inputValue && entriesList.length) {
        const filteredOptions = matchSorter(entriesList, inputValue, {keys});
        if (filteredOptions.length) {
          filteredOptions.some(item => {
            if (item.text.toUpperCase() === inputValue.toUpperCase()) {
              selectItem(item);
              return true;
            }
          });
        } else if (!isAddressLookup) {
          selectItem({});
        }
      } else {
        selectItem({});
      }
    }

    if (onBlur) onBlur();
    closeFn();
  };

  // the below is used for hydrating the formik form with an initial value but only on initial load.
  let formikFieldValue = {};

  if (field && field.value && field.value.length && props.form && props.form.initialValues[field.name] && props.form.initialValues[field.name] === field.value) {
    if (isAddressLookup) {
      formikFieldValue.value = field.value;
    } else {
      const optionItem = optionFromValue(field.value);
      // added in check incase somehow it doesn't exist in options even though is in initial values + matching test.
      formikFieldValue.value = optionItem ? optionItem.text : '';
    }
  }

  /*
    To prevent browsers ever trying to populate an auto complete we give it a random string
    Unfortunately Chrome ignores autocomplete="off"
  */
  const omgImSoRandom = `random-string-blah-${Math.round(Math.random() * 10000)}`;

  return (
    <Downshift itemToString={itemToString} initialSelectedItem={optionFromValue(value)} {...field} {...input} onChange={selectedItem => onChange(selectedItem && selectedItem.value)}>
      {({getItemProps, getInputProps, getLabelProps, getMenuProps, highlightedIndex, inputValue = '', isOpen, selectItem, selectedItem, clearSelection, openMenu, closeMenu}) => (
        <div>
          <div className={className}>
            <Label {...getLabelProps()} disabled={disabled}>
              {label}
            </Label>
            {help && (
              <Help id={`help-${id}`} disabled={disabled}>
                {help}
              </Help>
            )}

            <InputWrapper width={width}>
              <Relative>
                <FullStoryMask level={fullstory}>
                  <Textbox
                    {...getInputProps({
                      isOpen,
                      placeholder,
                      name,
                      valid,
                      validated,
                      disabled,
                      onChange: clearSelection,
                      onBlur: () => handleBlur(closeMenu, inputValue, selectItem),
                      onFocus: () => handleFocus(openMenu) && onFocus(),
                      autoComplete: omgImSoRandom,
                      isEmptyAndOptional: isEmptyAndOptional
                    })}
                    {...meta}
                    {...formikFieldValue}
                  />
                </FullStoryMask>
                <Menu {...getMenuProps({isOpen})}>
                  <FullStoryMask level={fullstory}>
                    {isOpen && !isAddressLookup && (
                      <ItemList
                        options={entriesList}
                        maxOptionsDisplayed={maxOptionsDisplayed}
                        suggestions={suggestions}
                        getItemProps={getItemProps}
                        highlightedIndex={highlightedIndex}
                        inputValue={inputValue}
                        selectedItem={selectedItem}
                        suggestionsTitle={suggestionsTitle}
                        keys={keys}
                      />
                    )}
                    {isOpen && isAddressLookup && (
                      <AddressLookup inputValue={inputValue} {...otherProps}>
                        {({loading, options = []}) => {
                          entriesList = options;

                          return (
                            <React.Fragment>
                              <ItemList
                                options={options}
                                maxOptionsDisplayed={maxOptionsDisplayed}
                                suggestions={suggestions}
                                getItemProps={getItemProps}
                                highlightedIndex={highlightedIndex}
                                inputValue={inputValue}
                                selectedItem={selectedItem}
                                keys={keys}
                                loading={loading}
                                skipSorting={isAddressLookup}
                              />
                              <DividingLine />
                              <ManualAddContainer
                                key={`option--${options.length}`}
                                {...getItemProps({
                                  item: {text: '', value: showHideManualEntryFields},
                                  index: options.length,
                                  isActive: highlightedIndex === options.length,
                                  isSelected: selectedItem === {text: '', value: showHideManualEntryFields}
                                })}
                              >
                                <Link component="button" type="button" onClick={() => showHideManualEntryFields()}>
                                  Enter address manually
                                </Link>
                              </ManualAddContainer>
                            </React.Fragment>
                          );
                        }}
                      </AddressLookup>
                    )}
                  </FullStoryMask>
                </Menu>
              </Relative>
              {((validated && !valid) || (validated && !isEmptyAndOptional)) && <ValidationIcon valid={valid} disabled={disabled} />}
            </InputWrapper>

            {validated && error && <ErrorMessage valid={valid} id={`error-${id}`} error={error} disabled={disabled} />}
            {validated && info && <InfoMessage valid={valid} id={`info-${id}`} info={info} disabled={disabled} />}
          </div>
        </div>
      )}
    </Downshift>
  );
};

Autocomplete.propTypes = {
  placeholder: PropTypes.string,
  maxOptionsDisplayed: PropTypes.number,
  keys: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string
    })
  ).isRequired,
  isAddressLookup: PropTypes.bool,
  showHideManualEntryFields: PropTypes.func,
  isEmptyAndOptional: PropTypes.bool,
  fullstory: PropTypes.oneOf(validFullStoryLevels)
};

export default Autocomplete;
