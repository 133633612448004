import React from 'react';
import styled from 'styled-components';
import {Margin} from '@nib/layout';
import Copy from '@nib-components/copy';
import Heading, {componentValues} from '@nib-components/heading';
import {Nullable} from '../constants';

export interface LimitProps {
  id: number;
  productId: number;
  productName: string;
  limitAmount: Nullable<number>;
  limitText: Nullable<string>;
  applyToName: Nullable<string>;
  serviceId: number;
  timePeriod: string;
}

export interface ChildLimitProps extends LimitProps {
  parentId: number;
  serviceName: string;
}

export interface LimitsForActiveServiceForEachProductProps extends LimitProps {
  annualIncrease?: Nullable<number>;
  maxLimitPerTimePeriod?: Nullable<number>;
  childLimits?: ChildLimitProps[];
}
export interface AnnualLimitsProps {
  limitsForActiveServiceForEachProduct?: LimitsForActiveServiceForEachProductProps[];
  productId?: number;
  onlyShowOwnProductLimits?: boolean;
  extrasProductName?: string;
  titleComponent?: componentValues;
}

const getLimitAmountText = (limit: LimitProps) => {
  if (limit.limitAmount) return `$${limit.limitAmount}`;
  return limit.limitText;
};

const LimitValueStyled = styled.div`
  display: inline-block;
`;

const getAnnualLimitName = (limit: LimitProps, extrasProductName = '') => {
  if (!limit.limitAmount) return ' (unlimited)';
  return limit.productName.replace(extrasProductName, '');
};

const AnnualLimitsText: React.FC<AnnualLimitsProps> = ({limitsForActiveServiceForEachProduct, productId, extrasProductName, titleComponent}) => {
  return (
    <Margin bottom={4}>
      <Margin bottom={4}>
        <Heading size={5} component={titleComponent}>
          Annual limit:
        </Heading>
      </Margin>

      {limitsForActiveServiceForEachProduct &&
        limitsForActiveServiceForEachProduct
          .filter(limit => limit.productId === productId)
          .map(limit => (
            <Margin bottom={2} key={`limit-data-${limit.productId}`}>
              <LimitValueStyled>
                {limit && limit.limitAmount && limit.annualIncrease && limit.applyToName && limit.maxLimitPerTimePeriod ? (
                  <Copy component={'span'}>
                    The annual limit on this service starts at ${limit.limitAmount.toString()} and increases by ${limit.annualIncrease.toString()} per {limit.applyToName.toLowerCase()} per calendar
                    year to ${limit.maxLimitPerTimePeriod.toString()} {limit.timePeriod.toLowerCase()} limit.
                  </Copy>
                ) : (
                  <Copy component={'span'} key={`limit-for-${limit.productId}`} align="right">
                    {getLimitAmountText(limit)}
                  </Copy>
                )}

                <Copy component={'span'}>{getAnnualLimitName(limit, extrasProductName)}</Copy>
                {limit.childLimits &&
                  limit.childLimits.map(child => (
                    <Copy component={'span'} key={child.id}>
                      {'\n'}You can use up to ${child.limitAmount} of your limit on {child.serviceName}
                    </Copy>
                  ))}
              </LimitValueStyled>
            </Margin>
          ))}
    </Margin>
  );
};

export default AnnualLimitsText;
