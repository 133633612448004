import HeroPanel from '@nib-components/hero-panel';
import desktop from "../../../../content/images/hero-panel/desktop.jpg";
import desktop2x from "../../../../content/images/hero-panel/desktop@2x.jpg";
import mobile from "../../../../content/images/hero-panel/mobile.jpg";
import mobile2x from "../../../../content/images/hero-panel/mobile@2x.jpg";
import tablet from "../../../../content/images/hero-panel/tablet.jpg";
import tablet2x from "../../../../content/images/hero-panel/tablet@2x.jpg";
import * as React from 'react';
export default {
  HeroPanel,
  desktop,
  desktop2x,
  mobile,
  mobile2x,
  tablet,
  tablet2x,
  React
};