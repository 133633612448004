import {PhoneSystemIcon, ChatSystemIcon, LogoutSystemIcon} from '@nib/icons';

export const navLinks = [
  {
    id: 'ga-nav-item',
    title: 'Nav item',
    slug: 'nav-item',
    url: '#'
  },
  {
    id: 'ga-nav-item-2',
    title: 'Nav item 2',
    slug: 'nav-item-2',
    url: '#'
  },
  {
    id: 'ga-nav-item-3',
    title: 'Nav item 3',
    slug: 'nav-item-3',
    url: '#'
  },
  {
    id: 'ga-nav-themes',
    title: 'Nav item 4 w/ dropdown',
    slug: 'nav-item-4',
    url: '#',
    children: [
      {
        id: 'ga-nav-ni4-sub-item',
        title: 'Sub-item',
        url: '/health-insurance/singles'
      },
      {
        id: 'ga-nav-ni4-sub-item-2',
        title: 'Sub-item with a longer label',
        url: '#'
      }
    ]
  }
];

export const iconLinks = [
  {
    id: 'ga-nav-os-phone',
    title: '13 16 42',
    slug: 'phone',
    url: 'tel:131642',
    icon: PhoneSystemIcon
  },
  {
    id: 'ga-nav-os-chat-now',
    title: 'Chat now',
    slug: 'chat',
    url: 'https://www.nib.com.au/nibby-web/index.html?startedOnPage=ARHIMemberPageNavBar',
    target: 'popup',
    icon: ChatSystemIcon
  },
  {
    id: 'ga-nav-os-logout',
    title: 'Log out',
    slug: 'logout',
    url: '/onlineservices/oslogout',
    icon: LogoutSystemIcon
  }
];
