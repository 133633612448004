import React, {useMemo, useState} from 'react';
import styled from 'styled-components';
import {Box, Stack, Hidden} from '@nib/layout';
import {UploadSystemIcon} from '@nib/icons';
import {SecondaryButton} from '@nib-components/button';
import Copy, {Bold} from '@nib-components/copy';
import {DragDropBox, FilesList} from './Containers';
import {FileState, ParentFileState} from './Interfaces';
import {processFileSelection, processFileDrop, defaultValues} from './util';
import {colorDarker} from '@nib-components/theme';

export interface FileSelectParams<T extends FileState> extends ParentFileState<T> {
  acceptedFileTypes?: string;
  id?: string;
  name?: string;
  isCompact?: boolean;
  maxFileSizeLimitInMB?: number;
  maxCombinedFileSizeLimitInMB?: number;
  children?: React.ReactNode;
  serviceName?: string;
  internalId?: string;
  customerId?: string;
  sessionId?: string;
}

const InertOverlay = styled.div<{isDragging: boolean}>`
  * {
    ${props => (props.isDragging ? 'pointer-events: none;' : 'pointer-events: auto;')}
  }
`;

const HiddenInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  visibility: hidden;
`;

const UppercaseText = styled(Copy)`
  text-transform: uppercase;
  color: var(--themeColorFgMuted, ${colorDarker});
`;

let componentIdCache = 1;

export const FileSelect = <T extends FileState>({
  addFiles,
  files,
  removeFile,
  retryFile,
  acceptedFileTypes = defaultValues.acceptTypes,
  children,
  id,
  name,
  isCompact,
  maxFileSizeLimitInMB,
  maxCombinedFileSizeLimitInMB,
  sessionId = ''
}: FileSelectParams<T>): JSX.Element => {
  const [isDragging, setIsDragging] = useState(false);

  const componentId = useMemo(() => `file-upload-${id || componentIdCache++}`, [id]);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const UploadButton = ({componentId, children = 'Browse files', ...rest}: {componentId: string; children?: string; [key: string]: unknown}) => (
    <SecondaryButton icon={UploadSystemIcon} iconPlacement="left" type="button" role="button" tabIndex={0} aria-controls={componentId} {...rest} onClick={() => inputRef.current?.click()}>
      {children}
    </SecondaryButton>
  );
  return (
    <DragDropBox
      onDragStart={e => {
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.effectAllowed = 'all';
        setIsDragging(true);
      }}
      onDrop={e => {
        e.preventDefault();
        e.stopPropagation();
        processFileDrop({event: e, currentFiles: files, addFiles, acceptedFileTypes, maxFileSizeLimitInMB, maxCombinedFileSizeLimitInMB});
        setIsDragging(false);
      }}
      onDragEnter={e => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
      }}
      onDragOver={e => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
      }}
      onDragLeave={e => {
        e.preventDefault();
        e.stopPropagation();
        if (!e.currentTarget.contains(e.relatedTarget as Node)) {
          setIsDragging(false);
        }
      }}
      midDrag={isDragging}
      data-mesh-component="FILE-SELECT"
    >
      <InertOverlay isDragging={isDragging}>
        <Box padding={isCompact ? {xs: 2, mini: 4} : {xs: 2, mini: 4, sm: 5}}>
          <HiddenInput
            type="file"
            name={name}
            id={componentId}
            multiple
            accept={acceptedFileTypes}
            ref={inputRef}
            onChange={e => {
              e.preventDefault();
              e.stopPropagation();
              e.target.files && processFileSelection({currentFiles: files, files: Array.from(e.target.files), addFiles, acceptedFileTypes, maxFileSizeLimitInMB, maxCombinedFileSizeLimitInMB});
              e.target.files = null;
              e.target.value = '';
            }}
          />

          {/* 
            A hidden input to store the sessionId when a file has been uploaded.
            Useful if grabbing form data using FormData: https://developer.mozilla.org/en-US/docs/Web/API/FormData
          */}
          <input type="hidden" name={`${name}-sessionId`} id={`${componentId}-sessionId`} value={sessionId} />

          <Stack space={4} align="center">
            {files.length === 0 ? (
              <>
                {!isCompact ? (
                  <>
                    <Bold align="center">
                      <Hidden above="md">Select your files to upload</Hidden>
                      <Hidden below="md">
                        Drag and drop your files
                        <br />
                        here to upload
                      </Hidden>
                    </Bold>

                    <Hidden below="md">
                      <UppercaseText small align="center" component="span">
                        or
                      </UppercaseText>
                    </Hidden>

                    <UploadButton componentId={componentId} fullWidth={{xs: true, md: false}} />

                    {children}
                  </>
                ) : (
                  <UploadButton componentId={componentId} fullWidth />
                )}
              </>
            ) : (
              <>
                <FilesList files={files} removeFile={removeFile} retryFile={retryFile} />
                <Box textAlign="start">
                  <SecondaryButton
                    icon={UploadSystemIcon}
                    iconPlacement="left"
                    role="button"
                    type="button"
                    tabIndex={0}
                    aria-controls={componentId}
                    fullWidth={{xs: true, md: false}}
                    size="small"
                    onClick={() => inputRef.current?.click()}
                  >
                    Upload new file
                  </SecondaryButton>
                </Box>
              </>
            )}
          </Stack>
        </Box>
      </InertOverlay>
    </DragDropBox>
  );
};
