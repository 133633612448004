import React from 'react';
import styled, {css} from 'styled-components';
import {buttonDestructiveStyles, ThemeSchema} from '@nib-components/theme';
import BaseButton, {BaseButtonProps, sizeStyling, fullWidthStyling} from '../BaseButton';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const DestructiveButton = styled(({theme, ...otherProps}: BaseButtonProps & {theme: ThemeSchema}) => <BaseButton data-mesh-component="BUTTON-DESTRUCTIVE" {...otherProps} />)`
  ${props => buttonDestructiveStyles(props).normal}

  ${props =>
    props.selected &&
    css`
      ${buttonDestructiveStyles(props).selected};
    `}

  ${props =>
    'disabled' in props &&
    props.disabled &&
    css`
      ${buttonDestructiveStyles(props).disabled};
    `}

  ${props =>
    props &&
    css`
      &:disabled {
        ${buttonDestructiveStyles(props).disabled};
      }
    `}

  ${sizeStyling}
  ${fullWidthStyling}
`;

DestructiveButton.displayName = 'DestructiveButton';
