import React from 'react';
import styled, {css} from 'styled-components';
import {breakpoint, mr, ml, mt} from '@nib/layout';
import {formBorderColorValid, formBorderColorInvalid} from '@nib-components/theme';
import {TickSystemIcon, ErrorSystemIcon} from '@nib/icons';
import {disabledOpacity} from '../disabled';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PositionIcon = styled(({isCompact, isFieldset, outside, ...rest}) => <span {...rest} />)`
  position: absolute;
  font-size: 0;
  top: 50%;
  transform: translateY(-50%);
  inset-inline-end: 0.5rem;

  ${breakpoint('mini')`
    inset-inline-end: ${({isCompact}: {isCompact: boolean}) => (isCompact ? '0.5rem' : '1rem')};
  `}

  ${({isFieldset}) =>
    isFieldset &&
    css`
      display: inline-block;
      ${mt(2)};
      ${mr(2)};
      position: static;
      align-self: center;
      transform: none;
    `}

  ${({outside}) =>
    outside &&
    css`
      &&& {
        ${ml(2)};
        inset-inline-end: auto;
      }
    `}

  ${({disabled}) =>
    disabled &&
    css`
      opacity: ${disabledOpacity};
    `};
`;

const StyledTick = styled(TickSystemIcon)`
  color: var(--themeColorFgSuccess, ${formBorderColorValid});
`;

const StyledError = styled(ErrorSystemIcon)`
  color: var(--themeColorFgError, ${formBorderColorInvalid});
`;

export interface ValidationIconProps {
  valid?: boolean;
  outside?: boolean;
  isFieldset?: boolean;
  isCompact?: boolean;
  disabled?: boolean;
}

const ValidationIcon: React.FC<ValidationIconProps> = ({valid, outside, isFieldset, isCompact, disabled, ...rest}) => (
  <PositionIcon outside={outside} isFieldset={isFieldset} isCompact={isCompact} disabled={disabled} {...rest} data-mesh-component="FORM-CONTROL--VALIDATION-ICON">
    {valid ? <StyledTick data-testid="tick-icon" size={isCompact ? 20 : 'sm'} fill="currentColor" /> : <StyledError data-testid="error-icon" size={isCompact ? 20 : 'sm'} fill="currentColor" />}
  </PositionIcon>
);

export default ValidationIcon;
