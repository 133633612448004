import React, {HTMLProps} from 'react';
import styled, {css} from 'styled-components';
import {formInputBackgroundColor, formBorderColor, formSelectedToggleColor} from '@nib-components/theme';

type RelativeWrapperProps = {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  size?: any;
};

type CustomCheckboxLabelProps = {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  checked?: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  indeterminate?: any;
};

const SRHide = styled.div`
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
`;

const RelativeWrapper = styled.div<RelativeWrapperProps>`
  position: relative;
  ${({size}) =>
    size &&
    css`
      width: ${size};
      height: ${size};
    `}
`;

const CustomCheckboxLabel = styled.label<CustomCheckboxLabelProps>`
  display: inline-block;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px var(--inputColorBorder, ${formBorderColor});
  border-radius: 4px;
  cursor: pointer;

  ${({checked}) =>
    checked &&
    css`
      background-color: var(--themeColorFgSelected, ${formSelectedToggleColor});
      box-shadow: inset 0 0 0 1px var(--themeColorFgSelected, ${formSelectedToggleColor});
    `}

  ${({indeterminate}) =>
    indeterminate &&
    css`
      background-color: var(--themeColorFgSelected, ${formSelectedToggleColor});
      box-shadow: inset 0 0 0 1px var(--themeColorFgSelected, ${formSelectedToggleColor});

      &::after {
        content: '';
        display: block;
        background-color: var(--inputColorBg, ${formInputBackgroundColor});
        border-radius: 2px;
        width: 12px;
        height: 2px;
        position: absolute;
        inset-block-start: 50%;
        inset-inline-start: 50%;
        transform: translate(-50%, -50%);
      }
    `}
`;

const Icon = styled.svg`
  margin-block-start: 4px;
  margin-inline-start: 3px;
  width: 14px;
  height: 12px;
`;

export function IndeterminateCheckbox({indeterminate, id, ...rest}: {indeterminate?: boolean} & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <RelativeWrapper size="20px">
      <SRHide>
        <input id={id} type="checkbox" ref={ref} checked={rest.checked} {...rest} />
      </SRHide>
      <CustomCheckboxLabel htmlFor={id} checked={rest.checked} indeterminate={indeterminate}>
        {rest.checked && (
          <Icon width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.5758 2.12621C13.841 1.808 13.798 1.33508 13.4798 1.0699C13.1616 0.804729 12.6887 0.847722 12.4235 1.16593L5.32516 9.68392L2.07472 6.00008C1.80066 5.68949 1.32672 5.65987 1.01612 5.93392C0.705529 6.20797 0.675907 6.68192 0.94996 6.99252L4.77905 11.3322C4.92387 11.4963 5.13316 11.589 5.35202 11.5859C5.57088 11.5828 5.77747 11.4842 5.9176 11.3161L13.5758 2.12621Z"
              fill="#fff"
            />
          </Icon>
        )}
      </CustomCheckboxLabel>
    </RelativeWrapper>
  );
}
