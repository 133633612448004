import styled from 'styled-components';
import {type Theme, type Values, _m, _mx, _my, _mt, _mr, _mb, _ml, _mbs, _mie, _mbe, _mis, _mbl, _mi} from './core';
import {type SpacingProps} from './types';

export function m(values: Values) {
  return function ({theme}: {theme: Theme}) {
    return _m(values, theme);
  };
}

export function mx(values: Values) {
  return function ({theme}: {theme: Theme}) {
    return _mx(values, theme);
  };
}

export function my(values: Values) {
  return function ({theme}: {theme: Theme}) {
    return _my(values, theme);
  };
}

export function mt(values: Values) {
  return function ({theme}: {theme: Theme}) {
    return _mt(values, theme);
  };
}

export function mr(values: Values) {
  return function ({theme}: {theme: Theme}) {
    return _mr(values, theme);
  };
}

export function mb(values: Values) {
  return function ({theme}: {theme: Theme}) {
    return _mb(values, theme);
  };
}

export function ml(values: Values) {
  return function ({theme}: {theme: Theme}) {
    return _ml(values, theme);
  };
}

export function mbs(values: Values) {
  return function ({theme}: {theme: Theme}) {
    return _mbs(values, theme);
  };
}

export function mie(values: Values) {
  return function ({theme}: {theme: Theme}) {
    return _mie(values, theme);
  };
}

export function mbe(values: Values) {
  return function ({theme}: {theme: Theme}) {
    return _mbe(values, theme);
  };
}

export function mis(values: Values) {
  return function ({theme}: {theme: Theme}) {
    return _mis(values, theme);
  };
}

// We already got a mixin `mb` which stands for margin-bottom. So we are using `mbl` which stands for logical margin-block.
export function mbl(values: Values) {
  return function ({theme}: {theme: Theme}) {
    return _mbl(values, theme);
  };
}

export function mi(values: Values) {
  return function ({theme}: {theme: Theme}) {
    return _mi(values, theme);
  };
}

export const Margin = styled.div.attrs({
  'data-mesh-component': 'MARGIN'
})<SpacingProps>`
  ${({inline}) => inline && 'display: inline-block;'}
  ${({all, theme}) => all && _m(all, theme)}
  ${({horizontal, theme}) => horizontal && _mx(horizontal, theme)} 
  ${({vertical, theme}) => vertical && _my(vertical, theme)} 
  ${({top, theme}) => top && _mt(top, theme)} 
  ${({right, theme}) => right && _mr(right, theme)} 
  ${({bottom, theme}) => bottom && _mb(bottom, theme)} 
  ${({left, theme}) => left && _ml(left, theme)}

  ${({blockStart, theme}) => blockStart && _mbs(blockStart, theme)} 
  ${({inlineEnd, theme}) => inlineEnd && _mie(inlineEnd, theme)} 
  ${({blockEnd, theme}) => blockEnd && _mbe(blockEnd, theme)} 
  ${({inlineStart, theme}) => inlineStart && _mis(inlineStart, theme)}

  ${({block, theme}) => block && _mbl(block, theme)} 
  ${({inline, theme}) => inline && _mi(inline, theme)}
`;

Margin.displayName = 'Margin';
