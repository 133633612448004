import React from 'react';
import styled from 'styled-components';
import {colorBrandBase, colorWhite} from '@nib-components/theme';
import {SizeMap, LogoComponent} from '../../types';

export const Path = styled.path<{invert?: boolean}>`
  fill: ${props => (props.invert ? colorWhite : colorBrandBase)};
`;

// small === min-height from brand guidelines
const sizes: SizeMap = {
  small: {
    width: '70px',
    height: '24px'
  },
  header: {
    width: '116px',
    height: '40px'
  },
  medium: {
    width: '139px',
    height: '48px'
  },
  large: {
    width: '186px',
    height: '64px'
  }
};

const ImanLogo: LogoComponent = props => {
  const {invert = false, size = 'small', ...otherProps} = props;

  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 142 48" role="img" {...otherProps}>
      <Path
        d="M61.897 12.454c-5.189 0-9.34 3.373-12.194 5.19-1.557-2.855-4.411-5.19-9.34-5.19-4.671 0-8.303 2.854-11.677 4.93l-1.037-4.93-12.195 1.816v2.335l2.595.26c1.297.26 1.816.778 1.816 3.373v26.724h9.081V25.686c0-1.297 0-2.335.519-3.372 1.038-3.114 3.632-4.93 6.746-4.93 4.41 0 5.708 3.113 5.708 7.265v22.573H51V25.686c0-1.297 0-2.594.26-3.372.778-3.373 3.373-5.19 6.227-5.19 3.632 0 5.967 2.335 5.967 7.265v22.573h9.081v-23.87c0-6.487-3.373-10.638-10.638-10.638ZM130.914 12.454c-4.411 0-7.525 2.335-11.936 4.93l-1.037-4.93-12.454 1.816v2.335l2.594.26c1.297.26 1.816.778 1.816 3.373v26.724h9.081V25.168c0-1.557.26-2.854.779-3.892 1.038-2.076 3.373-3.892 6.486-3.892 3.892 0 5.968 2.335 5.968 7.005v22.833h9.081v-24.13c0-6.487-3.373-10.638-10.378-10.638ZM81.357 24.649c1.557 0 2.335-1.038 2.594-2.595 1.817-5.708 4.152-6.746 6.487-6.746s4.67 1.297 4.67 5.708v2.335a1073.69 1073.69 0 0 1 8.303-4.67c-.779-3.373-3.633-6.227-11.935-6.227-9.34 0-15.308 3.892-15.308 8.303 0 2.854 3.632 3.892 5.189 3.892ZM103.93 22.314v-1.817c-2.335 1.557-7.006 3.892-7.006 3.892-3.373 1.816-8.302 3.373-12.973 4.67-.518.26-1.297.26-1.816.52-5.449 1.816-7.265 4.67-7.265 8.562C74.87 43.33 78.503 48 85.25 48c4.151 0 7.524-2.595 9.86-4.151C96.145 46.184 97.183 48 100.296 48c1.038 0 2.335-.26 3.633-.26V22.313Zm-8.822 15.308c0 4.41-3.113 5.448-5.189 5.448-3.373 0-5.449-2.335-5.449-6.227 0-3.373 1.298-4.93 3.892-6.227 2.595-1.297 5.19-2.075 6.746-2.854v9.86Z"
        invert={invert}
      />
      <path d="M2.222 5.449C2.222 2.335 4.557 0 7.93 0s5.708 2.335 5.708 5.449c0 2.854-2.335 5.448-5.708 5.448S2.222 8.303 2.222 5.45Z" fill="#78BC41" />
      <Path d="M.146 10.119v2.335l2.595 2.335c1.297 1.298 1.816 2.854 1.816 5.449v26.724h9.08V11.676C6.633 17.384.147 10.119.147 10.119Z" invert={invert} />
    </svg>
  );
};

ImanLogo.displayName = 'ImanLogo';

export default ImanLogo;
