import React from 'react';
import {Box, BoxProps} from '../Box';
import {Container} from '../Container';

export interface SectionProps extends Pick<BoxProps, 'background'> {
  children?: React.ReactNode;
  [key: string]: unknown; //other props
}

export const Section = (props: SectionProps): JSX.Element => {
  const {background, children, ...otherProps} = props;

  return (
    <Box data-mesh-component="SECTION" padding={{xs: 4, md: 6, lg: 8}} background={background ?? 'transparent'} {...otherProps}>
      <Container>{children}</Container>
    </Box>
  );
};

Section.displayName = 'Section';
