import React from 'react';
import styled, {css} from 'styled-components';
import {copyColor, ThemeSchema, copyFontFamily, copyFontSize, copyLineHeight, copyFontSizeSmall, copyFontSizeLarge, PropsWithTheme, token, copyFontWeight} from '@nib-components/theme';
import {color as getColor, colorFgValues, validFgColorValues, map, breakpoint} from '@nib/layout';
import {Nullable, ResponsiveOrStaticProp, validAlignValues, validComponentValues, validWeightValues} from './utils';

type componentValues = (typeof validComponentValues)[number];
type alignValues = (typeof validAlignValues)[number];
type weightValues = (typeof validWeightValues)[number];
export const validSizeValues = ['small', 'medium', 'large'] as const;
type sizeValues = (typeof validSizeValues)[number];
type NamedColor = colorFgValues;
type OtherColor = ((props: PropsWithTheme) => string) | string;

export interface CopyProps extends Omit<React.HTMLAttributes<HTMLElement>, 'color'> {
  component?: componentValues;
  measure?: boolean;
  small?: boolean;
  large?: boolean;
  size?: ResponsiveOrStaticProp<Nullable<sizeValues>>;
  align?: ResponsiveOrStaticProp<Nullable<alignValues>>;
  color?: NamedColor | OtherColor;
  transform?: string;
  weight?: weightValues | number;
  theme?: ThemeSchema;
}

const BaseCopy: React.FC<CopyProps> = props => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const {component: Component = 'p', measure, small, large, size, align, color, weight, transform, theme, ...otherProps} = props;
  return <Component data-mesh-component="COPY" {...otherProps} />;
};

const isNamedColor = (color?: OtherColor) => {
  if (!color) return false;
  if (validFgColorValues.includes(color as colorFgValues)) {
    return true;
  }
  return false;
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const setColor: any = (props: CopyProps) => {
  if (isNamedColor(props.color)) {
    return getColor();
  }
  if (props.color !== 'inherit') {
    return css`
      color: ${props.color || `var(--themeColorFg, ${copyColor})`};
    `;
  }
  return 'color: inherit';
};

const Copy = styled(BaseCopy).attrs(props => ({
  measure: true,
  align: 'start',
  color: 'inherit',
  small: false,
  large: false,
  ...props
}))<CopyProps>`
  margin: 0;

  /* // eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
  ${props => setColor({...props, color: props.color!})};
  font-family: ${token('theme.typography.copy.fontFamily', copyFontFamily)};
  font-size: ${token('theme.typography.copy.fontSize', copyFontSize)};
  font-weight: ${token('theme.typography.copy.fontWeight', copyFontWeight)};
  line-height: ${token('theme.typography.copy.lineHeight', copyLineHeight)};
  letter-spacing: ${token('theme.typography.copy.letterSpacing')};
  word-break: break-word;
  hyphens: auto;

  ${breakpoint('mini')`
    hyphens: none;  
  `}

  ${props =>
    props.transform &&
    css`
      text-transform: ${props.transform};
    `}

  ${props =>
    props.measure &&
    css`
      max-width: 32em;
    `}

  ${({align, measure}) =>
    map(align, (val: alignValues) => {
      if (measure) {
        if (val === 'left' || val === 'start')
          return css`
            text-align: start;
            margin-inline: 0 auto;
          `;

        if (val === 'center')
          return css`
            text-align: ${val};
            margin-inline: auto;
          `;

        if (val === 'right' || val === 'end')
          return css`
            text-align: end;
            margin-inline: auto 0;
          `;
      } else if (align) {
        if (val === 'left' || val === 'start')
          return css`
            text-align: start;
          `;

        if (val === 'center')
          return css`
            text-align: ${val};
          `;

        if (val === 'right' || val === 'end')
          return css`
            text-align: end;
          `;
      }

      return '';
    })}

  ${props =>
    props.small &&
    css`
      font-family: ${token('theme.typography.copy.small.fontFamily', copyFontFamily)};
      font-size: ${token('theme.typography.copy.small.fontSize', copyFontSizeSmall)};
      font-weight: ${token('theme.typography.copy.small.fontWeight', copyFontWeight)};
      line-height: ${token('theme.typography.copy.small.lineHeight', copyLineHeight)};
      letter-spacing: ${token('theme.typography.copy.small.letterSpacing')};
    `}

  ${props =>
    props.large &&
    css`
      font-family: ${token('theme.typography.copy.large.fontFamily', copyFontFamily)};
      font-size: ${token('theme.typography.copy.large.fontSize', copyFontSizeLarge)};
      font-weight: ${token('theme.typography.copy.large.fontWeight', copyFontWeight)};
      line-height: ${token('theme.typography.copy.large.lineHeight', copyLineHeight)};
      letter-spacing: ${token('theme.typography.copy.large.letterSpacing')};
    `}
  
  ${props =>
    props.size &&
    !props.small &&
    !props.large &&
    map(props.size, (val: sizeValues) => {
      if (val === 'small') {
        return css`
          font-size: ${token('theme.typography.copy.small.fontSize', copyFontSizeSmall)};
        `;
      } else if (val === 'large') {
        return css`
          font-size: ${token('theme.typography.copy.large.fontSize', copyFontSizeLarge)};
        `;
      } else {
        return css`
          font-size: ${token('theme.typography.copy.fontSize', copyFontSize)};
        `;
      }
    })}
`;

Copy.displayName = 'Copy';

export default Copy;

export const Italic = styled(Copy).attrs({
  component: 'em',
  'data-mesh-component': 'ITALIC'
})`
  font-style: italic;

  ${Copy} > & {
    font-size: inherit;
  }
`;

Italic.displayName = 'Italic';

export const Bold = styled(Copy).attrs(props => ({
  component: 'strong',
  'data-mesh-component': 'BOLD',
  ...props
}))`
  font-weight: ${props => (props.weight ? props.weight : token('theme.typography.copy.bold.fontWeight', 'bold'))};

  ${Copy} > & {
    font-size: inherit;
  }
`;

Bold.displayName = 'Bold';
