import { Box, Stack, Inline, Column, Columns, Tiles, Hidden } from '@nib/layout';
import Copy from '@nib-components/copy';
import Tag from '@nib/tag';
import { SmallPlaceholder, LargePlaceholder } from "../../../../src/components/examples/Placeholder";
import * as React from 'react';
export default {
  Box,
  Stack,
  Inline,
  Column,
  Columns,
  Tiles,
  Hidden,
  Copy,
  Tag,
  SmallPlaceholder,
  LargePlaceholder,
  React
};