const FORMAT_AUS = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})/;
const FORMAT_ISO = /^([0-9]{4})-([0-9]{2})-([0-9]{2})/;

export const toISO = (value: string): string => {
  const matches = value.match(FORMAT_AUS);

  if (matches) {
    return `${matches[3]}-${matches[2]}-${matches[1]}`;
  } else {
    return value;
  }
};

export const fromISO = (value: string): string => {
  const matches = value.match(FORMAT_ISO);

  if (matches) {
    return `${matches[3]}/${matches[2]}/${matches[1]}`;
  } else {
    return value;
  }
};
