import React from 'react';
import {TickInCircleSystemIcon, InfoSystemIcon, WarningSystemIcon, CrossInCircleSystemIcon} from '@nib/icons';
import {typeValues} from './types';

export const pickBorderColor = (alertType: typeValues): string => {
  switch (alertType) {
    case 'info':
    default:
      return 'var(--themeColorBorderInfo,#005bb9)';
    case 'error':
      return 'var(--themeColorBorderError,#c81414)';
    case 'success':
      return 'var(--themeColorBorderSuccess,#007800)';
    case 'warning':
      return 'var(--themeColorBorderWarning,#ffb400)';
  }
};

export const pickBackgroundColor = (alertType: typeValues): string => {
  switch (alertType) {
    case 'info':
    default:
      return 'var(--alertColorBgInfo,#ebf2fa)';
    case 'error':
      return 'var(--alertColorBgError,#fcedeb)';
    case 'success':
      return 'var(--alertColorBgSuccess,#ebf6ee)';
    case 'warning':
      return 'var(--alertColorBgWarning,#fff9eb)';
  }
};

export const pickIconColor = (alertType: typeValues): string => {
  switch (alertType) {
    case 'info':
    default:
      return 'var(--themeColorFgInfo,#005bb9)';
    case 'error':
      return 'var(--themeColorFgError,#c81414)';
    case 'success':
      return 'var(--themeColorFgSuccess,#007800)';
    case 'warning':
      return 'var(--themeColorFgWarning,#444444)';
  }
};

export const renderIcon = (type: typeValues): JSX.Element => {
  const fill = pickIconColor(type);

  switch (type) {
    case 'info':
    default:
      return <InfoSystemIcon fill={fill} />;
    case 'error':
      return <CrossInCircleSystemIcon fill={fill} />;
    case 'success':
      return <TickInCircleSystemIcon fill={fill} />;
    case 'warning':
      return <WarningSystemIcon fill={fill} />;
  }
};
