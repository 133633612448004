import {HeaderConfigType, GetConfigParams} from './types';
import {getBaseUrl, getLogoUrl} from './utils';

const ABSOLUTE_BASEURL = 'https://www.nib.com.au';
const LOGO_URL = '/providers';

const getProvidersConfig = (config: GetConfigParams): HeaderConfigType => {
  const baseUrl = getBaseUrl(config, ABSOLUTE_BASEURL);
  const logoUrl = getLogoUrl(config, baseUrl, LOGO_URL);

  return {
    id: 'header-providers',
    logoUrl: logoUrl,
    callToAction: {
      id: 'id',
      title: 'Contact us',
      url: `${baseUrl}/providers/contact-us`
    },
    ribbon: [
      {
        id: 'ga-secnav-providers-nib-retail',
        title: 'nib health funds',
        url: `${baseUrl}/`
      }
    ],
    navLinks: [
      {
        id: 'ga-nav-providers-medical-specialists',
        title: 'Medical specialists',
        children: [
          {
            id: 'ga-nav-providers-medical-specialists-information',
            title: 'Medical specialists information',
            url: `${baseUrl}/providers/medical`
          },
          {
            id: 'ga-nav-providers-mediGap-network',
            title: 'MediGap network',
            url: `${baseUrl}/providers/medigap`
          }
        ]
      },
      {
        id: 'ga-nav-providers-hospitals',
        title: 'Hospitals',
        children: [
          {
            id: 'ga-nav-providers-agreement-hospitals',
            title: 'Agreement hospitals',
            url: `${baseUrl}/providers/hospital`
          },
          {
            id: 'ga-nav-providers-nib-HCP-portal',
            title: 'nib HCP portal',
            url: `${baseUrl}/providers/hcp-portal/user/login`
          }
        ]
      },
      {
        id: 'ga-nav-providers-ancillary',
        title: 'Ancillary',
        children: [
          {
            id: 'ga-nav-providers-recognised-ancillary-providers',
            title: 'Recognised ancillary providers',
            url: `${baseUrl}/providers/ancillary`
          },
          {
            id: 'ga-nav-providers-nib-first-choice-network',
            title: 'nib First Choice network',
            url: `${baseUrl}/providers/first-choice-network`
          }
        ]
      },
      {
        id: 'ga-nav-providers-health-care-at-home',
        title: 'Health care at home',
        url: `${baseUrl}/providers/nib-health-care-home`
      },
      {
        id: 'ga-nav-providers-international',
        title: 'International',
        children: [
          {
            id: 'ga-nav-providers-international-claiming-options',
            title: 'International claiming options',
            url: `${baseUrl}/providers/international-claiming-options `
          }
        ]
      }
    ],
    smallHeaderConfig: {
      id: 'small-header-providers',
      logoUrl: logoUrl,
      navLinks: []
    }
  };
};

export default getProvidersConfig;
