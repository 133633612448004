import {createGlobalStyle} from 'styled-components';
import {FONTS_CDN_URL} from '../constants';

const BRAND_CDN_URL = `${FONTS_CDN_URL}/nib`;
const BUENOS_AIRES = 'Buenos Aires';
const GRENETTE = 'Grenette Pro';

export const NibFonts = createGlobalStyle`
  @font-face {
    font-family: ${BUENOS_AIRES};
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(${BRAND_CDN_URL}/BuenosAiresWeb-Regular.woff2) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url(${BRAND_CDN_URL}/BuenosAiresWeb-Regular.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  @font-face {
    font-family: ${BUENOS_AIRES};
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(${BRAND_CDN_URL}/BuenosAiresWeb-SemiBold.woff2) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url(${BRAND_CDN_URL}/BuenosAiresWeb-SemiBold.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  @font-face {
    font-family: ${BUENOS_AIRES};
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(${BRAND_CDN_URL}/BuenosAiresWeb-SemiBold.woff2) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url(${BRAND_CDN_URL}/BuenosAiresWeb-SemiBold.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  @font-face {
    font-family: ${BUENOS_AIRES};
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(${BRAND_CDN_URL}/BuenosAiresWeb-Bold.woff2) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url(${BRAND_CDN_URL}/BuenosAiresWeb-Bold.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  @font-face {
    font-family: ${GRENETTE};
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(${BRAND_CDN_URL}/Grenette-SemiBold-Pro.woff2) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url(${BRAND_CDN_URL}/Grenette-SemiBold-Pro.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
`;
