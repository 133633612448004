import React, {FunctionComponent} from 'react';
import styled, {keyframes} from 'styled-components';
import Copy from '@nib-components/copy';
import {FileState, FileProgressEnum} from '../Interfaces';
import {Hidden} from '@nib/layout';

const dotAnimation = keyframes`
  0%, 20% {
    content: '.'
  }
  40% {
    content: '..'
  }
  60% {
    content: '...'
  }
  90%, 100% {
    content: ''
  }
`;

const LoadingDots = styled.span`
  &::before {
    content: '';
    animation: 2s ${dotAnimation} linear infinite;
  }
`;

export const FileListDescription: FunctionComponent<{file: FileState}> = ({file}) => {
  switch (file.progress) {
    case FileProgressEnum.UPLOADED:
      return (
        <Copy size="small">
          <Hidden screen as="span">
            Successfully uploaded {file.name}
          </Hidden>{' '}
          Attachment
        </Copy>
      );
    case FileProgressEnum.ERROR_UPLOAD_BLOCKED:
    case FileProgressEnum.ERROR:
      /* Hard coding Error text for now.
       * Need to send better values to errorFileThroughDispatch()
       * before using FileErrorAction.message as the error text.
       * */
      return (
        <Copy size="small">
          {file.errorMessage || (
            <>
              Sorry, your file{' '}
              <Hidden screen as="span">
                {file.name}
              </Hidden>{' '}
              could not be uploaded.
            </>
          )}
        </Copy>
      );
    case FileProgressEnum.UPLOADING:
      return (
        <Copy size="small">
          <Hidden screenReader as="span">
            Uploading
          </Hidden>
          <Hidden screen as="span">
            Uploading {file.name}
          </Hidden>
          <LoadingDots />
        </Copy>
      );
    default:
      return null;
  }
};
