import React from 'react';
import styled from 'styled-components';
import {Stack, px, py} from '@nib/layout';
import {ResponsiveSpaceProp} from '../constants';

const Relative = styled.div`
  position: relative;
  ${py(6)};
  ${px(5)};
`;

export const Overlay = styled.div.attrs({'data-testid': 'overlay'})`
  background-color: var(--themeColorBg, rgb(255 255 255 / 80%));
  position: absolute;
  inset: 0;
`;

export interface ProductCardBodyProps {
  children: React.ReactNode;
  isUnavailable: boolean;
  space?: ResponsiveSpaceProp;
  [key: string]: unknown; // other props
}

const ProductCardBody: React.FC<ProductCardBodyProps> = ({children, space = 6, isUnavailable, ...otherProps}) => {
  return (
    <Relative {...otherProps}>
      <Stack space={space}>{children}</Stack>
      {isUnavailable && <Overlay />}
    </Relative>
  );
};

export default ProductCardBody;
