import React from 'react';
import styled from 'styled-components';
import {Stack, breakpoint, m, px, py} from '@nib/layout';
import Heading, {componentValues} from '@nib-components/heading';
import Copy from '@nib-components/copy';
import {colorHospital, colorExtras, colorCombined, copyFontSizeSmall, colorLighter, colorWhite} from '@nib-components/theme';
import Loader from '@nib/loader';
import {PRODUCT_TYPES_Values, ResponsiveHeightProp, ResponsiveSpaceProp, TIER_SLUGS_Values} from '../constants';
import MinHeight from '../MinHeight';

export const productColors = {
  hospital: colorHospital,
  extras: colorExtras,
  combined: colorCombined
};

export const ProductName = styled.h3`
  ${m(0)};
  font-family: ${props => props.theme.headings.fontFamily};
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5;

  ${breakpoint('xxl')`
    height: 40px;
    display: inline;
    justify-content: center;
  `}
`;

export const SmallText = styled(Copy).attrs({component: 'div', align: 'center', size: 'small'})``;

export const StrikeThroughPrice = styled(SmallText)`
  text-decoration: line-through;
`;

const PriceEl = styled(({titleComponent, ...props}) => <Heading size={2} component={titleComponent} {...props} />)`
  color: currentColor;
  word-break: break-all;

  /* Hardcoded because we often have to fix heights for equal cards. Brands have different type scales so we need to pick an absolute value */
  font-size: 32px;
  font-weight: 400;
`;

export interface PriceProps {
  children: React.ReactNode;
  hasDisclaimer?: boolean;
  titleComponent?: componentValues;
}

export const Price = (props: PriceProps) => {
  const {children, hasDisclaimer, titleComponent = 'div'} = props;
  return (
    <PriceEl titleComponent={titleComponent}>
      ${children}
      {hasDisclaimer && <sup>*</sup>}
    </PriceEl>
  );
};

export const PaymentFrequency = styled(Heading).attrs({size: 4, component: 'div'})`
  color: currentColor;
  font-size: ${copyFontSizeSmall};
`;

const BORDER_BOTTOM_WIDTH = '1px';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const HeadEl = styled(({type, tier, ...rest}) => <MinHeight {...rest} />)`
  position: relative;
  text-align: center;
  border: none;
  ${m(0)};
  ${py(6)};
  ${px(5)};
  border-bottom: ${BORDER_BOTTOM_WIDTH} solid var(--themeColorBorder, ${colorLighter});
`;

export const PriceLoader = styled.div.attrs({'data-testid': 'price-loader'})`
  background-color: var(--themeColorBgSurface, ${colorWhite});
  opacity: 0.9;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset-block: 0 ${BORDER_BOTTOM_WIDTH};
  inset-inline: 0;

  /* Don't overlay the border */
  margin: 0 !important;
`;

const CentredLoader = styled(Loader)`
  margin-block-start: -1rem;
`;

export interface ProductCardHeaderProps {
  children: React.ReactNode;
  type: PRODUCT_TYPES_Values;
  isLoading?: boolean;
  space?: ResponsiveSpaceProp;
  tier?: TIER_SLUGS_Values;
  height?: ResponsiveHeightProp;
  [key: string]: unknown; // other props
}

const ProductCardHeader: React.FC<ProductCardHeaderProps> & {
  Name: typeof ProductName;
  Price: typeof Price;
  PaymentFrequency: typeof PaymentFrequency;
  SmallText: typeof SmallText;
  StrikeThroughPrice: typeof StrikeThroughPrice;
} = props => {
  const {children, type, tier, space = 6, height, isLoading, ...otherProps} = props;

  return (
    <HeadEl type={type} tier={tier} height={height} {...otherProps}>
      <Stack space={space}>{children}</Stack>
      {isLoading && (
        <PriceLoader>
          <CentredLoader size="lg" />
        </PriceLoader>
      )}
    </HeadEl>
  );
};

ProductCardHeader.Name = ProductName;
ProductCardHeader.Price = Price;
ProductCardHeader.PaymentFrequency = PaymentFrequency;

ProductCardHeader.SmallText = SmallText;
ProductCardHeader.StrikeThroughPrice = StrikeThroughPrice;

export default ProductCardHeader;
