import React from 'react';
import styled, {css} from 'styled-components';
import Copy, {Bold} from '@nib-components/copy';
import Expander from '@nib-components/expander';
import {ChevronDownSystemIcon, ChevronUpSystemIcon} from '@nib/icons';
import {Box, Column, Columns, Stack, pr, mr} from '@nib/layout';
import {copyFontFamily, copyFontSizeLarge, colorWhite} from '@nib-components/theme';
import type {StepType} from './ProgressStepper';
import {DesktopProgressStepper} from './DesktopProgressStepper';
import {Title as StepTitle} from './Step';
import {DEFAULT_STEP_COLOR, SELECTED_STEP_COLOR} from './constant';
import {UnstyledButton} from '@nib-components/button';

export const Node = styled.div.attrs({'data-mesh-component': 'PROGRESS-STEPPER-NODE'})<{
  currentIndex: number;
  isAccessed: boolean;
  isCurrent: boolean;
  hasAccessedPrevious: boolean;
  hasAccessedNext: boolean;
}>`
  height: 8px;
  width: 8px;
  border-radius: 100%;
  position: relative;

  ${({isAccessed, isCurrent}) => css`
    background-color: ${isAccessed || isCurrent ? SELECTED_STEP_COLOR : DEFAULT_STEP_COLOR};

    ${isCurrent &&
    `height: 12px;
    width: 12px;`}
  `}

  ${mr(2)};
  ${({currentIndex, isAccessed, isCurrent, hasAccessedPrevious, hasAccessedNext}) =>
    currentIndex != 0 &&
    (hasAccessedPrevious || hasAccessedNext) &&
    css`
      &::after {
        position: absolute;
        content: '';
        width: 8px;
        height: 1px;
        background-color: ${isAccessed || isCurrent ? SELECTED_STEP_COLOR : DEFAULT_STEP_COLOR};
        inset-inline-start: -8px;
        inset-block-start: ${isCurrent ? '6px' : '4px'};
      }
    `}
`;

export interface CollapsedProgressStepperProps {
  currentStep: number;
  steps: Array<StepType>;
  showIcons?: boolean;
  hasAccessedPrevious: boolean;
  hasAccessedNext: boolean;
  goToStep?: (index: number) => void;
}

const SecondColumn = styled(Column)`
  display: flex;
  align-items: center;
  width: fit-content;
`;

const ChevronWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
`;

export const Title = styled.div`
  font-family: ${copyFontFamily};
  font-size: ${copyFontSizeLarge};
  color: ${SELECTED_STEP_COLOR};
  font-weight: 700;
  text-align: end;
  ${pr(4)};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

const NodeColumn = styled(Column)`
  display: flex;
  align-items: center;
  ${pr(2)};
`;

const StyledBox = styled(Box)`
  border-bottom: 2px solid ${DEFAULT_STEP_COLOR};
`;

export const NextTitle = styled(Copy)`
  text-align: end;
  max-width: none;
  ${pr(4)};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

const Wrapper = styled.div`
  background: var(--themeColorBg, ${colorWhite});
  ${StepTitle} {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-align: start;
  }
`;

export const InvisibleButton = styled(props => <UnstyledButton {...props} />)`
  width: 100%;
`;

export const CollapsedProgressStepper: React.FC<CollapsedProgressStepperProps> = props => {
  const {steps, currentStep, hasAccessedPrevious, hasAccessedNext, goToStep} = props;

  const [expanded, setExpanded] = React.useState(false);
  return (
    <Wrapper data-mesh-component="COLLAPSED-PROGRESS-STEPPER">
      <InvisibleButton onClick={() => setExpanded(!expanded)} aria-expanded={expanded} aria-controls="CollapsedProgressStepper">
        <StyledBox paddingVertical={3} paddingHorizontal={4}>
          <Columns space={2}>
            <Column>
              <Stack space={2}>
                <Row>
                  <NodeColumn width="content">
                    {steps.map((step, index) => {
                      return (
                        <Node
                          key={index}
                          currentIndex={index}
                          isAccessed={step.isAccessed}
                          isCurrent={currentStep == index}
                          hasAccessedPrevious={hasAccessedPrevious}
                          hasAccessedNext={hasAccessedNext}
                        />
                      );
                    })}
                  </NodeColumn>

                  <Column>
                    <Title>{currentStep < 0 ? steps[0].title : currentStep > steps.length ? steps[steps.length - 1].title : steps[currentStep].title}</Title>
                  </Column>
                </Row>
                <Columns space={2}>
                  <Column width="content">
                    <Copy size="small" component="div">
                      Step {currentStep < 0 ? '1' : currentStep > steps.length - 1 ? steps.length : currentStep + 1} of {steps.length}
                    </Copy>
                  </Column>

                  <Column>
                    {currentStep >= 0 && currentStep < steps.length - 1 && (
                      <NextTitle size="small" component="div">
                        Next:<Bold weight={700}> {steps[currentStep + 1].title}</Bold>
                      </NextTitle>
                    )}
                  </Column>
                </Columns>
              </Stack>
            </Column>
            <SecondColumn>
              <ChevronWrapper>{expanded ? <ChevronUpSystemIcon size="xs" /> : <ChevronDownSystemIcon size="xs" />}</ChevronWrapper>
            </SecondColumn>
          </Columns>
        </StyledBox>
      </InvisibleButton>
      <Expander expanded={expanded}>
        <Box id="CollapsedProgressStepper">
          <DesktopProgressStepper align="stacked" goToStep={goToStep} {...props} />
        </Box>
      </Expander>
    </Wrapper>
  );
};

CollapsedProgressStepper.displayName = 'CollapsedProgressStepper';
