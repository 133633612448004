import React, {Children} from 'react';

import styled, {css} from 'styled-components';
import {SpacingType} from '@nib-components/theme';
import {map} from '../breakpoint';
import {m, mt, p, pl} from '../spacing';
import {isList, isValidSpacing, getSpacingValue, mapColumnstoFlexBasis, ResponsiveSpaceProp, ResponsiveOrStaticProp, Nullable, TilesColumnsType} from '../utils';

export interface TilesProps {
  columns?: ResponsiveOrStaticProp<Nullable<TilesColumnsType>>;
  space?: ResponsiveSpaceProp;
  flex?: boolean;
  as?: any; // eslint-disable-line  @typescript-eslint/no-explicit-any
  children: React.ReactNode;
  [key: string]: unknown;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TilesWrapper = styled(({as, space, columns, ...rest}) => <div {...rest} />)`
  display: flex;
  flex-wrap: wrap;

  /* If set to be a <ul> or <ol> we need to reset the default list styling */
  ${({as}) =>
    as &&
    isList(as) &&
    css`
      ${m(0)};
      ${p(0)};
      list-style-type: none;
    `}

  /* Negative left and top margins for parent */
  ${({space}) =>
    map(
      space,
      (val: SpacingType) =>
        isValidSpacing(val) &&
        css`
          margin-block-start: calc(0px - ${getSpacingValue(val)});
          margin-inline-start: calc(0px - ${getSpacingValue(val)});
        `
    )};

  /* Positive left padding and top margin for child */

  > * {
    box-sizing: border-box;
    min-inline-size: 0;
    width: 100%;

    ${({space}) =>
      map(
        space,
        (val: SpacingType) =>
          isValidSpacing(val) &&
          css`
            ${mt(val)};
            /* Have to use padding because of box model */
            ${pl(val)};
          `
      )};

    ${({columns}) => map(columns, (val: number) => val && mapColumnstoFlexBasis(val))};
  }

  /* If grid is supported everything is a lot simpler */
  @supports (display: grid) {
    display: grid;
    ${m(0)};

    > * {
      ${m(0)};
      ${p(0)};
    }

    ${({space}) =>
      map(
        space,
        (val: SpacingType) =>
          isValidSpacing(val) &&
          css`
            grid-gap: ${getSpacingValue(val)};
          `
      )};

    ${({columns}) =>
      map(
        columns,
        (val: number) =>
          val &&
          css`
            grid-template-columns: repeat(${val}, minmax(0, 1fr));
          `
      )};
  }
`;

const ChildWrapper = styled.div`
  ${({flex}: {flex: boolean}) =>
    flex &&
    `
    display: flex;
    width: 100%;
  `};
`;
ChildWrapper.displayName = 'ChildWrapper';

export const Tiles: React.FC<TilesProps> = props => {
  const {children, space = 0, flex = false, ...otherProps} = props;
  const childComponent = isList(props.as) ? 'li' : 'div';
  return (
    <TilesWrapper data-mesh-component="TILES" space={space} {...otherProps}>
      {Children.map(children, child => {
        if (React.isValidElement(child)) {
          return (
            <ChildWrapper as={childComponent} flex={flex}>
              {child}
            </ChildWrapper>
          );
        }
        return null;
      })}
    </TilesWrapper>
  );
};

Tiles.displayName = 'Tiles';
