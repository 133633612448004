import {tokenMapping} from '@nib-components/theme';
import * as TokensMapping from '@nib-group/design-tokens/build/web/tokens/token-mapping';

export const getTokens = (brand, mode) => {
  const token = tokenMapping[brand][mode] ? tokenMapping[brand][mode] : tokenMapping[brand]['default'];

  const tokenString = '{' + token.toString().replace(/^.*\{|\}/g, '') + '}';

  const correctJson = tokenString
    .replaceAll(';', ',')
    .replace(/(['"])?([a-z0-9A-Z_]+)(['"])?:/g, '"$2": ')
    .replaceAll('-', '')
    .replaceAll(':', ': "')
    .replaceAll(/(,\n)+/g, '" ,')
    .replace(/,([^,]*)$/, '$1');

  return JSON.parse(correctJson);
};

export const getFormattedTokenName = token => {
  const checkToken = '--' + token;
  for (var key in TokensMapping) {
    if (TokensMapping[key] == checkToken) {
      return key;
    }
  }
};
