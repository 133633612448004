import { FIGMA_LIBRARY_COMPONENTS_URL, FIGMA_LIBRARY_FOUNDATIONS_URL, STORYBOOK_URL } from "../../../../src/constants.js";
import { HyperlinkSystemIcon } from '@nib/icons';
import { Inline } from '@nib/layout';
import * as React from 'react';
export default {
  FIGMA_LIBRARY_COMPONENTS_URL,
  FIGMA_LIBRARY_FOUNDATIONS_URL,
  STORYBOOK_URL,
  HyperlinkSystemIcon,
  Inline,
  React
};