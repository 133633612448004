import React from 'react';
import styled from 'styled-components';
import {Columns, Column} from '@nib/layout';
import Help from '../Help';
import Label from '../Label';
import LabelWithTag from '../LabelWithTag';
import ValidationIcon from '../ValidationIcon';
import {ErrorMessage, InfoMessage} from '../ErrorMessage';
import InputWrapper from '../InputWrapper';
import defaultProps from '../common-default-props';
import {formControlTypeValues} from '../common-prop-types';
import {FullStoryLevel} from '../FullStoryMask';

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface SharedFormControlProps {
  children: any;
  label: string;
  className?: string;
  disabled?: boolean;
  info?: string;
  error?: string;
  help?: any;
  id?: string;
  name?: string;
  valid?: boolean;
  validated?: boolean;
  isCompact?: boolean;
  formControlType?: formControlTypeValues;
  formType?: formControlTypeValues;
  fullstory?: FullStoryLevel;
  blur?: any;
  change?: any;
  filter?: any;
  focus?: any;
  initialise?: any;
  validate?: any;

  active?: any;
  filtered?: any;
  filtering?: any;
  initialised?: any;
  lastValidValue?: any;
  validating?: any;

  [key: string]: unknown;
}

export interface FormControlProps extends SharedFormControlProps {
  width?: string | number;
  isEmptyAndOptional?: boolean;
  isFullWidthSelect?: boolean;
}

const ZeroFontSizeColumn = styled(Column)`
  font-size: 0;
`;

const FormControl: React.FC<FormControlProps> = ({
  children,
  className = defaultProps.className,
  disabled = defaultProps.disabled,
  error = defaultProps.error,
  info,
  help = defaultProps.help,
  id = defaultProps.id,
  label,
  name = defaultProps.name,
  valid = defaultProps.valid,
  validated = defaultProps.validated,
  isCompact = defaultProps.isCompact,
  formControlType,
  formType,
  width,
  isEmptyAndOptional = defaultProps.isEmptyAndOptional,
  isFullWidthSelect = false,
  fullstory = defaultProps.fullstory,

  /* eslint-disable @typescript-eslint/no-unused-vars */
  //redux-formo methods
  blur,
  change,
  filter,
  focus,
  initialise,
  validate,

  //redux-form props
  active,
  filtered,
  filtering,
  initialised,
  validating,
  lastValidValue,
  /* eslint-enable @typescript-eslint/no-unused-vars */
  ...otherProps
}) => {
  const isSelectInput = children && children.type.displayName === 'Select';
  const isFileUpload = children && children.type.displayName === 'FileUploadComponent';

  const fullStoryClassName = `fs-${fullstory}`;
  const inputClasses = [fullStoryClassName];
  if (children && children.props.className) {
    inputClasses.push(children.props.className);
  }

  return (
    <div className={className} data-mesh-component="FORM-CONTROL">
      <Label htmlFor={id} isCompact={isCompact} disabled={disabled}>
        {formControlType ? <LabelWithTag label={label} formControlType={formControlType} /> : label}
      </Label>

      {help ? (
        React.isValidElement(help) ? (
          <div id={`help-${id}`}>{help}</div>
        ) : (
          <Help id={`help-${id}`} disabled={disabled}>
            {help}
          </Help>
        )
      ) : null}

      <InputWrapper isSelectInput={isSelectInput} width={width} isCompact={isCompact} isFullWidthSelect={isFullWidthSelect}>
        {children &&
          React.cloneElement(children, {
            ...otherProps,
            className: inputClasses.join(' '),
            name,
            disabled,
            valid,
            validated,
            id,
            'aria-describedby': `help-${id} error-${id}`,
            'aria-invalid': validated && !valid,
            'aria-required': formControlType === 'required' || (formType === 'required' && !formControlType),
            isCompact: children.props.isCompact || isCompact
          })}
        {!isFileUpload && ((validated && !valid) || (validated && !isEmptyAndOptional)) && <ValidationIcon valid={valid} outside={isSelectInput} isCompact={isCompact} disabled={disabled} />}
      </InputWrapper>

      {isFileUpload && validated && error && (
        <Columns verticalAlign="center">
          <ZeroFontSizeColumn width="content">{validated && !valid && <ValidationIcon valid={valid} disabled={disabled} outside isFieldset isCompact={isCompact} />}</ZeroFontSizeColumn>
          <Column>{validated && error && <ErrorMessage valid={valid} disabled={disabled} id={`${id}`} error={error} />}</Column>
        </Columns>
      )}
      {!isFileUpload && validated && error && <ErrorMessage valid={valid} id={`${id}`} error={error} disabled={disabled} />}
      {validated && info && <InfoMessage valid={valid} id={`${id}`} info={info} disabled={disabled} fullstory={fullstory} />}
    </div>
  );
};

export default FormControl;
