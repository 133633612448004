import {css} from 'styled-components';
import {map} from '../../breakpoint';
import {ResponsiveOrStaticProp} from './../../utils';

export type LineHeightType = ResponsiveOrStaticProp<string>;

export const lineHeight = () => css`
  ${({lineHeight}: {lineHeight?: LineHeightType}) =>
    map(
      lineHeight,
      (val: string) =>
        val !== undefined &&
        css`
          line-height: ${val};
        `
    )};
`;
