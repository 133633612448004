import {SessionState} from './SessionState';
import {UploadProgressState} from './UploadProgressState';

export type FileOnParent = Readonly<Omit<UploadProgressState, 'uploadStatus' | 'inProgress' | 'uploaded'>>;

export interface ParentFileState {
  filesOnParent: FileOnParent[];
  sessionInitializer?: Promise<string>;
  sessionId?: string;
}

export type ParentFileStateRehydrate = Omit<ParentFileState, 'sessionId'>;

export interface ParentFileStateProps extends Partial<ParentFileStateRehydrate> {
  setFilesOnParent?: (state: Readonly<ParentFileState>) => void;
}

export interface ParentUploadReadyState {
  setFilesReadyForUpload?: (filesReadyForUpload: boolean) => void;
}

export enum APIUploadStatus {
  Unknown = 'Unknown',
  Pending = 'Pending',
  Processing = 'Processing',
  Error = 'Processing Failed',
  Complete = 'Processing Complete'
}

export interface FileUploadStore {
  files: UploadProgressState[]; // Simpler file state (mainly for UI purposes)
  gatewayEndpoint: string;
  session: SessionState;
}
