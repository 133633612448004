import React from 'react';
import styled, {css} from 'styled-components';
import {Step} from './Step';
import {pb} from '@nib/layout';
import {BORDER_WIDTH, DEFAULT_STEP_COLOR, SELECTED_STEP_COLOR} from './constant';
import type {ProgressStepperProps} from './ProgressStepper';

type StyledOrderListProps = Pick<ProgressStepperProps, 'align' | 'fill'>;
type StyledListProps = Pick<ProgressStepperProps, 'align' | 'fill'>;

const PseudoStyle = css`
  position: absolute;
  content: '';
`;

const getInlineBeforeStyle = (hasAccessedPrevious: boolean) => {
  return css`
    &::before {
      ${PseudoStyle};
      height: ${BORDER_WIDTH};
      inset-block-end: 0;
      width: 50%;
      inset-inline-start: 0;
      background-color: ${hasAccessedPrevious ? SELECTED_STEP_COLOR : DEFAULT_STEP_COLOR};
    }
  `;
};

const getInlineAfterStyle = (hasAccessedNext: boolean) => {
  return css`
    &::after {
      ${PseudoStyle};
      inset-inline-end: 0;
      height: ${BORDER_WIDTH};
      inset-block-end: 0;
      width: 50%;
      background-color: ${hasAccessedNext ? SELECTED_STEP_COLOR : DEFAULT_STEP_COLOR};
    }
  `;
};

const getStackedBeforeStyle = (hasAccessedPrevious: boolean) => {
  return css`
    &::before {
      ${PseudoStyle};
      width: ${BORDER_WIDTH};
      height: 50%;
      background-color: ${hasAccessedPrevious ? SELECTED_STEP_COLOR : DEFAULT_STEP_COLOR};
      inset-block-start: 0;
      inset-inline-start: 22px;
    }
  `;
};

const getStackedAfterStyle = (hasAccessedNext: boolean) => {
  return css`
    &::after {
      ${PseudoStyle};
      width: ${BORDER_WIDTH};
      height: 50%;
      background-color: ${hasAccessedNext ? SELECTED_STEP_COLOR : DEFAULT_STEP_COLOR};
      inset-block-end: 0;
      inset-inline-start: 22px;
    }
  `;
};

// eslint-disable-next-line  @typescript-eslint/no-unused-vars
export const StepperOl = styled(({fill, align, ...otherProps}) => <ol {...otherProps} />)<StyledOrderListProps>`
  padding: 0;
  margin: 0;
  display: flex;
  overflow-x: auto;
  ${({align, fill}) =>
    css`
      ${align == 'inline' && 'align-items: end'};
      flex-direction: ${align == 'inline' ? 'row' : 'column'};
      ${align == 'inline' && fill && 'flex:1;'}
      ${align == 'inline' && pb(5)};
    `};
`;

// eslint-disable-next-line  @typescript-eslint/no-unused-vars
export const StepperLi = styled(({fill, align, position, hasAccessedPrevious, hasAccessedNext, ...otherProps}) => <li {...otherProps} />)<StyledListProps>`
  list-style-type: none;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;

  ${({align, fill}) =>
    css`
      ${align == 'inline' && fill && 'width:100%;'}
    `}

  ${({align}) => {
    if (align == 'inline') {
      return css`
        height: 100%;
        flex-direction: column;

        ${
          // eslint-disable-next-line  @typescript-eslint/no-explicit-any
          ({position, hasAccessedPrevious, hasAccessedNext}: any) => {
            if (position === 'first') {
              return getInlineAfterStyle(hasAccessedNext);
            }
            if (position === 'last') {
              return getInlineBeforeStyle(hasAccessedPrevious);
            }
            if (position === 'middle') {
              return css`
                ${getInlineBeforeStyle(hasAccessedPrevious)} ${getInlineAfterStyle(hasAccessedNext)}
              `;
            }
          }
        }
      `;
    } else {
      return css`
        gap: 1rem;
        justify-content: flex-start;
        flex-direction: row;

        ${
          // eslint-disable-next-line  @typescript-eslint/no-explicit-any
          ({position, hasAccessedPrevious, hasAccessedNext}: any) => {
            if (position === 'first') {
              return getStackedAfterStyle(hasAccessedNext);
            }
            if (position === 'last') {
              return getStackedBeforeStyle(hasAccessedPrevious);
            }
            if (position === 'middle') {
              return css`
                ${getStackedBeforeStyle(hasAccessedPrevious)} ${getStackedAfterStyle(hasAccessedNext)}
              `;
            }
          }
        }
      `;
    }
  }}
`;

const Nav = styled.nav`
  isolation: isolate;
`;

export const DesktopProgressStepper: React.FC<ProgressStepperProps> = props => {
  const {steps, align = 'inline', showIcons = true, fill = true, stepWidth, currentStep, isGated = true, goToStep} = props;
  return (
    <Nav aria-label="progress" data-mesh-component="DESKTOP-PROGRESS-STEPPER">
      <StepperOl align={align} fill={fill}>
        {steps.map((step, index) => (
          <StepperLi
            key={index}
            fill={fill}
            align={align}
            position={index == 0 ? 'first' : index == steps.length - 1 ? 'last' : 'middle'}
            hasAccessedPrevious={index != 0 && steps[index - 1].isAccessed && steps[index].isAccessed}
            hasAccessedNext={index != steps.length - 1 && steps[index + 1].isAccessed && steps[index].isAccessed}
          >
            <Step
              position={index == 0 ? 'first' : index == steps.length - 1 ? 'last' : 'middle'}
              title={step.title}
              icon={showIcons ? step.icon : undefined}
              align={align}
              isAccessed={step.isAccessed}
              hasAccessedNext={index != steps.length - 1 && steps[index + 1].isAccessed && steps[index].isAccessed}
              isCurrent={currentStep === index}
              hasAccessedPrevious={index != 0 && steps[index - 1].isAccessed && steps[index].isAccessed}
              isDisabled={step.isDisabled}
              width={!fill ? stepWidth : ''}
              href={step.href}
              component={step.component}
              isComplete={step.isComplete}
              isGated={isGated}
              aria-hidden={step.isDisabled || (isGated && !step.isAccessed)}
              aria-current={currentStep === index ? 'step' : 'false'}
              aria-label={`${step.title}  ${step.isDisabled || (isGated && !step.isAccessed) ? 'disabled' : ' '}`}
              onClick={
                step.isAccessed || !isGated
                  ? () => {
                      if (goToStep) goToStep(index);
                    }
                  : () => null
              }
            />
          </StepperLi>
        ))}
      </StepperOl>
    </Nav>
  );
};
