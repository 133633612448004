import React from 'react';
import styled, {css} from 'styled-components';
import {Margin, py, px} from '@nib/layout';
import Copy from '@nib-components/copy';
import Heading, {componentValues} from '@nib-components/heading';
import {colorLighter, colorLightest, copyFontSizeSmall} from '@nib-components/theme';
import {Nullable} from '../constants';

export interface LimitProps {
  id: number;
  productId: number;
  productName: string;
  limitAmount: Nullable<number>;
  limitText: Nullable<string>;
  applyToName: Nullable<string>;
  serviceId: number;
  timePeriod: string;
}

export interface ChildLimitProps extends LimitProps {
  parentId: number;
  serviceName: string;
}

export interface LimitsForActiveServiceForEachProductProps extends LimitProps {
  annualIncrease?: Nullable<number>;
  maxLimitPerTimePeriod?: Nullable<number>;
  childLimits?: ChildLimitProps[];
}
export interface AnnualLimitsProps {
  limitsForActiveServiceForEachProduct?: LimitsForActiveServiceForEachProductProps[];
  productId?: number;
  onlyShowOwnProductLimits?: boolean;
  titleComponent?: componentValues;
}

export interface TableDataProps {
  highlight?: boolean;
}

const Table = styled.table`
  border-spacing: 0;
  border: 1px solid var(--themeColorBorder, ${colorLighter});
  border-bottom: none;
  width: 100%;
`;

const Th = styled.th`
  ${py(1)};
  ${px(2)};
  font-size: ${copyFontSizeSmall};
  text-align: start;
  text-transform: uppercase;
  border-bottom: 1px solid var(--themeColorBorder, ${colorLighter});
`;

const ThLimit = styled(Th)`
  text-align: end;
`;

export const Td = styled.td<TableDataProps>`
  vertical-align: top;
  ${py(1)};
  ${px(2)};
  border-bottom: 1px solid var(--themeColorBorder, ${colorLighter});
  ${props =>
    props.highlight &&
    css`
      background-color: var(--themeColorBgSurface, ${colorLightest});
    `}
`;

const ChildCopy = styled(Copy)`
  ${py(2)};
`;

const AnnualLimitsTable: React.FC<AnnualLimitsProps> = ({limitsForActiveServiceForEachProduct, productId, onlyShowOwnProductLimits, titleComponent}) => {
  return (
    <Margin bottom={4}>
      <Margin bottom={4}>
        <Heading size={4} component={titleComponent}>
          Annual limits:
        </Heading>
      </Margin>
      <Table>
        <thead>
          <tr>
            <Th>Product</Th>
            <ThLimit>Annual Limit</ThLimit>
          </tr>
        </thead>
        <tbody>
          {limitsForActiveServiceForEachProduct &&
            limitsForActiveServiceForEachProduct
              .filter(limit => {
                if (onlyShowOwnProductLimits) {
                  return limit.productId === productId;
                } else {
                  return true;
                }
              })
              .map((limit, i) => (
                <tr key={`limit-for-${limit.productId}-${i}`}>
                  <Td highlight={productId === limit.productId}>
                    <Copy>{limit.productName}</Copy>
                    {limit.childLimits &&
                      limit.childLimits.map(child => (
                        <ChildCopy size="small" key={child.id}>
                          You can use up to ${child.limitAmount} of your limit on {child.serviceName}
                        </ChildCopy>
                      ))}
                  </Td>
                  <Td highlight={productId === limit.productId}>
                    {limit && limit.limitAmount && limit.annualIncrease && limit.applyToName && limit.maxLimitPerTimePeriod ? (
                      <Copy>
                        The annual limit on this service starts at ${limit.limitAmount.toString()} and increases by ${limit.annualIncrease.toString()} per {limit.applyToName.toLowerCase()} per
                        calendar year to ${limit.maxLimitPerTimePeriod.toString()} {limit.timePeriod.toLowerCase()} limit.
                      </Copy>
                    ) : (
                      <Copy align="right">{limit.limitAmount ? `$${limit.limitAmount}` : limit.limitText}</Copy>
                    )}
                  </Td>
                </tr>
              ))}
        </tbody>
      </Table>
    </Margin>
  );
};

export default AnnualLimitsTable;
