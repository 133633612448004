import React, {type AnchorHTMLAttributes, type ButtonHTMLAttributes} from 'react';
import styled, {css} from 'styled-components';
import {buttonLargeStyles, buttonMediumStyles, buttonSmallStyles, validBreakpointValues} from '@nib-components/theme';
import {IconProps} from '@nib/icons';
import {breakpoint, Column, Columns, map, py, px} from '@nib/layout';
import Loader from '@nib/loader';

type breakpointValues = (typeof validBreakpointValues)[number];

export const validSizes = ['small', 'medium', 'large'] as const;
type sizeValues = (typeof validSizes)[number];

export const validIconPlacement = ['left', 'right', 'start', 'end'] as const;
type iconPlacementValues = (typeof validIconPlacement)[number];

type Nullable<T> = T | null;

type PartialRecord<K extends keyof never, T> = {
  [P in K]?: T;
};
type ResponsiveProp<T> = PartialRecord<breakpointValues, T>;
type ResponsiveOrStaticProp<T> = T | ResponsiveProp<T>;

interface CommonButtonProps {
  icon?: React.FC<IconProps>;
  iconPlacement?: iconPlacementValues;
  selected?: boolean;
  size?: ResponsiveOrStaticProp<Nullable<sizeValues>>;
  /** @deprecated Please use size="small" instead. */
  small?: boolean;
  /** @deprecated Please use size="large" instead. */
  large?: boolean;
  fullWidth?: ResponsiveOrStaticProp<Nullable<boolean>>;
  isLoading?: boolean;
  isCompact?: boolean;
}

export interface ButtonElementProps extends CommonButtonProps, ButtonHTMLAttributes<HTMLButtonElement> {
  // 'any' due to our use of GatsbyJS's Link component which doesn't fall under any defined PropType.
  component?: any; // eslint-disable-line  @typescript-eslint/no-explicit-any
}

export interface AnchorElementProps extends CommonButtonProps, AnchorHTMLAttributes<HTMLAnchorElement> {
  component?: 'a';
}

export type BaseButtonProps = ButtonElementProps | AnchorElementProps;

interface FullWidthStylingProps {
  readonly fullWidth?: ResponsiveOrStaticProp<Nullable<boolean>>;
}

export const fullWidthStyling = css<FullWidthStylingProps>`
  ${props =>
    map(props.fullWidth ?? false, (val: boolean) => {
      if (val) {
        return `
          width: 100%;
        `;
      } else {
        return `width: auto;`;
      }
    })}
`;

interface SizeStylingProps {
  readonly size?: ResponsiveOrStaticProp<Nullable<sizeValues>>;
  readonly small?: boolean;
  readonly large?: boolean;
}

export const sizeStyling = css<SizeStylingProps>`
  /* Reduce padding below 240px screen */
  ${breakpoint('xs', 'mini')`
    ${py(2)};
    ${px(3)};
  `}

  ${props => {
    const intepretedSize = props.small ? 'small' : props.large ? 'large' : props.size ?? 'medium';

    return map(intepretedSize, (val: sizeValues) => {
      if (val === 'small') {
        return css`
          ${buttonSmallStyles(props)};
        `;
      } else if (val === 'large') {
        return css`
          ${buttonLargeStyles(props)};
        `;
      } else {
        return css`
          ${buttonMediumStyles(props)};
        `;
      }
    });
  }}
`;

export const StyledSpan = styled.span`
  word-break: break-word;
`;

const StyledLineHeight = styled.div`
  line-height: 0;
`;

const ShrinkColumn = styled(Column)`
  flex-shrink: 1;
`;

const BaseButton = React.forwardRef<HTMLAnchorElement | HTMLButtonElement, BaseButtonProps>((props, ref) => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const {
    component = 'button',
    icon: Icon,
    iconPlacement = 'end' as const,
    size = 'medium' as const,
    small = false,
    large = false,
    fullWidth = false,
    isCompact,
    isLoading = false,
    children,
    ...otherProps
  } = props;

  const Component = 'href' in props ? 'a' : component;

  return (
    <Component ref={ref} {...otherProps}>
      <Columns space={2} verticalAlign="center" align="center" wrap>
        {Icon && !isLoading && (iconPlacement === 'left' || iconPlacement === 'start') && (
          <Column width="content">
            <StyledLineHeight>
              <Icon size="xs" fill="currentColor" />
            </StyledLineHeight>
          </Column>
        )}
        {isLoading && (iconPlacement === 'left' || iconPlacement === 'start') && (
          <Column width="content">
            <StyledLineHeight>
              <Loader size="xs" fill="currentColor" />
            </StyledLineHeight>
          </Column>
        )}
        <ShrinkColumn width="content">
          <StyledSpan>{children}</StyledSpan>
        </ShrinkColumn>
        {Icon && !isLoading && (iconPlacement === 'right' || iconPlacement === 'end') && (
          <Column width="content">
            <StyledLineHeight>
              <Icon size="xs" fill="currentColor" />
            </StyledLineHeight>
          </Column>
        )}
        {isLoading && (iconPlacement === 'right' || iconPlacement === 'end') && (
          <Column width="content">
            <StyledLineHeight>
              <Loader size="xs" fill="currentColor" />
            </StyledLineHeight>
          </Column>
        )}
      </Columns>
    </Component>
  );
});

export default BaseButton;
