import {css} from 'styled-components';
import Colr from 'colr';
import {
  buttonReset,
  getSmallButtonTokens,
  getMediumButtonTokens,
  getLargeButtonTokens,
  getPrimaryButtonTokens,
  getPrimaryButtonSelectedTokens,
  getPrimaryButtonDisabledTokens,
  getSecondaryButtonTokens,
  getSecondaryButtonSelectedTokens,
  getSecondaryButtonDisabledTokens,
  getTertiaryButtonTokens,
  getTertiaryButtonSelectedTokens,
  getTertiaryButtonDisabledTokens,
  getDestructiveButtonTokens,
  getDestructiveButtonSelectedTokens,
  getDestructiveButtonDisabledTokens
} from '../shared';
import common, {spacing} from '../../common';
import {ThemeSchema} from '../../types';
import token from '../../getToken';

export const hoverColor = (color: string): string => Colr.fromHex(color).darken(10).toHex();
export const activeColor = (color: string): string => Colr.fromHex(color).darken(20).toHex();

export const disabledColor = (color: string, percent?: number): string => {
  const opacity = percent || 0.3;
  const rgb = Colr.fromHex(color).toRgbArray().toString();
  return `rgba(${rgb}, ${opacity})`;
};

const typeScale = {
  1: '2rem',
  2: '1.5rem',
  3: '1.125rem',
  4: '1rem',
  5: '.875rem',
  6: '.75rem'
};

const lineHeights = [1, 1.1667, 1.375, 1.5];

const tracking = {
  normal: 'normal'
};

const fonts = {
  buenosAires: '"Buenos Aires", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
  copy: '"Buenos Aires", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif'
};

const colors = {
  brand: {
    base: '#3F94AC',
    light: '#00C6D7',
    dark: '#3D6C89'
  },
  accent: '#00B49D',
  shades: {
    black: '#000000',
    darkest: '#444444',
    darker: '#626366',
    dark: '#999999',
    light: '#ebebeb',
    lighter: '#DCE5EC',
    lightest: '#e7eef7',
    nearWhite: '#fafafa',
    white: '#ffffff'
  },
  focus: '#00828d',
  error: '#dd5942',
  success: '#00b1c0',
  info: '#005bb9',
  product: {
    hospital: '#3D6C89',
    extras: '#3D6C89',
    combined: '#3D6C89',
    highlight: {
      backgroundColor: '#3D6C89',
      textColor: '#ffffff'
    }
  }
};

const focus = {
  outlineColor: colors.focus,
  outlineStyle: 'dotted',
  outlineWidth: '2px',
  outlineOffset: '0.125em'
};

const baseButtonStyles = css`
  ${buttonReset};
  font-family: ${fonts.buenosAires};
  font-weight: 600;
  border: none;
  border-radius: ${token('button.dimension.border.radius', '999px')};
  border-width: ${token('button.dimension.border.width', '2px')};

  &:disabled {
    background-color: #e9eaec;
    color: #bcbdc1;
    pointer-events: none;
  }
`;

const buttonStyles = {
  primary: {
    normal: css`
      ${baseButtonStyles};
      ${getPrimaryButtonTokens({
        bg: {
          default: '#00b1c0',
          hover: colors.brand.dark,
          active: colors.brand.dark,
          disabled: '#e9eaec'
        },
        fg: {
          default: colors.shades.white,
          hover: colors.shades.white,
          active: colors.shades.white,
          disabled: '#bcbdc1'
        },
        border: {
          default: 'transparent',
          hover: 'transparent',
          active: 'transparent',
          disabled: 'transparent'
        }
      })};
    `,
    selected: css`
      ${getPrimaryButtonSelectedTokens({
        bg: colors.brand.dark,
        fg: colors.shades.white,
        border: 'transparent'
      })};
    `,
    disabled: css`
      ${getPrimaryButtonDisabledTokens({
        bg: '#e9eaec',
        fg: '#bcbdc1',
        border: 'transparent'
      })};
    `
  },
  secondary: {
    normal: css`
      ${baseButtonStyles};
      ${getSecondaryButtonTokens({
        bg: {
          default: colors.shades.white,
          hover: colors.shades.white,
          active: colors.shades.white,
          disabled: '#e9eaec'
        },
        fg: {
          default: colors.brand.dark,
          hover: colors.brand.dark,
          active: colors.brand.dark,
          disabled: disabledColor(colors.brand.dark)
        },
        border: {
          default: colors.brand.dark,
          hover: colors.brand.dark,
          active: colors.brand.dark,
          disabled: disabledColor(colors.brand.dark)
        }
      })};
    `,
    selected: css`
      ${getSecondaryButtonSelectedTokens({
        bg: colors.shades.white,
        fg: activeColor(colors.brand.dark),
        border: activeColor(colors.brand.dark)
      })};
    `,
    disabled: css`
      ${getSecondaryButtonDisabledTokens({
        bg: '#e9eaec',
        fg: disabledColor(colors.brand.dark),
        border: disabledColor(colors.brand.dark)
      })};
    `
  },
  tertiary: {
    normal: css`
      ${baseButtonStyles};
      ${getTertiaryButtonTokens({
        bg: {
          default: colors.shades.white,
          hover: colors.shades.white,
          active: colors.shades.white,
          disabled: '#e9eaec'
        },
        fg: {
          default: colors.brand.dark,
          hover: hoverColor(colors.brand.dark),
          active: activeColor(colors.brand.dark),
          disabled: disabledColor(colors.brand.dark)
        },
        border: {
          default: 'transparent',
          hover: 'transparent',
          active: 'transparent',
          disabled: 'transparent'
        }
      })};
    `,
    selected: css`
      ${getTertiaryButtonSelectedTokens({
        bg: colors.shades.white,
        fg: activeColor(colors.brand.dark),
        border: 'transparent'
      })};
    `,
    disabled: css`
      ${getTertiaryButtonDisabledTokens({
        bg: '#e9eaec',
        fg: disabledColor(colors.brand.dark),
        border: 'transparent'
      })};
    `
  },
  destructive: {
    normal: css`
      ${baseButtonStyles};
      ${getDestructiveButtonTokens({
        bg: {
          default: colors.shades.white,
          hover: colors.shades.white,
          active: colors.shades.white,
          disabled: '#e9eaec'
        },
        fg: {
          default: colors.error,
          hover: hoverColor(colors.error),
          active: activeColor(colors.error),
          disabled: disabledColor(colors.error, 0.6)
        },
        border: {
          default: colors.error,
          hover: hoverColor(colors.error),
          active: activeColor(colors.error),
          disabled: disabledColor(colors.error, 0.6)
        }
      })};
    `,
    selected: css`
      ${getDestructiveButtonSelectedTokens({
        bg: colors.shades.white,
        fg: activeColor(colors.error),
        border: activeColor(colors.error)
      })};
    `,
    disabled: css`
      ${getDestructiveButtonDisabledTokens({
        bg: '#e9eaec',
        fg: disabledColor(colors.error, 0.6),
        border: disabledColor(colors.error, 0.6)
      })};
    `
  },
  small: css`
    ${getSmallButtonTokens({
      fontFamily: fonts.buenosAires,
      fontSize: typeScale[5],
      fontWeight: 600,
      lineHeight: lineHeights[0],
      letterSpacing: tracking.normal,
      textTransform: 'none',
      paddingBlock: spacing[3],
      paddingInline: spacing[3],
      gap: spacing[2]
    })};
  `,
  medium: css`
    ${getMediumButtonTokens({
      fontFamily: fonts.buenosAires,
      fontSize: typeScale[4],
      fontWeight: 600,
      lineHeight: 1.25,
      letterSpacing: tracking.normal,
      textTransform: 'none',
      paddingBlock: spacing[3],
      paddingInline: spacing[5],
      gap: spacing[2]
    })};
  `,
  large: css`
    ${getLargeButtonTokens({
      fontFamily: fonts.buenosAires,
      fontSize: typeScale[3],
      fontWeight: 600,
      lineHeight: 1.33,
      letterSpacing: tracking.normal,
      textTransform: 'none',
      paddingBlock: spacing[3],
      paddingInline: spacing[5],
      gap: spacing[2]
    })};
  `
};

const baseFormValues = {
  borderColor: colors.shades.dark,
  borderColorValid: colors.success,
  borderColorInvalid: colors.error,
  helpTextColor: colors.shades.darker,
  helpTextSize: typeScale[5],
  labelColor: colors.shades.darkest,
  labelSize: typeScale[4],
  selectedToggleColor: colors.brand.base,
  errorMessageTextColor: colors.error,
  infoMessageTextColor: colors.info
};

const gu: ThemeSchema = {
  ...common,
  typeScale,
  fonts,
  colors,
  focus,

  id: 'gu',
  mode: {
    form: 'light' //default
  },

  headings: {
    color: 'currentColor',
    h1: {
      fontFamily: fonts.buenosAires,
      fontSize: typeScale[1],
      lineHeight: lineHeights[1],
      fontWeight: 600,
      tracking: tracking.normal,
      uppercase: false
    },
    h2: {
      fontFamily: fonts.buenosAires,
      fontSize: typeScale[2],
      lineHeight: lineHeights[2],
      fontWeight: 400,
      tracking: tracking.normal,
      uppercase: false
    },
    h3: {
      fontFamily: fonts.buenosAires,
      fontSize: typeScale[3],
      lineHeight: lineHeights[2],
      fontWeight: 400,
      tracking: tracking.normal,
      uppercase: false
    },
    h4: {
      fontFamily: fonts.buenosAires,
      fontSize: typeScale[4],
      lineHeight: lineHeights[2],
      fontWeight: 600,
      tracking: tracking.normal,
      uppercase: false
    },
    h5: {
      fontFamily: fonts.buenosAires,
      fontSize: typeScale[6],
      lineHeight: lineHeights[3],
      fontWeight: 400,
      tracking: tracking.normal,
      uppercase: true
    },
    h6: {
      fontFamily: fonts.buenosAires,
      fontSize: typeScale[6],
      lineHeight: lineHeights[2],
      fontWeight: 400,
      tracking: tracking.normal,
      uppercase: true
    }
  },

  copy: {
    color: colors.shades.darker,
    fontFamily: fonts.buenosAires,
    fontSize: typeScale[4],
    fontWeight: 400,
    lineHeight: lineHeights[3],
    smallFontSize: typeScale[5],
    largeFontSize: typeScale[3]
  },

  link: {
    loudColor: colors.brand.dark,
    darkColor: colors.shades.darker,
    lightColor: colors.shades.white,
    destructiveColor: colors.error
  },

  button: {
    small: buttonStyles.small,
    medium: buttonStyles.medium,
    large: buttonStyles.large,
    primary: buttonStyles.primary,
    secondary: buttonStyles.secondary,
    tertiary: buttonStyles.tertiary,
    destructive: buttonStyles.destructive
  },

  forms: {
    light: {
      ...baseFormValues,
      backgroundColor: colors.shades.lightest,
      inputBackgroundColor: colors.shades.white,
      boxShadow: `0 4px 8px 0 ${colors.shades.lighter}`
    },
    white: {
      ...baseFormValues,
      backgroundColor: colors.shades.white,
      inputBackgroundColor: colors.shades.lightest,
      boxShadow: 'none'
    }
  },

  smallFooter: {
    backgroundColor: '#0e4160',
    textColor: colors.shades.lightest,
    linkColor: colors.shades.lightest
  },

  panel: {
    loud: {
      backgroundColor: colors.brand.base,
      textColor: colors.shades.white
    },
    muted: {
      backgroundColor: colors.shades.lighter,
      textColor: colors.shades.darkest
    },
    light: {
      backgroundColor: colors.shades.lightest,
      textColor: colors.shades.darkest
    },
    dark: {
      backgroundColor: colors.shades.darkest,
      textColor: colors.shades.white
    },
    white: {
      backgroundColor: colors.shades.white,
      textColor: colors.shades.darkest
    }
  }
};

export default gu;
