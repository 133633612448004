import { AddSystemIcon, AchievementGraphicIcon } from '@nib/icons';
import { Inline, Margin } from '@nib/layout';
import Copy from '@nib-components/copy';
import Link from '@nib-components/link';
import Heading from '@nib-components/heading';
import { ICON_EMAIL_TEMPLATE, SVG_ICON_DOWNLOAD_LINK, PNG_ICON_DOWNLOAD_LINK } from "../../../../src/constants.js";
import IconLibrary from "../../../../src/components/icons/IconLibrary.js";
import * as React from 'react';
export default {
  AddSystemIcon,
  AchievementGraphicIcon,
  Inline,
  Margin,
  Copy,
  Link,
  Heading,
  ICON_EMAIL_TEMPLATE,
  SVG_ICON_DOWNLOAD_LINK,
  PNG_ICON_DOWNLOAD_LINK,
  IconLibrary,
  React
};