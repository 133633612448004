import React from 'react';
import {flexRender, getCoreRowModel, getSortedRowModel, useReactTable} from '@tanstack/react-table';
import {TableWrapper, Table, TableHead, TableHeadRow, TableHeading, TableBody, TableRow, TableData} from '@nib/table';
import {makeData} from './MakeData';

export const SortingTable = () => {
  let makeDataArray = makeData(10);
  const [sorting, setSorting] = React.useState([]);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'firstName',
        cell: info => info.getValue(),
        footer: props => props.column.id,
        header: () => <span>First Name</span>
      },
      {
        accessorFn: row => row.lastName,
        id: 'lastName',
        cell: info => info.getValue(),
        header: () => <span>Last Name</span>,
        footer: props => props.column.id
      },
      {
        accessorKey: 'age',
        header: () => 'Age',
        footer: props => props.column.id
      },
      {
        accessorKey: 'visits',
        header: () => <span>Visits</span>,
        footer: props => props.column.id
      },
      {
        accessorKey: 'status',
        header: 'Status',
        footer: props => props.column.id
      },
      {
        accessorKey: 'progress',
        header: 'Profile Progress',
        footer: props => props.column.id
      },
      {
        accessorKey: 'createdAt',
        header: 'Created At'
      }
    ],
    []
  );

  const [data, setData] = React.useState(makeDataArray);
  const refreshData = () => setData(makeDataArray); // eslint-disable-line no-unused-vars

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true
  });

  return (
    <TableWrapper>
      <Table equalColumns={false}>
        <TableHead>
          {table.getHeaderGroups().map(headerGroup => (
            <TableHeadRow key={headerGroup.id} fixedHeader={false} rowHeight={'regular'}>
              {headerGroup.headers.map(header => {
                return (
                  <TableHeading
                    fixedColumn={false}
                    key={header.id}
                    colSpan={header.colSpan}
                    canColumnSort={header.column.getCanSort()}
                    onClick={header.column.getToggleSortingHandler()}
                    isSorted={header.column.getIsSorted()}
                  >
                    {header.isPlaceholder ? null : <span>{flexRender(header.column.columnDef.header, header.getContext())}</span>}
                  </TableHeading>
                );
              })}
            </TableHeadRow>
          ))}
        </TableHead>
        <TableBody stripedRows={true}>
          {table
            .getRowModel()
            .rows.slice(0, 10)
            .map(row => {
              return (
                <TableRow key={row.id} rowHeight={'regular'}>
                  {row.getVisibleCells().map(cell => {
                    return (
                      <TableData fixedColumn={false} key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableData>
                    );
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};
