import { Link as GatsbyLink } from 'gatsby';
import UtilityButton from '@nib/utility-button';
import Link from '@nib-components/link';
import { ConversationGraphicIcon, HealthManagementGraphicIcon, HospitalGraphicIcon, DownloadSystemIcon } from '@nib/icons';
import { Box, Columns, Column } from '@nib/layout';
import { ColorDot } from "../../../../src/components/colors/ColorPalette.js";
import * as React from 'react';
export default {
  GatsbyLink,
  UtilityButton,
  Link,
  ConversationGraphicIcon,
  HealthManagementGraphicIcon,
  HospitalGraphicIcon,
  DownloadSystemIcon,
  Box,
  Columns,
  Column,
  ColorDot,
  React
};