import { SimpleTable, TableWrapper, Table, TableHead, TableHeadRow, TableHeading, TableBody, TableRow, TableData } from '@nib/table';
import { createColumnHelper } from '@tanstack/react-table';
import Alert from '@nib-components/alert';
import Copy from '@nib-components/copy';
import Link from '@nib-components/link';
import * as React from 'react';
export default {
  SimpleTable,
  TableWrapper,
  Table,
  TableHead,
  TableHeadRow,
  TableHeading,
  TableBody,
  TableRow,
  TableData,
  createColumnHelper,
  Alert,
  Copy,
  Link,
  React
};