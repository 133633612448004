import { AchievementGraphicIcon, AddSystemIcon, CardSystemIcon, WrongEmailGraphicIcon } from '@nib/icons';
import { Tiles, Inline, Stack, Margin } from '@nib/layout';
import Copy from '@nib-components/copy';
import { Link as GatsbyLink } from 'gatsby';
import Link from '@nib-components/link';
import Heading from '@nib-components/heading';
import ColorPalette from "../../../../src/components/colors/ColorPalette.js";
import { ICON_EMAIL_TEMPLATE, SVG_ICON_DOWNLOAD_LINK, PNG_ICON_DOWNLOAD_LINK } from "../../../../src/constants.js";
import * as React from 'react';
export default {
  AchievementGraphicIcon,
  AddSystemIcon,
  CardSystemIcon,
  WrongEmailGraphicIcon,
  Tiles,
  Inline,
  Stack,
  Margin,
  Copy,
  GatsbyLink,
  Link,
  Heading,
  ColorPalette,
  ICON_EMAIL_TEMPLATE,
  SVG_ICON_DOWNLOAD_LINK,
  PNG_ICON_DOWNLOAD_LINK,
  React
};