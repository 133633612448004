import {FileProgressEnum} from '../../../../../FileSelect';
import {UploadProgressState} from '../../../Interfaces';

export const stateWithErrorFile = (state: UploadProgressState[], file: UploadProgressState): UploadProgressState[] => {
  // Means file is not present in state, without this check would add the file to the state. We only want to update.
  if (state.filter(stateFile => stateFile.name === file?.name).length < 1) {
    return state;
  }

  return [
    {
      ...file,
      progress: FileProgressEnum.ERROR,
      uploaded: false,
      inProgress: false
    } as UploadProgressState,
    ...state.filter(stateFile => stateFile.name !== file?.name)
  ];
};
