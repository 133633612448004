import React from 'react';
import {TabPanel as ReactTabPanel} from 'react-tabs';
import styled, {css} from 'styled-components';
import {colorLight, colorWhite} from '@nib-components/theme';
import {Container, p} from '@nib/layout';
import {BORDER_WIDTH, SELECTED_TAB_PANEL_CLASS_NAME, HORIZONTAL_PADDING, TAB_HOVER_MARGIN} from './config';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TabPanelWrapper = styled(({showBorder, ...rest}) => <ReactTabPanel {...rest} />)`
  /*
  We only want to style the currently active TabPanel
  */
  &.${SELECTED_TAB_PANEL_CLASS_NAME} {
    z-index: 2;
    position: relative;
    margin-block-start: calc(-${TAB_HOVER_MARGIN});
    ${p(HORIZONTAL_PADDING)}
    background-color: var(--themeColorBgSurfaceProminent, ${colorWhite});
    overflow: auto;

    ${({showBorder}: {showBorder?: boolean}) =>
      showBorder &&
      css`
        border-top: ${BORDER_WIDTH} solid var(--themeColorBorder, ${colorLight});
        margin-top: calc(-${TAB_HOVER_MARGIN} - ${BORDER_WIDTH});
      `};
  }
`;

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const TabPanel = ({children, showBorder, withContainer, ...rest}: any) => {
  const ConditionalContainer = withContainer ? Container : 'div';
  return (
    <TabPanelWrapper showBorder={showBorder} {...rest}>
      <ConditionalContainer>{children}</ConditionalContainer>
    </TabPanelWrapper>
  );
};

TabPanel.tabsRole = 'TabPanel';

export default TabPanel;
