import {css} from 'styled-components';
import {tokens} from '@nib-group/design-tokens/build/web';
import type {CSSTokenMap, Tokens} from '@nib-group/design-tokens';
import {PropsWithTheme} from './types';

export const TOKEN_NOT_FOUND_CSS_VAR = `--token-not-found`;

type Tokens = typeof Tokens;

/**
 * Takes a dot-separated token name and an optional fallback, and returns the CSS custom property for the corresponding token.
 * This should be used to implement design decisions throughout your application.
 *
 * @param {string} path - A dot-separated token name (example: `'theme.color.bg'` or `'common.dimension.spacing.4'`).
 * @param [fallback] - The fallback value that should render when token CSS is not present in your app. Can be a string or a selector
 *
 * @example
 * ```
 * const Component = styled.div`
 *   background-color: ${token('theme.color.bg.surface', colorWhite)};
 *   padding: ${token('common.dimension.spacing.4', '1rem')};
 * `;
 * ```
 *
 */

type FallbackType = ((props: PropsWithTheme) => string) | ((props: PropsWithTheme) => number) | string | number;

function getToken<T extends keyof Tokens>(path: T, fallback?: FallbackType): CSSTokenMap[T] {
  let token: Tokens[keyof Tokens] | typeof TOKEN_NOT_FOUND_CSS_VAR = tokens[path];

  // if the token is not found - replacing it with variable name without any value, to avoid it being undefined which would result in invalid css
  if (!token) {
    token = TOKEN_NOT_FOUND_CSS_VAR;
  }

  const tokenCall = fallback ? (typeof fallback === 'function' ? css`var(${token}, ${fallback})` : `var(${token}, ${fallback})`) : `var(${token})`;
  return tokenCall as CSSTokenMap[T];
}

export default getToken;
