import React from 'react';
import styled, {css} from 'styled-components';
import {buttonTertiaryStyles, ThemeSchema} from '@nib-components/theme';
import BaseButton, {BaseButtonProps, sizeStyling, fullWidthStyling} from '../BaseButton';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TertiaryButton = styled(({theme, ...otherProps}: BaseButtonProps & {theme: ThemeSchema}) => <BaseButton data-mesh-component="BUTTON-TERTIARY" {...otherProps} />)`
  ${props => buttonTertiaryStyles(props).normal}

  ${props =>
    props.selected &&
    css`
      ${buttonTertiaryStyles(props).selected};
    `}

  ${props =>
    'disabled' in props &&
    props.disabled &&
    css`
      ${buttonTertiaryStyles(props).disabled};
    `}

  ${props =>
    props &&
    css`
      &:disabled {
        ${buttonTertiaryStyles(props).disabled};
      }
    `}

    ${sizeStyling}
    ${fullWidthStyling}
`;

TertiaryButton.displayName = 'TertiaryButton';
