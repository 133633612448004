export const FileReducerCases = {
  ADD_NEW_FILE: 'ADD NEW FILE',
  UPDATE_FILE: 'UPDATE FILE',
  REMOVE_FILE: 'REMOVE FILE',
  ERROR_FILE: 'ERROR FILE',
  ERROR_BLOCK_FILE: 'ERROR BLOCK FILE'
} as const;

export const validFileReducerCases = Object.values(FileReducerCases);

export type FileReducerTypes = (typeof FileReducerCases)[keyof typeof FileReducerCases];

export const SessionReducerCases = {
  SET_INITIALISER: 'SET INITIALISER',
  CLEAR_INITIALISER: 'CLEAR INITIALISER',
  SET_SESSION_ID: 'SET SESSION ID',
  SET_SESSION_INTERVAL: 'SET SESSION INTERVAL',
  CLEAR_SESSION_INTERVAL: 'CLEAR SESSION INTERVAL'
} as const;

export const validSessionReducerCases = Object.values(SessionReducerCases);

export type SessionReducerTypes = (typeof SessionReducerCases)[keyof typeof SessionReducerCases];
