import React from 'react';
import {Box, Margin} from '@nib/layout';
import Copy from '@nib-components/copy';
import Heading, {componentValues} from '@nib-components/heading';
import Link from '@nib-components/link';
import {COVER_TYPES, COVER_TYPES_Values} from '../constants';

export interface CoverTypeDescriptionProps {
  coveredTypeCode?: COVER_TYPES_Values;
  policyBookletLink?: string;
}

export interface CoverTypeExplanationProps {
  title: string;
  children: React.ReactNode;
  titleComponent?: componentValues;
}
export const CoverTypeExplanation: React.FC<CoverTypeExplanationProps> = ({title, titleComponent, children}) => {
  return (
    <Box background="default" padding={4} marginBottom={4}>
      <Margin bottom={2}>
        <Heading component={titleComponent} size={5}>
          {title}
        </Heading>
      </Margin>
      {children}
    </Box>
  );
};

const CoverTypeDescription: React.FC<CoverTypeDescriptionProps> = ({coveredTypeCode, policyBookletLink}) => {
  switch (coveredTypeCode) {
    case COVER_TYPES.INCLUSION:
    case COVER_TYPES.EXCLUSION:
    default:
      return null;

    case COVER_TYPES.MBP:
      return (
        <CoverTypeExplanation title="Minimum Benefits Payable (MBP)">
          <Copy size="small">
            Minimum Benefits Payable (MBP) means that we will pay the minimum amount of benefits that we are required to pay under the Private Health Insurance Act, to or on behalf of a member for
            hospital treatment under a hospital cover. If you&apos;re attending a private hospital for these services, there will be significant out-of-pocket costs. If a treatment is important to you
            and is listed as MBP, we recommend you consider a higher level of cover.
          </Copy>
        </CoverTypeExplanation>
      );

    case COVER_TYPES.RESTRICTION:
      return (
        <CoverTypeExplanation title="Restriction">
          <Copy size="small">
            For Restricted procedures, benefits paid will be equivalent to treatment in a shared ward of a public hospital. This means you will be covered in a shared ward of a public hospital, but it
            won&apos;t fully cover you for the cost of staying in a private room in public hospital or in a private hospital. If a treatment is important to you and is listed as Restricted, we
            recommend you consider a higher level of cover.
          </Copy>
        </CoverTypeExplanation>
      );

    case COVER_TYPES.R:
      return (
        <CoverTypeExplanation title="Restricted (Hospital Benefits Only)">
          <Copy size="small">
            Hospital treatment provided by a registered podiatric surgeon is limited to cover for accommodation and prosthetic devices. Accommodation benefits will be paid equivalent to those paid for
            private patients receiving treatment in a shared ward of a public hospital. No benefits are payable for podiatric surgeon fees, medical specialist fees (e.g. anaesthetist) or theatre
            costs. Refer to the{' '}
            <Link href={policyBookletLink} id="ga-inlink-policy-booklet">
              Policy Booklet
            </Link>{' '}
            for more information.
          </Copy>
        </CoverTypeExplanation>
      );

    case COVER_TYPES.INCL:
      return (
        <CoverTypeExplanation title="Included (Hospital Benefits Only)">
          <Copy size="small">
            Hospital treatment provided by a registered podiatric surgeon is limited to cover for accommodation and prosthetic devices. No benefits are payable for podiatric surgeon fees, medical
            specialist fees (e.g. anaesthetist) or theatre costs. Refer to the{' '}
            <Link href={policyBookletLink} id="ga-inlink-policy-booklet">
              Policy Booklet
            </Link>{' '}
            for more information.
          </Copy>
        </CoverTypeExplanation>
      );

    case COVER_TYPES.LB:
      return (
        <CoverTypeExplanation title="Lower Benefits">
          <Copy size="small">
            Gazetted rate (as determined by State and Territory health authorities) for hopsital accomodation; 100% of the MBS fee for inpatient medical. This may result in significant out-of-pocket
            costs for the customer. In the case of accident, current cover applies.
          </Copy>
        </CoverTypeExplanation>
      );
  }
};

CoverTypeDescription.displayName = 'CoverTypeDescription';

export default CoverTypeDescription;
