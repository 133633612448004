import React from 'react';
import styled from 'styled-components';
import {colorWhite} from '@nib-components/theme';
import {SizeMap, LogoComponent} from '../../types';

export const StyledPath = styled.path<{invert?: boolean}>`
  fill: ${props => (props.invert ? colorWhite : '#CC0000')}; /* colorBrandBase */
`;

export const StyledRect = styled.rect<{invert?: boolean}>`
  fill: ${props => (props.invert ? colorWhite : '#CC0000')}; /* colorBrandBase */
`;

// small === min-height from brand guidelines
const sizes: SizeMap = {
  small: {
    width: '58px',
    height: '20px'
  },
  header: {
    width: '92px',
    height: '32px'
  },
  medium: {
    width: '138px',
    height: '48px'
  },
  large: {
    width: '184px',
    height: '64px'
  }
};

const AamiLogo: LogoComponent = props => {
  const {invert = false, size = 'small', ...otherProps} = props;
  return (
    <svg x="0" y="0" viewBox="0 0 68.9 24" width={sizes[size].width} height={sizes[size].height} aria-label="AAMI" role="img" {...otherProps}>
      <StyledRect invert={invert} x="65.2" y="0.8" width="3.7" height="22.8" />
      <StyledPath
        invert={invert}
        d="M8.2,14.3h5.7L11,7.7L8.2,14.3z M26.7,14.3h5.7l-2.8-6.5L26.7,14.3z M50.8,24L43.6,9.7l-3,14h-0.1l0,0h-0.9
        h-2.7h-0.4l-0.1-0.3l-2.5-5.7h-8.7l-2.5,5.7l-0.2,0.3h-0.4h-0.3H21h-1.6h-0.9h-0.3h-0.4l-0.2-0.3l-2.5-5.7H6.7l-2.5,5.7L4,23.7H3.6
        H0.9H0l0.4-0.8L10.6,1.2L11.1,0h0l0.5,1.2L20.3,20l8.9-18.9L29.7,0h0l0.5,1.2l8.1,17.6l4.3-18.7l8.1,16.7l8.1-16.7l5.6,23.5H61
        l-3-14.2L50.8,24z"
      />
    </svg>
  );
};

AamiLogo.displayName = 'AamiLogo';

export default AamiLogo;
