import {useEffect} from 'react';
import {FileProgressEnum} from '../../../FileSelect';

import {ParentFileStateProps, ParentUploadReadyState, FileOnParent, UploadProgressState, FileUploadStore} from '../../StateManagement';

interface FilesOnParentParams extends ParentFileStateProps, ParentUploadReadyState {
  fileUploadState: FileUploadStore;
}

const fileUploadStateToFileOnParent = (files: UploadProgressState[]): FileOnParent[] => {
  return files.map(file => {
    return {
      name: file.name,
      progress: file.progress,
      file: file.file,
      fileId: file.fileId,
      backgroundUploader: file.backgroundUploader,
      errorMessage: file.errorMessage
    };
  });
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const arrayEquals = (a: any, b: any) => {
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => JSON.stringify(val) === JSON.stringify(b[index]));
};

export const useSetFilesOnParent = ({setFilesOnParent, filesOnParent, setFilesReadyForUpload, fileUploadState}: FilesOnParentParams) => {
  const {files, session} = fileUploadState;

  useEffect(() => {
    const uploadedFiles: FileOnParent[] = fileUploadStateToFileOnParent(files);

    if (!setFilesOnParent) {
      return;
    }

    if (!arrayEquals(uploadedFiles, filesOnParent)) {
      setFilesOnParent({
        filesOnParent: uploadedFiles,
        sessionId: session.sessionId,
        sessionInitializer: session.initializer
      });
    }
  }, [filesOnParent, setFilesOnParent, files]);

  useEffect(() => {
    const uploadedFiles: FileOnParent[] = fileUploadStateToFileOnParent(files);

    if (uploadedFiles.length < 1 || !setFilesOnParent) {
      return;
    }

    setFilesOnParent({
      filesOnParent: uploadedFiles,
      sessionId: session.sessionId,
      sessionInitializer: session.initializer
    });
  }, [session.initializer, session.sessionId]);

  useEffect(() => {
    const uploadedFiles: FileOnParent[] = fileUploadStateToFileOnParent(files);

    if (!setFilesReadyForUpload || !uploadedFiles || !Array.isArray(uploadedFiles)) {
      return;
    }

    const filesReady: boolean = uploadedFiles.length > 0 && uploadedFiles.filter((file: FileOnParent) => file.progress !== FileProgressEnum.UPLOADED).length === 0;

    setFilesReadyForUpload(filesReady);
  }, [filesOnParent, setFilesOnParent, setFilesReadyForUpload, files]);
};
