import {css} from 'styled-components';
import {borderRadiusStandard, borderRadiusSmall, borderRadiusFull, BorderRadiusType, borderRadiusNone, validBorderRadiusValues} from '@nib-components/theme';
import {map} from '../../breakpoint';
import {ResponsiveOrStaticProp, Nullable} from './../../utils';

export type borderRadiusValues = (typeof validBorderRadiusValues)[number];

const mapStringToSelector = (string: borderRadiusValues) => {
  switch (string) {
    case 'none':
      return borderRadiusNone;
    case 'small':
      return borderRadiusSmall;
    case 'standard':
      return borderRadiusStandard || '4px';
    case 'full':
      return borderRadiusFull;
  }
};

export const borderRadius = () => css`
  ${({borderRadius}: {borderRadius?: ResponsiveOrStaticProp<Nullable<BorderRadiusType>>}) =>
    map(
      borderRadius,
      (val: borderRadiusValues) =>
        val !== undefined &&
        css`
          border-radius: ${mapStringToSelector(val)};
        `
    )};
`;
