import React from 'react';
import styled, {css} from 'styled-components';
import {colorWhite, colorExtras, colorCombined, colorDarkest} from '@nib-components/theme';
import {PRODUCT_TYPES, PRODUCT_TYPES_Values, TIER_SLUGS_Values} from '../constants';

export interface ProductCardWrapperProps {
  type: PRODUCT_TYPES_Values;
  tier?: TIER_SLUGS_Values;
  children?: React.ReactNode;
  [key: string]: unknown;
}

// Fallbacks copy-pasted here to drop dependency on old @nib-components/colors package
export const hospitalTiers = {
  basic: '#144a38',
  bronze: '#cd7f32',
  silver: '#83aebe',
  gold: '#c5b358'
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ProductCardEl = styled(({type, tier, ...rest}) => <div {...rest} />)`
  position: relative;
  background-color: var(--themeColorBgSurface, ${colorWhite});
  color: var(--themeColorFg, ${colorDarkest});
  border-radius: 0 0 8px 8px;

  /* This border-top needs to reflect the tier for hospital, or the purple for extras. What about combined...? */
  ${({type, tier}) => {
    if (type === PRODUCT_TYPES.EXTRAS) {
      return css`
        border-top: 5px solid var(--themeColorBorderProductExtras, ${colorExtras});
      `;
    }
    if (type === PRODUCT_TYPES.COMBINED) {
      return css`
        border-top: 5px solid var(--themeColorBorderProductCombined, ${colorCombined});
      `;
    }

    if (type === PRODUCT_TYPES.HOSPITAL) {
      if (tier === 'gold') {
        return css`
          border-top: 5px solid var(--themeColorBorderProductGold, ${hospitalTiers.gold});
        `;
      }
      if (tier && tier.includes('silver')) {
        return css`
          border-top: 5px solid var(--themeColorBorderProductSilver, ${hospitalTiers.silver});
        `;
      }
      if (tier && tier.includes('bronze')) {
        return css`
          border-top: 5px solid var(--themeColorBorderProductBronze, ${hospitalTiers.bronze});
        `;
      }
      if (tier && tier.includes('basic')) {
        return css`
          border-top: 5px solid var(--themeColorBorderProductBasic, ${hospitalTiers.basic});
        `;
      }
    }
  }}
`;

const ProductCardWrapper: React.FC<ProductCardWrapperProps> = ({children, ...otherProps}) => {
  return (
    <ProductCardEl {...otherProps} data-mesh-component="PRODUCT-CARD">
      {children}
    </ProductCardEl>
  );
};

ProductCardWrapper.displayName = 'ProductCardWrapper';

export default ProductCardWrapper;
