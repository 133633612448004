import styled from 'styled-components';
import {type Theme, type Values, _p, _px, _py, _pt, _pr, _pb, _pl, _pbs, _pie, _pbe, _pis, _pbl, _pi} from './core';
import {type SpacingProps} from './types';
export function p(values: Values) {
  return function ({theme}: {theme: Theme}) {
    return _p(values, theme);
  };
}

export function px(values: Values) {
  return function ({theme}: {theme: Theme}) {
    return _px(values, theme);
  };
}

export function py(values: Values) {
  return function ({theme}: {theme: Theme}) {
    return _py(values, theme);
  };
}

export function pt(values: Values) {
  return function ({theme}: {theme: Theme}) {
    return _pt(values, theme);
  };
}

export function pr(values: Values) {
  return function ({theme}: {theme: Theme}) {
    return _pr(values, theme);
  };
}

export function pb(values: Values) {
  return function ({theme}: {theme: Theme}) {
    return _pb(values, theme);
  };
}

export function pl(values: Values) {
  return function ({theme}: {theme: Theme}) {
    return _pl(values, theme);
  };
}

export function pbs(values: Values) {
  return function ({theme}: {theme: Theme}) {
    return _pbs(values, theme);
  };
}

export function pie(values: Values) {
  return function ({theme}: {theme: Theme}) {
    return _pie(values, theme);
  };
}

export function pbe(values: Values) {
  return function ({theme}: {theme: Theme}) {
    return _pbe(values, theme);
  };
}

export function pis(values: Values) {
  return function ({theme}: {theme: Theme}) {
    return _pis(values, theme);
  };
}

// We already got a mixin `pb` which stands for padding-bottom. So we are using `pbl` which stands for logical padding-block.
export function pbl(values: Values) {
  return function ({theme}: {theme: Theme}) {
    return _pbl(values, theme);
  };
}

export function pi(values: Values) {
  return function ({theme}: {theme: Theme}) {
    return _pi(values, theme);
  };
}

export const Padding = styled.div.attrs({
  'data-mesh-component': 'PADDING'
})<SpacingProps>`
  ${({inline}) => inline && 'display: inline-block;'}
  ${({all, theme}) => all && _p(all, theme)}
  ${({horizontal, theme}) => horizontal && _px(horizontal, theme)} 
  ${({vertical, theme}) => vertical && _py(vertical, theme)} 
  ${({top, theme}) => top && _pt(top, theme)} 
  ${({right, theme}) => right && _pr(right, theme)} 
  ${({bottom, theme}) => bottom && _pb(bottom, theme)} 
  ${({left, theme}) => left && _pl(left, theme)}

  ${({blockStart, theme}) => blockStart && _pbs(blockStart, theme)} 
  ${({inlineEnd, theme}) => inlineEnd && _pie(inlineEnd, theme)} 
  ${({blockEnd, theme}) => blockEnd && _pbe(blockEnd, theme)} 
  ${({inlineStart, theme}) => inlineStart && _pis(inlineStart, theme)}

  ${({block, theme}) => block && _pbl(block, theme)} 
  ${({inline, theme}) => inline && _pi(inline, theme)}
`;

Padding.displayName = 'Padding';

export default Padding;
