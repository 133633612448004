import {type CSSProperties} from 'react';
import {css} from 'styled-components';
import {map} from '../../breakpoint';
import {ResponsiveOrStaticProp, Nullable} from './../../utils';

export type displayValues = Exclude<CSSProperties['display'], undefined>;

export type DisplayType = ResponsiveOrStaticProp<Nullable<displayValues>>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/explicit-module-boundary-types
export const display = () => css`
  ${({display}: {display?: DisplayType}) =>
    map(
      display,
      (val: CSSProperties['display']) =>
        val &&
        css`
          display: ${val};
        `
    )};
`;
