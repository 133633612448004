import React from 'react';
import {flexRender, getCoreRowModel, useReactTable, ColumnDef} from '@tanstack/react-table';
import {rowHeightValues, captionSideValues} from '../utils';
import {TableWrapper, TableCaption, Table, TableHead, TableHeadRow, TableHeading, TableBody, TableRow, TableData} from '../lib';

type ColumnHeader = {
  column1: string;
  column2: string;
  column3: string;
  column4: string;
  column5: string;
  column6: string;
};

export interface SimpleTableProps {
  columns: ColumnDef<ColumnHeader, string>[];
  data: ColumnHeader[];
  caption?: string;
  captionSide?: captionSideValues;
  rowHeight?: rowHeightValues;
  height?: string;
  maxHeight?: string;
  rowHover?: boolean;
  stripedRows?: boolean;
  equalColumns?: boolean;
  fixedHeader?: boolean;
  fixedColumn?: boolean;
  [key: string]: unknown;
}

export const SimpleTable: React.FC<SimpleTableProps> = ({
  columns,
  data,
  caption,
  captionSide = 'top',
  stripedRows = false,
  rowHover = false,
  height,
  maxHeight,
  equalColumns = false,
  rowHeight = 'regular',
  fixedHeader = false,
  fixedColumn = false,
  ...otherProps
}) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <TableWrapper height={height} maxHeight={maxHeight}>
      <Table {...otherProps} equalColumns={equalColumns} data-mesh-component="TABLE">
        {caption && <TableCaption captionSide={captionSide}>{caption}</TableCaption>}
        <TableHead>
          {table.getHeaderGroups().map((headerGroup, index) => (
            <TableHeadRow key={`header-group-${index}`} fixedHeader={fixedHeader}>
              {headerGroup.headers.map(header => (
                <TableHeading key={header.id} fixedColumn={fixedColumn}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHeading>
              ))}
            </TableHeadRow>
          ))}
        </TableHead>

        <TableBody stripedRows={stripedRows} rowHover={rowHover}>
          {table.getRowModel().rows.map(row => (
            <TableRow key={row.id} rowHeight={rowHeight}>
              {row.getVisibleCells().map(cell => (
                <TableData key={cell.id} fixedColumn={fixedColumn}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableData>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

SimpleTable.displayName = 'SimpleTable';
