import React from 'react';
import Label from '../Label';

export interface LegendProps {
  [key: string]: unknown;
}

const Legend: React.FC<LegendProps> = props => <Label as="legend" data-mesh-component="FORM-CONTROL--LEGEND" {...props} />;

export default Legend;
