import { Box, Stack } from '@nib/layout';
import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';
import { PrimaryButton } from '@nib-components/button';
import * as React from 'react';
export default {
  Box,
  Stack,
  Copy,
  Heading,
  PrimaryButton,
  React
};