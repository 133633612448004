import React, {Children} from 'react';
import flattenChildren from 'react-keyed-flatten-children';
import styled, {css} from 'styled-components';
import {SpacingType} from '@nib-components/theme';
import {map} from '../breakpoint';
import {p, m, mt} from '../spacing';
import {isList, isValidSpacing, ResponsiveSpaceProp} from '../utils';

export interface StackProps {
  space?: ResponsiveSpaceProp;
  as?: any; // eslint-disable-line  @typescript-eslint/no-explicit-any
  children: React.ReactNode;
  [key: string]: unknown;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StackWrapper = styled(({space, as, ...rest}) => <div {...rest} />).attrs({
  'data-mesh-component': 'STACK'
})`
  /* If stack is set to be a ul or ol we need to reset the default styling */

  ${({as}) =>
    as &&
    isList(as) &&
    css`
      ${m(0)};
      ${p(0)};
      list-style-type: none;
    `}

  > * {
    &:not(:first-child) {
      ${({space}: {space: ResponsiveSpaceProp}) =>
        map(
          space,
          (val: SpacingType) =>
            isValidSpacing(val) &&
            css`
              ${mt(val)};
            `
        )};
    }
  }
`;

export const Stack: React.FC<StackProps> = props => {
  const {children, space = 0, ...otherProps} = props;
  const ChildComponent = isList(props.as) ? 'li' : 'div';
  return (
    <StackWrapper space={space} {...otherProps}>
      {Children.map(flattenChildren(children), child => {
        if (React.isValidElement(child)) {
          return <ChildComponent>{child}</ChildComponent>;
        }
        return null;
      })}
    </StackWrapper>
  );
};

Stack.displayName = 'Stack';
