import Form from '@nib-components/form';
import FormControl from '@nib-components/form-control';
import RadioGroup from '@nib-components/radio-group';
import Textarea from '@nib-components/textarea';
import Textbox from '@nib-components/textbox';
import Checkbox from '@nib-components/checkbox';
import * as React from 'react';
export default {
  Form,
  FormControl,
  RadioGroup,
  Textarea,
  Textbox,
  Checkbox,
  React
};