import { Box, Stack, Inline } from '@nib/layout';
import Heading from '@nib-components/heading';
import { AchievementGraphicIcon } from '@nib/icons';
import * as React from 'react';
export default {
  Box,
  Stack,
  Inline,
  Heading,
  AchievementGraphicIcon,
  React
};