import Logo from '@nib-components/logo';
import Alert from '@nib-components/alert';
import { Link as GatsbyLink } from 'gatsby';
import Link from '@nib-components/link';
import * as React from 'react';
export default {
  Logo,
  Alert,
  GatsbyLink,
  Link,
  React
};