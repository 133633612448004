import Theme, { nib, gu, iman } from '@nib-components/theme';
import { PrimaryButton, SecondaryButton } from '@nib-components/button';
import ColorShowcase from "../../../../src/components/theme/ColorShowcase.js";
import { Link as GatsbyLink } from 'gatsby';
import Link from '@nib-components/link';
import * as React from 'react';
export default {
  Theme,
  nib,
  gu,
  iman,
  PrimaryButton,
  SecondaryButton,
  ColorShowcase,
  GatsbyLink,
  Link,
  React
};