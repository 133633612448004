import React, {useId} from 'react';
import styled from 'styled-components';
import {colorDarkest, colorWhite} from '@nib-components/theme';
import {SizeMap, LogoComponent} from '../../types';

export const DividingLine = styled.line<{invert?: boolean}>`
  fill: none;
  stroke: ${props => (props.invert ? colorWhite : colorDarkest)};
  stroke-miterlimit: 10;
  stroke-width: 0.5px;
`;

export const WordMarkPath = styled.path<{invert?: boolean}>`
  fill: ${props => (props.invert ? colorWhite : colorDarkest)};
`;

// small === min-height from brand guidelines
const sizes: SizeMap = {
  small: {
    width: '117px',
    height: '24px'
  },
  header: {
    width: '175px',
    height: '36px'
  },
  medium: {
    width: '233px',
    height: '48px'
  },
  large: {
    width: '310px',
    height: '64px'
  }
};

const QantasLogo: LogoComponent = props => {
  const {invert = false, size = 'small', ...otherProps} = props;
  const idBase = useId();
  // Because this logo uses gradients, ids are unavoidable.
  const uniqueId1 = `${idBase}-gradient-1`;
  const uniqueId2 = `${idBase}-gradient-2`;
  const uniqueId3 = `${idBase}-gradient-3`;
  const uniqueId4 = `${idBase}-gradient-4`;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 165.1 34.1" role="img" width={sizes[size].width} height={sizes[size].height} {...otherProps}>
      <linearGradient id={uniqueId1} gradientUnits="userSpaceOnUse" x1="30.0285" y1="0.4788" x2="6.9785" y2="34.1388" gradientTransform="matrix(1 0 0 -1 0 34.2396)">
        <stop offset="0" stopColor="#BE0000" />
        <stop offset="0.39" stopColor="#DE0000" />
        <stop offset="0.77" stopColor="#F60000" />
        <stop offset="1" stopColor="#FF0000" />
      </linearGradient>

      <linearGradient id={uniqueId2} gradientUnits="userSpaceOnUse" x1="23.6592" y1="15.6799" x2="23.9693" y2="19.2313" gradientTransform="matrix(1 0 0 -1 0 34.2396)">
        <stop offset="0.65" stopColor="#FFFFFF" stopOpacity="0" />
        <stop offset="0.69" stopColor="#EDEDED" stopOpacity="0.23" />
        <stop offset="0.76" stopColor="#D7D7D7" stopOpacity="0.5" />
        <stop offset="0.81" stopColor="#C6C6C6" stopOpacity="0.72" />
        <stop offset="0.87" stopColor="#B9B9B9" stopOpacity="0.87" />
        <stop offset="0.91" stopColor="#B2B2B2" stopOpacity="0.97" />
        <stop offset="0.95" stopColor="#AFAFAF" />
      </linearGradient>

      <linearGradient id={uniqueId3} gradientUnits="userSpaceOnUse" x1="19.1" y1="6.3084" x2="34.04" y2="6.3084" gradientTransform="matrix(1 0 0 -1 0 34.2396)">
        <stop offset="0" stopColor="#FFFFFF" stopOpacity="0" />
        <stop offset="7.000000e-02" stopColor="#F3F3F3" stopOpacity="0.15" />
        <stop offset="0.21" stopColor="#DEDEDE" stopOpacity="0.41" />
        <stop offset="0.35" stopColor="#CDCDCD" stopOpacity="0.62" />
        <stop offset="0.49" stopColor="#C0C0C0" stopOpacity="0.79" />
        <stop offset="0.63" stopColor="#B7B7B7" stopOpacity="0.91" />
        <stop offset="0.77" stopColor="#B1B1B1" stopOpacity="0.98" />
        <stop offset="0.9" stopColor="#AFAFAF" />
      </linearGradient>

      <linearGradient id={uniqueId4} gradientUnits="userSpaceOnUse" x1="0.43" y1="21.8198" x2="17.14" y2="21.8198" gradientTransform="matrix(1 0 0 -1 0 34.2396)">
        <stop offset="0.25" stopColor="#FFFFFF" stopOpacity="0" />
        <stop offset="0.36" stopColor="#F7F7F7" stopOpacity="0.11" />
        <stop offset="0.58" stopColor="#E1E1E1" stopOpacity="0.38" />
        <stop offset="0.88" stopColor="#BEBEBE" stopOpacity="0.82" />
        <stop offset="1" stopColor="#AFAFAF" />
      </linearGradient>

      <WordMarkPath
        invert={invert}
        d="M80.3,9c0.1,1.4-0.6,2.8-1.8,3.6l1.8,2.8h-1.6L77.1,13c-0.2,0-0.4,0.1-0.6,0.1c-2.2,0-3.7-1.6-3.7-4.1V4.1
          c0-2.5,1.5-4.1,3.7-4.1s3.8,1.6,3.8,4V9z M74.1,9c-0.1,1.3,0.8,2.5,2.1,2.7c1.3,0.1,2.5-0.8,2.7-2.1c0-0.2,0-0.4,0-0.5V4
          c0.1-1.3-0.8-2.5-2.1-2.7c-1.3-0.1-2.5,0.8-2.7,2.1c0,0.2,0,0.4,0,0.5V9z"
      />
      <WordMarkPath invert={invert} d="M83.4,12.9L87,0.2h1.3L92,12.9h-1.4l-0.8-2.9h-4.3l-0.8,2.9H83.4z M85.9,8.7h3.7l-1.8-6.5L85.9,8.7z" />
      <WordMarkPath invert={invert} d="M102.9,12.9h-1.1l-4.9-9.7v9.8h-1.2V0.2h1.2l4.8,9.6V0.2h1.2V12.9z" />
      <WordMarkPath invert={invert} d="M106.4,0.2h7.3v1.3h-3v11.3h-1.3V1.5h-3L106.4,0.2z" />
      <WordMarkPath invert={invert} d="M115.3,12.9L119,0.2h1.3l3.7,12.7h-1.4l-0.8-2.9h-4.3l-0.8,2.9H115.3z M117.8,8.7h3.7l-1.9-6.5L117.8,8.7z" />
      <WordMarkPath
        invert={invert}
        d="M132.7,3.8V3.5c0.1-1.2-0.8-2.2-2-2.3c-0.1,0-0.2,0-0.2,0c-1.4,0-2.2,0.8-2.2,1.9c0,0.8,0.5,1.6,1.3,1.9
          l1.9,0.9c1.3,0.6,2.8,1.3,2.8,3.5c0.1,1.9-1.4,3.6-3.3,3.7c-0.1,0-0.3,0-0.4,0c-1.9,0.2-3.5-1.2-3.7-3.1c0-0.1,0-0.3,0-0.4V9.2h1.3
          v0.4c0,1.2,0.9,2.2,2.1,2.3c0.1,0,0.2,0,0.3,0c1.2,0.1,2.3-0.8,2.4-2c0-0.1,0-0.2,0-0.3c0-1.4-0.9-1.9-1.9-2.4l-1.6-0.7
          C128,6,127,4.7,127,3.3c0-1.8,1.4-3.2,3.1-3.3c0.1,0,0.2,0,0.3,0c1.8-0.1,3.4,1.3,3.5,3.1c0,0.2,0,0.3,0,0.5v0.2H132.7z"
      />
      <WordMarkPath invert={invert} d="M74.4,33.9h-1.3V21.2h1.3V33.9z" />
      <WordMarkPath invert={invert} d="M86.3,33.9h-1.1l-4.9-9.7v9.8H79V21.2h1.2l4.8,9.6v-9.6h1.2L86.3,33.9z" />
      <WordMarkPath
        invert={invert}
        d="M95.9,24.8v-0.3c0.1-1.2-0.8-2.2-2-2.3c-0.1,0-0.2,0-0.2,0c-1.4,0-2.2,0.8-2.2,1.9c0,0.8,0.5,1.6,1.3,1.8
          l1.9,0.8c1.3,0.6,2.8,1.3,2.8,3.5c0.1,1.9-1.4,3.6-3.3,3.7c-0.1,0-0.3,0-0.4,0c-1.9,0.2-3.5-1.2-3.7-3.1c0-0.1,0-0.3,0-0.4v-0.3h1.3
          v0.4c0,1.2,0.9,2.2,2.1,2.3c0.1,0,0.2,0,0.3,0c1.2,0.1,2.3-0.8,2.4-2c0-0.1,0-0.2,0-0.3c0-1.4-0.9-1.9-1.9-2.4l-1.6-0.7
          c-1.4-0.4-2.3-1.7-2.3-3.2c0-1.8,1.4-3.2,3.1-3.3c0.1,0,0.2,0,0.3,0c1.8-0.1,3.4,1.3,3.5,3.1c0,0.1,0,0.3,0,0.4v0.2L95.9,24.8z"
      />
      <WordMarkPath
        invert={invert}
        d="M108.6,21.2V30c0,2.5-1.5,4-3.7,4s-3.7-1.6-3.7-4v-8.8h1.3V30c0,1.5,0.8,2.7,2.3,2.7c1.3,0,2.4-1.1,2.4-2.4
          c0-0.1,0-0.2,0-0.3v-8.8H108.6z"
      />
      <WordMarkPath
        invert={invert}
        d="M119,33.9l-2-5c-0.2,0-0.3,0-0.5,0h-2.1v5h-1.3V21.2h3.5c2.1,0,3.8,1.8,3.8,3.9c0,1.4-0.8,2.8-2.1,3.4l2.1,5.4
          H119z M114.4,22.5v5.1h2.1c1.3,0.1,2.5-0.9,2.6-2.3c0-0.1,0-0.2,0-0.3c0.1-1.3-0.9-2.4-2.2-2.5c-0.1,0-0.2,0-0.3,0L114.4,22.5z"
      />
      <WordMarkPath invert={invert} d="M123.5,33.9l3.6-12.7h1.3l3.7,12.7h-1.4l-0.8-2.9h-4.3l-0.8,2.9H123.5z M126,29.7h3.6l-1.9-6.5L126,29.7z" />
      <WordMarkPath invert={invert} d="M142.7,33.9h-1.1l-4.9-9.7v9.8h-1.2V21.2h1.2l4.9,9.6v-9.6h1.2V33.9z" />
      <WordMarkPath
        invert={invert}
        d="M154.4,29.8v0.4c0.2,1.9-1.3,3.6-3.2,3.8c-0.2,0-0.3,0-0.5,0c-2.2,0-3.7-1.6-3.7-4v-5c0-2.5,1.5-4,3.7-4
          c1.9-0.1,3.6,1.4,3.7,3.3c0,0.2,0,0.3,0,0.5v0.2h-1.3v-0.3c0.1-1.3-0.8-2.4-2.1-2.5c-0.1,0-0.2,0-0.3,0c-1.3,0-2.4,1.1-2.4,2.4
          c0,0.1,0,0.2,0,0.3v5c-0.2,1.3,0.8,2.5,2.1,2.7c0.1,0,0.2,0,0.3,0c1.3,0.1,2.3-0.9,2.4-2.1c0-0.1,0-0.2,0-0.3v-0.5L154.4,29.8z"
      />
      <WordMarkPath invert={invert} d="M159.8,28v4.5h5.3v1.3h-6.6V21.2h6.5v1.3h-5.2v4.2h4.8V28L159.8,28z" />
      <DividingLine invert={invert} x1="60.3" y1="0" x2="60.3" y2="34.1" />
      <path fill={`url(#${uniqueId1})`} d="M12.8,33.9h34.5L19.7,6.1C16,2.4,11,0.3,5.8,0.2H0l0.4,1.1l1.9,5L12.8,33.9z" />
      <path
        fill="#ffffff"
        d="M2.4,6.4c3.8,6.5,13.7,7.1,14.8,17.2c0,0.4,0.4,0.8,0.8,0.8c5.9,0.9,11.4,3.4,16.1,7.1l0,0c0,0,0.1,0,0.1,0
          c0,0,0.1,0,0.1,0c0.1-0.1,0.1-0.2,0-0.2c-3.4-3.6-7.5-6.4-12-8.2l-1.4-0.6c-0.7-0.3-1.2-1-1.2-1.8c0.1-3,7.2-2.4,7.9-3.8l0.1-0.2
          c-1.5-1.3-3.2-2.2-5.1-2.7c0,0.4,0.1,0.8,0.3,1.1c0.4,0.7-0.5,1.8-1.8,0.6L21,15.4C11.4,6.6,4.2,11.2,0.4,1.3L2.4,6.4z"
      />
      <path fill={`url(#${uniqueId2})`} d="M25.4,16.4c-1,0-2-0.6-2.5-1.5c0.2,0.4,0.1,0.8-0.3,1C22.5,16,22.3,16,22.2,16v2.4c1-0.3,2.2-0.5,3.2-0.7V16.4z" />
      <path fill={`url(#${uniqueId3})`} d="M19.1,24.6c5.5,1.1,10.6,3.4,14.9,6.9C24,22.9,19.4,24.4,19.1,24.6z" />
      <path fill={`url(#${uniqueId4})`} d="M4.4,6.8L4.4,6.8C2.5,5.4,1.2,3.5,0.4,1.3l1.9,5c3.8,6.5,13.7,7.1,14.8,17.2C16,10.7,10.4,12.6,4.4,6.8z" />
    </svg>
  );
};

QantasLogo.displayName = 'QantasLogo';

export default QantasLogo;
