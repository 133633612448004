import React, {FC} from 'react';

export const validFullStoryLevels = ['exclude', 'mask', 'unmask'] as const;
export type FullStoryLevel = (typeof validFullStoryLevels)[number];

export interface FullStoryMaskProps extends React.HTMLAttributes<HTMLDivElement | HTMLSpanElement> {
  level?: FullStoryLevel;
  inline?: boolean;
}

const FullStoryMask: FC<FullStoryMaskProps> = ({className, level = 'mask', inline = false, children, ...otherProps}) => {
  const classes = [`fs-${level}`];

  if (className) {
    classes.push(className);
  }

  if (inline) {
    return (
      <span className={classes.join(' ')} {...otherProps} data-mesh-component="FORM-CONTROL--FULL-STORY-MASK">
        {children}
      </span>
    );
  }

  return (
    <div className={classes.join(' ')} {...otherProps}>
      {children}
    </div>
  );
};

export default FullStoryMask;
