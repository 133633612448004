import {FileActions} from './Actions';
import {isSessionAction, SessionActions} from './Actions/Session';
import {FileUploadStore} from './Interfaces';
import {filesReducer, sessionReducer} from './Reducers';

export type Rehydrate = {
  type: 'REHYDRATE';
  payload: Pick<FileUploadStore, 'files' | 'session'>;
};

export type AllActions = SessionActions | FileActions | Rehydrate;

export const GlobalReducer: React.Reducer<FileUploadStore, AllActions> = (state: FileUploadStore, action: AllActions): FileUploadStore => {
  if (action.type === 'REHYDRATE') {
    return {
      ...state,
      ...action.payload
    };
  }

  let files = state.files;
  let session = state.session;
  if (isSessionAction(action)) {
    session = sessionReducer(state.session, action);
  } else {
    files = filesReducer(state.files, action);
  }

  return {
    files,
    gatewayEndpoint: state.gatewayEndpoint,
    session
  };
};
