import React from 'react';
import styled from 'styled-components';
import {Padding, Margin, m, ml} from '@nib/layout';
import {Tabs, Tab, TabList, TabPanel} from '@nib-components/tabs';
import Theme, {
  nib,
  gu,
  iman,
  colorBrandBase,
  colorBrandLight,
  colorBrandDark,
  colorAccent,
  colorBlack,
  colorDarkest,
  colorDarker,
  colorDark,
  colorLight,
  colorLighter,
  colorLightest,
  colorWhite
} from '@nib-components/theme';
import Heading from '@nib-components/heading';

const TileGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Tile = styled.div`
  height: 5rem;
  width: 5rem;
  ${m(2)};
  ${ml(0)};
  background-color: ${props => props.bg};
`;

const ColorTitle = styled.p`
  text-align: center;
  font-size: 0.875rem;
`;

const ColorDisplay = () => (
  <>
    <TileGroup>
      <div>
        <Tile bg={colorBrandBase} />
        <ColorTitle>Brand Base</ColorTitle>
      </div>
      <div>
        <Tile bg={colorBrandLight} />
        <ColorTitle>Brand Light</ColorTitle>
      </div>
      <div>
        <Tile bg={colorBrandDark} />
        <ColorTitle>Brand Dark</ColorTitle>
      </div>
      <div>
        <Tile bg={colorAccent} />
        <ColorTitle>Accent</ColorTitle>
      </div>
    </TileGroup>
    <TileGroup>
      <div>
        <Tile bg={colorBlack} />
        <ColorTitle>Black</ColorTitle>
      </div>
      <div>
        <Tile bg={colorDarkest} />
        <ColorTitle>Darkest</ColorTitle>
      </div>
      <div>
        <Tile bg={colorDarker} />
        <ColorTitle>Darker</ColorTitle>
      </div>
      <div>
        <Tile bg={colorDark} />
        <ColorTitle>Dark</ColorTitle>
      </div>
      <div>
        <Tile bg={colorLight} />
        <ColorTitle>Light</ColorTitle>
      </div>
      <div>
        <Tile bg={colorLighter} />
        <ColorTitle>Lighter</ColorTitle>
      </div>
      <div>
        <Tile bg={colorLightest} />
        <ColorTitle>Lightest</ColorTitle>
      </div>
      <div>
        <Tile bg={colorWhite} />
        <ColorTitle>White</ColorTitle>
      </div>
    </TileGroup>
  </>
);

const ColorShowcase = () => {
  return (
    <Tabs defaultIndex={0}>
      <TabList>
        <Tab>nib</Tab>
        <Tab>GU</Tab>
        <Tab>IMAN</Tab>
      </TabList>

      <TabPanel>
        <Padding vertical={{xs: 4, md: 6}}>
          <Margin bottom={3}>
            <Heading size={3}>nib colors</Heading>
          </Margin>
          <Theme theme={nib} mode={{form: 'white'}}>
            <ColorDisplay />
          </Theme>
        </Padding>
      </TabPanel>
      <TabPanel>
        <Padding vertical={{xs: 4, md: 4}}>
          <Margin bottom={3}>
            <Heading size={3}>GU colors</Heading>
          </Margin>
          <Theme theme={gu} mode={{form: 'white'}}>
            <ColorDisplay />
          </Theme>
        </Padding>
      </TabPanel>
      <TabPanel>
        <Padding vertical={{xs: 4, md: 4}}>
          <Margin bottom={3}>
            <Heading size={3}>IMAN colors</Heading>
          </Margin>
          <Theme theme={iman} mode={{form: 'white'}}>
            <ColorDisplay />
          </Theme>
        </Padding>
      </TabPanel>
    </Tabs>
  );
};

ColorShowcase.displayName = 'ColorShowcase';

export default ColorShowcase;
