import React from 'react';
import {Tabs} from 'react-tabs';
import type {TabsProps as ReactTabsProps} from 'react-tabs';
import styled from 'styled-components';
import {Hidden} from '@nib/layout';
import Accordion from '@nib-components/accordion';
import {BreakpointType} from '@nib-components/theme';

export interface TabsProps extends Omit<ReactTabsProps, 'children'> {
  collapseBelow?: BreakpointType;
  collapsed?: boolean;
  multiExpanded?: boolean;
  showBorder?: boolean;
  withContainer?: boolean;
  children: [React.ReactElement, ...React.ReactElement[]];
  [key: string]: unknown;
}

// TODO: how can we pass props through to the accordion nicely?
// * Accordion is easy enough, accordionProps 1:1
// * But AccordionItems can have independent props (icon, )

export const StyledTabs = styled((props: TabsProps) => <Tabs {...props} data-mesh-component="TABS" />)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100vh;
  max-height: 100dvh;
  margin-top: -0.5rem;
`;

const TabsWrapper = (props: TabsProps) => {
  // First child should be the TabList, rest are TabPanels
  // TODO: what if the children order is not as expected?
  // * child.type.name (minimised in prod) so can't be used...
  const {collapseBelow, showBorder = true, withContainer, multiExpanded, collapsed, children, ...otherProps}: TabsProps = props;
  const [tabList, ...tabPanels] = children;
  return (
    <>
      <Hidden below={collapseBelow}>
        {/*
        // @ts-expect-error: children types are not defined inside react-tabs */}
        <StyledTabs {...otherProps}>{React.Children.map(children, child => React.cloneElement(child, {showBorder, withContainer}))}</StyledTabs>
      </Hidden>

      {collapseBelow && (
        <Hidden above={collapseBelow}>
          <Accordion multiExpanded={multiExpanded} expandedIndex={!collapsed && 0}>
            {React.Children.map(tabPanels, (tabPanel, index) => {
              const tab = tabList.props.children[index];
              return (
                <Accordion.Item title={tab.props.children} subtitle={tab.props.subtitle}>
                  {tabPanel.props.children}
                </Accordion.Item>
              );
            })}
          </Accordion>
        </Hidden>
      )}
    </>
  );
};

TabsWrapper.tabsRole = 'Tabs';

export default TabsWrapper;
