import {useEffect} from 'react';
import {fileUploadDispatchType} from '../../StateManagement';

export type RehydrateFromParentStateOptions = {
  fileUploadDispatch: fileUploadDispatchType;
  sessionInitializer?: Promise<string>;
};

// FIXME: This also isn't great...
// The way this is currently setup and working
// is less than ideal. It's using the same files
// property that the SetFilesOnParent is using,
// however the value is only honored on the first render.
// Tried to make it clear that the Files prop isn't used
// to update the component state by marking all the properties
// on the FileOnParent as read only.
// Ideally it should be initialFiles or something similar.
export const useRehydrateSessionIdFromParentState = ({fileUploadDispatch, sessionInitializer}: RehydrateFromParentStateOptions) => {
  useEffect(() => {
    if (!sessionInitializer) {
      return;
    }

    sessionInitializer.then(sessionId => {
      fileUploadDispatch({
        type: 'SET SESSION ID',
        payload: sessionId
      });
    });
  }, []);
};
