/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/explicit-module-boundary-types */
import {css} from 'styled-components';
import {map} from '../../breakpoint';
import {ResponsiveOrStaticProp} from './../../utils';

export type DimensionsType = ResponsiveOrStaticProp<string>;

export const width = () => css`
  ${({width}: {width?: DimensionsType}) =>
    map(
      width,
      (val: string) =>
        val !== undefined &&
        css`
          width: ${val};
        `
    )};
`;

export const minWidth = () => css`
  ${({minWidth}: {minWidth?: DimensionsType}) =>
    map(
      minWidth,
      (val: string) =>
        val !== undefined &&
        css`
          min-width: ${val};
        `
    )};
`;

export const maxWidth = () => css`
  ${({maxWidth}: {maxWidth?: DimensionsType}) =>
    map(
      maxWidth,
      (val: string) =>
        val !== undefined &&
        css`
          max-width: ${val};
        `
    )};
`;

export const height = () => css`
  ${({height}: {height?: DimensionsType}) =>
    map(
      height,
      (val: string) =>
        val !== undefined &&
        css`
          height: ${val};
        `
    )};
`;

export const minHeight = () => css`
  ${({minHeight}: {minHeight?: DimensionsType}) =>
    map(
      minHeight,
      (val: string) =>
        val !== undefined &&
        css`
          min-height: ${val};
        `
    )};
`;

export const maxHeight = () => css`
  ${({maxHeight}: {maxHeight?: DimensionsType}) =>
    map(
      maxHeight,
      (val: string) =>
        val !== undefined &&
        css`
          max-height: ${val};
        `
    )};
`;
