import styled, {css} from 'styled-components';
import {mt} from '@nib/layout';
import Copy, {CopyProps} from '@nib-components/copy';
import {formHelpTextColor, formHelpTextSize, token} from '@nib-components/theme';
import {disabledOpacity} from '../disabled';

export interface HelpProps extends CopyProps {
  disabled?: boolean;
}
const Help = styled(Copy).attrs({component: 'div', 'data-mesh-component': 'FORM-CONTROL--HELP'})<HelpProps>`
  ${mt(1)};
  color: var(--themeColorFgMuted, ${formHelpTextColor});
  font-family: ${token('formControl.typography.help.fontFamily')};
  font-size: ${token('formControl.typography.help.fontSize', formHelpTextSize)};
  font-weight: ${token('formControl.typography.help.fontWeight')};
  line-height: ${token('formControl.typography.help.lineHeight')};
  letter-spacing: ${token('formControl.typography.help.letterSpacing')};

  ${({disabled}) =>
    disabled &&
    css`
      opacity: ${disabledOpacity};
    `};
`;

Help.displayName = 'Help';

export default Help;
