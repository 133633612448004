import { Box, Stack, Inline, Tiles } from '@nib/layout';
import Heading from '@nib-components/heading';
import Copy from '@nib-components/copy';
import { TickSystemIcon, CrossInCircleSystemIcon, ChevronRightSystemIcon, ChevronDownSystemIcon } from '@nib/icons';
import ColorPalette, { ColorDot } from "../../../../src/components/colors/ColorPalette.js";
import AccessibilityMatrix, { MatrixHeader } from "../../../../src/components/colors/AccessibilityMatrix.js";
import { Link as GatsbyLink } from 'gatsby';
import Link from '@nib-components/link';
import * as React from 'react';
export default {
  Box,
  Stack,
  Inline,
  Tiles,
  Heading,
  Copy,
  TickSystemIcon,
  CrossInCircleSystemIcon,
  ChevronRightSystemIcon,
  ChevronDownSystemIcon,
  ColorPalette,
  ColorDot,
  AccessibilityMatrix,
  MatrixHeader,
  GatsbyLink,
  Link,
  React
};