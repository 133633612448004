import { Inline } from '@nib/layout';
import { PrimaryButton, SecondaryButton } from '@nib-components/button';
import Alert from '@nib-components/alert';
import * as React from 'react';
export default {
  Inline,
  PrimaryButton,
  SecondaryButton,
  Alert,
  React
};