import React from 'react';
import styled from 'styled-components';

export interface TargetProps {
  component?: any; // eslint-disable-line  @typescript-eslint/no-explicit-any
  id: string;
  [key: string]: unknown; // other props
}

export const BaseTarget: React.FC<TargetProps> = ({id, component: Component = 'div', ...otherProps}) => {
  return <Component {...otherProps} id={id} tabIndex="-1" />;
};

const Target = styled(BaseTarget)`
  outline: none;
`;

Target.displayName = 'Target';

export default Target;
