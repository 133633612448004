import VillainPanel from '@nib-components/villain-panel';
import villainDesktop from "../../../../content/images/villain-panel/desktop.jpg";
import villainTablet from "../../../../content/images/villain-panel/tablet.jpg";
import villainMobile from "../../../../content/images/villain-panel/mobile.jpg";
import * as React from 'react';
export default {
  VillainPanel,
  villainDesktop,
  villainTablet,
  villainMobile,
  React
};