import {createGlobalStyle} from 'styled-components';
import {FONTS_CDN_URL} from '../constants';

const BRAND_CDN_URL = `${FONTS_CDN_URL}/ing`;
const FONT_FAMILY = 'ING Me';

export const IngFonts = createGlobalStyle`
  @font-face {
    font-family: ${FONT_FAMILY};
    src: url(${BRAND_CDN_URL}/INGMeWeb-Regular.woff2) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url(${BRAND_CDN_URL}/INGMeWeb-Regular.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: ${FONT_FAMILY};
    src: url(${BRAND_CDN_URL}/INGMeWeb-Bold.woff2) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url(${BRAND_CDN_URL}/INGMeWeb-Bold.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
`;
