import {css} from 'styled-components';
import token from '../getToken';
import {standardFocusStyleDeclarations} from '../utils';

export const buttonReset = css`
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
  -webkit-tap-highlight-color: transparent;
  appearance: none;
  box-sizing: border-box;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  text-wrap: balance;
  text-decoration: none;
  vertical-align: middle;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
  transition-property: background, border-color, color;

  &:disabled {
    cursor: default;
    pointer-events: auto;
  }

  &:focus-visible {
    ${standardFocusStyleDeclarations};
  }
`;
type ButtonType = 'primary' | 'secondary' | 'tertiary' | 'destructive';

type Fallbacks = {
  bg: {
    default: string;
    hover: string;
    active: string;
    disabled: string;
  };
  fg: {
    default: string;
    hover: string;
    active: string;
    disabled: string;
  };
  border: {
    default: string;
    hover: string;
    active: string;
    disabled: string;
    width?: string;
  };
};

type ButtonTypeFallbacks = {
  type: ButtonType;
  fallbacks: Fallbacks;
};

type ButtonStateByTypeFallbacks = {
  type: ButtonType;
  fallbacks: {
    bg: string;
    fg: string;
    border: string;
  };
};

type SizeFallbacks = {
  fontFamily: string;
  fontSize: string;
  fontWeight: number;
  lineHeight: number;
  letterSpacing: string;
  textTransform: string;
  paddingBlock: string;
  paddingInline: string;
  gap: string;
};

type ButtonSizeFallbacks = {
  size: 'small' | 'medium' | 'large';
  fallbacks: SizeFallbacks;
};

export const getButtonTokensForType = ({type, fallbacks}: ButtonTypeFallbacks) => css`
  background-color: ${token(`button.color.bg.${type}`, fallbacks.bg.default)};
  color: ${token(`button.color.fg.${type}`, fallbacks.fg.default)};
  border: ${token('button.dimension.border.width', fallbacks.border.width ? fallbacks.border.width : '1px')} solid ${token(`button.color.border.${type}`, fallbacks.border.default)};

  &:hover:not([disabled]) {
    background-color: ${token(`button.color.bg.${type}.hover`, fallbacks.bg.hover)};
    color: ${token(`button.color.fg.${type}.hover`, fallbacks.fg.hover)};
    border-color: ${token(`button.color.border.${type}.hover`, fallbacks.border.hover)};
  }

  &:active:not([disabled]) {
    background-color: ${token(`button.color.bg.${type}.active`, fallbacks.bg.active)};
    color: ${token(`button.color.fg.${type}.active`, fallbacks.fg.active)};
    border-color: ${token(`button.color.border.${type}.active`, fallbacks.border.active)};
  }
`;

export const getButtonSelectedTokensForType = ({type, fallbacks}: ButtonStateByTypeFallbacks) => css`
  background-color: ${token(`button.color.bg.${type}.active`, fallbacks.bg)};
  color: ${token(`button.color.fg.${type}.active`, fallbacks.fg)};
  border-color: ${token(`button.color.border.${type}.active`, fallbacks.border)};
`;

export const getButtonDisabledTokensForType = ({type, fallbacks}: ButtonStateByTypeFallbacks) => css`
  background-color: ${token(`button.color.bg.${type}.disabled`, fallbacks.bg)};
  color: ${token(`button.color.fg.${type}.disabled`, fallbacks.fg)};
  border-color: ${token(`button.color.border.${type}.disabled`, fallbacks.border)};
`;

export const getPrimaryButtonTokens = (fallbacks: Fallbacks) => getButtonTokensForType({fallbacks, type: 'primary'});
export const getSecondaryButtonTokens = (fallbacks: Fallbacks) => getButtonTokensForType({fallbacks, type: 'secondary'});
export const getTertiaryButtonTokens = (fallbacks: Fallbacks) => getButtonTokensForType({fallbacks, type: 'tertiary'});
export const getDestructiveButtonTokens = (fallbacks: Fallbacks) => getButtonTokensForType({fallbacks, type: 'destructive'});

// Selected
export const getPrimaryButtonSelectedTokens = (fallbacks: {bg: string; fg: string; border: string}) => getButtonSelectedTokensForType({fallbacks, type: 'primary'});
export const getSecondaryButtonSelectedTokens = (fallbacks: {bg: string; fg: string; border: string}) => getButtonSelectedTokensForType({fallbacks, type: 'secondary'});
export const getTertiaryButtonSelectedTokens = (fallbacks: {bg: string; fg: string; border: string}) => getButtonSelectedTokensForType({fallbacks, type: 'tertiary'});
export const getDestructiveButtonSelectedTokens = (fallbacks: {bg: string; fg: string; border: string}) => getButtonSelectedTokensForType({fallbacks, type: 'destructive'});

// Disabled
export const getPrimaryButtonDisabledTokens = (fallbacks: {bg: string; fg: string; border: string}) => getButtonDisabledTokensForType({fallbacks, type: 'primary'});
export const getSecondaryButtonDisabledTokens = (fallbacks: {bg: string; fg: string; border: string}) => getButtonDisabledTokensForType({fallbacks, type: 'secondary'});
export const getTertiaryButtonDisabledTokens = (fallbacks: {bg: string; fg: string; border: string}) => getButtonDisabledTokensForType({fallbacks, type: 'tertiary'});
export const getDestructiveButtonDisabledTokens = (fallbacks: {bg: string; fg: string; border: string}) => getButtonDisabledTokensForType({fallbacks, type: 'destructive'});

export const getButtonTokensForSize = ({size, fallbacks}: ButtonSizeFallbacks) => {
  const normalisedSize: '' | '.small' | '.large' = size === 'medium' ? '' : `.${size}`;
  return css`
    font-family: ${token(`button.typography${normalisedSize}.fontFamily`, fallbacks.fontFamily)};
    font-size: ${token(`button.typography${normalisedSize}.fontSize`, fallbacks.fontSize)};
    font-weight: ${token(`button.typography${normalisedSize}.fontWeight`, fallbacks.fontWeight)};
    line-height: ${token(`button.typography${normalisedSize}.lineHeight`, fallbacks.lineHeight)};
    letter-spacing: ${token(`button.typography${normalisedSize}.letterSpacing`, fallbacks.letterSpacing)};
    text-transform: ${token(`button.typography${normalisedSize}.textTransform`, fallbacks.textTransform)};
    padding-block: ${token(`button.dimension.padding${normalisedSize}.vertical`, fallbacks.paddingBlock)};
    padding-inline: ${token(`button.dimension.padding${normalisedSize}.horizontal`, fallbacks.paddingInline)};
    gap: ${token(`button.dimension.padding${normalisedSize}.gap`, fallbacks.gap)};
  `;
};

export const getSmallButtonTokens = (fallbacks: SizeFallbacks) => getButtonTokensForSize({fallbacks, size: 'small'});
export const getMediumButtonTokens = (fallbacks: SizeFallbacks) => getButtonTokensForSize({fallbacks, size: 'medium'});
export const getLargeButtonTokens = (fallbacks: SizeFallbacks) => getButtonTokensForSize({fallbacks, size: 'large'});

export const baseButtonStyles = css`
  --fontFamily: ${token('button.typography.fontFamily')};
  --fontSize: ${token('button.typography.fontSize')};
  --fontWeight: ${token('button.typography.fontWeight')};
  --lineHeight: ${token('button.typography.lineHeight')};
  --letterSpacing: ${token('button.typography.letterSpacing')};
  --textTransform: ${token('button.typography.textTransform')};
  --borderRadius: ${token('button.dimension.border.radius')};
  --paddingHorizontal: ${token('button.dimension.padding.horizontal')};
  --paddingVertical: ${token('button.dimension.padding.vertical')};

  /* Unsure how to use this */
  --gap: ${token('button.dimension.padding.gap')};
  --buttonColorBg: ${token('button.color.bg.primary')};
  --buttonColorBgHover: ${token('button.color.bg.primary.hover')};
  --buttonColorBgActive: ${token('button.color.bg.primary.active')};
  --buttonColorBgDisabled: ${token('button.color.bg.primary.disabled')};
  --buttonColorFg: ${token('button.color.fg.primary')};
  --buttonColorFgHover: ${token('button.color.fg.primary.hover')};
  --buttonColorFgActive: ${token('button.color.fg.primary.active')};
  --buttonColorFgDisabled: ${token('button.color.fg.primary.disabled')};
  --buttonColorBorder: ${token('button.color.border.primary')};
  --buttonColorBorderHover: ${token('button.color.border.primary.hover')};
  --buttonColorBorderActive: ${token('button.color.border.primary.active')};
  --buttonColorBorderDisabled: ${token('button.color.border.primary.disabled')};
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
  -webkit-tap-highlight-color: transparent;
  appearance: none;
  box-sizing: border-box;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  text-wrap: balance;
  text-decoration: none;
  vertical-align: middle;
  background-color: var(--buttonColorBg);
  color: var(--buttonColorFg);
  border: var(--buttonBorderWidth) solid var(--buttonColorBorder);
  border-radius: var(--borderRadius);
  font-family: var(--fontFamily);
  font-size: var(--fontSize);
  font-weight: var(--fontWeight);
  line-height: var(--lineHeight);
  letter-spacing: var(--letterSpacing);
  text-transform: var(--textTransform);
  padding: var(--paddingVertical) var(--paddingHorizontal) var(--paddingVertical) var(--paddingHorizontal);
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
  transition-property: background, border-color, color;

  &:disabled {
    cursor: default;
    pointer-events: auto;

    --buttonColorBg: var(--buttonColorBgDisabled);
    --buttonColorFg: var(--buttonColorFgDisabled);
    --buttonColorBorder: var(--buttonColorBorderDisabled);
  }

  &:hover:not([disabled]) {
    --buttonColorBg: var(--buttonColorBgHover);
    --buttonColorFg: var(--buttonColorFgHover);
    --buttonColorBorder: var(--buttonColorBorderHover);
  }

  &:active:not([disabled]) {
    --buttonColorBg: var(--buttonColorBgActive);
    --buttonColorFg: var(--buttonColorFgActive);
    --buttonColorBorder: var(--buttonColorBorderActive);
  }

  &:focus-visible {
    ${standardFocusStyleDeclarations};
  }
`;
