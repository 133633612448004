/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {TiersType} from './tiers';

// These stroke-dash arrays are pretty magical ✨
// Check out this article for background: https://medium.com/@heyoka/scratch-made-svg-donut-pie-charts-in-html5-2c587e935d72
// eslint-disable-next-line   @typescript-eslint/no-explicit-any
export const getStrokeDashArrayFromTier = (tier: TiersType): any => {
  switch (tier) {
    case 'basic':
      return '25 75';
    case 'basic-plus':
      return '37.5 62.5';
    case 'bronze':
      return '50 50';
    case 'bronze-plus':
      return '62.5 37.5';
    case 'silver':
      return '75 25';
    case 'silver-plus':
      return '87.5 12.5';
    case 'gold':
      return '100 0';

    default: {
      return null;
    }
  }
};

export const hospitalTiers = {
  basic: '#144a38',
  bronze: '#cd7f32',
  silver: '#83aebe',
  gold: '#c5b358'
};

// eslint-disable-next-line   @typescript-eslint/no-explicit-any
export const getStrokeColorFromTier = (tier: TiersType): any => {
  switch (tier) {
    case 'basic':
    case 'basic-plus':
      return `var(--themeColorBorderProductHospital, ${hospitalTiers.basic})`;

    case 'bronze':
    case 'bronze-plus':
      return `var(--themeColorBorderProductBronze, ${hospitalTiers.bronze})`;

    case 'silver':
    case 'silver-plus':
      return `var(--themeColorBorderProductSilver, ${hospitalTiers.silver})`;

    case 'gold':
      return `var(--themeColorBorderProductGold, ${hospitalTiers.gold})`;

    default: {
      return null;
    }
  }
};
