import React, {useContext, useMemo, createContext} from 'react';
import {ThemeContext} from 'styled-components';
import {tokenMapping} from './tokenMapping';
import {getActiveMode} from './utils';
import {ModeType, ThemeVariants} from '../types';

interface ModeProviderProps {
  mode: ModeType;
  children: React.ReactNode;
}

function mergeMode(mode: ModeType, outerMode?: ModeType | undefined): ModeType {
  if (typeof mode === 'string') {
    return mode;
  }

  if (typeof outerMode === 'string') {
    return {
      rest: outerMode,
      ...mode
    };
  }

  return outerMode ? {...outerMode, ...mode} : mode;
}

export const ModeContext = createContext<ModeType | undefined>(undefined);
export const ModeConsumer = ModeContext.Consumer;

export const ModeProvider: React.FC<ModeProviderProps> = ({mode, children}) => {
  const themeContext = useContext(ThemeContext);
  const brand: ThemeVariants = themeContext.id;
  const activeMode = getActiveMode(brand, mode);

  const outerMode = useContext(ModeContext);
  const modeContext = useMemo(() => mergeMode(mode, outerMode), [mode, outerMode]);

  return (
    <ModeContext.Provider value={modeContext}>
      <div data-mesh-component="MODE-PROVIDER" data-mode={activeMode}>
        {children}
      </div>
    </ModeContext.Provider>
  );
};

export {getActiveMode, tokenMapping};
