import React from 'react';
import {flexRender, getCoreRowModel, createColumnHelper, useReactTable} from '@tanstack/react-table';
import {TableWrapper, TableCaption, Table, TableHead, TableHeadRow, TableHeading, TableBody, TableRow, TableData} from '@nib/table';

export const BasicTable = ({caption, captionSide, stripedRows, rowHover, height, maxHeight, equalColumns, rowHeight, fixedHeader, fixedColumn, ...otherProps}) => {
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('column1', {
      cell: info => info.getValue(),
      footer: info => info.column.id
    }),
    columnHelper.accessor('column2', {
      cell: info => info.getValue(),
      footer: info => info.column.id
    }),
    columnHelper.accessor('column3', {
      cell: info => info.getValue(),
      footer: info => info.column.id
    }),
    columnHelper.accessor('column4', {
      cell: info => info.getValue(),
      footer: info => info.column.id
    }),
    columnHelper.accessor('column5', {
      cell: info => info.getValue(),
      footer: info => info.column.id
    }),
    columnHelper.accessor('column6', {
      cell: info => info.getValue(),
      footer: info => info.column.id
    })
  ];
  const data = [
    {
      column1: 'Column 1',
      column2: 'Column 2',
      column3: 'Column 3',
      column4: 'Column 4',
      column5: 'Column 5',
      column6: 'Column 6'
    },
    {
      column1: 'Column 1',
      column2: 'Column 2',
      column3: 'Column 3',
      column4: 'Column 4',
      column5: 'Column 5',
      column6: 'Column 6'
    },
    {
      column1: 'Lorem Ipsum is simply dummy text ',
      column2: 'Column 2',
      column3: 'Column 3',
      column4: 'Column 4',
      column5: 'Column 5',
      column6: 'Column 6'
    },
    {
      column1: 'Column 1',
      column2: 'Column 2',
      column3: 'Column 3',
      column4: 'Column 4',
      column5: 'Column 5',
      column6: 'Column 6'
    },
    {
      column1: 'Column 1',
      column2: 'Column 2',
      column3: 'Column 3',
      column4: 'Column 4',
      column5: 'Column 5',
      column6: 'Column 6'
    }
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <TableWrapper height={height} maxHeight={maxHeight}>
      <Table {...otherProps} equalColumns={equalColumns}>
        {caption && <TableCaption captionSide={captionSide}>{caption}</TableCaption>}
        <TableHead>
          {table.getHeaderGroups().map((headerGroup, index) => (
            <TableHeadRow key={`header-group-${index}`} fixedHeader={fixedHeader}>
              {headerGroup.headers.map(header => (
                <TableHeading key={header.id} fixedColumn={fixedColumn}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHeading>
              ))}
            </TableHeadRow>
          ))}
        </TableHead>

        <TableBody stripedRows={stripedRows} rowHover={rowHover}>
          {table.getRowModel().rows.map(row => (
            <TableRow key={row.id} rowHeight={rowHeight}>
              {row.getVisibleCells().map(cell => (
                <TableData key={cell.id} fixedColumn={fixedColumn}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableData>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};
