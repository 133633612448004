import {UploadProgressState} from '../../../Interfaces';
import {fileAlreadyInState} from './fileAlreadyInState';

export const stateWithUpdatedFile = (state: UploadProgressState[], file: UploadProgressState): UploadProgressState[] => {
  if (!fileAlreadyInState(state, file)) {
    return state;
  }

  return [{...file}, ...state.filter(stateFile => stateFile.name !== file.name)];
};
