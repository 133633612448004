import React from 'react';
import styled from 'styled-components';
import {Box, Container, colorFgValues, colorBgValues, Tiles, breakpoint, mb, px} from '@nib/layout';
import Heading from '@nib-components/heading';
import {SpacingType} from '@nib-components/theme';
import Feature, {DisplayIconsType, TitleType, ResponsiveOrStaticProp, Nullable} from './Feature';

export interface FeaturePanelProps {
  title?: string;
  titleComponent?: TitleType;
  className?: string;
  displayIcons?: DisplayIconsType;
  children: React.ReactNode;
  space?: ResponsiveOrStaticProp<Nullable<SpacingType>>;
  color?: colorFgValues;
  background?: colorBgValues;
  iconFill?: colorFgValues;
  iconAccentFill?: colorFgValues;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const BoxWrapper = styled(Box)`
  box-sizing: border-box;
  ${px({xs: 0, sm: 5})};
`;

export const FeaturePanelTitle = styled(Heading)`
  text-align: center;
  ${mb(3)};

  ${breakpoint('md')`
    ${mb(6)};
  `};
`;

const FeaturePanel: React.FC<FeaturePanelProps> & {Feature: typeof Feature} = props => {
  const {title, titleComponent = 'h2', displayIcons = true, children, space = 0, background, color, iconFill, iconAccentFill, ...otherProps} = props;
  const featureCount = React.Children.count(children);

  //warn the user if they provide not-enough or too many features
  //eslint-disable-next-line no-undef
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const invariant = require('invariant');
    invariant(featureCount >= 2 || featureCount <= 4, '@nib-components/feature-panel: Must have 2-4 features.');
  }

  const featureSize = () => {
    if (featureCount === 1) {
      return 1;
    } else if (featureCount === 2 || featureCount === 4) {
      return 2;
    } else {
      return 3;
    }
  };

  const panelTitle = title && (
    <FeaturePanelTitle component={titleComponent} size={{xs: 3, md: 2}}>
      {title}
    </FeaturePanelTitle>
  );

  return (
    <Box data-mesh-component="FEATURE-PANEL" background={background} color={color} paddingVertical={{xs: 6, sm: 8}} paddingHorizontal={{xs: 4, sm: 5}} {...otherProps}>
      <Container>
        {panelTitle}
        <Tiles space={space} columns={{xs: 1, lg: featureSize()}}>
          {React.Children.map(
            props.children,
            (
              child: any // eslint-disable-line  @typescript-eslint/no-explicit-any
            ) => (
              <BoxWrapper padding={5} paddingVertical={{lg: 6}} height="100%">
                {React.cloneElement(child, {
                  displayIcons,
                  iconFill,
                  iconAccentFill
                })}
              </BoxWrapper>
            )
          )}
        </Tiles>
      </Container>
    </Box>
  );
};

FeaturePanel.displayName = 'FeaturePanel';

FeaturePanel.Feature = Feature;

export default FeaturePanel;
