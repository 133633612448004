import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Link as GatsbyLink} from 'gatsby';
import styled from 'styled-components';
import FormControl, {InputWrapper} from '@nib-components/form-control';
import Heading from '@nib-components/heading';
import Textbox from '@nib-components/textbox';
import Select from '@nib-components/select';
import {colorLighter, colorDarker, standardFocusStyleDeclarations} from '@nib-components/theme';
import * as SystemIcons from '@nib/icons/dist/cjs/system';
import * as GraphicIcons from '@nib/icons/dist/cjs/graphic';
import {metadata} from '@nib/icons';
import {Columns, Column, Padding, Margin, breakpoint, py, px} from '@nib/layout';

const Label = styled.div`
  font-size: 0.875rem;
  word-break: break-word;
  color: ${colorDarker};
`;

const IconGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
    grid-gap: 1px;

    ${breakpoint('xl')`
      grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
    `}
  }
`;

export const Item = styled.div`
  ${py(7)};
  ${px(5)};
  font-size: 0; // Stop line-height from making icons jump when theme knob is changed
  text-align: center;
  box-shadow: 0 0 0 1px ${colorLighter};
  color: inherit;
  text-decoration: none;

  &:focus-visible {
    ${standardFocusStyleDeclarations};
    z-index: 1;
  }
`;

const ItemWithLink = styled(Item)`
  color: inherit;
  text-decoration: none;

  &:hover {
    position: relative;
    box-shadow: 0 0 0 1px ${colorDarker};
    z-index: 1;
  }

  &:focus-visible {
    ${standardFocusStyleDeclarations};
    z-index: 1;
  }
`;

const IconCategory = props => {
  // TODO: Remove the conditions for LifeInsuranceGraphicIcon next breaking change
  if (!props.icons || props.icons.filter(x => x.iconName !== 'LifeInsurance' && x.iconName.toLowerCase().includes(props.filterString.toLowerCase())).length === 0) {
    return null;
  }

  const sortedIcons = props.icons.sort(function (a, b) {
    if (a.iconName < b.iconName) {
      return -1;
    }
    if (a.iconName > b.iconName) {
      return 1;
    }
    return 0;
  });

  return (
    <>
      <Heading size={4} component="h3">
        {props.category}
      </Heading>
      <IconGrid>
        {sortedIcons
          .filter(x => x.iconName && x.iconName.toLowerCase().includes(props.filterString.toLowerCase()))
          .map((Icon, index) => (
            <ItemWithLink key={index} as={GatsbyLink} to={`/foundations/iconography/${props.type.toLowerCase()}-icons/${Icon.iconName.toLowerCase()}/`}>
              <Margin bottom={4}>
                <Icon />
              </Margin>
              <Label>{Icon.iconName}</Label>
            </ItemWithLink>
          ))}
      </IconGrid>
    </>
  );
};

IconCategory.propTypes = {
  category: PropTypes.string,
  icons: PropTypes.array,
  filterString: PropTypes.string
};

const CustomSelect = styled(Select)`
  ${InputWrapper} {
    max-width: auto;
  }
`;

/**
 * Build an object from metadata and react components with category keys
 * {
 *   actions: arrayOf(react components),
 *   people: arrayOf(react components),
 *   ...
 * }
 */
const buildMegaList = (type = 'System') => {
  const iconsList = metadata.filter(item => item.type === type);
  const uniqueCategories = Array.from(new Set(iconsList.map(item => item.category)));

  //  Construct an object with keys for each category
  return Object.assign(
    {},
    ...uniqueCategories.map(value => ({
      [value]: [...iconsList.filter(item => item.category === value).map(p => Object.values(type === 'System' ? SystemIcons : GraphicIcons).find(icon => icon.iconName === p.title))]
    }))
  );
};

const categorisedSystemIcons = buildMegaList('System');
const categorisedGraphicIcons = buildMegaList('Graphic');

const IconLibrary = ({iconType}) => {
  const [searchString, setSearchString] = useState('');
  const [category, setCategory] = useState('all');

  const categorisedIcons = iconType === 'System' ? categorisedSystemIcons : categorisedGraphicIcons;

  // ['actions', 'people', etc.]
  const categorisedIconsKeys = Object.keys(categorisedIcons);

  // Boolean - search string has no matches (all categories)
  const noSearchMatches = metadata.filter(item => item.type === iconType).filter(x => x.title.toLowerCase().includes(searchString.toLowerCase())).length === 0;

  // Generate options for the category select box from data, plus 'All categories' option
  const categoryOptions = [{value: 'all', label: 'All categories'}, ...categorisedIconsKeys.map(x => ({value: x, label: x}))];

  // Show only the selected category of icons, or all for 'All categories'
  const filteredCategorisedIcons = category === 'all' ? categorisedIconsKeys : categorisedIconsKeys.filter(x => x === category);

  return (
    <>
      <form id="icon-filter" name="icon-filter">
        <Columns collapseBelow="md">
          <Column width="1/2">
            <Padding right={{md: 6}} bottom={6}>
              <FormControl id="filterString" name="filterString" label="Search">
                <Textbox value={searchString} onChange={() => setSearchString(event.target.value)} />
              </FormControl>
            </Padding>
          </Column>

          <Column width="content">
            <Padding right={{md: 6}} bottom={6}>
              <FormControl id="filterCategory" name="filterCategory" label="Category">
                <CustomSelect value={category} options={categoryOptions} onChange={() => setCategory(event.target.value)} />
              </FormControl>
            </Padding>
          </Column>
        </Columns>
      </form>

      {filteredCategorisedIcons.map(cat => (
        <IconCategory category={cat} icons={categorisedIcons[cat]} type={iconType} filterString={searchString} key={cat} />
      ))}

      {noSearchMatches ? (
        <Heading size={4} component="div">
          Sorry, no icons were found for your search <em>&ldquo;{searchString}&rdquo;</em>.
        </Heading>
      ) : null}
    </>
  );
};

IconLibrary.propTypes = {
  iconType: PropTypes.oneOf(['System', 'Graphic'])
};

export default IconLibrary;
