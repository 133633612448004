import FeaturePanel from '@nib-components/feature-panel';
import { DentalGraphicIcon, AccidentGraphicIcon, AmbulanceGraphicIcon } from '@nib/icons';
import { ColorDot } from "../../../../src/components/colors/ColorPalette.js";
import * as React from 'react';
export default {
  FeaturePanel,
  DentalGraphicIcon,
  AccidentGraphicIcon,
  AmbulanceGraphicIcon,
  ColorDot,
  React
};