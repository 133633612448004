import { Link as GatsbyLink } from 'gatsby';
import Card, { FlashCard } from '@nib/card';
import { Box, Columns, Column } from '@nib/layout';
import { DoctorGraphicIcon, PearGraphicIcon } from '@nib/icons';
import Copy from '@nib-components/copy';
import Link from '@nib-components/link';
import Tag from '@nib/tag';
import Alert from '@nib-components/alert';
import { SLACK_URL, WORKPLACE_URL, DESIGNOPS_MAILTO } from "../../../../src/constants.js";
import * as React from 'react';
export default {
  GatsbyLink,
  Card,
  FlashCard,
  Box,
  Columns,
  Column,
  DoctorGraphicIcon,
  PearGraphicIcon,
  Copy,
  Link,
  Tag,
  Alert,
  SLACK_URL,
  WORKPLACE_URL,
  DESIGNOPS_MAILTO,
  React
};