import React from 'react';
import styled, {css, StyledComponent} from 'styled-components';
import {colorWhite, colorNearWhite, colorLighter, colorDarker, colorSageGreen20} from '@nib-components/theme';
import {ChevronDownSystemIcon, ChevronUpSystemIcon} from '@nib/icons';
import {Padding, m, p, px, py} from '@nib/layout';
import {getTableRowHeight, rowHeightValues, captionSideValues} from '../utils';
import {Th, TableHeadingButton} from './TableHeading';
import {UnstyledButton} from '@nib-components/button';

const SortIconHideReveal = styled.span`
  opacity: 0;
`;

// eslint-disable-next-line  @typescript-eslint/no-unused-vars
export const InvisibleButton = styled(({isSorted, ...props}) => <UnstyledButton {...props} padding={2} />)`
  &:hover,
  &:focus-visible {
    ${SortIconHideReveal} {
      opacity: 1;
    }
  }
`;

interface TableWrapperProps {
  height?: string;
  maxHeight?: string;
}

export const TableWrapper = styled.div<TableWrapperProps>`
  overflow: auto;

  ${({height}) =>
    height &&
    css`
      height: ${height};
    `};

  ${({maxHeight}) =>
    maxHeight &&
    css`
      max-height: ${maxHeight};
    `};
`;

export const Table = styled.table`
  width: 100%;
  position: relative;
  border-spacing: 0;

  ${({equalColumns}: {equalColumns?: boolean}) =>
    equalColumns &&
    `
      th, td {
        width: 1%;
      }
  `};
`;

export const TableCaption = styled.caption<{captionSide?: captionSideValues}>`
  ${p(4)};
  color: var(--themeColorFg, ${colorDarker});
  caption-side: ${props => props.captionSide};
`;

export const TableHead = styled.thead``;

export const TableHeadRow = styled.tr<{rowHeight?: rowHeightValues; fixedHeader?: boolean}>`
  ${({fixedHeader}) =>
    fixedHeader &&
    css`
      & th {
        background-color: var(--themeColorBg, ${colorWhite});
        position: sticky;
        inset-block-start: 0;
        z-index: 1;

        &::before {
          content: '';
          display: block;
          position: absolute;
          height: 2px;
          z-index: 1;
          inset-block-end: 0;
          inset-inline: 0;
          box-shadow: 0 2px 2px 0 ${colorLighter};
        }
      }
    `};

  ${({rowHeight}) =>
    rowHeight
      ? css`
          ${Th},
          ${TableHeadingButton} {
            padding: ${getTableRowHeight(rowHeight)} 0.5rem;
          }
        `
      : css`
          td {
            ${px(2)};
            ${py(3)};
          }
        `};
`;

export const TableBody = styled.tbody<{stripedRows?: boolean; rowHover?: boolean}>`
  ${({stripedRows}) =>
    stripedRows &&
    css`
      td {
        border-block-end: 0 !important;
      }

      & > tr:nth-child(odd) td {
        background-color: ${colorNearWhite};
      }
    `};

  ${({rowHover}) =>
    rowHover &&
    css`
      & > ${TableRow}:hover td {
        background-color: ${colorSageGreen20};
      }
    `};
`;

export interface TableRowProps {
  rowHeight?: rowHeightValues;
  isExpanded?: boolean;
  isSelected?: boolean;
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const TableRow: StyledComponent<'tr', any, TableRowProps, never> = styled.tr<TableRowProps>`
  ${({rowHeight}) =>
    rowHeight
      ? css`
          td {
            padding: ${getTableRowHeight(rowHeight)} 0.5rem;
          }
        `
      : css`
          td {
            ${px(2)};
            ${py(3)};
          }
        `};

  ${({isSelected}) =>
    isSelected &&
    `
    ${TableData}${TableData}${TableData} {
      background-color: hsla(134, 100%, 27%, .1);
    }
  `};
`;

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const TableExpandHeading: any = styled(Th)`
  &&& {
    /* shrink to be as small as possible */
    width: 0.0000000001%;
  }
`;

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const TableExpandRow = ({onClick, isExpanded, rowHeight, ...props}: {onClick: any; isExpanded?: boolean; rowHeight?: rowHeightValues; style?: any; children?: React.ReactNode}) => (
  <TableRow isExpanded={isExpanded} rowHeight={rowHeight} {...props}>
    {props.children}
    <TableExpandData>
      <InvisibleButton onClick={onClick} aria-label={isExpanded ? 'Collapse Row' : 'Expand Row'}>
        <Padding all={rowHeight === 'condensed' ? 2 : 3}>{isExpanded ? <ChevronUpSystemIcon size="xs" /> : <ChevronDownSystemIcon size="xs" />}</Padding>
      </InvisibleButton>
    </TableExpandData>
  </TableRow>
);

// eslint-disable-next-line  @typescript-eslint/no-unused-vars
export const TableData = styled(({fixedColumn, collapseColumn, stripedRows, textAlign, ...rest}) => (fixedColumn ? <th scope="row" {...rest} /> : <td {...rest} />))`
  background-color: var(--themeColorBg, ${colorWhite});
  border-bottom: 1px solid var(--themeColorBorder, ${colorLighter});
  line-height: 1;

  ${m(0)};

  ${({textAlign}) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `};

  ${({collapseColumn}) =>
    collapseColumn &&
    `
      width: 0.0000000001% !important;
  `};

  ${({fixedColumn}) =>
    fixedColumn &&
    css`
      position: sticky;
      inset-inline-start: 0;
      z-index: 1;
      text-align: start;
      ${m(0)};
      ${p(2)};

      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 100%;
        z-index: 1;
        inset-block: 0;
        inset-inline-end: 2px;
        box-shadow: 2px 0 1px 1px ${colorLighter};
      }
    `};
`;

export const TableExpandData = styled(TableData)`
  &&& {
    padding: 0;

    /* shrink to be as small as possible */
    width: 0.0000000001%;
  }
`;
