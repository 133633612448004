import React, {Children} from 'react';
import styled, {css} from 'styled-components';
import {map} from '../breakpoint';
import {BreakpointType} from '@nib-components/theme';
import {mapFlexWidths, ResponsiveOrStaticProp, Nullable, WidthType} from '../utils';
import {Hidden} from '../Hidden';

export interface ColumnProps {
  width?: ResponsiveOrStaticProp<Nullable<WidthType>>;
  hiddenAbove?: BreakpointType;
  hiddenBelow?: BreakpointType;
  flex?: boolean;
  children: React.ReactNode;
  [key: string]: unknown;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ColumnWrapper = styled(({width, hiddenAbove, hiddenBelow, flex, ...rest}) => (hiddenAbove || hiddenBelow ? <Hidden above={hiddenAbove} below={hiddenBelow} {...rest} /> : <div {...rest} />))`
  min-width: 0;

  ${({flex}) =>
    flex &&
    `
    display: flex;
    width: 100%;
  `};

  ${({width}) =>
    width
      ? map(width, (val: WidthType) =>
          val === 'content' || val === '1'
            ? `
              flex: 0 0 auto;
              width: ${val === 'content' ? 'auto' : '100%'};
            `
            : css`
                flex: 0 0 ${mapFlexWidths(val)}%;
                width: 100%;
              `
        )
      : css`
          width: 100%;
        `}
`;

export const ColumnChild = styled.div`
  ${({flex}: {flex: boolean}) =>
    flex &&
    `
    display: flex;
    width: 100%;
  `};
`;

export const Column: React.FC<ColumnProps> = ({children, width, hiddenAbove, hiddenBelow, flex = false, ...otherProps}) => (
  <ColumnWrapper data-mesh-component="COLUMN" width={width} hiddenAbove={hiddenAbove} hiddenBelow={hiddenBelow} flex={flex} {...otherProps}>
    {Children.map(children, child => {
      if (React.isValidElement(child)) {
        return <ColumnChild flex={flex}>{child}</ColumnChild>;
      }
      return null;
    })}
  </ColumnWrapper>
);

Column.displayName = 'Column';
