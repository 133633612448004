import {UploadProgressState} from '../../../Interfaces';
import {fileAlreadyInState} from './fileAlreadyInState';

export const stateWithNewFile = (state: UploadProgressState[], files: UploadProgressState[]): UploadProgressState[] => {
  const newFiles = files
    .filter(f => !fileAlreadyInState(state, f))
    .map(f => ({
      ...f
    }));

  return [...state, ...newFiles];
};
