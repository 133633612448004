import {css} from 'styled-components';
import Colr from 'colr';
import {
  buttonReset,
  getSmallButtonTokens,
  getMediumButtonTokens,
  getLargeButtonTokens,
  getPrimaryButtonTokens,
  getPrimaryButtonSelectedTokens,
  getPrimaryButtonDisabledTokens,
  getSecondaryButtonTokens,
  getSecondaryButtonSelectedTokens,
  getSecondaryButtonDisabledTokens,
  getTertiaryButtonTokens,
  getTertiaryButtonSelectedTokens,
  getTertiaryButtonDisabledTokens,
  getDestructiveButtonTokens,
  getDestructiveButtonSelectedTokens,
  getDestructiveButtonDisabledTokens
} from '../shared';
import common, {spacing} from '../../common';
import {ThemeSchema} from '../../types';
import token from '../../getToken';

export const hoverColor = (color: string): string => Colr.fromHex(color).darken(10).toHex();

export const activeColor = (color: string): string => Colr.fromHex(color).darken(20).toHex();

export const disabledColor = (color: string, percent?: number): string => {
  const opacity = percent || 0.3;
  const rgb = Colr.fromHex(color).toRgbArray().toString();
  return `rgba(${rgb}, ${opacity})`;
};

const typeScale = {
  1: '3.375rem',
  2: '2.25rem',
  3: '1.5rem',
  4: '1.125rem',
  5: '1rem',
  6: '.875rem'
};

const lineHeight = {
  tighter: 1,
  tight: 1.2,
  normal: 1.5
};

const tracking = {
  normal: 'normal',
  brand: '-0.02em'
};

const fonts = {
  serif: '"Grenette Pro", "Georgia", serif',
  sansSerif: '"Buenos Aires", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif'
};

const rebrandColors = {
  trueGreen: {
    100: '#144a38',
    80: '#436e60',
    60: '#729288',
    40: '#a1b7af',
    20: '#d0dbd7'
  },
  brightGreen: {
    100: '#82e578',
    80: '#9bea93',
    60: '#b4efae',
    40: '#cdf5c9',
    20: '#e6fae4'
  },
  sageGreen: {
    100: '#c2d6b5',
    80: '#cedec4',
    60: '#dae6d3',
    40: '#e7efe1',
    20: '#f3f7f0'
  },
  sunsetPink: {
    100: '#fdd6bc',
    80: '#fddec9',
    60: '#fee6d7',
    40: '#feefe4',
    20: '#fff7f2'
  },
  warmWhite: {
    100: '#f5f0d6',
    80: '#f7f3de',
    60: '#f9f6e6',
    40: '#fbf9ef',
    20: '#fdfcf7'
  },
  errorRed: '#c81414',
  successGreen: '#007800',
  infoBlue: '#005bb9'
};

const colors = {
  brand: {
    base: '#144a38',
    light: '#82e578',
    dark: '#144a38'
  },
  accent: '#525199',
  shades: {
    black: '#141414',
    darkest: '#434343',
    darker: '#636363',
    dark: '#8a8a8a',
    light: '#c7c7c7',
    lighter: '#e4e4e4',
    lightest: '#f1f1f1',
    nearWhite: '#fafafa',
    white: `#ffffff`
  },
  focus: '#525199',
  error: rebrandColors.errorRed,
  success: rebrandColors.successGreen,
  info: rebrandColors.infoBlue,
  product: {
    hospital: '#18659f',
    extras: '#7e3e97',
    combined: '#144a38',
    highlight: {
      backgroundColor: '#144a38',
      textColor: '#82e578'
    }
  },
  //NIB-SPECIFIC COLOURS
  trueGreen: rebrandColors.trueGreen[100],
  trueGreen80: rebrandColors.trueGreen[80],
  trueGreen60: rebrandColors.trueGreen[60],
  trueGreen40: rebrandColors.trueGreen[40],
  trueGreen20: rebrandColors.trueGreen[20],

  brightGreen: rebrandColors.brightGreen[100],
  brightGreen80: rebrandColors.brightGreen[80],
  brightGreen60: rebrandColors.brightGreen[60],
  brightGreen40: rebrandColors.brightGreen[40],
  brightGreen20: rebrandColors.brightGreen[20],

  sunsetPink: rebrandColors.sunsetPink[100],
  sunsetPink80: rebrandColors.sunsetPink[80],
  sunsetPink60: rebrandColors.sunsetPink[60],
  sunsetPink40: rebrandColors.sunsetPink[40],
  sunsetPink20: rebrandColors.sunsetPink[20],

  sageGreen: rebrandColors.sageGreen[100],
  sageGreen80: rebrandColors.sageGreen[80],
  sageGreen60: rebrandColors.sageGreen[60],
  sageGreen40: rebrandColors.sageGreen[40],
  sageGreen20: rebrandColors.sageGreen[20],

  warmWhite: rebrandColors.warmWhite[100],
  warmWhite80: rebrandColors.warmWhite[80],
  warmWhite60: rebrandColors.warmWhite[60],
  warmWhite40: rebrandColors.warmWhite[40],
  warmWhite20: rebrandColors.warmWhite[20]
};

const focus = {
  outlineColor: colors.focus,
  outlineStyle: 'solid',
  outlineWidth: '3px',
  outlineOffset: '0.125em'
};

const baseButtonStyles = css`
  ${buttonReset};
  font-family: ${fonts.sansSerif};
  font-weight: 700;
  letter-spacing: ${tracking.brand};
  border-radius: ${token('button.dimension.border.radius', '100px')};
  border-width: ${token('button.dimension.border.width', '2px')};
`;

const buttonStyles = {
  primary: {
    normal: css`
      ${baseButtonStyles};

      ${getPrimaryButtonTokens({
        bg: {
          default: colors.brand.light,
          hover: hoverColor(colors.brand.light),
          active: activeColor(colors.brand.light),
          disabled: disabledColor(colors.brand.light, 0.6)
        },
        fg: {
          default: colors.brand.base,
          hover: colors.brand.base,
          active: colors.brand.base,
          disabled: disabledColor(colors.brand.base, 0.6)
        },
        border: {
          default: 'transparent',
          hover: 'transparent',
          active: 'transparent',
          disabled: 'transparent'
        }
      })};
    `,
    selected: css`
      ${getPrimaryButtonSelectedTokens({
        bg: activeColor(colors.brand.light),
        fg: colors.brand.base,
        border: 'transparent'
      })};
    `,
    disabled: css`
      ${getPrimaryButtonDisabledTokens({
        bg: disabledColor(colors.brand.light, 0.6),
        fg: disabledColor(colors.brand.base, 0.6),
        border: 'transparent'
      })};
      cursor: not-allowed;
    `
  },
  secondary: {
    normal: css`
      ${baseButtonStyles};
      ${getSecondaryButtonTokens({
        bg: {
          default: 'transparent',
          hover: 'transparent',
          active: 'transparent',
          disabled: 'transparent'
        },
        fg: {
          default: colors.brand.base,
          hover: hoverColor(colors.brand.base),
          active: activeColor(colors.brand.base),
          disabled: disabledColor(colors.brand.base, 0.4)
        },
        border: {
          default: colors.brand.base,
          hover: hoverColor(colors.brand.base),
          active: activeColor(colors.brand.base),
          disabled: disabledColor(colors.brand.base, 0.4)
        }
      })};
    `,
    selected: css`
      ${getSecondaryButtonSelectedTokens({
        bg: 'transparent',
        fg: activeColor(colors.brand.base),
        border: activeColor(colors.brand.base)
      })};
    `,
    disabled: css`
      ${getSecondaryButtonDisabledTokens({
        bg: 'transparent',
        fg: disabledColor(colors.brand.base, 0.4),
        border: disabledColor(colors.brand.base, 0.4)
      })};
      cursor: not-allowed;
    `
  },
  tertiary: {
    normal: css`
      ${baseButtonStyles};
      ${getTertiaryButtonTokens({
        bg: {
          default: 'transparent',
          hover: 'transparent',
          active: 'transparent',
          disabled: 'transparent'
        },
        fg: {
          default: colors.brand.base,
          hover: hoverColor(colors.brand.base),
          active: activeColor(colors.brand.base),
          disabled: disabledColor(colors.brand.base, 0.4)
        },
        border: {
          default: 'transparent',
          hover: 'transparent',
          active: 'transparent',
          disabled: 'transparent'
        }
      })};
    `,
    selected: css`
      ${getTertiaryButtonSelectedTokens({
        bg: 'transparent',
        fg: activeColor(colors.brand.base),
        border: 'transparent'
      })};
    `,
    disabled: css`
      ${getTertiaryButtonDisabledTokens({
        bg: 'transparent',
        fg: disabledColor(colors.brand.base, 0.4),
        border: 'transparent'
      })};
      cursor: not-allowed;
    `
  },
  destructive: {
    normal: css`
      ${baseButtonStyles};
      ${getDestructiveButtonTokens({
        bg: {
          default: 'transparent',
          hover: 'transparent',
          active: 'transparent',
          disabled: 'transparent'
        },
        fg: {
          default: colors.error,
          hover: hoverColor(colors.error),
          active: activeColor(colors.error),
          disabled: disabledColor(colors.error, 0.4)
        },
        border: {
          default: colors.error,
          hover: hoverColor(colors.error),
          active: activeColor(colors.error),
          disabled: disabledColor(colors.error, 0.4)
        }
      })};
    `,
    selected: css`
      ${getDestructiveButtonSelectedTokens({
        bg: 'transparent',
        fg: activeColor(colors.error),
        border: activeColor(colors.error)
      })};
    `,
    disabled: css`
      ${getDestructiveButtonDisabledTokens({
        bg: 'transparent',
        fg: disabledColor(colors.error, 0.4),
        border: disabledColor(colors.error, 0.4)
      })};
      cursor: not-allowed;
    `
  },
  small: css`
    ${getSmallButtonTokens({
      fontFamily: fonts.sansSerif,
      fontSize: typeScale[5],
      fontWeight: 700,
      lineHeight: lineHeight.tighter,
      letterSpacing: tracking.brand,
      textTransform: 'none',
      paddingBlock: spacing[3],
      paddingInline: spacing[3],
      gap: spacing[2]
    })};
  `,
  medium: css`
    ${getMediumButtonTokens({
      fontFamily: fonts.sansSerif,
      fontSize: typeScale[5],
      fontWeight: 700,
      lineHeight: 1.25,
      letterSpacing: tracking.brand,
      textTransform: 'none',
      paddingBlock: spacing[4],
      paddingInline: spacing[6],
      gap: spacing[2]
    })};
  `,
  large: css`
    ${getLargeButtonTokens({
      fontFamily: fonts.sansSerif,
      fontSize: typeScale[4],
      fontWeight: 700,
      lineHeight: 1.5,
      letterSpacing: tracking.brand,
      textTransform: 'none',
      paddingBlock: spacing[4],
      paddingInline: spacing[6],
      gap: spacing[2]
    })};
  `
};

const baseFormValues = {
  borderColor: colors.shades.dark,
  borderColorValid: colors.success,
  borderColorInvalid: colors.error,
  selectedToggleColor: colors.brand.base,
  helpTextColor: colors.shades.darker,
  helpTextSize: typeScale[6],
  labelColor: colors.brand.base,
  labelSize: typeScale[5],
  errorMessageTextColor: colors.error,
  infoMessageTextColor: colors.info
};

const nib: ThemeSchema = {
  ...common,
  borderRadius: {
    ...common.borderRadius,
    small: 'var(--themeDimensionBorderRadiusSmall, 4px)',
    standard: 'var(--themeDimensionBorderRadiusStandard, 8px)'
  },
  typeScale,
  fonts,
  colors,
  focus,

  id: 'nib',
  mode: {
    form: 'light' //default
  },

  headings: {
    fontFamily: fonts.serif,
    color: 'currentColor',
    lineHeight: lineHeight.tighter,

    h1: {
      fontFamily: fonts.serif,
      fontSize: typeScale[1],
      fontWeight: 600,
      lineHeight: lineHeight.tighter,
      tracking: tracking.brand,
      uppercase: false
    },
    h2: {
      fontFamily: fonts.sansSerif,
      fontSize: typeScale[2],
      lineHeight: lineHeight.tighter,
      fontWeight: 600,
      tracking: tracking.brand,
      uppercase: false
    },
    h3: {
      fontFamily: fonts.sansSerif,
      fontSize: typeScale[3],
      lineHeight: lineHeight.tighter,
      fontWeight: 600,
      tracking: tracking.brand,
      uppercase: false
    },
    h4: {
      fontFamily: fonts.sansSerif,
      fontSize: typeScale[4],
      lineHeight: lineHeight.tight,
      fontWeight: 700,
      tracking: tracking.brand,
      uppercase: false
    },
    h5: {
      fontFamily: fonts.sansSerif,
      fontSize: typeScale[5],
      lineHeight: lineHeight.tight,
      fontWeight: 700,
      tracking: tracking.brand,
      uppercase: false
    },
    h6: {
      fontFamily: fonts.sansSerif,
      fontSize: typeScale[5],
      lineHeight: lineHeight.tight,
      fontWeight: 700,
      tracking: tracking.brand,
      uppercase: false
    }
  },

  copy: {
    color: 'currentColor',
    fontFamily: fonts.sansSerif,
    fontSize: typeScale[5],
    fontWeight: 400,
    lineHeight: lineHeight.normal,
    smallFontSize: typeScale[6],
    largeFontSize: typeScale[4]
  },

  link: {
    loudColor: colors.brand.base,
    darkColor: colors.shades.darkest,
    lightColor: colors.shades.white,
    destructiveColor: colors.error
  },

  button: {
    small: buttonStyles.small,
    medium: buttonStyles.medium,
    large: buttonStyles.large,
    primary: buttonStyles.primary,
    secondary: buttonStyles.secondary,
    tertiary: buttonStyles.tertiary,
    destructive: buttonStyles.destructive
  },

  forms: {
    light: {
      ...baseFormValues,
      backgroundColor: rebrandColors.sunsetPink[20],
      inputBackgroundColor: colors.shades.white,
      boxShadow: `0 4px 8px 0 ${colors.shades.lighter}`
    },
    white: {
      ...baseFormValues,
      backgroundColor: colors.shades.white,
      inputBackgroundColor: colors.shades.lightest,
      boxShadow: 'none'
    }
  },

  smallFooter: {
    backgroundColor: colors.brand.base,
    textColor: colors.shades.white,
    linkColor: colors.shades.white
  },

  panel: {
    loud: {
      backgroundColor: colors.brand.base,
      textColor: colors.shades.white
    },
    muted: {
      backgroundColor: colors.shades.lighter,
      textColor: colors.shades.black
    },
    light: {
      backgroundColor: colors.shades.lightest,
      textColor: colors.shades.black
    },
    dark: {
      backgroundColor: colors.shades.darkest,
      textColor: colors.shades.white
    },
    white: {
      backgroundColor: colors.shades.white,
      textColor: colors.shades.black
    }
  }
};

export default nib;
