import React from 'react';
import styled from 'styled-components';
import {SearchSystemIcon} from '@nib/icons';
import {Box} from '@nib/layout';
import Fc from '@nib-components/form-control';
import Textbox from '@nib-components/textbox';

const SearchIconWrapper = styled.span`
  position: absolute;
  inset-block-end: 0;
  inset-inline-end: 0;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const SearchTextbox = (props: any) => (
  <Box position="relative">
    <Textbox {...props} />
    <SearchIconWrapper>
      <SearchSystemIcon size={20} />
    </SearchIconWrapper>
  </Box>
);

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const SearchBox = (props: any) => {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.onSubmit && props.onSubmit(event);
  };
  return (
    <Box padding={4} position="relative" as="form" onSubmit={handleSubmit}>
      <Fc label="Filter Table">
        <SearchTextbox placeholder="Filter table" {...props} />
      </Fc>
    </Box>
  );
};
