/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useContext} from 'react';
import styled, {css, ThemeContext} from 'styled-components';
import {ModeType, getActiveMode} from '@nib-components/theme';
import {m, p} from '../spacing';
import {isList, ResponsiveMarginSpaceProp, ResponsiveSpaceProp} from '../utils';

import {margins} from './lib/margin';
import {paddings} from './lib/padding';
import {display, DisplayType} from './lib/display';
import {width, maxWidth, height, maxHeight, DimensionsType, minWidth, minHeight} from './lib/dimensions';
import {gridColumn, gridRow, type GridColumnType, type GridRowType} from './lib/grid';
import {alignItems, justifyContent, flexDirection, flexWrap, flexGrow, flexShrink, JustifyContentType, FlexDirectionType, FlexWrapType, FlexGrowShrinkType, alignSelf, FlexAlignType} from './lib/flex';
import {textAlign, TextAlignType} from './lib/textAlign';
import {background, color, responsiveFgColorValues, responsiveBgColorValues, responsiveBorderColorValues} from './lib/colors';
import {borderRadius, borderRadiusValues} from './lib/border-radius';
import {boxShadow, boxShadowValues} from './lib/box-shadow';
import {columnGap, gap, rowGap} from './lib/gap';
import {LineHeightType, lineHeight} from './lib/line-height';
import {position, PositionType, ElementPositionType} from './lib/position';
import {overflow, OverflowType} from './lib/overflow';
import {border, BorderStyleType, BorderWidthType} from './lib/border';
import {zIndex, ZIndexType} from './lib/z-index';

export interface BoxProps extends Omit<React.HTMLAttributes<HTMLElement>, 'color'> {
  padding?: ResponsiveSpaceProp;
  paddingVertical?: ResponsiveSpaceProp;
  paddingHorizontal?: ResponsiveSpaceProp;
  paddingTop?: ResponsiveSpaceProp;
  paddingBottom?: ResponsiveSpaceProp;
  paddingLeft?: ResponsiveSpaceProp;
  paddingRight?: ResponsiveSpaceProp;
  paddingBlockStart?: ResponsiveSpaceProp;
  paddingInlineEnd?: ResponsiveSpaceProp;
  paddingBlockEnd?: ResponsiveSpaceProp;
  paddingInlineStart?: ResponsiveSpaceProp;
  paddingBlock?: ResponsiveSpaceProp;
  paddingInline?: ResponsiveSpaceProp;
  margin?: ResponsiveMarginSpaceProp;
  marginVertical?: ResponsiveMarginSpaceProp;
  marginHorizontal?: ResponsiveMarginSpaceProp;
  marginTop?: ResponsiveMarginSpaceProp;
  marginBottom?: ResponsiveMarginSpaceProp;
  marginLeft?: ResponsiveMarginSpaceProp;
  marginRight?: ResponsiveMarginSpaceProp;
  marginBlockStart?: ResponsiveMarginSpaceProp;
  marginInlineEnd?: ResponsiveMarginSpaceProp;
  marginBlockEnd?: ResponsiveMarginSpaceProp;
  marginInlineStart?: ResponsiveMarginSpaceProp;
  marginBlock?: ResponsiveMarginSpaceProp;
  marginInline?: ResponsiveMarginSpaceProp;
  display?: DisplayType;
  width?: DimensionsType;
  minWidth?: DimensionsType;
  maxWidth?: DimensionsType;
  height?: DimensionsType;
  minHeight?: DimensionsType;
  maxHeight?: DimensionsType;
  alignItems?: FlexAlignType;
  alignSelf?: FlexAlignType;
  justifyContent?: JustifyContentType;
  flexDirection?: FlexDirectionType;
  flexWrap?: FlexWrapType;
  flexGrow?: FlexGrowShrinkType;
  flexShrink?: FlexGrowShrinkType;
  gridColumn?: GridColumnType;
  gridRow?: GridRowType;
  textAlign?: TextAlignType;
  background?: responsiveBgColorValues;
  color?: responsiveFgColorValues;
  borderRadius?: borderRadiusValues;
  boxShadow?: boxShadowValues;
  gap?: ResponsiveSpaceProp;
  columnGap?: ResponsiveSpaceProp;
  rowGap?: ResponsiveSpaceProp;
  lineHeight?: LineHeightType;
  position?: PositionType;
  overflow?: OverflowType;
  borderColor?: responsiveBorderColorValues;
  borderWidth?: DimensionsType | BorderWidthType;
  borderStyle?: BorderStyleType;
  zIndex?: ZIndexType;
  isolate?: boolean;
  top?: ElementPositionType;
  right?: ElementPositionType;
  bottom?: ElementPositionType;
  left?: ElementPositionType;
  inset?: ElementPositionType;
  mode?: ModeType;
  as?: string | React.ElementType;
  children?: React.ReactNode;
}

export const Box = styled(
  ({
    padding,
    paddingVertical,
    paddingHorizontal,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingBlockStart,
    paddingInlineEnd,
    paddingBlockEnd,
    paddingInlineStart,
    paddingBlock,
    paddingInline,
    margin,
    marginVertical,
    marginHorizontal,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    marginBlockStart,
    marginInlineEnd,
    marginBlockEnd,
    marginInlineStart,
    marginBlock,
    marginInline,
    display,
    width,
    minWidth,
    maxWidth,
    height,
    minHeight,
    maxHeight,
    alignItems,
    alignSelf,
    justifyContent,
    flexDirection,
    flexWrap,
    flexGrow,
    flexShrink,
    gridColumn,
    gridRow,
    textAlign,
    background,
    color,
    borderRadius,
    boxShadow,
    gap,
    columnGap,
    rowGap,
    lineHeight,
    position,
    overflow,
    zIndex,
    isolate,
    borderStyle,
    borderColor,
    borderWidth,
    mode,
    as,
    ...rest
  }: BoxProps) => {
    const theme = useContext(ThemeContext);
    return <div data-mesh-component="BOX" data-mode={mode && getActiveMode(theme.id, mode)} {...rest} />;
  }
)<BoxProps>`
  box-sizing: border-box;

  /* If box is set to be a ul or ol we need to reset the default styling */
  ${({as}) =>
    as &&
    isList(as) &&
    css`
      ${m(0)};
      ${p(0)};
      list-style-type: none;
    `}

  ${({isolate}) =>
    isolate &&
    css`
      isolation: isolate;
    `}

  ${props => margins()};
  ${props => paddings()};

  ${props => display()};

  ${props => width()};
  ${props => minWidth()};
  ${props => maxWidth()};
  ${props => height()};
  ${props => minHeight()};
  ${props => maxHeight()};

  ${props => alignItems()};
  ${props => alignSelf()};
  ${props => justifyContent()};
  ${props => flexDirection()};
  ${props => flexWrap()};
  ${props => flexGrow()};
  ${props => flexShrink()};

  ${props => gridColumn()};
  ${props => gridRow()};

  ${props => textAlign()};

  ${props => background()};
  ${props => color()};
  ${props => borderRadius()};
  ${props => boxShadow()};

  ${props => gap()};
  ${props => columnGap()};
  ${props => rowGap()};
  ${props => lineHeight()};
  ${props => position()};
  ${props => overflow()};
  ${props => border()};
  ${props => zIndex()};
`;

Box.displayName = 'Box';
