import React, {useState, useEffect} from 'react';
import TokenSwatch from '../index';
import {Box, SplitSection} from '@nib/layout';
import FormControl from '@nib-components/form-control';
import Select from '@nib-components/select';
import {getTokens} from './utils';
import * as Tokens from '@nib-group/design-tokens/build/web';
import styled from 'styled-components';

export const PrimitivePanel = () => {
  const [selectedBrand, setSelectedBrand] = useState('nib');
  const [tokenJson, setTokenJson] = useState({});

  useEffect(() => {
    setTokenJson(getTokens(selectedBrand, 'primitive'));
  }, [selectedBrand]);

  const getBrandOptions = () => {
    const brands = Object.keys(Tokens.validBrandModes);

    let brandArray = [];
    brands.map(brand => {
      brandArray.push({label: brand, value: brand});
    });
    return brandArray;
  };

  const Wrapper = styled.div`
    table {
      width: 100%;
    }
    table,
    th,
    td {
      border: 1px solid lightgrey;
      border-collapse: collapse;
    }
    th,
    td {
      padding: 8px;
    }
  `;

  return (
    <Wrapper>
      <Box background="lightest" position="sticky" top="0">
        <SplitSection collapseBelow="md" padding={5}>
          <SplitSection.Item>
            <Box>
              <FormControl label="Brand" name="brand">
                <Select options={getBrandOptions()} value={selectedBrand} onChange={e => setSelectedBrand(e.target.value)} />
              </FormControl>
            </Box>
          </SplitSection.Item>
          <SplitSection.Item></SplitSection.Item>
        </SplitSection>
      </Box>
      <Box marginTop={5} overflow="auto" height="50vh">
        <Box marginVertical={5}>
          <table>
            <thead>
              <tr>
                <th>Token</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(tokenJson)
                .sort()
                .map(innerAttr => {
                  if (innerAttr.includes('primitive')) {
                    return (
                      <tr>
                        <td>
                          {CSS.supports('color', tokenJson[innerAttr]) ? (
                            <TokenSwatch
                              type="color"
                              value={tokenJson[innerAttr]}
                              name={innerAttr
                                .replace(/([a-z])([A-Z])/g, '$1.$2')
                                .replace(/(\d+)/, '.$1 ')
                                .toLowerCase()}
                            />
                          ) : (
                            <TokenSwatch
                              type="dimension"
                              name={innerAttr
                                .replace(/([a-z])([A-Z])/g, '$1.$2')
                                .replace(/(\d+)/, '.$1 ')
                                .toLowerCase()}
                            />
                          )}
                        </td>
                        <td>{tokenJson[innerAttr]}</td>
                      </tr>
                    );
                  }
                })}
            </tbody>
          </table>
        </Box>
      </Box>
    </Wrapper>
  );
};
