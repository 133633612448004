import React from 'react';
import {Margin} from '@nib/layout';
import {InclusionType} from '../Inclusion';
import InclusionButton from '../InclusionButton';
import Heading, {componentValues} from '@nib-components/heading';

import {InclusionListProps, ServiceComponentGroupMapping, InclusionListUl} from '../InclusionList';

export interface InclusionListNamedGroupProps extends InclusionListProps {
  inclusions: InclusionType[];
  onInclusionClick: (inclusion: InclusionType) => void;
  serviceComponentGroupStructure: ServiceComponentGroupMapping;
}

export interface NamedGroup extends InclusionListNamedGroupProps {
  groupName: string;
  titleComponent?: componentValues;
}

export const InclusionListNamedGroup = (props: NamedGroup) => {
  const {serviceComponentGroupStructure, onInclusionClick, groupName, titleComponent, ...otherProps} = props;

  return (
    <React.Fragment>
      <Margin top={6} bottom={4}>
        <Heading component={titleComponent} size={6}>
          {groupName}
        </Heading>
      </Margin>

      <InclusionListUl {...otherProps}>
        {(serviceComponentGroupStructure as ServiceComponentGroupMapping)[groupName].map((inclusion, index) => {
          return <InclusionButton key={`li-${inclusion.id}-${index}`} inclusion={inclusion} index={index} onInclusionClick={onInclusionClick} />;
        })}
      </InclusionListUl>
    </React.Fragment>
  );
};

const InclusionListNamedGroups = (props: InclusionListNamedGroupProps) => {
  const {serviceComponentGroupStructure} = props;
  return (
    <React.Fragment>
      {Object.keys(serviceComponentGroupStructure).map(groupName => (
        <InclusionListNamedGroup groupName={groupName} {...props} offset={0} />
      ))}
    </React.Fragment>
  );
};

export default InclusionListNamedGroups;
