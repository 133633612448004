import React from 'react';
import styled from 'styled-components';
import Link from '@nib-components/link';
import {copyFontSizeSmall} from '@nib-components/theme';
import {Inline, Container, breakpoint, mr} from '@nib/layout';

const RibbonWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  color: inherit;
`;

const RibbonLinks = styled.div`
  ${mr(5)};

  ${breakpoint('xl', 'xxl')`
    ${mr(3)};
  `}
`;

export const RibbonLink = styled(Link)`
  &&& {
    color: inherit;
    font-size: ${copyFontSizeSmall};
  }
`;

export interface RibbonProps {
  data: {
    id: string;
    title: string;
    url: string;
  }[];
  [key: string]: unknown;
}

const Ribbon: React.FC<RibbonProps> = ({data, ...otherProps}) => {
  if (!data || data.length === 0) {
    return null;
  }

  return (
    <RibbonWrapper {...otherProps}>
      <Container>
        <RibbonLinks>
          <Inline space={3} align="right">
            {data.map((link, i) => (
              <RibbonLink id={`${link.id}-desktop`} key={`item-${i}-${link.id}-desktop`} href={link.url} title={link.title}>
                {link.title}
              </RibbonLink>
            ))}
          </Inline>
        </RibbonLinks>
      </Container>
    </RibbonWrapper>
  );
};

export default Ribbon;
