import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {py} from '@nib/layout';
import matchSorter from 'match-sorter';
import {colorDarker} from '@nib-components/theme';
import Item from '../Item';

const NoMatchesEl = styled(Item)`
  ${py(4)};
  color: var(--themeColorFg, ${colorDarker});
  cursor: default;
`;

export const NoMatches = ({string}) => <NoMatchesEl>No results match &ldquo;{string}&rdquo;.</NoMatchesEl>;
NoMatches.propTypes = {string: PropTypes.string};

export const Loading = ({string}) => <NoMatchesEl>Searching for &ldquo;{string}&rdquo;.</NoMatchesEl>;
Loading.propTypes = {string: PropTypes.string};

export const NonItemMessage = ({inputValue, loading = false}) => {
  return <React.Fragment>{loading ? <Loading string={inputValue} /> : <NoMatches string={inputValue} />}</React.Fragment>;
};

NonItemMessage.propTypes = {
  inputValue: PropTypes.string,
  loading: PropTypes.bool
};

const OptionsList = ({options, inputValue, getItemProps, highlightedIndex, selectedItem, maxOptionsDisplayed = 5, keys = ['value', 'text'], loading, skipSorting}) => {
  const listOptions = skipSorting ? options : matchSorter(options, inputValue, {keys});

  return (
    <React.Fragment>
      {listOptions.length === 0 && inputValue.length > 3 ? (
        <NonItemMessage inputValue={inputValue} loading={loading} />
      ) : (
        listOptions.slice(0, maxOptionsDisplayed - 1).map((item, index) => (
          <Item
            key={`option-${item.text}-${index}`}
            {...getItemProps({
              item,
              index,
              isActive: highlightedIndex === index,
              isSelected: selectedItem === item
            })}
          >
            {item.text}
          </Item>
        ))
      )}
    </React.Fragment>
  );
};

OptionsList.propTypes = {
  maxOptionsDisplayed: PropTypes.number,
  keys: PropTypes.arrayOf(PropTypes.string),
  inputValue: PropTypes.string,
  getItemProps: PropTypes.func,
  highlightedIndex: PropTypes.number,
  selectedItem: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string
    })
  ).isRequired,
  loading: PropTypes.bool,
  skipSorting: PropTypes.bool
};

export default OptionsList;
