import React from 'react';
import styled, {css} from 'styled-components';
import {buttonPrimaryStyles, ThemeSchema} from '@nib-components/theme';
import BaseButton, {BaseButtonProps, sizeStyling, fullWidthStyling} from '../BaseButton';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PrimaryButton = styled(({theme, ...otherProps}: BaseButtonProps & {theme: ThemeSchema}) => <BaseButton data-mesh-component="BUTTON-PRIMARY" {...otherProps} />)`
  ${props => buttonPrimaryStyles(props).normal}

  ${props =>
    props.selected &&
    css`
      ${buttonPrimaryStyles(props).selected};
    `}

  ${props =>
    'disabled' in props &&
    props.disabled &&
    css`
      ${buttonPrimaryStyles(props).disabled};
    `}

  ${props =>
    props &&
    css`
      &:disabled {
        ${buttonPrimaryStyles(props).disabled};
      }
    `}
  
  ${sizeStyling}
  ${fullWidthStyling}
`;

PrimaryButton.displayName = 'PrimaryButton';
