import React, {useState, useEffect} from 'react';
import TokenSwatch from '../index';
import {Box} from '@nib/layout';
import {getTokens, getFormattedTokenName} from './utils';
import styled from 'styled-components';

export const CommonPanel = () => {
  const [tokenJson, setTokenJson] = useState({});

  useEffect(() => {
    setTokenJson(getTokens('nib', 'default'));
  }, []);

  const Wrapper = styled.div`
    table {
      width: 100%;
    }
    table,
    th,
    td {
      border: 1px solid lightgrey;
      border-collapse: collapse;
    }
    th,
    td {
      padding: 8px;
    }
  `;

  return (
    <Wrapper>
      <Box marginTop={5} overflow="auto" height="50vh">
        <Box marginVertical={5}>
          <table>
            <thead>
              <tr>
                <th>Token</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(tokenJson)
                .sort()
                .map(innerAttr => {
                  if (innerAttr.includes('common')) {
                    return (
                      <tr>
                        <td>
                          {CSS.supports('color', tokenJson[innerAttr]) ? (
                            <TokenSwatch type="color" value={tokenJson[innerAttr]} name={getFormattedTokenName(innerAttr)} />
                          ) : (
                            <TokenSwatch type="dimension" name={getFormattedTokenName(innerAttr)} />
                          )}
                        </td>
                        <td>{tokenJson[innerAttr]}</td>
                      </tr>
                    );
                  }
                })}
            </tbody>
          </table>
        </Box>
      </Box>
    </Wrapper>
  );
};
