import styled, {css} from 'styled-components';
import {colorLight, colorBrandLight, colorBrandBase} from '@nib-components/theme';

export const DragDropBox = styled.div<{midDrag?: boolean}>`
  position: relative;
  text-align: center;
  background-color: var(--commonColorTransparent, transparent);
  border: 2px dashed var(--themeColorBorderMuted, ${colorLight});
  transition: background-color 0.4s;
  ${props =>
    props.midDrag
      ? css`
          &::after {
            content: 'Drop your file to upload';
            font-weight: 700;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            inset-inline-start: -2px;
            inset-block-start: -2px;
            height: 100%;
            width: 100%;
            background-color: var(--themeColorBgSurface, ${colorBrandLight});
            border: 2px dashed var(--themeColorBorderSelected, ${colorBrandBase});
          }
        `
      : ''};
`;
