import React from 'react';
import PropTypes from 'prop-types';
import Expander from '@nib-components/expander';

const ExpandedSection = props => (
  <div>
    <button onClick={props.onToggleExpanded}>
      <strong>{props.title}</strong>
    </button>
    <Expander expanded={props.expanded}>{props.children}</Expander>
  </div>
);

ExpandedSection.displayName = 'ExpandedSection';
ExpandedSection.propTypes = {
  title: PropTypes.string,
  onToggleExpanded: PropTypes.func,
  expanded: PropTypes.bool,
  children: PropTypes.node
};

export default ExpandedSection;
