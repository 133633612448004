import React from 'react';
import styled, {css} from 'styled-components';
import {map} from '@nib/layout';
import {ResponsiveHeightProp} from '../constants';

export const BaseEl: React.FC<MinHeightProps> = props => {
  const {
    component = 'div',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    height,
    ...otherProps
  } = props;
  const Component: any = component; // eslint-disable-line  @typescript-eslint/no-explicit-any
  return <Component {...otherProps} />;
};

export interface MinHeightProps {
  component?: any; // eslint-disable-line  @typescript-eslint/no-explicit-any
  height?: ResponsiveHeightProp;
  [key: string]: unknown; // other props
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MinHeight = styled(BaseEl).attrs(({theme, ...props}) => ({component: 'div', ...props}))<MinHeightProps>`
  box-sizing: border-box;

  ${({height}) =>
    map(height, (val?: number) => {
      if (height && val !== undefined) {
        return css`
          min-height: ${val}px;
        `;
      }
    })}
`;

export default MinHeight;
