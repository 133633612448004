import React from 'react';
import styled from 'styled-components';
import {colorLightest, colorBrandBase} from '@nib-components/theme';
import {ChevronLeftSystemIcon, ChevronRightSystemIcon, LastSystemIcon, FirstSystemIcon} from '@nib/icons';
import Select from '@nib-components/select';
import Textbox from '@nib-components/textbox';
import {InvisibleButton} from './index';
import {Box, Inline, Padding, breakpoint, p, pr} from '@nib/layout';

const COLLAPSE_BREAKPOINT = 'md';

const PaginationBar = styled.div`
  background-color: var(--themeColorBgSurface, ${colorLightest});
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  ${breakpoint(COLLAPSE_BREAKPOINT)`
    justify-content: flex-start;
  `}
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  ${breakpoint(COLLAPSE_BREAKPOINT)`
    width: auto;
  `}
`;

const PageControls = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  ${breakpoint(COLLAPSE_BREAKPOINT)`
    width: auto;
  `}
`;

export interface PaginationProps {
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageOptions: number[];
  pageSizeOptions: number[];
  pageCount: number;
  gotoPage: (n: number) => void;
  nextPage: () => void;
  previousPage: () => void;
  setPageSize: (n: number) => void;
  pageIndex: number;
  pageSize: number;
}

const Button = styled(InvisibleButton)`
  ${p({xs: 3, sm: 4, lg: 5})};
  font-size: 0;

  :hover:not([disabled]) {
    color: var(--themeColorFgLinkHover, ${colorBrandBase});
  }
`;

const CustomSelect = styled(Select)`
  width: 100%;

  ${breakpoint(COLLAPSE_BREAKPOINT)`
    width: auto;
  `}
`;

const SelectWrapper = styled.div`
  width: 100%;
  ${p(4)};

  ${breakpoint(COLLAPSE_BREAKPOINT)`
    width: auto;
  `}
`;

const PageTextbox = styled(Textbox)`
  display: inline-block;
  width: 3rem;
  ${pr(2)};
`;

const PageNumber = styled.span`
  font-weight: 700;
  font-variant-numeric: tabular-nums;
`;

export function Pagination({canPreviousPage, canNextPage, pageSizeOptions = [10, 20, 30, 40, 50], pageCount, gotoPage, nextPage, previousPage, setPageSize, pageIndex, pageSize}: PaginationProps) {
  const pageSizeOptionsForSelect = pageSizeOptions.map(option => ({value: option.toString(), label: `Show ${option}`}));
  return (
    <PaginationBar>
      <ButtonGroup>
        <Button aria-label="Go to first page" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          <FirstSystemIcon size="xs" />
        </Button>
        <Button aria-label="Go to previous page" onClick={() => previousPage()} disabled={!canPreviousPage}>
          <ChevronLeftSystemIcon size="xs" />
        </Button>
        <Padding inline all={4}>
          Page <PageNumber>{pageIndex + 1}</PageNumber> of <PageNumber>{pageCount}</PageNumber>
        </Padding>
        <Button aria-label="Go to next page" onClick={() => nextPage()} disabled={!canNextPage}>
          <ChevronRightSystemIcon size="xs" />
        </Button>
        <Button aria-label="Go to last page" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          <LastSystemIcon size="xs" />
        </Button>
      </ButtonGroup>

      <PageControls>
        <Box paddingVertical={2} paddingHorizontal={4} display="flex" alignItems="center">
          <Inline space={4} verticalAlign="center">
            <div>Go to page:</div>
            <PageTextbox
              aria-label="Go to page number"
              type="number"
              defaultValue={pageIndex + 1}
              isCompact
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
            />
          </Inline>
        </Box>

        <SelectWrapper>
          <CustomSelect
            value={pageSize}
            aria-label="Select how many rows to display"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPageSize(Number(e.target.value));
            }}
            isCompact
            options={pageSizeOptionsForSelect}
          />
        </SelectWrapper>
      </PageControls>
    </PaginationBar>
  );
}
