import React, {useId} from 'react';
import styled from 'styled-components';
import {colorWhite} from '@nib-components/theme';
import {SizeMap, LogoComponent} from '../../types';

export const WordMarkPath = styled.path<{invert?: boolean}>`
  fill: ${props => (props.invert ? colorWhite : '#004145')};
`;

// small === min-height from brand guidelines
const sizes: SizeMap = {
  small: {
    width: '124px',
    height: '36px'
  },
  header: {
    width: '166px',
    height: '48px'
  },
  medium: {
    width: '167px',
    height: '48px'
  },
  large: {
    width: '221px',
    height: '64px'
  }
};

const SuncorpLogo: LogoComponent = props => {
  const {invert = false, size = 'small', ...otherProps} = props;

  const idBase = useId();
  // Because this logo uses gradients, ids are unavoidable.
  const uniqueId1 = `${idBase}-gradient-1`;
  const uniqueId2 = `${idBase}-gradient-2`;
  const uniqueId3 = `${idBase}-gradient-3`;
  const uniqueId4 = `${idBase}-gradient-4`;
  const uniqueId5 = `${idBase}-gradient-5`;
  const uniqueId6 = `${idBase}-gradient-6`;
  const uniqueId7 = `${idBase}-gradient-7`;
  const uniqueId8 = `${idBase}-gradient-8`;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 703.4 204.1"
      enableBackground="new 0 0 703.4 204.1"
      width={sizes[size].width}
      height={sizes[size].height}
      role="img"
      {...otherProps}
    >
      <defs>
        <path id={uniqueId1} d="M.3.9h703.4V205H.3z" />
      </defs>
      <clipPath id={uniqueId2}>
        <use xlinkHref={`#${uniqueId1}`} overflow="visible" />
      </clipPath>
      <WordMarkPath
        clipPath={`url(#${uniqueId2})`}
        invert={invert}
        d="M2.4 85.3c0 11.8 6.9 17.1 21.4 20.3l11.1 2.4c13 2.9 15.6 5.9 15.6 12.3 0 7.6-6.7 12.4-18.7 12.4-12.6 0-19.1-5.4-19.8-16.5H.3c0 17.1 12.2 26.8 31.2 26.8 20.9 0 31-10.5 31-24.2 0-12.3-7.3-18.2-19.7-20.9l-11.6-2.7c-13.3-2.9-17.1-4.6-17.1-11.4 0-6.6 5-11.8 16.1-11.8 13.3 0 17.3 6 18.3 14.2h11.6c-.1-16.7-13-24.8-29.4-24.8-19.1 0-28.3 11-28.3 23.9m65.4-21.8v46.2c0 23.6 11.2 33.2 30.7 33.2 19.8 0 30.7-9.8 30.7-33.2V63.5h-12.7v47.7c0 15.2-6.5 21-18.6 21-10.9 0-17.4-6.2-17.4-21V63.5H67.8zm69.8 0v77.3h11.6V80.7l37 60.1h13.2V63.5h-11.7v59.7l-36.3-59.7h-13.8zm136.5 24C272.8 72 260.2 61.7 242 61.7c-22.5 0-36.1 16.4-36.1 40.2 0 27.7 15.2 41 35.1 41 20.1 0 31.9-13.6 33.5-29.7h-12.2c-2.5 12.3-9.3 18.9-20.6 18.9-12.8 0-23.1-8.5-23.1-29 0-21.2 9.6-30.8 23.1-30.8 11.8 0 17.9 5.7 20.1 15.2h12.3zm5.4 14.8c0 26.4 15.2 40.7 37.2 40.7 23.5 0 37.7-16.7 37.7-41.9 0-27.9-16.4-39.9-37.6-39.9-21.2 0-37.3 14.4-37.3 41.1m12.6.5c0-19.9 10.3-30.7 25.2-30.7 13.7 0 24.7 9.8 24.7 29.2 0 20.6-10 30.9-24.3 30.9-15.8-.1-25.6-10-25.6-29.4m81.1-28.9h23.3c9.1 0 13.9 3.7 13.9 12.2 0 8.8-4.7 12.4-15 12.4h-22.2V73.9zm-12.3-10.4v77.3h12.3v-32.2h21.4c10.2 0 13.8 3.1 14.1 11.8l.5 10.9c.2 4.6.5 7.3 1.4 9.5h13.2c-1.7-3.1-2.4-6.5-2.6-13.2l-.2-8.6c-.2-8.9-2.5-12.8-9.6-15.8 7.1-3.2 11.3-9.5 11.3-18.4 0-12.8-7.9-21.2-25.4-21.2h-36.4zm69.2 0v77.3h12.4v-31.1h21.6c15.9 0 24.2-8.9 24.2-23.3 0-15-9.3-22.9-23.8-22.9h-34.4zm12.4 10.4h18.9c10.1 0 14.5 4.2 14.5 12.6 0 8.6-4.7 12.8-14.5 12.8h-18.9V73.9z"
      />
      <g>
        <defs>
          <path
            id={uniqueId3}
            d="M550.6 102.9c0-28.2 22.8-51 51-51s51 22.8 51 51-22.8 51-51 51c-28.1.1-51-22.8-51-51m-51 0c0 56.4 45.7 102.1 102.1 102.1 56.2 0 101.7-45.4 102.1-101.5v-1.2C703.4 46.2 657.8.9 601.7.9c-56.4 0-102.1 45.7-102.1 102"
          />
        </defs>
        <clipPath id={uniqueId5}>
          <use xlinkHref={`#${uniqueId3}`} overflow="visible" />
        </clipPath>
        <defs>
          <filter id={uniqueId4} filterUnits="userSpaceOnUse" x="499.6" y=".9" width="204.1" height="204.1">
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
          </filter>
        </defs>
        <mask maskUnits="userSpaceOnUse" x="499.6" y=".9" width="204.1" height="204.1" id={uniqueId7}>
          <g filter={`url(#${uniqueId4})`}>
            <radialGradient id={uniqueId6} cx="-611.843" cy="641.765" r="1" gradientTransform="matrix(102.0586 0 0 -102.0586 63045.508 65600.547)" gradientUnits="userSpaceOnUse">
              <stop offset="0" stopColor="#fff" />
              <stop offset=".493" stopColor="#fff" />
              <stop offset=".579" stopColor="#c6c6c6" />
              <stop offset=".694" stopColor="gray" />
              <stop offset=".798" stopColor="#494949" />
              <stop offset=".887" stopColor="#212121" />
              <stop offset=".957" stopColor="#090909" />
              <stop offset="1" />
            </radialGradient>
            <path clipPath={`url(#${uniqueId5})`} fill={`url(#${uniqueId6})`} d="M499.6.9h204.1V205H499.6z" />
          </g>
        </mask>
        <radialGradient id={uniqueId8} cx="-611.843" cy="641.765" r="1" gradientTransform="matrix(102.0586 0 0 -102.0586 63045.512 65600.547)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#ffce00" />
          <stop offset=".493" stopColor="#ffce00" />
          <stop offset="1" stopColor="#ffce00" />
        </radialGradient>
        <path clipPath={`url(#${uniqueId5})`} mask={`url(#${uniqueId7})`} fill={`url(#${uniqueId8})`} d="M499.6.9h204.1V205H499.6z" />
      </g>
    </svg>
  );
};

SuncorpLogo.displayName = 'SuncorpLogo';

export default SuncorpLogo;
